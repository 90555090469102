<template>
  <div class="input-regist">
    <input :type="type"
           :placeholder="placeholder"
           v-model="inputValue"
           @focusin="focusIn()"
           @focusout="focusOut()"
           :disabled="disable"
           :class="{'fs-20' : isBig, 'weight-bold' : isBig, 'pr-40':inputValue !== '' && !disable}"
           :inputmode="inputMode"
           :maxlength="maxLength > 0 ? maxLength : ''"
    />

    <!-- TODO: 입력창 활성화 될 때만 close-circle 나오게 처리 해주세요 -->
    <button v-show="inputValue !== '' && !disable" type="button" class="close-circle" @click="deleteAll()"></button>
  </div>
</template>

<script>
export default {
  name: "MTRInput",
  props: {
    model: {
      required: true
    },
    placeholder: {
      required: false
    },
    type: {
      required: true
    },
    disable: {
      required: false
    },
    isBig: {
      required: false,
      type: Boolean,
      default: false,
    },
    inputMode: {
      required: false,
      type: String,
      default: '',
    },
    maxLength: {
      required: false,
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      inputValue: '',
      isFocus: false,
    }
  },
  created() {
    this.inputValue = this.model
  },
  methods: {
    deleteAll() {
      this.inputValue = ''
      this.$emit('update', '')
    },
    focusIn() {
      this.isFocus = true
    },
    focusOut() {
      this.isFocus = false
    },
  },
  watch: {
    inputValue() {
      if (this.inputMode === 'decimal') {
        if (/\.([0-9]*)\./.test(this.inputValue)) {
          this.inputValue = /([0-9]*)\.([0-9]*)/.exec(this.inputValue)[0]
        } else if (/^\./.test(this.inputValue)) {
          this.inputValue = ''
        }
      } else if (this.inputMode === 'numeric'){
        if (!(/^[0-9]*$/.test(this.inputValue))) {
          this.inputValue = /([0-9]*)/.exec(this.inputValue)[0]
        }
      }

      this.$emit('update', this.inputValue)
    },
    model() {
      this.inputValue = this.model
    }
  }
}
</script>

<style scoped>
</style>
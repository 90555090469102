import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
  // let tokenMiddle = [
  // ]
  //
  // if(tokenMiddle.indexOf(to.name) !== -1) {
  //   let token = sessionStorage.getItem('accessToken')
  //   if(!token) {
  //     next({ name: 'BeforeLogin' })
  //   }
  // }

  next()
})

Vue.config.productionTip = false

/**
 * https://github.com/jkchao/vue-loading
 */
import VueLoading from "vue-loading-template";
Vue.use(VueLoading)

import loading from 'vuejs-loading-screen'
Vue.use(loading, {
  bg: 'rgba(234,234,234,0.4)',
  icon: 'fa fa-refresh',
  size: 3,
  icon_color: '#AE73DD',

})

import api from './commonjs/api';
Vue.use(api)

import api_reusable from "@/commonjs/api_reusable";
Vue.use(api_reusable)

import api_shop from "@/commonjs/api_shop";
Vue.use(api_shop)

import api_quickorder from "@/commonjs/api_quickorder";
Vue.use(api_quickorder)


import common from "@/commonjs/common";
Vue.use(common)

import map from "@/commonjs/map";
Vue.use(map)

import event from './commonjs/event';
Vue.use(event)

import vueMoment from 'vue-moment'
Vue.use(vueMoment)

import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
Vue.component('datePicker', datePicker)

import agreement from './commonjs/agreement';
Vue.use(agreement)



new Vue({
  render: h => h(App),
  router,
  store: store
}).$mount('#app')

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <h1><img src="/image/header_logo.png" alt="아미스"></h1>
          <button type="button" class="nav-btn right" @click="$router.replace({name : 'Main'})"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <!-- TODO: 높이 값에 따라 클래스 type이 다릅니다. -->
      <div class="guide_alram type1">
        <div class="alram_inner">
          <figure>
            <div class="img_150">
              <img src="/image/symbol_150.png" alt="결제가 완료되었습니다.">
            </div>
            <figcaption>
              <p class="ar_title">결제가 실패하였습니다.</p>
              <p class="ar_desc" v-if="nonOrderableProductsIdxs">주문할수 없는 상품이 있습니다.</p>
              <p class="ar_desc2" v-if="nonOrderableProductsIdxs">{{ nonOrderableProductName }}</p>
              <p class="ar_desc" v-if="!nonOrderableProductsIdxs">다시 한번 시도해 주세요.</p>
            </figcaption>
          </figure>
        </div>
      </div>
      <div class="bottom_wrap">
        <div class="alram_inner">
          <div class="button-group">
            <button class="outline_l_btn btn-line-black" @click="$router.replace({name : 'OrderPayment'})">다시 결제</button>
            <button class="outline_l_btn btn-black" @click="$router.replace({name : 'Order'})">주문</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentFailed",
  props : {
    nonOrderableProductsIdxs : Array
  },

  data() {
    return {
      nonOrderableProductName : ''
    }
  },

  mounted() {
    console.log('idxs : ' + JSON.stringify(this.nonOrderableProductsIdxs))
    let cart = this.$store.getters.getCartList
    let totalQuantity = 0
    if (this.nonOrderableProductsIdxs) {
      let nonOrderableProducts = []
      this.nonOrderableProductsIdxs.forEach(o => {
        nonOrderableProducts.push(cart[o])
        totalQuantity += cart[o].quantity
      })

      this.nonOrderableProductName = this.getProductName(this.nonOrderableProducts, totalQuantity)
    }
  },
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
.header-wrap .header-group h1 { display: inline-block; width: 110px; height: auto; }
</style>
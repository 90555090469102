<template>
  <div class="wrap">
    <div class="popup type3" @click.self="$emit('close')">
      <div class="popup_wrap">
        <div class="container">
          <div class="inner">
            <div class="popup_tit">
              <h2>영양 정보</h2>
            </div>
            <div class="popup_contents sub_hr">
              <div>
                <h3 class="hide">영양정보</h3>
                <ul class="list info1">
                  <li v-for="[key, value] of Object.entries(nutrition)" :key="key">
                    <span class="itit">{{ key }}</span>
                    <b class="idesc">{{ value }}</b>
                  </li>
                </ul>
              </div>
              <div>
                <h3>알레르기 유발 요인</h3>
<!--                <p class="desc">대두, 우유, 밀</p>-->
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="popup_close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NutritionInfo",
  data() {
    return {
      nutrition : {}
    }
  },
  props : {
    nutritionStr : String,
  },
  created() {
    console.log('str : ' + this.nutritionStr)
    this.nutrition = JSON.parse(this.nutritionStr)
    console.log('str11 : ')
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="close"><i class="icon-back"></i></button>
          <span class="title">개인정보 처리방침</span>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <div class="container">
        <div class="row">
          <div class="main-inner" v-html="content">

            <!-- 약관 내용 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Term02",
  props : {
    isUsedAsComponent : Boolean
  },
  data() {
    return {
      content : ''
    }
  },
  mounted() {
    this.content = this.getPrivacy()
  },

  methods : {
    close() {
      if (this.isUsedAsComponent) {
        this.$emit('close')
      } else {
        this.$router.back()
      }
    }

  },
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>
<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">이벤트</span>
        </div>
      </div>
    </div>
    <div class="notice-content-wrap">
      <div class="container">
        <div class="bg-white p-25">
          <p class="fs-16 mb-10 mt-5">{{ item.title }}</p>
          <p class="mb-18 list-date">
            <em v-if="item.timeS !== null">{{ $moment(item.timeS, 'YYYYMMDD') | moment('YY.MM.DD') }}</em> ~ <em
              v-if="item.timeE !== null">{{ $moment(item.timeE, 'YYYYMMDD') | moment('YY.MM.DD') }}</em>
          </p>
          <div class="mb-18" v-for="(image, index) in item.imageList" :key="index">
            <img :src="removeHost(image.imagePath)">
          </div>
          <div class="event-detail-content" v-html="item.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventDetail",
  data() {
    return {
      item: {
        title: '',
        timeS: '',
        timeE: '',
        imageList: [],
        content: '',
      }
    }
  },
  mounted() {
    this.$get(this.$EVENT_DETAIL + this.$route.params.idx, 'eventdetail_mounted', (result) => {
      this.item = result.data
    }, (result) => {
      this.httpError(result.data)
    })
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

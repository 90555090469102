<template>
  <div class="popup-wrap z-index-500">
    <div class="overlay-bg" @click.self="$emit('close')"></div>
    <div class="half-layer-wrap">
      <div class="container">
        <div class="payment-method-wrap">
          <div class="payment-method">
            <ul class="method-tab">
              <!-- TODO : 탭 선택시 .select 클래스 추가 -->
              <li :class="{'select' : type === 'amis'}"><a @click="type = 'amis'">아미스</a></li>
              <li :class="{'select' : type === 'card'}"><a @click="type = 'card'">신용카드</a></li>
              <li class=""><a @click="openQR()">QR스캔</a></li>
            </ul>
          </div>
        </div>
        <!-- 아미스 -->
        <div v-show="type === 'amis'">
          <PaymentMetr
              ref="PaymentMetr"
          />
        </div>
        <!-- 신용카드 -->
        <div v-show="type === 'card'">
          <PaymentCard
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PaymentMetr from "./PaymentMetr";
import PaymentCard from "./PaymentCard";

export default {
  name: "PaymentReal",
  components: {
    PaymentCard,
    PaymentMetr,
  },
  data() {
    return {
      type: 'amis',
    }
  },
  mounted() {
    window.addEventListener(this.$EVENT_QR_STRING, this.setQr)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_QR_STRING, this.setQr)
  },
  methods: {
    initSwiper(){
      this.$refs.PaymentMetr.initSwiper()
    },
    openQR() {
      window.openQRReader('결제하기')
    },
    setQr(e) {
      //인증결제 qr 달라진 부분 작업할것
      let qr = e.detail

      //qr정보 조회 후 화면 이동
      let formData = new FormData()
      formData.append('qr', qr)
      this.$post(this.$PAYMENT_READ_PAYNARA_QR, 'payment_setqr', formData, (result) => {
        let amount = result.data.amount
        //화면 이동
        this.$router.push({name:'PaymentInputAmount',params:{
            paymentQR:qr,
            amount: amount,
          }})
      }, (result) => {
        this.httpError(result.data)
      })

    },
  }
}
</script>

<style scoped>

</style>

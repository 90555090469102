<template>
  <div class="wrap">
    <!-- 상단 고정 해더 영역 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">휴면계정 해제</h5>
            <div class="w-100">
              <div class="pt-30">
                <MTRInput
                    :type="'text'"
                    :placeholder="'아이디를 입력해 주세요.'"
                    :model="id"
                    @update="(_model) => {this.id=_model}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn btn-black " @click="requestRelease()">본인인증</button>
        </div>
      </div>
    </div>
    <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
      <input type="hidden" name="m" value="checkplusService">
      <input type="hidden" name="EncodeData" id="nice-enc">
    </form>
  </div>
</template>

<script>
import MTRInput from "../../../common/MTRInput";
import axios from "axios";
export default {
  name: "InactivityRequest",
  components: {
    MTRInput
  },
  data() {
    return {
      id: '',
      nice: '',
      phone: '',
    }
  },
  methods: {
    requestRelease() {
      if (this.id === '') {
        this.notify('error', '아이디를 정확하게 입력해주세요.')
        return
      }
      this.niceEncode()
    },
    niceEncode() {
      axios.post("https://nice.payhub.co.kr/check-pay-plus/request", null, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
      }).then(response => {
        if (response.data.success === true) {
          this.niceShow(response.data.enc_data)
        } else {
          console.log(response.data)
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    niceShow(enc_data) {
      window.removeEventListener('message', this.niceDone)

      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      window.$('#nice-enc').val(enc_data)
      window.$('#nice-form').attr('target', 'popupChk')
      window.$('#nice-form').submit()

      window.addEventListener('message', this.niceDone);
    },
    niceDone(e) {
      if(e.origin === 'https://nice.payhub.co.kr') {
        this.phone = e.data.phone_no.substr(0,3)+'-'+e.data.phone_no.substr(3,4)+'-'+e.data.phone_no.substr(7,4)
        this.nice = e.data.payload

        window.removeEventListener('message', this.niceDone)

        this.niceCheck()
      }
    },
    niceCheck() {
      let formData = new FormData()
      formData.append('id', this.id)
      formData.append('auth', this.nice)

      //test
      this.$put(this.$AUTH_RELEASE, 'inactivity_request_nicecheck', formData, (result) => {
        this.$router.replace({ name: 'InactivitySuccess', params: { id: result.data.id } })
      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({ name: 'InactivityFail'})
      })
    },
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

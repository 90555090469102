<template>
  <div class="popup-wrap madal-bg z-index-500" v-if="show" @click.self="close()">
    <div class="container">
      <div class="row">
        <div class="section_confirm">
          <div class="relative-wrap">
            <div class="confirm-content">
              <div class="permit-group">
                <div class="confirm-box">
<!--                  <img src="/image/logo_row01.svg" class="update-logo" alt="meterra">-->
                  <i class="bg_symbol_20 mb-30"></i>
                  <h3 class="confirm-title">{{ title }}</h3>
                </div>
                <hr class="hr-1">
                <div class="confirm-box">
                  <p class="confirm-guid" v-html="msg"></p>
                </div>
              </div>
              <div class="button-wrap">
                <div class="container">
                  <div class="button-group">
                    <button class="btn btn-gray" @click="no()">{{ textNo }}</button>
                    <button class="btn btn-black" @click="ok()">{{ textOk }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MTRConfirm",
  data() {
    return {
      show: false,
      title: '',
      msg: '',
      callback: null,
      cancel: null,
      textOk: '',
      textNo: '',
    }
  },
  methods: {
    showDialog(title, msg, textOk, textNo, callback, cancel) {
      this.title = title
      this.msg = msg
      this.textOk = textOk
      this.textNo = textNo
      this.callback = callback
      this.cancel = cancel
      this.show = true
    },
    close() {
      this.show = false
    },
    ok() {
      this.close()
      this.callback()
    },
    no() {
      this.close()
      this.cancel()
    }
  }
}
</script>

<style scoped>
.button-group button + button {margin-left: 0 !important;}
.bg_symbol_20 { display: block; margin: 0 auto; width: 50px; height: 50px; background: url(/assets/image/character_100.svg)no-repeat center center / 50px 50px; }
</style>
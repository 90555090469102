<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
        <span class="logo">
          <img src="/image/logo_row01.svg" alt="amis">
        </span>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <div class="section-password">
        <div class="area-password">
          <div class="instruction">
            <div class="container">
              <div class="row">
                <h3>PIN</h3>
                <h4>핀번호를 한 번 더 입력해 주세요.</h4>
              </div>
            </div>
          </div>
          <div class="password">
            <div class="container">
              <div class="row">
                <span class="blind">비밀번호 6자리는 입력하면 자동전환됩니다.</span>
                <!-- TODO : 비밀번호 입력 시 .on 클래스 추가 -->
                <span id="key_1" class="word" :class="{'on' : pin.length >= 1}"></span>
                <span id="key_2" class="word" :class="{'on' : pin.length >= 2}"></span>
                <span id="key_3" class="word" :class="{'on' : pin.length >= 3}"></span>
                <span id="key_4" class="word" :class="{'on' : pin.length >= 4}"></span>
                <span id="key_5" class="word" :class="{'on' : pin.length >= 5}"></span>
                <span id="key_6" class="word" :class="{'on' : pin.length >= 6}"></span>
              </div>
            </div>
          </div>
          <button class="reset-pin-btn" @click="$router.replace({name:'ChangePinRegist',params:{old_pin:old_pin,password:password, kakao:kakao}})">핀번호 재입력<i class="icon-arrow-right arrow-dgray pl-5"></i></button>
        </div>
        <div class="area-keyboard">
          <div class="container">
            <table class="keyboard">
              <tbody>
              <tr>
                <td>
                  <button type="button" class="key" @click="numBtn('1')">
                    <span>1</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('2')">
                    <span>2</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('3')">
                    <span>3</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="key" @click="numBtn('4')">
                    <span>4</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('5')">
                    <span>5</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('6')">
                    <span>6</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="key" @click="numBtn('7')">
                    <span>7</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('8')">
                    <span>8</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('9')">
                    <span>9</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="key" @click="cancel()">
                    <span class="cancel">취소</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('0')">
                    <span>0</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="backspace()">
                    <span class="icon delete">지우기</span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePinRegistRE",
  data() {
    return {
      old_pin:'',
      new_pin:'',
      pin: '',
      password: '',
      kakao: '',
    }
  },
  mounted() {
    this.old_pin = this.$route.params.old_pin
    this.new_pin = this.$route.params.new_pin
    this.password = this.$route.params.password
    this.kakao = this.$route.params.kakao
  },
  methods: {
    numBtn(_num) {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin + _num
      if (this.pin.length === 6 ) {
        let url = this.$AUTH_UPDATE_PIN
        let formData = new FormData()
        formData.append('newPin', this.new_pin)
        formData.append('newPinCk', this.pin)
        if (this.old_pin.length === 6) {
          formData.append('oldPin', this.old_pin)
        } else if (this.password.length >= 8) {
          formData.append('password', this.password)
        } else if (this.kakao !== '') {
          formData.append('token', this.kakao)
          url = this.$AUTH_UPDATE_PIN_KAKAO
        } else {
          this.notify('error', '잘못된 접근입니다.', false)
          this.pin = ''
          return
        }
        this.$put(url, 'changepinregistre_numbtn', formData, () => {
          window.setPin(this.pin)
          this.$router.replace({name:'ChangePinSuccess'})
        }, (result) => {
          this.httpError(result.data)
          this.pin = ''
        })
      }
    },
    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },
    cancel() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.header-group .logo { position: relative; display: inline-block; height: 20px; width: 70px; }
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="payment-wrap">
    <!-- 등록된 카드 있을 경우 -->
    <div v-if="selectedCard !== null && barcodeStr !== ''">
      <div class="payment-sort">
        <div class="scan">
          <vue-barcode :value="barcodeStr"/>
        </div>
      </div>
      <!-- 카드 리스트 -->
      <div class="payment-info" @click="showCard=true">
        <div class="d-flex-row">
          <div class="mr-auto">
            <ul class="bank-list">
              <li>
                <a>
                  <div class="bank-icon">
                    <i class="sp-card" :class="selectedCard.name | cardImage"></i>
                  </div>
                  <div class="bank-summary">
                    <span>{{ selectedCard.title }}<i v-show="selectedCard.mainCard" class="badge ml-10">주카드</i></span>
                    <em class="disabled">{{ selectedCard.alias ? selectedCard.alias : '별명없음' }}</em>
                  </div>
                </a></li>
            </ul>
          </div>
          <div class="ml-auto item-center">
            <i class="icon-arrow-right arrow-gray ml-10"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- 등록된 카드 없을 경우 -->
    <div v-else>
      <div class="list-none-card">
        <p>
          <i class="bg_symbol_15"></i>
        </p>
        <p class="confirm-subtit mt-30">결제 가능한 카드가 없습니다.</p>
        <p class="confirm-desc">신용카드를 먼저 등록해주세요.</p>
        <p class="mt-65">
          <button class="outline_l_btn btn-outline-black" @click="$router.push({name:'RegistCard'})"><i
              class="icon more"></i>신용카드 등록
          </button>
        </p>
      </div>
    </div>

    <PaymentCardSelect
        v-show="showCard"
        :cards="cardList"
        @selected="setSelectedCard"
        @close="showCard=false"
    />
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
import PaymentCardSelect from "./PaymentCardSelect";
export default {
  name: "PaymentCard",
  components: {
    VueBarcode,
    PaymentCardSelect
  },
  data() {
    return {
      barcodeStr: '',
      selectedCard: null,
      cardList: [],
      showCard: false,
    }
  },
  mounted() {
    this.$get(this.$CREDITCARD_LIST, 'paymentcard_mounted', (result) => {
      this.cardList = result.data
      for (let i = 0; i < result.data.length; i++) {
        let card = result.data[i]
        if (card.mainCard) {
          this.selectedCard = card
          break
        }
      }
      if (this.selectedCard === null && this.cardList.length > 0) {
        this.selectedCard = this.cardList[0]
      }

      this.getCode()
    }, (result) => {
      this.httpError(result.data)
    })
  },
  methods: {
    getCode() {
      if (this.selectedCard === null) {
        return
      }
      let formData = new FormData()
      formData.append('cardIdx', this.selectedCard.idx)

      this.$post(this.$PAYMENT_CARD_BARCODE, 'paymentcard_getcode', formData, (result) => {
        this.barcodeStr = result.data[0]

      }, (result) => {
        this.httpError(result.data)
      })

    },
    setSelectedCard(_card) {
      this.selectedCard = _card
      this.showCard = false
    },
  },
  filters: {
    cardImage: (value) => {
      if (value.startsWith('삼성') || value.toLowerCase().startsWith('samsung')) {
        return 'card-02'
      } else if (value.startsWith('비씨') || value.toLowerCase().startsWith('bc')) {
        return 'card-08'
      } else if (value.startsWith('롯데') || value.toLowerCase().startsWith('lotte')) {
        return 'card-05'
      } else if (value.startsWith('현대') || value.toLowerCase().startsWith('hyundai')) {
        return 'card-04'
      } else if (value.startsWith('국민') || value.toLowerCase().startsWith('kb')) {
        return 'card-01'
      } else if (value.startsWith('신한') || value.toLowerCase().startsWith('shinhan')) {
        return 'card-03'
      } else if (value.startsWith('시티') || value.toLowerCase().startsWith('citi')) {
        return 'card-09'
      } else if (value.startsWith('농협') || value.toLowerCase().startsWith('nh')) {
        return 'card-11'
      } else if (value.startsWith('하나') || value.toLowerCase().startsWith('hana')) {
        return 'card-06'
      } else if (value.startsWith('비자') || value.toLowerCase().startsWith('visa')) {
        return 'card-15'
      } else if (value.startsWith('마스터') || value.toLowerCase().startsWith('master')) {
        return 'card-16'
      }
      return 'bank-30'
    }
  }
}
</script>

<style scoped>

</style>

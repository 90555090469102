<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">마이페이지</span>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <div class="mypage-top">
        <div class="mypage-landing">
          <div class="container">
            <div class="row">
              <div class="user-info-setting">
                <div class="user-profile">
                  <div class="user-thumb-box">
<!--                    <img class="profile-img" :src="profile.imagePath? profile.imagePath : '/image/user_large.svg'" alt="사용자 사진">-->
                    <img class="profile-img" :src="profile.imagePath? removeHost(profile.imagePath) : '/image/user_large.svg'" alt="사용자 사진">
                    <button class="photo-btn icon photo" @click="uploadImage()"></button>
                    <input type="file" @change="upload($event)" id="imageFileUpload" accept="image/*" hidden/>
                  </div>
                </div>
                <div class="user-name">{{ profile.id }}</div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mypage-content">
      <div class="menulist-article">
        <div class="container">
          <div class="menulist-inner">
            <div class="menulist-item-area">
              <a class="menulist-item" @click="$router.push({name: 'ChangeEmail'})">
                <span class="menulist-item-name">{{ profile.email }}</span>
                <i class="icon-arrow-right"></i>
              </a>
            </div>
            <div class="menulist-item-area">
              <a class="menulist-item" @click="$router.push({name: 'ChangePhone'})">
                <span class="menulist-item-name">{{ profile.hp }}</span>
                <i class="icon-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="menulist-article">
        <div class="container">
          <div class="menulist-inner">
            <div class="menulist-item-area">
              <a class="menulist-item" @click="$router.push({name: 'ChangePassword'})">
                <span class="menulist-item-name">비밀번호 변경</span>
                <i class="icon-arrow-right"></i>
              </a>
            </div>
            <div class="menulist-item-area">
              <a class="menulist-item" @click="$router.push({name: 'ChangePinCheck'})">
                <span class="menulist-item-name">핀번호 변경</span>
                <i class="icon-arrow-right"></i>
              </a>
            </div>
            <div class="menulist-item-area">
              <div class="menulist-item">
                <span class="menulist-item-name" :class="{'color-light-gray04':!ableBio}">생체인증 사용</span>
                <p class="toggle-group">
                  <!-- TODO : 수신 허용시 .on 클래스 추가 -->
                  <span class="blind btn-toggle"
                        :class="{'on' : useLocalBio === 'true', 'color-light-gray04':!ableBio}"
                        @click="changeLocalBio()">생체인증 사용</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="menulist-article">
        <div class="container">
          <div class="menulist-inner">
            <div class="menulist-item-area">
              <a class="menulist-item" @click="$router.push({name:'CardList'})">
                <span class="menulist-item-name">신용카드 관리</span>
                <i class="icon-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>-->
      <div class="menulist-article">
        <div class="container">
          <div class="menulist-inner">
            <div class="menulist-item-area">
              <a class="menulist-item" @click="goLogout()">
                <span class="menulist-item-name">로그아웃</span>
              </a>
            </div>
            <div class="menulist-item-area">
              <a class="menulist-item" @click="$router.push({name:'Leave'})">
                <span class="menulist-item-name">탈퇴</span>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
    <pin-check
        ref="PinCheck"
        :title="'생체 인증 등록'"
        :amount="0"
        :unit="''"
        :sub-amount="0"
        :sub-unit="''"
    />
  </div>
</template>

<script>
import PinCheck from "@/components/common/PinCheck";

export default {
  name: "MyInfo",
  components: {
    PinCheck,
  },
  data() {
    return {
      profile: {
        id: '',
        imageIdx: '',
        imagePath: '',
        email: '',
        hp: '',
        biometrics: false,
        userType: '' //user , shop
      },
      useLocalBio: 'false',
      ableBio: false,

    }
  },
  mounted() {
    this.version = window.getVersion()
    this.ableBio = window.getAbleBio()
    this.useLocalBio = localStorage.getItem('useLocalBio')
    this.getInfo()
    window.addEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
  },
  methods: {
    getInfo() {
      this.$get(this.$USER_INFO_DETAIL, 'myinfo_getinfo', (result) => {
        this.profile = result.data
        this.changeRemoteBio()
      }, (result) => {
        this.httpError(result.data)
      })
    },
    changeRemoteBio() {
      let _useLocalBio = (this.useLocalBio === 'true')
      if (_useLocalBio !== this.profile.biometrics) {
        this.$put(this.$AUTH_USE_BIO, 'myinfo_changeremotebio', null, (result) => {
          this.profile.biometrics = result.data
        }, () => {
          // this.notify('error', '푸시 서비스 등록에 문제가 발생했습니다.')
        })
      }

    },
    changeLocalBio() {
      if (this.ableBio === 'false') {
        this.notify('', '지문 인증을 사용할 수 없습니다.', false, 2000)
        return
      }
      if (this.useLocalBio === 'true') {
        localStorage.setItem('useLocalBio', 'false')
        this.useLocalBio = 'false'
      } else {
        this.$refs.PinCheck.showPin(() => {
          window.requestBio()
        })
      }
      this.changeRemoteBio()
    },
    bioResult(e) {
      let pin = e.detail
      this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
      if (pin.length === 6) {
        localStorage.setItem('useLocalBio', 'true')
        this.useLocalBio = 'true'
        this.changeRemoteBio()
        this.$router.push({name: 'RegistBioSuccess'})
      } else {
        this.$router.push({name: 'RegistBioFail'})
      }
    },
    uploadImage() {
      window.document.getElementById('imageFileUpload').click()
    },
    upload(e) {
      let files = e.target.files || e.dataTransfer.files
      console.log('file size : ' + files[0].size)
      if (!files.length) {
        return
      }

      let formData = new FormData()

      //deleteIdx 부분 삭제 처리 20210702
      // if (this.profile.imageIdx !== null) {
      //   formData.append('deleteIdx', this.profile.imageIdx)
      // }

      formData.append('profileImage', files[0])

      this.$post(this.$USER_UPLOAD_IMAGE, 'myinfo_upload', formData, () => {
        this.getInfo()
      }, (result) => {
        this.httpError(result.data)
      })
    },
    goLogout() {
      this.$root.$refs.MTRConfirm.showDialog('로그아웃', '로그아웃 하시겠습니까?', '확인', '취소', () => {
        this.$get(this.$AUTH_LOGOUT, 'myinfo_checkid', () => {
          sessionStorage.removeItem('accessToken')
          sessionStorage.removeItem('refreshToken')
          sessionStorage.removeItem('ssoToken')
          sessionStorage.removeItem('accessToken_reusable')
          sessionStorage.removeItem('refreshToken_reusable')
          sessionStorage.removeItem('accessToken_quick_order')
          sessionStorage.removeItem('refreshToken_quick_order')

          localStorage.removeItem('passToken')
          localStorage.removeItem('useLocalBio')
          localStorage.removeItem('vuex')
          this.$router.replace({name: 'BeforeLogin'})
        }, (result) => {
          this.httpError(result.data)
        })

      }, () => {

      })
    },

    /*beforeUpload(e) {
      let files = e.target.files || e.dataTransfer.files
      console.log('before file size : ' + files[0].size)
      if (e.target.files) {
        let imageVal = e.target.files[0];
        let reader = new FileReader();
        reader.onload = function (e2) {
          let img = document.createElement("img");
          img.onload = function () {
            // This line is dynamically creating a canvas element
            let canvas = document.createElement("canvas");

            let ctx = canvas.getContext("2d");


            //This line shows the actual resizing of image
            ctx.drawImage(img, -100, -100, 400, 400);


            //This line is used to display the resized image in the body
            let url = canvas.toDataURL(imageVal.type);
            document.getElementById("img-content").src = url;

            //document.getElementById("img-content").src = url;
          }
          img.src = e2.target.result;

        }
        reader.readAsDataURL(imageVal);
      }

      console.log('after file size : ' + files[0].size)
      //this.upload(e)

    },*/


  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

import axios from 'axios';
import https from 'https';

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
        },
        timeout: 60000
    }
}
let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze({
    install(Vue) {
        if (process.env.VUE_APP_DEBUG === '1') {
            const httpsAgent = new https.Agent({rejectUnauthorized: false});
            axios.defaults.httpsAgent = httpsAgent
        }
        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN + "/v1"

        Vue.prototype.$AUTH_REFRESHTOKEN = "/auth/refreshToken"
        Vue.prototype.$AUTH_AUTOLOGIN = "/auth/autoLogin"
        Vue.prototype.$AUTH_EMAIL = "/auth/email"
        Vue.prototype.$AUTH_FINDID = "/auth/findId"
        Vue.prototype.$AUTH_FINDPW = "/auth/findPw"
        Vue.prototype.$AUTH_ID = "/auth/id"
        Vue.prototype.$AUTH_LEAVE = "/auth/leave"
        Vue.prototype.$AUTH_LOGIN = "/auth/login"
        Vue.prototype.$AUTH_LOGOUT = "/auth/logout"
        Vue.prototype.$AUTH_PASSWORD = "/auth/password"
        Vue.prototype.$AUTH_CHECK_PIN = "/auth/pin"
        Vue.prototype.$AUTH_REGISTER_PIN = "/auth/registerPin"
        Vue.prototype.$AUTH_RELEASE = "/auth/release"
        Vue.prototype.$AUTH_SIGNUP = "/auth/signUp"
        Vue.prototype.$AUTH_SIGNUP_PROCESSING = "/auth/signup/processing"
        Vue.prototype.$AUTH_UPDATE_EMAIL = "/auth/updateEmail"
        Vue.prototype.$AUTH_UPDATE_FIND_PW = "/auth/updateFindPw"
        Vue.prototype.$AUTH_UPDATE_MY_PW = "/auth/updateMyPw"
        Vue.prototype.$AUTH_UPDATE_PHONE = "/auth/updatePhone"
        Vue.prototype.$AUTH_UPDATE_PIN = "/auth/updatePin"
        Vue.prototype.$AUTH_UPDATE_PIN_KAKAO = "/auth/updatePin/kakao"
        Vue.prototype.$AUTH_USE_BIO = "/auth/useBiometric"

        Vue.prototype.$AUTH_KAKAO_LOGIN = "/auth/kakaologin"
        Vue.prototype.$AUTH_KAKAO_SIGNUP = "/auth/kakaosignup"

        Vue.prototype.$FAQ_LIST = "/faq/"
        Vue.prototype.$FAQ_CATEGORY = "/faq/category"

        Vue.prototype.$QNA_LIST = "/qna/"
        Vue.prototype.$QNA_REGIST = "/qna/"
        Vue.prototype.$QNA_DETAIL = "/qna/"
        Vue.prototype.$QNA_CATEGORY = "/qna/category"
        Vue.prototype.$QNA_DELETE = "/qna/"
        Vue.prototype.$QNA_EDIT = "/qna/"
        Vue.prototype.$QNA_UPDATE_DETAIL = "/qna/update/"

        Vue.prototype.$PAYMENT_QR_BARCODE = "/payment/code"
        Vue.prototype.$PAYMENT_CARD_BARCODE = "/payment/cardCode"
        //Vue.prototype.$PAYMENT_CREDIT_CARD = "/payment/creditCard"
        Vue.prototype.$PAYMENT_SHOP_QR = "/payment/readShopQr"
        Vue.prototype.$PAYMENT_READ_PAYNARA_QR = "/payment/readPaynaraQr"
        Vue.prototype.$PAYMENT_PAYMENT_PAYNARA_QR = "/payment/qrForPaynaraUser"
        //Vue.prototype.$PAYMENT_USER_QR = "/payment/userQr"

        Vue.prototype.$ACCOUNT_LIST = "/account/"
        Vue.prototype.$ACCOUNT_REGIST = "/account/"
        Vue.prototype.$ACCOUNT_REMOVE = "/account/"
        Vue.prototype.$ACCOUNT_BANK_LIST = "/account/bankList"
        Vue.prototype.$ACCOUNT_SET_ALIAS = "/account/setAlias"
        Vue.prototype.$ACCOUNT_SET_MAIN = "/account/setMain"
        Vue.prototype.$ACCOUNT_CONNECT = "/account/connect"
        Vue.prototype.$ACCOUNT_AUTH = "/account/auth"
        Vue.prototype.$ACCOUNT_MEMBER = "/account/member"
        Vue.prototype.$ACCOUNT_ARS = "/account/ars"
        Vue.prototype.$ACCOUNT_ARS_CONFIRM = "/account/arsConfirm"

        Vue.prototype.$VIRTUAL_ACCOUNT_CREATE = "/account/virtual"

        Vue.prototype.$USER_FCM_UPDATE = "/fcmToken"

        Vue.prototype.$NOTICE_LIST = "/notice/"
        Vue.prototype.$NOTICE_DETAIL = "/notice/"

        Vue.prototype.$MAIN_INFO = "/main"

        Vue.prototype.$USER_INFO = "/user/"
        Vue.prototype.$USER_IS_REGIST_PIN = "/user/checkPin"
        Vue.prototype.$USER_INFO_DETAIL = "/user/detail"
        Vue.prototype.$USER_UPLOAD_IMAGE = "/user/uploadImage"
        Vue.prototype.$USER_CHANGE_ALARM = "/user/changeAlarm"

        Vue.prototype.$REMITTANCE_CHECK_PHONE = "/remittance/checkByPhone"
        Vue.prototype.$REMITTANCE_CHECK_QR_REAPPAY = "/remittance/checkByQr/0?qr="
        Vue.prototype.$REMITTANCE_CHECK_QR_REAPCAIN = "/remittance/checkByQr?qr="
        Vue.prototype.$REMITTANCE_RECEIVE_REAPCHAIN = "/remittance/receiveReapChain"
        // Vue.prototype.$REMITTANCE_RECEIVE_REAPPAY = "/remittance/receiveReapPay"
        Vue.prototype.$REMITTANCE_RECEIVE_METR = "/remittance/receiveToken"
        Vue.prototype.$REMITTANCE_SEND = "/remittance/send"

        Vue.prototype.$CREDITCARD_LIST = "/creditCard/"
        Vue.prototype.$CREDITCARD_REGIST = "/creditCard/"
        Vue.prototype.$CREDITCARD_REMOVE_ALL = "/creditCard/"
        Vue.prototype.$CREDITCARD_REMOVE = "/creditCard"
        Vue.prototype.$CREDITCARD_COMPANY_LIST = "/creditCard/companyList"
        Vue.prototype.$CREDITCARD_SET_ALIAS = "/creditCard/setAlias"
        Vue.prototype.$CREDITCARD_SET_MAIN = "/creditCard/setMain"

        Vue.prototype.$NOTIFICATION_LIST = "/notification/"

        Vue.prototype.$EVENT_LIST = "/event/"
        Vue.prototype.$EVENT_DETAIL = "/event/detail/"

        Vue.prototype.$WALLET_CARD_HISTORY = "/transaction/card/history"
        Vue.prototype.$WALLET_REAPCHAIN_BALANCE = "/transaction/wallet?type=1"
        Vue.prototype.$WALLET_REAPCHAIN_HISTORY = "/transaction/reapChain/history"
        Vue.prototype.$WALLET_REAPCHAIN_WITHDRAW = "/transaction/token/withdraw"
        Vue.prototype.$WALLET_REAPPAY_BALANCE = "/transaction/wallet?type=1"
        Vue.prototype.$WALLET_REAPPAY_HISTORY = "/transaction/token/history"
        Vue.prototype.$WALLET_REAPPAY_WITHDRAW = "/transaction/reapPay/withdraw"
        Vue.prototype.$WALLET_REWARD_BALANCE = "/transaction/wallet?type=2"
        Vue.prototype.$WALLET_ETH_BALANCE = "/transaction/wallet?type=4"
        Vue.prototype.$WALLET_REWARD_HISTORY = "/transaction/reward/history"

        Vue.prototype.$WALLET_MIN_SEND_REAPPAY = "/minvalue/0"  //아미스 토큰 송금
        Vue.prototype.$WALLET_MIN_WITHDRAW_REAPCHAIN = "/minvalue/1"  //아미스 토큰 출금
        Vue.prototype.$WALLET_MIN_SEND_ETH = "/minvalue/2" //이더 토큰 송금
        Vue.prototype.$WALLET_MIN_WITHDRAW_ETH = "/minvalue/2" //이더 토큰 출금

        Vue.prototype.$CHARGE_REQUEST = "/charge/"
        Vue.prototype.$CHARGE_AUTO_CONDITION = "/charge/autoInfo"
        Vue.prototype.$CHARGE_AUTO_SET = "/charge/auto"
        Vue.prototype.$CHARGE_AUTO_RELEASE = "/charge/auto"
        Vue.prototype.$CHARGE_AUTO_INFO = "/charge/auto"

        // Vue.prototype.$REAP_PRICE = "https://api.kucoin.com/api/v1/market/orderbook/level1?symbol=REAP-USDT"
        Vue.prototype.$REAP_PRICE = "/USD?coin=REAP"
        Vue.prototype.$REAP_FEES = "/reapchain/fee"

        Vue.prototype.$TOKEN_PRICE = "/conversion/token"
        Vue.prototype.$ETH_PRICE = "/conversion/token/eth"
        Vue.prototype.$KRW_PRICE = "/conversion/krw"  //원화당 아미스 가격 조회

        Vue.prototype.$CO2 = "/carbonEmissions"

        /*립페이 계좌 송금
          가능 여부 확인(CHECK) : get
          송금(SEND) : post
         */
        Vue.prototype.$CHECK_PAY_TO_KRW = "/remittance/check/reapToKRW"

        /*립페이 출금가능 금액 확인*/
        Vue.prototype.$WALLET_WITHDRAWABLE_REAPPAY = "/transaction/withdrawableAmount"
        /*립페이 출금제한 시간*/
        Vue.prototype.$WITHDRAW_LIMIT = "/withdraw_limit"

        /*립페이(송금, 출금)수수료*/
        Vue.prototype.$WALLET_TRANSACTION_GETFEE = "/transaction/getFee"
        /*전체 수수료 조회 api*/
        Vue.prototype.$WALLET_TRANSACTION_GETFEEINFO = "/transaction/getFeeInfo"

        /*립페이 SSO*/
        Vue.prototype.$SSO_AUTH_WITH_ID_PW = "/sso/login"
        Vue.prototype.$SSO_AUTH_WITH_TOKEN = "/sso/accessLogin"
        Vue.prototype.$SSO_AUTH_WITH_KAKAO = "/sso/kakaoLogin"

        Vue.prototype.$LOAN_CODE = "/loanCode"

        Vue.prototype.$METR_PAYMENT_QR = "/payment/qr"

        Vue.prototype.$PAYMENT_CHARGE = "/payment/charge"


        //락업
        Vue.prototype.$LOCKUP_PROCESSING = "/lockup/"
        Vue.prototype.$LOCKUP_HISTORY = "/lockup/history"
        Vue.prototype.$LOCKUP_DETAIL = "/lockup/detail"

        Vue.prototype.$TEST = "/test"
        Vue.prototype.$post = function (callUrl, caller, postData, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            console.log(caller + ' axios post start')

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                console.log(caller + ' axios post end')

                pending_post[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$put = function (callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$delete = function (callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }
    }
})


<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">공지사항</span>
        </div>
      </div>
    </div>
    <div class="notice-content-wrap">
      <div class="container">

        <div class="wrapper-center" v-if="loaded && items.length <= 0">
          <!-- TODO : 공지사항이 없을 경우 -->
          <div class="alram-wrap">
            <div class="list-none">
              <i class="bg_symbol_15_op mb-20"></i>
              <p class="list-desc">등록된 공지가 없습니다.</p>
            </div>
          </div>
        </div>

        <!-- TODO : 공지사항이 있을 경우 -->
        <div class="notice-list" v-if="items.length > 0">
          <ul>
            <li v-for="(item, index) in items" :key="index">
              <a @click="goDetail(item.idx)">
                <p class="notice-date">{{ $moment(item.timeS, 'YYYYMMDD') | moment('YY.MM.DD') }}<small v-show="item.newNotice">NEW</small>
                </p>
                <p class="mr-20 fs-16">{{ item.title }}</p>
                <i :class="{'icon-arrow-down':item.idx !== selectedIdx, 'icon-arrow-up':item.idx === selectedIdx}"></i>
              </a>
              <div class="bg-gray p-1">
                <div v-show="item.idx === selectedIdx" class="p-20">
                  <div>
                    <div class="content-reset" v-html="detailContent"></div>
                  </div>
                  <div class="pt-10" v-for="(img, imgIndex) in imageList" :key="imgIndex">
                    <img :src="removeHost(img.imagePath)" alt="공지사항 이미지">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeList",
  data() {
    return {
      items: [],
      selectedIdx: '',
      detailContent : '',
      imageList: [],
      loaded: false,
    }
  },
  mounted() {
    this.getItems()

  },
  methods: {
    getItems() {

      this.$get(this.$NOTICE_LIST, 'noticelist_getitems', (result) => {
        this.items = result.data
        this.loaded = true

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goDetail(_no) {
      if (this.selectedIdx === _no) {
        this.selectedIdx = ''
        return
      }
      this.selectedIdx = _no
      this.imageList = []
      this.detailContent = ''
      this.$get(this.$NOTICE_DETAIL + _no, 'noticelist_godetail', (result) => {
        this.imageList = result.data.imageList
        this.detailContent = result.data.content

      }, (result) => {
        this.httpError(result.data)
      })
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
.wrapper-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.notice-content-wrap{ position: relative; height: 100%; padding-top: 50px; overflow: auto; }
</style>

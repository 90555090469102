<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <button type="button" class="nav-btn" @click="$router.back()"><i class="icon-back"></i></button>
      </div>
    </div>
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="sign-box">
            <i class="bg_symbol_20 mb-20"></i>
            <h3 class="sign-tit">고객님 환영합니다.</h3>
            <p class="tit-desc">일상을 편리하게 변화시킬 아미스 입니다.</p>
          </div>

          <div class="sign-checkbox">
            <div class="checks sign-all">
              <input type="checkbox" id="autoChecks" v-model="agree_all" @click="checkAllClick(0)">
              <label for="autoChecks">약관 전체 동의 및 인증 요청</label>
            </div>
            <div class="regist-box">
              <div class="checks">
                <input type="checkbox" id="chk2" v-model="agree_01" @click="checkAllClick(1)">
                <label for="chk2">(필수) 이용약관</label>
                <button type="button" class="next-btn" @click="$router.push({name:'Term01'})"><i class="icon-arrow-right"></i></button>
              </div>
              <div class="checks">
                <input type="checkbox" id="chk3" v-model="agree_02" @click="checkAllClick(2)">
                <label for="chk3">(필수) 개인정보 처리방침</label>
                <button type="button" class="next-btn" @click="$router.push({name:'Term02'})"><i class="icon-arrow-right"></i></button>
              </div>
<!--              <div class="checks">
                <input type="checkbox" id="chk4" v-model="agree_03" @click="checkAllClick(3)">
                <label for="chk4">(필수) 위치기반서비스 이용약관</label>
                <button type="button" class="next-btn" @click="$router.push({name:'Term03'})"><i class="icon-arrow-right"></i></button>
              </div>
              <div class="checks">
                <input type="checkbox" id="chk5" v-model="agree_04" @click="checkAllClick(4)">
                <label for="chk5">(필수) 전자금융거래 이용약관</label>
                <button type="button" class="next-btn" @click="$router.push({name:'Term04'})"><i class="icon-arrow-right"></i></button>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn full_btn btn-black" :class="{'btn-disable':!agree_all || !agree_01 || !agree_02 || !agree_03 || !agree_04}" @click="goNext()">다음</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermAgree",
  data() {
    return {
      agree_all: false,
      agree_01 : false,
      agree_02 : false,
      agree_03 : false,
      agree_04 : false,
    }
  },
  methods: {
    goNext() {
      if (this.agree_01 === true && this.agree_02 === true && this.agree_03 === true && this.agree_04 === true) {
        this.$router.replace({name: 'SignUp01'})
      }
    },

    checkAllClick(num) {
      if (num === 0) {
        this.agree_all = !this.agree_all
        if (this.agree_all) {
          this.agree_01 = true
          this.agree_02 = true
          this.agree_03 = true
          this.agree_04 = true
        } else {
          this.agree_01 = false
          this.agree_02 = false
          this.agree_03 = false
          this.agree_04 = false
        }
      } else if (num === 1) {
        this.agree_01 = !this.agree_01
      } else if (num === 2) {
        this.agree_02 = !this.agree_02
      } else if (num === 3) {
        this.agree_03 = !this.agree_03
      } else if (num === 4) {
        this.agree_04 = !this.agree_04
      }

      if (this.agree_01 === true && this.agree_02 === true && this.agree_03 === true && this.agree_04 === true) {
        this.agree_all = true
      } else {
        this.agree_all = false
      }
    },
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="logo">
            <img src="/image/logo_row01.svg" alt="amis">
          </span>
        </div>
      </div>
    </div>
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <strong class="weight-bold fs-16">{{ shopName }} <i>에서</i></strong>
              <strong class="confirm-tit mt-10">{{ payAmount | numberWithCommas }}<em class="unit">AMIS</em></strong>
              <span class="weight-semi-bold fs-18 mt-10">= {{ Math.floor(payAmount * rate) | numberWithCommas }}원 <em class="fs-16">(1AMIS={{ rate | numberWithCommas }}원)</em></span>
              <i class="point-color-green mt-10 fs-20" v-show="rewardAmount * 1 > 0">+ {{ rewardAmount | numberWithCommas }}AMIS</i>
              <p class="confirm-desc">결제완료 되었습니다.</p>
            </div>
            <div class="confirm-info-list">
              <dl>
                <dt class="text-tit">남은 AMIS</dt>
                <dd class="point-color-black">{{ payBalance | numberWithCommas }}AMIS</dd>
              </dl>
              <dl>
                <dt class="text-tit">Point 적립</dt>
                <dd class="point-color-green">+{{ point | numberWithCommas }}AMIS</dd>
              </dl>
              <dl>
                <dt class="text-tit">결제일시</dt>
                <dd class="text-desc">{{ new Date() | moment('YYYY-MM-DD HH:mm:ss') }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="$router.back()">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentSuccess",
  data() {
    return {
      paymentQR: '',
      rewardAmount: '0',
      payAmount: '0',
      point: '0',
      paymentTime: '',
      payBalance: '0',
      shopName: '',
      rate: 100,
    }
  },
  mounted() {
    this.paymentQR = this.$route.params.paymentQR
    this.rewardAmount = this.$route.params.rewardAmount
    this.payAmount = this.$route.params.payAmount
    this.point = this.$route.params.point
    this.shopName = this.$route.params.shopName
    console.log(this.$route.params.paymentQR)
    console.log(this.$route.params.rewardAmount)
    console.log(this.$route.params.payAmount)
    console.log(this.$route.params.point)
    console.log(this.$route.params.shopName)

    let current = new Date()
    let year = current.getFullYear()
    let month = current.getMonth() + 1
    let day = current.getDate()
    let h = current.getHours()
    let m = current.getMinutes()
    let s = current.getSeconds()

    this.paymentTime = year + '.' + month + '.' + day + '.' + h + ':' + m + ':' + s

    this.$get(this.$WALLET_REAPPAY_BALANCE, 'paymentsuccess_mounted', (result) => {
      this.payBalance = result.data * 1
    }, (result) => {
      this.httpError(result.data)
    })

    if (this.$route.params.isOutside) {
      this.notify('success', '결제한곳으로 돌아가 결제 내역을 확인해주세요.', false)
    }

    this.$get(this.$TOKEN_PRICE, 'sendpaysuccess_mounted', (result) => {
      this.rate = result.data
    }, (result) => {
      this.httpError(result.data)
    })
  }
}
</script>

<style scoped>
.header-group .logo { position: relative; display: inline-block; height: 20px; width: 70px; }
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

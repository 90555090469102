<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">마이페이지</span>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <div class="mypage-top">
        <div class="mypage-landing">
          <div class="container">
            <div class="row">
              <div class="user-info">
                <div class="user-profile">
                  <div class="user-thumb-box">
                    <span v-if="!profile.imagePath" class="icon noimage">기본이미지</span>
                    <img v-else class="noimage" :src="removeHost(profile.imagePath)" alt="사용자 사진">
                  </div>
                  <div class="user-name">{{ profile.id }}</div>
                </div>
                <div class="setting" @click="$router.push({name:'MyInfo'})">
                  <span class="icon set cursor">환경설정</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mypage-content">
        <div class="menutab-article">
          <div class="container">
            <div class="menutab-inner">
              <div class="menutab-item">
<!--                <a @click="$router.replace({name : 'MyPage', query : {'token' : 'AMIS'}})" :class="{'active' : selectedToken === 'AMIS'}"> AMIS</a>
                <a @click="$router.replace({name : 'MyPage', query : {'token' : 'ETH'}})" :class="{'active' : selectedToken === 'ETH'}"> ETH</a>-->

                <a @click="changeTab('AMIS')" :class="{'active' : selectedToken === 'AMIS'}"> AMIS</a>
                <a @click="changeTab('ETH')" :class="{'active' : selectedToken === 'ETH'}"> ETH</a>
              </div>
              <!-- 아미스 -->
              <div class="menutab-con" v-show="selectedToken === 'AMIS'">
                <div class="money-group">
                  <strong class="data">{{ profile.tokenAmount | numberWithCommas | rewardDecimal }}<em class="unit2">.{{ getMetrDecimalPlace(profile.tokenAmount) }}</em><em class="unit"> AMIS</em></strong>
                </div>
                <div class="btn-group">
                  <button class="outline_m_btn btn-line-black" @click="$router.push({name:'HistoryMetr'})">내역</button>
                </div>
              </div>
              <!-- 이더 -->
              <div class="menutab-con" v-show="selectedToken === 'ETH'">
                <div class="money-group">
                  <strong class="data">{{ ethAmount | numberWithCommas | rewardDecimal}}<em class="unit2">.{{ getMetrDecimalPlace(ethAmount) }}</em><em class="unit"> ETH</em></strong>
                </div>
                <div class="btn-group">
                  <button class="outline_m_btn btn-line-black" @click="$router.push({name:'HistoryMetr', query : {'token' : selectedToken}})">내역</button>
                </div>
              </div>
              <!-- 리워드 -->
            </div>
          </div>
        </div>
<!--        <div class="menulist-article">
          <div class="container">
            <div class="menulist-inner">
              <div class="menulist-item-area">
                <button class="l_btn btn-black w-100 mt-20 mb-20" @click="$router.push({name:'HistoryReusableCub'})">
                  <span class="reusable_cup">리유저블컵 내역</span>
                </button>
              </div>
              <div class="menulist-item-area">
                <a href="#" class="menulist-item">
                  <span class="menulist-item-name">탄소 배출 감소 효과</span>
                  <span class="co2_text">-{{ co2 | numberWithCommas }}gCO₂e</span>
                </a>
              </div>
            </div>
          </div>
        </div>-->
        <div class="menulist-article" v-if="selectedToken === 'AMIS'">
          <div class="container">
            <div class="menulist-inner">
              <div class="menulist-item-area">
                <a class="menulist-item" @click="$router.push({name:'RequestMetr'})">
                  <span class="menulist-item-name">아미스 출금 신청</span>
                  <i class="icon-arrow-right"></i>
                </a>
              </div>
              <div class="menulist-item-area">
                <a class="menulist-item" @click="$router.push({name:'HistoryMetr'})">
                  <span class="menulist-item-name">아미스 거래 내역</span>
                  <i class="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menulist-article" v-else-if="selectedToken === 'ETH'">
          <div class="container">
            <div class="menulist-inner">
              <div class="menulist-item-area">
                <a class="menulist-item" @click="$router.push({name:'RequestMetr', query : {'token' : selectedToken}})">
                  <span class="menulist-item-name">이더리움 출금 신청</span>
                  <i class="icon-arrow-right"></i>
                </a>
              </div>
              <div class="menulist-item-area">
                <a class="menulist-item" @click="$router.push({name:'HistoryMetr', query : {'token' : selectedToken}})">
                  <span class="menulist-item-name">이더리움 거래 내역</span>
                  <i class="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

<!--        <div class="menulist-article">
          <div class="container">
            <div class="menulist-inner">
              <div class="menulist-item-area">
                <a class="menulist-item" @click="$router.push({name:'HistoryCard'})">
                  <span class="menulist-item-name">카드 결제 내역</span>
                  <i class="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>-->
        <div class="menulist-article">
          <div class="container">
            <div class="menulist-inner">
              <div class="menulist-item-area">
                <a class="menulist-item" @click="$router.push({name:'NoticeList'})">
                  <span class="menulist-item-name">공지사항<i class="blind" :class="{'new' : profile.newNotice}">NEW</i></span>
                </a>
              </div>
              <div class="menulist-item-area">
                <a class="menulist-item" @click="$router.push({name:'EventList'})">
                  <span class="menulist-item-name">이벤트</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menulist-article">
          <div class="container">
            <div class="menulist-inner">
              <h5 class="sub-tit">알림설정</h5>
              <div class="menulist-item-area">
                <div class="menulist-item">
                  <span class="menulist-item-link">이벤트 및 광고 알림</span>
                  <p class="toggle-group">
                    <!-- TODO : 수신 허용시 .on 클래스 추가 -->
                    <span class="blind btn-toggle" :class="{'on' : profile.eventAlarm}" @click="changeAlarm('1')">이벤트 및 광고 알림 수신</span>
                  </p>
                </div>
                <div class="menulist-desc">

                </div>
              </div>
              <div class="menulist-item-area">
                <div class="menulist-item">
                  <span class="menulist-item-link">공지사항 알림</span>
                  <p class="toggle-group">
                    <span class="blind btn-toggle" :class="{'on' : profile.noticeAlarm}" @click="changeAlarm('0')">공지사항 알림 수신</span>
                  </p>
                </div>
                <div class="menulist-desc">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menulist-article">
          <div class="container">
            <div class="menulist-inner">
              <div class="menulist-item-area">
                <a class="menulist-item" @click="$router.push({name:'FaqList'})">
                  <span class="menulist-item-name">FAQ / 문의</span>
                </a>
              </div>
              <div class="menulist-item-area">
                <a class="menulist-item" @click="$router.push({name:'TermList'})">
                  <span class="menulist-item-name">약관</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menulist-article">
          <div class="container">
            <div class="menulist-inner">
              <div class="menulist-item-area">
                <a class="menulist-item">
                  <span class="menulist-item-name">앱 버전</span>
                  <span class="version">{{ version }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyPage",
  data() {
    return {
      profile: {
        eventAlarm: false,
        id: '',
        imagePath: '',
        newEvent: false,
        newNotice: false,
        noticeAlarm: false,
        tokenAmount: 0,
        rewardAmount: '',
      },
      ethAmount : 0,
      selectedToken : 'AMIS',
      version: '1.0.0',
      isPending: false,
      co2: 0,
    }
  },
  mounted() {
    let paramToken = this.$route.query.token
    if (paramToken) {
      this.selectedToken = paramToken
    }

    this.$get(this.$USER_INFO, 'mypage_mounted', (result) => {
      this.profile = result.data
      this.profile.eventAlarm ? window.subscribedTopic(process.env.VUE_APP_TOPIC_EVENT) : window.unsubscribedTopic(process.env.VUE_APP_TOPIC_EVENT)
      this.profile.noticeAlarm ? window.subscribedTopic(process.env.VUE_APP_TOPIC_NOTICE) : window.unsubscribedTopic(process.env.VUE_APP_TOPIC_NOTICE)

    }, (result) => {
      this.httpError(result.data)
    })

    this.$get(this.$WALLET_ETH_BALANCE, 'eth-balance', (result) => {
      this.ethAmount = result.data
    }, () => {})

    this.version = window.getVersion()

    /*this.$get(this.$CO2, 'mypage_mounted', (result) => {
      this.co2 = result.data
    }, (result) => {
      this.httpError(result.data)
    })*/
  },

  methods: {

    changeTab(selectToken = 'AMIS') {
      this.$router.replace({name : 'MyPage', query : {'token' : selectToken}}).catch(() => {})
      this.selectedToken = this.$route.query.token
    },

    changeAlarm(type = '0') {
      if (this.isPending) {
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('type', type)

      this.$put(this.$USER_CHANGE_ALARM, 'refreshPush', formData, () => {
        if (type === '0') { //type === '0' 일 경우 공지알람
          this.profile.noticeAlarm = !this.profile.noticeAlarm
          this.profile.noticeAlarm ? window.subscribedTopic(process.env.VUE_APP_TOPIC_NOTICE) : window.unsubscribedTopic(process.env.VUE_APP_TOPIC_NOTICE)
        } else { //type === '1' 일 경우 이벤트알람
          this.profile.eventAlarm = !this.profile.eventAlarm
          this.profile.eventAlarm ? window.subscribedTopic(process.env.VUE_APP_TOPIC_EVENT) : window.unsubscribedTopic(process.env.VUE_APP_TOPIC_EVENT)
        }
        this.isPending = false
      }, () => {
        this.notify('error', '푸시 서비스 등록에 문제가 발생했습니다.')
        this.isPending = false
      })
    },
  }
}
</script>

<style scoped>
.menulist-desc { padding: 10px 0 5px; }
</style>

<template>
  <div class="wrap">
    <!-- 상단 고정 해더 영역 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">비밀번호 변경</h5>
            <div class="w-100">
              <div class="pt-30">
                <MTRInput
                    :type="'password'"
                    :placeholder="'기존 비밀번호를 입력해 주세요.'"
                    :model="old_password"
                    @update="(_model) => {this.old_password=_model}"
                />
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.old_password">{{ errors.old_password }}</p>
              <p class="alert-green mt-15 text-c" v-show="success.old_password">{{ success.old_password }}</p>
              <div class="pt-10">
                <MTRInput
                    :type="'password'"
                    :placeholder="'비밀번호(영문, 숫자, 특수문자 포함 8자 이상)'"
                    :model="password"
                    @update="(_model) => {this.password=_model}"
                />
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.password">{{ errors.password }}</p>
              <p class="alert-green mt-15 text-c" v-show="success.password">{{ success.password }}</p>
              <div class="pt-10">
                <MTRInput
                    :type="'password'"
                    :placeholder="'비밀번호를 한번 더 입력해 주세요.'"
                    :model="passwordCk"
                    @update="(_model) => {this.passwordCk=_model}"
                />
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.passwordCk">{{ errors.passwordCk }}</p>
              <p class="alert-green mt-15 text-c" v-show="success.passwordCk">{{ success.passwordCk }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn btn-black" :class="{'btn-disable' : !(old_password && password && passwordCk)}"
                  :disabled="!(old_password && password && passwordCk)" @click="changePassword()">비밀번호 변경</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTRInput from "../../common/MTRInput";
export default {
  name: "ChangePassword",
  components: {
    MTRInput
  },
  data() {
    return {
      old_password: '',
      password: '',
      passwordCk: '',
      errors: {
        old_password:'',
        password: '',
        passwordCk: '',
      },
      success: {
        old_password:'',
        password: '',
        passwordCk: '',
      },
    }
  },
  methods: {
    errorClear() {
      this.errors = {
        old_password:'',
        password: '',
        passwordCk: '',
      }
      this.success = {
        old_password:'',
        password: '',
        passwordCk: '',
      }
    },
    changePassword() {
      this.errorClear()
      if (this.old_password === '') {
        this.errors.old_password = '기존 비밀번호를 입력해주세요.'
        return
      }
      if (this.password === '') {
        this.errors.password = '새 비밀번호를 입력해주세요.'
        return
      }
      if (this.passwordCk === '') {
        this.errors.passwordCk = '새 비밀번호 확인을 입력해주세요.'
        return
      }
      if (this.password !== this.passwordCk) {
        this.errors.passwordCk = '비밀번호가 일치하지 않습니다.'
        return
      }

      if (this.old_password === this.password) {
        this.errors.passwordCk = '기존 비밀번호는 입력하실수 없습니다..'
        return
      }
      let isNum = /[0-9]/g.test(this.password)
      let isEng = /[a-z]/ig.test(this.password)
      let isSpe = /[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi.test(this.password)
      if (!isNum || !isEng || !isSpe) {
        this.errors.password = '비밀번호는 영문, 숫자, 특수문자 포함 8자 이상으로 입력해주세요.'
        return
      }

      let formData = new FormData()
      formData.append('oldPassword', this.old_password)
      formData.append('newPassword', this.password)
      formData.append('newPasswordCk', this.passwordCk)

      //test
      this.$put(this.$AUTH_UPDATE_MY_PW, 'changepassword_changepassword', formData, () => {
        this.$router.replace({name:'ChangePasswordSuccess'})
      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({name:'ChangePasswordFail',params:{reason:result.data.message}})
      })
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

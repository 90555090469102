<template>
  <div id="app">
    <div class="h-100vh">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <MTRConfirm ref="MTRConfirm"></MTRConfirm>
    <MTRAlert ref="MTRAlert"></MTRAlert>
    <div id="alert-wrap" class="alert-wrap" style="position: fixed"></div>
    <PermissionRequest ref="permission"></PermissionRequest>
  </div>
</template>

<script>

import MTRAlert from "./components/common/MTRAlert";
import MTRConfirm from "./components/common/MTRConfirm";
import PermissionRequest from "./components/intro/PermissionRequest";

export default {
  name: 'App',
  components: {
    PermissionRequest,
    MTRConfirm,
    MTRAlert
  },
  created() {
    this.refreshToken()
  },
  mounted() {
    this.$root.$refs.MTRConfirm = this.$refs.MTRConfirm
    this.$root.$refs.MTRAlert = this.$refs.MTRAlert
    let test = process.env.VUE_APP_TOPIC_NOTICE
    console.log('test : ' + test)
    // test start
    // this.$refs.permission.showDialog()
    // test end
    window.addEventListener(this.$EVENT_NATIVE_MESSAGE, this.getNativeMessage)
  },
  beforeCreate() {
    let console = (function (oldCons) {
      return {
        log: function () {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.log.apply(console, arguments)
          }
        },
        warn: function () {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.warn.apply(console, arguments)
          }
        },
        error: function () {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.error.apply(console, arguments)
          }
        },
      };
    }(window.console));
    window.console = console
  },
  methods: {
    getNativeMessage(e) {
      console.log(e)
      let callback = e.detail.data
      let orderId = e.detail.message.split(',')[0]
      let amount = e.detail.message.split(',')[1]
      //화면 이동
      this.$router.push({
        name: 'PaymentCharge', params: {
          orderId: orderId,
          amount: amount,
          callback: callback,
        }
      })
    },
  }
}
</script>

<style>
input[type=date], textarea {
  width: 100%;
  line-height: 48px;
  padding: 0 20px;
  font-size: 14px;
  color: var(--dark-gray02);
  border: 1px solid var(--light-gray03);
  border-radius: 25px;
  background: var(--white);
  vertical-align: middle;
}

/*내역*/
.date-box {
  width: 110px !important;
  text-align: center;
}

.border-zero {
  border: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* 날짜 필터 */
.date-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-area, .filter-area {
  display: flex;
  align-items: center;
}

.date-area .date-range {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
}

.filter-area .filter-srch {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
}

/*date-picker 위치*/
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:focus, .dropdown-menu > .disabled > a:hover {
  color: #777
}

.dropdown-menu > .disabled > a:focus, .dropdown-menu > .disabled > a:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.open > .dropdown-menu {
  display: block
}

.open > a {
  outline: 0
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

.dropdown-menu-left {
  right: auto;
  left: 0
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px solid
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto
  }

  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0
  }
}
</style>

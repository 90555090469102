<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">신용카드 관리</span>
        </div>
      </div>
    </div>
    <div class="content-wrap-v3" v-show="items.length === 0">
      <div class="container">
        <div class="row confirm-area-bottom">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_15_op mb-30"></i>
              <p class="confirm-subtit">등록된 신용카드가 없습니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrap-v3" v-show="items.length > 0">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <ul class="bank-list">
              <li v-for="(item, index) in items" :key="index">
                <a @click="openSetting(item)">
                  <div class="bank-icon">
                    <i class="sp-card" :class="item.name | cardImage"></i>
                  </div>
                  <div class="bank-summary">
                    <span>{{ item.title }}<i class="badge ml-10" v-show="item.mainCard">주카드</i></span>
                    <em class="disabled">{{ item.alias ? item.alias : '별명없음' }}</em>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="outline_l_btn btn-outline-black" @click="registCard()"><i class="icon more"></i>신용카드 등록</button>
          </div>
        </div>
      </div>
    </div>

    <card-setting
        v-show="showSetting"
        :conditions="typeList"
        :title="'카드 설정'"
        @selected="setSelectedCondition"
        @close="showSetting=false"
    />
  </div>
</template>

<script>
import CardSetting from "./CardSetting";

export default {
  name: "CardList",
  components: {
    CardSetting
  },
  data() {
    return {
      items: [],
      showSetting: false,
      typeList: [
        {
          type: '0',
          name: '주카드 설정/해지',
        },
        {
          type: '1',
          name: '신용카드 별명 변경',
        },
        {
          type: '2',
          name: '삭제',
        },
      ],
      selectedCard: {},
    }
  },
  mounted() {
    // test start
    // this.$router.push({ name:'CardChangeNick' })

    //삭제
    // this.$root.$refs.MTRConfirm.showDialog('신용카드 삭제', '해당 신용카드를 삭제합니다.', '확인', '취소', () => {
    //   this.$delete(this.$CREDITCARD_REMOVE+ '/' + this.selectedCard.idx, 'cardlist_setselectedcondition', () => {
    //     this.notify('success', '성공적으로 삭제되었습니다.', false)
    //     this.getItems()
    //   }, (result) => {
    //     this.httpError(result.data)
    //   })
    // }, () => {
    // })

    // test end
    this.getItems()
  },
  methods: {
    getItems() {
      this.$get(this.$CREDITCARD_LIST, 'cardlist_mounted', (result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    openSetting(_card) {
      this.selectedCard = _card
      this.showSetting = true
    },
    setSelectedCondition(_condition) {
      this.showSetting = false
      console.log(_condition)
      if (_condition.type === '0') {
        let str = '설정'
        if (this.selectedCard.mainCard) {
          str = '해지'
        }
        //주카드 설정
        this.$put(this.$CREDITCARD_SET_MAIN + '/' + this.selectedCard.idx, 'cardlist_setselectedcondition', null, () => {
          this.notify('success', '성공적으로 ' + str + '되었습니다.', false)
          this.getItems()
        }, (result) => {
          this.httpError(result.data)
        })
      } else if (_condition.type === '1') {
        //계좌 별명 변경
        this.$router.push({name:'CardChangeNick',params:{cardIdx:this.selectedCard.idx, oldAlias:this.selectedCard.alias}})
      } else if (_condition.type === '2') {
        //삭제
        this.$root.$refs.MTRConfirm.showDialog('신용카드 삭제', '해당 신용카드를 삭제합니다.', '확인', '취소', () => {
          this.$delete(this.$CREDITCARD_REMOVE+ '/' + this.selectedCard.idx, 'cardlist_setselectedcondition', () => {
            this.notify('success', '성공적으로 삭제되었습니다.', false)
            this.getItems()
          }, (result) => {
            this.httpError(result.data)
          })
        }, () => {
        })

      }
    },
    registCard() {
      this.$router.push({name:'RegistCard'})
    }
  },
  filters: {
    cardImage: (value) => {
      if (value.startsWith('삼성') || value.toLowerCase().startsWith('samsung')) {
        return 'card-02'
      } else if (value.startsWith('비씨') || value.toLowerCase().startsWith('bc')) {
        return 'card-08'
      } else if (value.startsWith('롯데') || value.toLowerCase().startsWith('lotte')) {
        return 'card-05'
      } else if (value.startsWith('현대') || value.toLowerCase().startsWith('hyundai')) {
        return 'card-04'
      } else if (value.startsWith('국민') || value.toLowerCase().startsWith('kb')) {
        return 'card-01'
      } else if (value.startsWith('신한') || value.toLowerCase().startsWith('shinhan')) {
        return 'card-03'
      } else if (value.startsWith('시티') || value.toLowerCase().startsWith('citi')) {
        return 'card-09'
      } else if (value.startsWith('농협') || value.toLowerCase().startsWith('nh')) {
        return 'card-11'
      } else if (value.startsWith('하나') || value.toLowerCase().startsWith('hana')) {
        return 'card-06'
      } else if (value.startsWith('비자') || value.toLowerCase().startsWith('visa')) {
        return 'card-15'
      } else if (value.startsWith('마스터') || value.toLowerCase().startsWith('master')) {
        return 'card-16'
      }
      return 'bank-30'
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <!-- 하단 네비영역 -->
  <div class="nav-wrap nav-pay">
    <div class="container">
      <div class="nav-row">
        <div class="nav-group">
          <!-- TODO : 네비로 이동시 active 추가 -->
          <div class="nav-group-box">
            <a class="nav-landing active">
              <i class="icon ver_2 asset1">락업</i>
              <span class="landing-name">락업</span>
            </a>
            <a class="nav-landing" @click="openSheetBar('trade')">
              <i class="icon ver_2 asset2">내역</i>
              <span class="landing-name">내역</span>
            </a>
          </div>
          <div class="nav-group-box">
            <a class="nav-landing" @click="openSheetBar('withdraw')">
              <i class="icon ver_2 asset3">출금</i>
              <span class="landing-name">출금</span>
            </a>
            <a class="nav-landing" @click="$router.push({name:'MyPage'})">
              <i class="icon ver_2 asset4">마이</i>
              <span class="landing-name">마이</span>
            </a>
          </div>
        </div>
        <div class="payment-group">
          <a class="nav-payment" @click="openSheetBar('send')">
            <div class="payment-area">
            </div>
            <span class="payment-name">송금</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomNav",
  methods: {
    //거래내역 하단 시트바 오픈
    openSheetBar(sheetBarType = 'trade') {
      this.$emit('test', sheetBarType)
    },
  }
}
</script>

<style scoped>

</style>
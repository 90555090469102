<template>
  <div class="wrap">
    <div class="intro-bg">
      <div class="container">
        <div class="row">
          <div class="intro-wrap">
            <div class="intro-box flex-center-wrap">
              <img src="/assets/image/intro.png" class="intro-logo" alt="일상을 편하게 변화시킬, 아미스">
            </div>
            <div class="intro-box">
              <p class="copyright">Copyright © 2022 AMIS Inc.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  mounted() {

    //네이티브 메세지를 못받는 상태값으로 변경
    window.isAlready = false
    window.changeAlreadyState()

    if (localStorage.getItem('passToken')) {
      this.autoLogin()
    } else {
      this.$router.replace({name : 'Login'})
    }
  },
  methods : {
    /*autoLogin() {
      setTimeout(() => {
        //자동 로그인
        let passToken = localStorage.getItem('passToken')
        if(passToken) {
          let formData = new FormData()
          formData.append('passToken', passToken)

          this.$post(this.$AUTH_AUTOLOGIN, 'intro_login_doAutoLogin', formData, (result) => {
            //자동로그인 후처리
            sessionStorage.setItem('accessToken', result.data.accessToken)
            sessionStorage.setItem('refreshToken', result.data.refreshToken)
            localStorage.setItem('passToken', result.data.passToken)

            //리프레시 토큰 처리
            setTimeout(() => {
              this.refreshToken()
            }, this.refreshTokenRate)

            //fcm, 토픽 처리 후 메인으로
            this.refreshPush()
            this.setPushAlarm(() => {
              localStorage.setItem('firstPinChecked', '0')
              this.$router.replace({ name: 'Main' })
            })

          }, (result) => {
            //자동로그인 실패 처리(로컬 스토리지 토큰 비우기)
            sessionStorage.removeItem('accessToken')
            sessionStorage.removeItem('refreshToken')
            localStorage.removeItem('passToken')
            localStorage.removeItem('useLocalBio')

            if (result.data.state === 400) {
              console.log(result.data.data)
              if (result.data.data.state === '1') { //탈퇴
                this.$router.push({ name: 'LeaveUser' })
              } else if (result.data.data.state === '2') { //정지
                this.$root.$refs.MTRAlert.showAlert('아미스 정책 위반',
                    '아미스 정책 위반으로 현재 계정은<br/>사용정지 처리되었습니다.',
                    '카드사 환불로 인한 어뷰징',
                    '22.04.01 00:00 ~ 22.04.08 00:00 (7일)',
                    () => {
                      this.$router.replace({ name: 'BeforeLogin' })
                    })
              } else if (result.data.data.state === '3') { //휴면
                this.$root.$refs.MTRConfirm.showDialog('아미스 휴면계정',
                    '현재 계정은 1년간 접속하지 않아<br/>휴면 상태로 전환된 상태입니다.<br/>' +
                    '휴면 계정 해제를 통해<br/>정상적으로 이용하실 수 있습니다.',
                    '휴면계정 해제', '취소', () => {
                      this.$router.push({name:'InactivityRequest'})
                    }, () => {
                      this.$router.replace({ name: 'BeforeLogin' })
                    })
              } else {
                this.httpError(result.data)
                this.$router.replace({ name: 'BeforeLogin' })
              }

            } else {
              this.httpError(result.data)
              this.$router.replace({ name: 'BeforeLogin' })
            }
          })
        } else {
          this.$router.replace({ name: 'BeforeLogin' })
        }
      }, 500)
    }*/


  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <!-- 결제예정금액 표시 -->
  <!-- 결제방식 -->
  <div class="payment_detail">
    <div class="container">
      <div class="row">
        <div class="detail_section">
          <!-- 아미스 -->
          <div class="clear">
            <div class="title">
              <h3>아미스</h3>
              <p>보유<strong class="price">{{ metrBalance }}.{{ getMetrDecimalPlace(metrBalance) }} AMIS</strong></p>
            </div>
            <div class="ex_amount clear">
              결제 예정 금액
              <!-- TODO : 결제예정금액 부족시 .error 추가 -->
              <strong class="">{{ paymentAmount }} AMIS</strong>
            </div>
            <em class="unit1 pt-10">(1AMIS={{ metrToKrw }}원)</em>
          </div>
          <!-- 리워드 -->
          <div>
            <div class="title">
              <h3>리워드</h3>
              <p>보유<strong class="price primary">{{ metrPoint }}.{{ getMetrDecimalPlace(metrPoint) }} AMIS</strong></p>
            </div>
            <div class="input_form type1">
              <div>
                <input type="number" placeholder="사용 리워드 입력" v-model="usePoint">
                <!-- TODO : 입력창 클릭시 단위표시 -->
                <span class="input_unit">Metr</span>
              </div>
              <button class="l_btn btn-black" @click="useFullPoint">전액사용</button>
            </div>
            <div class="checks always">
              <input type="checkbox" id="alwaysUseFull">
              <label for="alwaysUseFull">항상 전액 사용</label>
            </div>
          </div>
        </div>
        <div class="detail_descript">
          <p>실시간 가격변동으로 인해 결제완료 후, 결제예정금액과 차이가 있을 수 있습니다.</p>
          <p>퀵오더는 주문완료 후 취소가 어렵습니다. 기타 사항은 해당 가맹점으로 문의 부탁드립니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MetrPayment",
  props: ['orderProductPrice'],
  data() {
    return {
      metrBalance : 0,  //현재 지갑잔고
      metrBalance_tmp : 0,  //계산을 위해 현재 지갑잔고를 임시로 가지고 있을 변수

      metrPoint : 0,  //현재 리워드잔고
      metrPoint_tmp : 0,  //계산을 위해 리워드를 임시로 가지고있을 변수

      paymentAmount : 0,  //결제 예정금액
      paymentAmount_tmp : 0,

      metrToKrw : 0,  //1아미스당 원화시세
      krwToMetr : 0,  //1원당 아미스시세
      usePoint : 0, //사용할 리워드
      isUseFullPoint : false,  //리워드 전액사용 여부
    }
  },

  mounted() {
    this.getMyMtrBalance()
    this.getMyMtrPoint()
    this.exchangeKrwToMtr()
    this.exchangeMetrToKrw()
  },

  methods : {
    getMyMtrBalance() {
      this.$get(this.$WALLET_REAPCHAIN_BALANCE, 'MTR_BALANCE', (result) => {
        this.metrBalance = result.data
        this.metrBalance_tmp = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },

    //리워드나 리워드나 같은말임.  내 리워드(리워드) 잔고를 가져온다.
    getMyMtrPoint() {
      this.$get(this.$WALLET_REWARD_BALANCE, 'MTR_POINT', (result) => {
        this.metrPoint = result.data
        this.metrPoint_tmp = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },

    //1원당 아미스시세 KRW-METR
    exchangeKrwToMtr() {
      this.$get(this.$TOKEN_PRICE, 'metrToKrwPrice', (result) => {
        this.metrToKrw = parseFloat(result.data)
      }, (result) => {
        this.httpError(result.data)
      })
    },
    //1아미스당 원화시세  METR-KRW
    exchangeMetrToKrw() {
      this.$get(this.$KRW_PRICE, 'krwToMetrPrice', (result) => {
        this.krwToMetr = parseFloat(result.data)
        this.metrToKrw = 1 / this.krwToMetr

        this.paymentAmount = this.orderProductPrice * this.krwToMetr
        this.paymentAmount_tmp = this.paymentAmount

        this.$store.commit('setKrwToMetr', {krwToMetr : this.krwToMetr})
        this.$store.commit('setMetrToKrw', {metrToKrw : this.metrToKrw})
        this.$emit('getExchangeKrwToMetr', this.krwToMetr)
      }, (result) => {
        this.httpError(result.data)
      })
    },

    useFullPoint() {
      //보유리워드가 결제금액보다 더 클 경우
      if (this.paymentAmount < this.metrPoint) {
        this.usePoint = this.paymentAmount
        this.metrPoint = this.metrPoint - this.usePoint
        this.paymentAmount = this.paymentAmount_tmp - this.usePoint

      } else {
        this.paymentAmount = this.paymentAmount_tmp - this.metrPoint
        this.usePoint = this.metrPoint
      }

      this.isUseFullPoint = true
    },
  },

  watch: {
    usePoint() {
      if (this.metrPoint_tmp < this.usePoint) { //현재 가지고 있는 리워드가 입력한 사용 리워드보다 적을경우
        this.usePoint = this.metrPoint_tmp
      }
      this.metrPoint = this.metrPoint_tmp - this.usePoint
      this.paymentAmount = this.paymentAmount_tmp - this.usePoint

      if (this.paymentAmount < 0) {
        this.usePoint = this.paymentAmount_tmp
      }
      this.$emit('setUseMetrPoint', this.usePoint)
    },

    paymentAmount() {
      this.metrBalance = this.metrBalance_tmp - this.paymentAmount
    }
  }

}
</script>

<style scoped>
.primary { color: var(--meterra-green); }
.l_btn { height: 50px; padding: 0 10px; min-width: 86px; border-radius: 25px; font-size: 16px; font-weight: 500; color: var(--white); cursor: pointer;  }
</style>

let termOfUse = "<p style=\"line-height: 100%; margin-bottom: 0in\">\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보 처리방침</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">장\n" +
    "총칙</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【목적 및 정의】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관은 </span></font>AMIS Platform Corporation(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이하\n" +
    "“회사”라 합니다</span></font>)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이\n" +
    "제공하는 “</span></font>AMIS Wallet” <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스의\n" +
    "이용과 관련하여 회사와 이용자 간의 권리</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">의무 책임사항 및\n" +
    "기타 제반사항을 규정함을 목적으로 합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>2<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【용어의 정의】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관에서 사용하는 용어의 정의는 다음과 같습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1. “<font face=\"맑은 고딕\"><span lang=\"ko-KR\">블록체인”이란\n" +
    "전자적인 블록에 데이터를 담아 각 블록을 체인과 같이\n" +
    "순차적으로 연결하는 기술로</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">누구나\n" +
    "열람할 수 있는 장부에 거래 내역을 기록하고 블록체인\n" +
    "네트워크에 참여한 각 컴퓨터에 이를 복제하여 저장하는\n" +
    "분산형 데이터 저장 방식을 말합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">즉\n" +
    "거래 내용이 담긴 전자적인 블록이 형성되어 이 블록이\n" +
    "네트워크에 있는 모든 참여자에게 전송되면 참여자들은\n" +
    "해당 거래의 타당성 여부를 확인 및 승인하고 승인된\n" +
    "블록만이 기존 블록체인에 연결되는 방식입니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2. “<font face=\"맑은 고딕\"><span lang=\"ko-KR\">가상자산”이란\n" +
    "블록체인 기술을 활용한 분산형 시스템 방식으로 처리\n" +
    "및 유통되는 전자화폐의 일종을 말합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3. “<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자”란\n" +
    "본 약관에 따라 서비스에 가입 신청을 하고</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사의 승낙을 받아\n" +
    "회사와 서비스 이용계약을 체결한 자를 말합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4. “AMIS <font face=\"맑은 고딕\"><span lang=\"ko-KR\">토큰”은\n" +
    "회사에서 발행하는 가상자산으로</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">고유의\n" +
    "블록체인 플랫폼인 </span></font>AMIS Platform(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이하\n" +
    "“플랫폼”이라 합니다</span></font>) <font face=\"맑은 고딕\"><span lang=\"ko-KR\">내에서\n" +
    "통용되는 가상자산으로</span></font>, AMIS Wallet<font face=\"맑은 고딕\"><span lang=\"ko-KR\">에서\n" +
    "다양한 디지털 자산의 기축역할을 하고 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">5. “<font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스”\n" +
    "란 가상자산을 보관 및 관리하고 타인에게 가상자산을\n" +
    "송금할 수 있도록 회사가 제공하는 블록체인 기술\n" +
    "기반의 지갑 서비스를 의미합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관에서 ‘서비스’는 다른 언급이 없을 경우</span></font>,\n" +
    "“AMIS Wallet” <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스를\n" +
    "가리키며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">상세한\n" +
    "내용은 제</span></font>14<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조에서\n" +
    "규정한 바에 따릅니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">6. “AMIS Wallet\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">어플리케이션”</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이하\n" +
    "“앱”이라 합니다</span></font>)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">란\n" +
    "회사가 서비스 제공 목적으로 이용자에게 제공하는\n" +
    "전용 어플리케이션을 말합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">7. “<font face=\"맑은 고딕\"><span lang=\"ko-KR\">계정”이란\n" +
    "서비스에서 본인을 식별하기 위해 생성된 계정으로\n" +
    "이용자에게 부여되는 서비스 계정을 말합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">8. “<font face=\"맑은 고딕\"><span lang=\"ko-KR\">계정\n" +
    "주소”란 서비스에서 이용자 간 </span></font>AMIS <font face=\"맑은 고딕\"><span lang=\"ko-KR\">토큰의\n" +
    "거래 등 가상자산의 변화를 기록하기 위해 플랫폼에\n" +
    "존재하는 고유한 식별 주소를 말합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">9. “<font face=\"맑은 고딕\"><span lang=\"ko-KR\">비밀번호</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">또는\n" +
    "</span></font>PIN <font face=\"맑은 고딕\"><span lang=\"ko-KR\">번호</span></font>)”<font face=\"맑은 고딕\"><span lang=\"ko-KR\">란\n" +
    "서비스 부정사용 및 부정접근을 방지하기 위하여\n" +
    "사용되는 이용자 인증 암호로 이용자 자신이 별도로\n" +
    "설정한 숫자의 조합을 말합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관에서 사용하는 용어의 정의는 전 항에서 정하는\n" +
    "것을 제외하고는 관계 법령에서 정하는 바에 따릅니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【약관의 게시 및 효력 발생】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "본 약관의 내용을 이용자가 알 수 있도록 서비스 화면에\n" +
    "게시하거나 기타의 방법으로 회원에게 공지하고</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이에 동의한 회원이\n" +
    "서비스에 가입함으로써 효력이 발생합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>4<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【약관의 변경】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "필요하다고 인정되는 경우 본 약관을 변경할 수 있으며</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가 약관을 변경할\n" +
    "경우 적용일자 및 변경사항을 명시하여 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조와\n" +
    "같은 방법으로 그 적용일자 </span></font>7<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일\n" +
    "이전부터 적용일자까지 공지합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">다만\n" +
    "이용자에게 불리한 약관의 변경이거나 서비스와 관련하여\n" +
    "중대한 사항의 변경인 경우에는 적어도 그 적용일자\n" +
    "</span></font>30<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일 전부터\n" +
    "공지하며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">그렇지\n" +
    "않을 경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">해당\n" +
    "변경사항은 공지 후 </span></font>30<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일이\n" +
    "경과한 날부터 변경효력이 발생합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "전항에 따라 변경 약관을 공지하면서 이용자에게 약관\n" +
    "변경 적용일까지 거부의사를 표시하지 아니할 경우\n" +
    "약관의 변경에 동의한 것으로 간주한다는 내용을 별도로\n" +
    "고지할 수 있고</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">그와\n" +
    "같이 고지하였음에도 불구하고 이용자가 명시적으로\n" +
    "거부의 의사표시를 하지 않은 경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "변경 약관에 동의한 것으로 간주할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "변경 약관에 동의하지 않는다는 의사표시를 한 경우</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는 변경 약관의\n" +
    "내용을 적용할 수 없으며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이\n" +
    "경우 회사는 이용자의 서비스 이용을 제한하거나\n" +
    "이용자와의 계약을 해지할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">④ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "관련 법령에 위배하지 않는 범위에서 본 약관을 변경할\n" +
    "수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>5<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【약관의 해석 및 약관 외 준칙】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관에서 정하지 아니한 사항과 약관의 해석에 대하여는\n" +
    "관련 법령 또는 상관례에 따릅니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이 약관 외에 별도의 운영정책을 둘 수 있으며 개별\n" +
    "운영 정책 및 세부 이용 지침과 관련하여 변경이 있는\n" +
    "경우 회사는 이를 자신의 웹사이트 내에 공지합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>6<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【이용자에 대한 정보의 제공 및 광고의 게재】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자에 대한 통지를 위해 이용자가 회사에 제공한\n" +
    "이메일 주소 또는 전화 번호를 이용하거나 기타 합리적으로\n" +
    "적절한 수단을 이용할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "연락처를 기재하지 않거나</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">기존\n" +
    "연락처가 변경되었음에도 불구하고 이를 회사에 알리지\n" +
    "아니하거나</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자\n" +
    "전체에 대한 통지가 필요한 경우 등 개별 통지가 어려운\n" +
    "경우에는</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "그 통지사항을 </span></font>1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">주일\n" +
    "이상 서비스 앱 내 공지 사항 게시판에 게시함으로써\n" +
    "전항의 개별통지를 대신할 수 있습니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">다만</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자에게 불리하거나\n" +
    "중대한 사항에 대한 통지의 경우에는 게시기간을\n" +
    "</span></font>30<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일로\n" +
    "합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "서비스의 운영과 관련하여 서비스 화면</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">홈페이지에 광고를\n" +
    "게재할 수 있으며 수신 동의한 회원에 대해 전자우편\n" +
    "등의 방법으로 이를 제공할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">④ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "서비스 상에 게재되어 있거나 서비스를 통한 광고주와의\n" +
    "판촉활동에 회원이 참여하거나 교신 또는 거래로서\n" +
    "발생하는 모든 손실 또는 손해에 대해 책임지지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>7<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【회사정보 등의 제공】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "다음 각 호의 사항을 회원이 알아보기 쉽도록 서비스\n" +
    "내에 표시합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">다만</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보처리방침과\n" +
    "약관은 회원이 연결화면을 통하여 볼 수 있도록 할 수\n" +
    "있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">상호\n" +
    "및 대표자의 성명</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">영업소\n" +
    "소재지 주소</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회원의\n" +
    "불만을 처리할 수 있는 곳의 주소를 포함한다</span></font>)</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">전화번호</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">전자우편주소</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">사업자\n" +
    "등록번호</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">5. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보처리방침</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">6. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "이용약관</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>2<font face=\"맑은 고딕\"><span lang=\"ko-KR\">장\n" +
    "서비스 이용 계약</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>8<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【이용계약의 성립】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사와\n" +
    "이용자 간의 서비스 이용계약은 회원이 되고자 하는\n" +
    "자</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이하\n" +
    "“가입신청자”라 합니다</span></font>)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">가\n" +
    "회사가 정한 가입양식에 따라 회원 정보를 기입한 후\n" +
    "본 약관 및 개인정보 수집 및 이용에 대한 안내에 대해\n" +
    "동의한다는 의사표시를 함으로써 회원가입을 신청한\n" +
    "다음</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가\n" +
    "본인확인 인증절차를 마친 후 회사가 이를 승낙함으로써\n" +
    "성립합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">가입신청자가\n" +
    "본 약관 및 개인정보 수집 및 이용에 대한 안내에\n" +
    "“동의” 버튼을 누르면 동의한 것으로 간주됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "가입신청자에 대해 회사가 정한 본인확인 인증절차에\n" +
    "따라 실명확인 및 본인인증을 요청할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">④ PIN <font face=\"맑은 고딕\"><span lang=\"ko-KR\">번호를\n" +
    "비롯한 비밀번호 관리에 대한 일체의 책임은 이용자\n" +
    "본인에게 있습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "자신의 인증번호 정보를 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자에게\n" +
    "대여 또는 양도하거나 이와 유사한 행위를 해서는\n" +
    "아니되며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">관리를\n" +
    "소홀히 하여 발생되는 모든 책임은 이용자 본인에게\n" +
    "있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>9<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【이용신청에 대한 승낙의 제한】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "가입신청자의 가입 신청에 대해 승낙함을 원칙으로\n" +
    "합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">다만</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는 다음 각호의\n" +
    "어느 하나에 해당하는 이용 신청에 대하여는 승낙을\n" +
    "거절할 수 있으며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">가입이\n" +
    "된 이후에도 다음 각 호의 사유가 확인될 경우에는\n" +
    "승낙을 취소할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">기술상\n" +
    "서비스 제공이 불가능한 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">가입신청자가\n" +
    "가입신청서 내용을 실명이 아니거나 다른 사람의 명의를\n" +
    "사용하는 등 허위로 기재하는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">가입신청자가\n" +
    "가입신청서의 필수 제공사항인 정보를 누락하거나\n" +
    "오기하여 신청하는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">가입신청자의\n" +
    "나이가 만</span></font>14<font face=\"맑은 고딕\"><span lang=\"ko-KR\">세\n" +
    "미만인 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">5. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">부정한\n" +
    "용도 또는 별개의 영업을 목적으로 서비스를 이용하고자\n" +
    "하는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">6. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">자금세탁\n" +
    "등의 불법이나 부정한 목적으로 이용하려는 경우</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "거래나 이용목적에서 의심이 드는 경우 관련 법령에\n" +
    "따라 추가적인 정보나 이용 목적 등의 제공을 요청할\n" +
    "수 있습니다</span></font>)</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">7. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">관련\n" +
    "법령에 위배되거나 사회의 안녕질서 또는 미풍양속을\n" +
    "저해하거나 저해할 목적으로 신청하는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">8. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">해킹\n" +
    "및 사고가 발생한 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">9. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">기타\n" +
    "그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고\n" +
    "판단되는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "다음 각 호의 어느 하나에 해당하는 경우 그 사유가\n" +
    "해소될 때까지 승낙을 유보할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사의\n" +
    "설비에 여유가 없거나</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">특정\n" +
    "모바일 기기의 지원이 어렵거나</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">기술적\n" +
    "장애가 있는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">그\n" +
    "밖의 각 호에 준하는 사유로서 이용신청의 승낙이\n" +
    "어렵다고 판단되는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>10<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【이용자의 계약 해지</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용\n" +
    "중지 요청】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "언제든지 서비스 내 계정관리 메뉴를 통하여 서비스\n" +
    "이용계약 해지를 신청할 수 있으며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이를 즉시 처리합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">다만\n" +
    "이용자는 회사 이용 계약 해지 기준 및 절차에 따라\n" +
    "가상자산을 외부로 이전한 후 해지 신청을 하여야\n" +
    "합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">해지시\n" +
    "회원의 개인정보는 회사의 개인정보처리방침에 따라\n" +
    "파기됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>11<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【회사의 계약 해지 및 회원에 대한 서비스 이용제한】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자가 본 약관 또는 관련 법령을 위반하거나</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">그 밖에 회사가\n" +
    "이용자와의 계약을 해지해야 할 부득이한 사정이 발생한\n" +
    "경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>6<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조에서\n" +
    "정한 방법 기타 적절한 방법으로 해당 이용자에게\n" +
    "일정한 기간을 정하여 당해 사유의 시정을 요구할 수\n" +
    "있고</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">그\n" +
    "기간 내에 시정이 이루어지지 않거나 해지가 필요한\n" +
    "사정이 해소되지 않는 경우 해지 통지를 보내 이용자와의\n" +
    "서비스 이용 계약을 해지하거나</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자에게\n" +
    "경고를 하거나 이용자의 서비스 이용을 제한할 수\n" +
    "있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가\n" +
    "제</span></font>1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">항에\n" +
    "따라 이용 제한 조치를 하는 경우에는 다음 각 호의\n" +
    "사항을 이용자에게 상당한 기간 전에 사전 통지합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용제한\n" +
    "조치의 사유</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용제한\n" +
    "조치의 유형 및 기간</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용제한조치에\n" +
    "대한 이의신청 방법</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "전항에도 불구하고 명의도용</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">불법프로그램의\n" +
    "제공 및 운영방해</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">불법통신\n" +
    "및 해킹</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">악성프로그램의\n" +
    "배포</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">접속권한\n" +
    "초과행위 등과 같이 관련법을 위반한 경우를 포함하여\n" +
    "회사가 즉시 해지 및 이용 제한 조치를 취해야 할 사유가\n" +
    "발생한 경우 시정을 촉구하는 기간을 별도로 두지\n" +
    "아니하고</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">사후에\n" +
    "통지할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">④ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "본 조에 따라 서비스 이용 계약을 해지하거나 제한하는\n" +
    "경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "이미 보유한 가상자산에 대하여는 일단 동결한 이후에\n" +
    "관련 법령 및 관할 정부기관 등의 처분에 따라 처리하며</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">그러한 처분이 필요\n" +
    "없는 경우 적절한 방법으로 정산 처리합니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">다만 이 경우에도\n" +
    "개인 키는 소멸되지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>12<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【이용 제한 조치에 대한 이의신청 절차】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "회사의 이용제한 조치에 불복하고자 할 때에는 제</span></font>11<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "제</span></font>2<font face=\"맑은 고딕\"><span lang=\"ko-KR\">항에\n" +
    "따른 통지를 받거나 서비스 이용이 제한된 날로부터\n" +
    "</span></font>30<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일 이내에\n" +
    "불복 이유를 기재한 이의 신청서를 서면</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">전자우편 또는 이에\n" +
    "준하는 방법으로 회사에 제출하여야 합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "제</span></font>1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">항의\n" +
    "이의신청서를 접수한 날부터 </span></font>14<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일\n" +
    "이내에 서면</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">전자우편\n" +
    "또는 이에 준하는 방법으로 답변합니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">다만</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는 이 기간 내에\n" +
    "답변이 어려운 경우에는 그 사유와 처리일정을 회원에게\n" +
    "통지합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자의 이의 신청이 정당하다고 인정하는 경우</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">즉시 이용자의 서비스\n" +
    "애용을 재개합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>13<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【개인정보의 보호】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자의 서비스 이용에 필요한 최소한의 개인정보를\n" +
    "이용자의 동의를 얻어 수집할 수 있습니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">해당 개인정보에\n" +
    "대해서는 개인정보 보호 관련 법령이 적용됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자의 개인정보를 보호하기 위해 최선을 다합니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가 이용자의\n" +
    "개인정보를 보호하고 취급하는 방법은 서비스 앱 내\n" +
    "게시된 회사의 개인정보취급방침을 참조하시기 바랍니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "수집된 개인정보의 취급 및 관리 등의 업무를 스스로\n" +
    "수행함을 원칙으로 하나</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">필요한\n" +
    "경우 위 업무의 일부 또는 전부를 회사가 선정한 회사에\n" +
    "위탁할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">④ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "최근의 서비스 이용일부터 연속하여 </span></font>1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">년\n" +
    "동안 회사의 서비스를 이용하지 않은 회원</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이하\n" +
    "“휴면계정”이라 합니다</span></font>)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">의\n" +
    "개인정보를 보호하기 위해 이용계약을 해지하고 회원의\n" +
    "개인정보 파기 등의 조치를 취할 수 있습니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이 경우 조치일\n" +
    "</span></font>30<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일 전까지\n" +
    "계약해지</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "파기 등의 조치가 취해진다는 사실 및 파기될 개인정보\n" +
    "등을 회원에게 제</span></font>6<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조의\n" +
    "방법으로 통지합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">⑤ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "계정 생성 및 서비스 이용 과정에서 정확한 정보를\n" +
    "입력 또는 업데이트 해야 하며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이를\n" +
    "철저히 관리해야 합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "제공한 정보가 정확하지 않거나</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자의\n" +
    "관리 소홀로 인하여 제</span></font>6<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조에\n" +
    "따른 통지를 받지 못하거나 정보가 유출되어 발생한\n" +
    "불이익에 대해서 회사는 책임을 지지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">장\n" +
    "서비스 이용</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>14<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【서비스의 제공】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자에게 아래와 같은 서비스를 제공합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">새로운\n" +
    "계정 주소의 생성</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">보유\n" +
    "</span></font>AMIS <font face=\"맑은 고딕\"><span lang=\"ko-KR\">토큰\n" +
    "등의 조회</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">보유\n" +
    "</span></font>AMIS <font face=\"맑은 고딕\"><span lang=\"ko-KR\">토큰\n" +
    "전송 및 수취</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4. AMIS Lock Up,\n" +
    "Staking <font face=\"맑은 고딕\"><span lang=\"ko-KR\">및 보상</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">5. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">기타\n" +
    "회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을\n" +
    "통해 이용자에게 제공하는 일체의 서비스</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>15<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【서비스의 수수료】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자의 서비스 이용에 대하여 다음과 같은 수수료를\n" +
    "수취합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "서비스를 통해 </span></font>AMIS <font face=\"맑은 고딕\"><span lang=\"ko-KR\">토큰을\n" +
    "송금하는 경우 회사는 송금하는 이용자로부터</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "서비스를 통해 </span></font>AMIS <font face=\"맑은 고딕\"><span lang=\"ko-KR\">토큰을\n" +
    "지불받는 경우 회사는 지불받는 이용자로부터</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "수수료는 서비스 또는 서비스 홈페이지를 통해 별도\n" +
    "게재하며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "수수료가 변경되는 경우 </span></font>6<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조에\n" +
    "따라 사전 공지 후 적용합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>16<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【서비스의 변경</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">중단\n" +
    "및 종료】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스는\n" +
    "연중무휴</span></font>, 1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일\n" +
    "</span></font>24<font face=\"맑은 고딕\"><span lang=\"ko-KR\">시간\n" +
    "제공함을 원칙으로 합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">단</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사의 업무</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">기술</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">운영상의 이유로\n" +
    "서비스의 전부 또는 일부가 일정 기간 중지될 수\n" +
    "있습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이\n" +
    "경우 중지의 사유와 기간을 제</span></font>6<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조의\n" +
    "방법으로 통지합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">다만</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">다음 각 호에 해당하는\n" +
    "회사가 사전에 통지할 수 없는 부득이한 사유가 있는\n" +
    "경우 사후에 통지할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">시스템\n" +
    "정기정검</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">보수\n" +
    "등 공사로 인하여 부득이한 경우</span></font>;</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">영업양도</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">분할</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">합병 등에 따른\n" +
    "영업의 폐지</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "제공의 계약 만료</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">당해\n" +
    "서비스의 현저한 수익 악화 등 경영상의 중대한 사유로\n" +
    "인해 서비스를 지속하기 어려운 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">가상자산\n" +
    "시장의 중단 또는 그에 준하는 불가항력적 사건이\n" +
    "발생한 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">천재지변</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">국가비상사태 등\n" +
    "불가항력적인 사유가 발생한 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">5. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">기타\n" +
    "회사의 제반 사정으로 서비스를 유지할 수 없는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "정상적인 절차에 의한 것이거나 사전 공지된 서비스의\n" +
    "변경</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">중지\n" +
    "및 종료로 발생하는 문제에 대해서는 이용자에게 어떠한\n" +
    "책임도 지지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>17<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【회사의 의무】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "본 약관에서 정한 바에 따라 계속적</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">안정적으로\n" +
    "서비스를 제공하도록 노력합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "서비스 제공과 관련하여 알게 된 회원의 개인정보를\n" +
    "본인의 동의 없이 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자에게\n" +
    "누설</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">배포하지\n" +
    "않습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">단</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">관계 법령에 따라\n" +
    "적법한 절차에 의한 경우에는 그러하지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "서비스의 운영</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">유지와\n" +
    "관련 있는 법규를 준수합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>18<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【서비스 이용 관련 유의사항】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "본조 제</span></font>5<font face=\"맑은 고딕\"><span lang=\"ko-KR\">항상의\n" +
    "사유가 있거나 그와 같은 사유로 인한 이용자의 피해\n" +
    "발생을 방지하기 위해 필요한 경우 서비스를 통해\n" +
    "요청된 거래의 처리를 거부하거나 거래 금액 및 기타\n" +
    "다른 거래조건에 제한을 둘 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "어플리케이션을 삭제하거나</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">휴대폰을\n" +
    "변경</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">분실\n" +
    "어플리케이션을 다시 설치하고자 할 경우</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가 기존의\n" +
    "계정과 다른 계정을 사용하게 되면 기존에 보유하였던\n" +
    "자산에 접근이 불가능합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "</span></font>PIN <font face=\"맑은 고딕\"><span lang=\"ko-KR\">번호나\n" +
    "인증에 사용하였던 정보를 분실하여 어플을 이용하지\n" +
    "못함으로 인하여 발생하는 손해는 모두 이용자 스스로\n" +
    "부담합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">④ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "본 약관의 규정</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">관계법령</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용안내 및 서비스상에\n" +
    "공지한 주의사항</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가\n" +
    "통지하는 사항 등을 준수하여야 합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">⑤ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회원은\n" +
    "다음 각 호와 같은 행위를 해서는 안 됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "이용신청 또는 변경 시 허위 사실을 기재하거나 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자의\n" +
    "정보를 도용</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">부정하게\n" +
    "사용하는 행위</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">휴대전화\n" +
    "명의자의 허락 없이 실 사용자가 인증 등을 수행하는\n" +
    "행위</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자의\n" +
    "인증정보를 등록하여 서비스를 이용하는 행위</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사의\n" +
    "서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙\n" +
    "없이 복제 또는 유통시키거나 상업적으로 이용하는\n" +
    "행위</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스와\n" +
    "관련된 설비의 오작동이나 정보 등의 파괴 및 혼란을\n" +
    "유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는\n" +
    "유포하는 행위</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">5. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사와\n" +
    "기타 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자의\n" +
    "저작권 등 지식재산권</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">인격권\n" +
    "등을 침해하는 행위</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">6. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">다른\n" +
    "회원의 개인정보를 수집</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">저장</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">공개하는 행위</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">7. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">기타\n" +
    "회사가 게시한 정보</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스에\n" +
    "대한 회사의 소유권</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">지식재산권\n" +
    "기타 권리에 관한 표시나 통지내용을 임의로 제거</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">모호하게 하거나\n" +
    "변경하는 행위</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">8. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">기타\n" +
    "불법적이거나 부당한 행위</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">⑥ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "제</span></font>5<font face=\"맑은 고딕\"><span lang=\"ko-KR\">항에\n" +
    "명시된 행위를 하여 회사에 손해가 발생한 경우 손해배상\n" +
    "청구 등의 조치를 할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">⑦ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "인증정보 등을 도난당하거나 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자가\n" +
    "사용하고 있음을 인지한 경우에는 즉시 인증정보 등을\n" +
    "변경하는 등의 조치를 취하여야 하며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이와\n" +
    "동시에 회사에 통보하고</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사의\n" +
    "안내가 있는 경우에는 그에 따라야 합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">⑧ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "서비스 이용 과정에서 수집된 제반 정보는 서비스\n" +
    "개선∙향상</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">신규\n" +
    "서비스 개발 및 특화 등 목적으로 분석<font face=\"Noto Sans CJK KR\">‧</font>활용될\n" +
    "수 있습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "이와 같은 목적으로 회사가 정보를 사용하는 것을\n" +
    "허락합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>19<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【회원정보의 변경】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "서비스 이용 신청시 기재한 사항이 변경되었을 경우\n" +
    "회사가 정한 방법에 의하여 수정하여야 합니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는 이용자가\n" +
    "변경 사항을 적시에 수정하지 않음으로 인하여 발생하는\n" +
    "문제에 대하여는 어떠한 책임도 지지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>4<font face=\"맑은 고딕\"><span lang=\"ko-KR\">장\n" +
    "분쟁의 해결</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>20<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【회원의 고충 처리】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "회사에 대해서 불만사항이나 문의사항이 있을 경우\n" +
    "아래 연락처를 통해 해당 사항의 처리를 신청할 수\n" +
    "있고 회사는 이를 성실하게 처리합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">문의처\n" +
    "</span></font>: Contact@AMISPLATFORM.Com</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>21<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【회사의 면책】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "천재지변 또는 이에 준하는 불가항력</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">정보통신설비의\n" +
    "보수 점검</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">교체\n" +
    "또는 고장</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">통신의\n" +
    "두절 등으로 인하여 일시적 또는 종국적으로 서비스를\n" +
    "제공할 수 없는 경우 서비스 제공에 대한 책임이\n" +
    "면제됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가\n" +
    "이용자에게 제공하는 정보</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">문서</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">소프트웨어 등 기타\n" +
    "자료의 오류</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">결함\n" +
    "또는 부정확함에 대하여 회사의 귀책사유가 없는 한\n" +
    "회사는 이와 관련된 책임을 지지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자의 귀책사유로 인한 서비스 이용 장애에 대하여\n" +
    "책임을 지지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">④ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사의\n" +
    "서비스와 링크</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">제휴\n" +
    "등으로 연결된 시스템을 운영하는 피연결회사는 회사와\n" +
    "독자적으로 운영되며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "피연결회사와 이용자 간에 이루어진 거래 또는 계약에\n" +
    "대하여 책임을 지지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">⑤ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자\n" +
    "상호간의 거래로 인한 분쟁 해결 등 필요한 사후 처리는\n" +
    "거래 당사자인 이용자들이 직접 수행하여야 하고 회사는\n" +
    "이에 대하여 관여하지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>22<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【손해배상】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "본 약관의 규정을 위반하여 회사에 손해가 발생하는\n" +
    "경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관을 위반한 이용자는 회사가 입은 모든 손해를\n" +
    "배상하여야 합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "서비스를 이용함에 있어 불법행위나 본 약관의 위반으로\n" +
    "인하여 회사가 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자로부터\n" +
    "손해배상청구 또는 소송을 비롯한 각종 이의 제기를\n" +
    "받는 경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">당해\n" +
    "이용자는 자신의 책임과 비용으로 회사를 면책시켜야\n" +
    "하며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">그로\n" +
    "인하여 회사가 입은 모든 손해를 배상하여야 합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가\n" +
    "제공하는 서비스로 인하여 회원에게 손해가 발생한\n" +
    "경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "그러한 손해가 회사의 귀책사유로 인해 발생한 경우에\n" +
    "한하여 책임을 부담합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">④ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사의\n" +
    "책임있는 사유로 인하여 회원에게 손해가 발생한 경우\n" +
    "회사는 회사가 예측할 수 없는 이용자의 손해에 대해서는\n" +
    "책임을 지지 않습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자에\n" +
    "대한 회사의 책임은 이용자에게 발생할 수 있는 데이터\n" +
    "손실</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">영업손실\n" +
    "또는 사업 중단 등을 포함한 사업상 손실 기타 간접손실이나\n" +
    "우발손실을 포함하지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>23<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【준거법 및 관할법원】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관 및 약관에 따른 이용자와 회사의 관계에는 홍콩\n" +
    "법률이 적용됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관의 내용 또는 서비스 이용과 관련하여 회사와\n" +
    "이용자 사이에 분쟁이 발생한 경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사와\n" +
    "이용자는 분쟁의 해결을 위해 성실히 협의하고</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">분쟁 사항을 정당한\n" +
    "권한이 부여된 각 대표를 통해 먼저 해결하려고 시도하여야\n" +
    "합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관과 관련하여 발생하는 분쟁 그리고 본 약관의\n" +
    "존재</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">효력</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">해지와 관련된 모든\n" +
    "분쟁은</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">분쟁\n" +
    "해결에 돌입한지 </span></font>30<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일\n" +
    "이내에 해결할 수 없는 경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">홍콩\n" +
    "국제 중재 센터</span></font>(Hong Kong International\n" +
    "Arbitration Centre, “HKIAC”)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">에\n" +
    "의해 관리되는 중재절차에 회부하고 당시 시행되고\n" +
    "있는 홍콩 국재 중재 센터의 중재규칙</span></font>(“HKIAC\n" +
    "Rules”)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">에 따라\n" +
    "해결하며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "조에 따라 </span></font>HKIAC Rules<font face=\"맑은 고딕\"><span lang=\"ko-KR\">가\n" +
    "본 약관에 포함된 것으로 간주한다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">④ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">중재장소는\n" +
    "홍콩으로 한다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">중재\n" +
    "판정은 </span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">명의\n" +
    "중재인으로 구성된다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">중재\n" +
    "언어는 영어로 한다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>24<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【일반조항】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "이용약관의 일부 조항이 실효되거나 집행 불가능하게\n" +
    "되는 경우에도 나머지 조항은 유효하게 적용됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가\n" +
    "본 약관상의 권리 또는 권한을 집행하지 않거나 집행을\n" +
    "유예하더라도</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가\n" +
    "권리 또는 권한을 포기하는 것으로 간주되지 않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">③ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "회사의 사전 서면 동의 없이 본 계약 또는 본 계약에\n" +
    "따라 발생하는 이용자의 모든 권리나 의무를 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자에게\n" +
    "이전</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">양도</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">부과 또는 달리\n" +
    "처리할 수 없습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">단</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">인수∙합병 또는\n" +
    "서비스 매각이 발생하는 경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "회원의 동의 없이 본 약관에서 정한 회사의 권리 또는\n" +
    "위무를 양도할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>25<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【기타】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">① <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관의 보고의무</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">지급의무\n" +
    "등의 기한이 영업일이 아닌 경우 직전 영업일을 그\n" +
    "기한으로 한다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">② <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관의 당사자들은 참고용으로 사용하기 위하여 본\n" +
    "약관을 자신의 책임과 비용으로 다른 언어로 번역할\n" +
    "수 있으나</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "약관은 영어로 작성된 원본을 기준으로 이해하고\n" +
    "해석하여야 한다</span></font>.</p>"



let privacy = "<p style=\"line-height: 100%; margin-bottom: 0in\">\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보 처리방침</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">주식회사\n" +
    "</span></font>[AMIS Platform Corporation](<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이하\n" +
    "“회사”</span></font>)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">는\n" +
    "회사가 제공하는 지갑서비스 </span></font>AMIS Wallet (<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이하\n" +
    "“서비스”</span></font>)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">를\n" +
    "이용하기 위해 이용자가 제공한 개인정보를 보호하기\n" +
    "위하여 최선을 다하고 있습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "본 개인정보처리방침을 서비스 내에서 이용자가 언제든지\n" +
    "쉽게 확인할 수 있도록 공개하고 있습니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이를 통해 정보주체의\n" +
    "개인정보가 어떠한 목적과 방식으로 처리되고 있는지\n" +
    "알리고자 합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "처리 방침은 다음과 같은 중요한 의미를 가지고 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">⮚ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자에게 회사가 수집하는 개인정보의 내역</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용 방법</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">제공 또는 위탁되는\n" +
    "정보</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">파기\n" +
    "방법 등을 개인정보처리방침을 통해 알려드리고\n" +
    "있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">⮚ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "개인정보자기결정권을 비롯한 자신의 개인정보에 대한\n" +
    "법률상 권리를 보유하고 있습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">그리고\n" +
    "개인정보처리방침은 이용자의 개인정보에 대한 법률상\n" +
    "권리의 행사 방법을 안내합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">⮚ <font face=\"맑은 고딕\"><span lang=\"ko-KR\">또한\n" +
    "개인정보 침해사고를 예방하고</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">사고가\n" +
    "이미 발생하였을 때 피해를 복구하기 위한 방법을\n" +
    "안내합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【개인정보의 수집∙이용 목적】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "개인정보를 다음의 목적을 위하여 처리합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자\n" +
    "가입 및 이용자 정보 관리</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자\n" +
    "가입의사 확인</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회원제\n" +
    "서비스 제공에 따른 본인 식별∙인증</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회원자격\n" +
    "유지∙관리</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "부정이용 방지</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">만\n" +
    "</span></font>14<font face=\"맑은 고딕\"><span lang=\"ko-KR\">세 미만\n" +
    "아동의 개인정보 처리 시 법정 대리인의 동의 여부\n" +
    "확인</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">각종\n" +
    "고지∙통지</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">분쟁조정\n" +
    "해결을 위한 기록 보존</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">고충처리</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">민원인의\n" +
    "신원 확인</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">민원사항</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">문의사항\n" +
    "또는 불만처리 등</span></font>) <font face=\"맑은 고딕\"><span lang=\"ko-KR\">확인</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">사실조사를 위한\n" +
    "연락∙통지</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">답변\n" +
    "통보</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">신규\n" +
    "서비스 및 마케팅 활용</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자에\n" +
    "최적화된 서비스의 제공</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">관심\n" +
    "및 성향 추정에 따른 맞춤형 컨텐츠 추천</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">신규 서비스 및 상품\n" +
    "개발</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이벤트\n" +
    "정보 및 광고성 정보 제공 등 마케팅 및 프로모션</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">접속 빈도 파악</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">프라이버시 보호\n" +
    "측면의 서비스 환경 구축 및 개선</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">관련\n" +
    "법령에 따른 자료제공</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">법령에\n" +
    "정해진 절차와 방법에 따라 수사기관의 요구가 있는\n" +
    "경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">피해\n" +
    "신고에 따른 자료 제공</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보는\n" +
    "전항에 따른 목적 이외의 용도로는 사용되지 않으며</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용 목적이 변경되는\n" +
    "경우에는 별도의 동의를 받는 등 필요한 조치를 이행할\n" +
    "것입니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>2<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【개인정보의 수집∙이용 항목 및 보유∙이용기간】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "다음과 같은 개인정보의 항목을 수집합니다 </span></font>:</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "이용 과정에서 필수적으로 수집되는 개인정보 항목\n" +
    "</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">필수항목</span></font>)</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">구분\n" +
    "개인정보 항목 비고</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">회원가입\n" +
    "및 서비스 이용 휴대전화번호</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">보안\n" +
    "비밀번호</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">성명</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이메일주소</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1:1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">문의\n" +
    "</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">고객서비스\n" +
    "이용 이메일 주소</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">휴대전화번호</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">가상자산\n" +
    "관련 서비스 이용 코인</span></font>/<font face=\"맑은 고딕\"><span lang=\"ko-KR\">토큰\n" +
    "계좌 정보</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">거래\n" +
    "기록</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "이용 및 처리 과정에서 자동으로 생성되어 수집되는\n" +
    "정보</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "항목 비고 및 이용기간</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "이용 기록</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">접속\n" +
    "로그</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">쿠키</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">접속</span></font>IP<font face=\"맑은 고딕\"><span lang=\"ko-KR\">정보</span></font>(IP\n" +
    "Address, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">접속 시간</span></font>),\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">체결 기록</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자의 브라우저\n" +
    "종류</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">사용자\n" +
    "설정 정보</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이더리움\n" +
    "지갑주소 정보 </span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">퍼블릭키</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">프라이빗키</span></font>)\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자탈퇴 시 혹은\n" +
    "법령이 정한 시점</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "다음과 같은 방법으로 개인정보를 수집합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "수집 방법</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는\n" +
    "다음과 같은 경우 </span></font>: <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회원\n" +
    "가입</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회원\n" +
    "변경</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서비스\n" +
    "이용 시</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본인\n" +
    "확인 등 이용자의 신청에 따른 제공</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">고충처리를\n" +
    "위한 상담 과정에서 수집하는 경우 </span></font>: <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이메일\n" +
    "상담 등을 민원 처리 시의 수집</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">온오프라인에서\n" +
    "진행되는 이벤트</span></font>/<font face=\"맑은 고딕\"><span lang=\"ko-KR\">행사\n" +
    "참여 </span></font>: <font face=\"맑은 고딕\"><span lang=\"ko-KR\">유</span></font>/<font face=\"맑은 고딕\"><span lang=\"ko-KR\">무선\n" +
    "홈페이지 및 어플리케이션</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">서면\n" +
    "양식</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이메일\n" +
    "주소</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">전화</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">팩스</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">경품 행사 응모</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">제휴\n" +
    "서비스 또는 단체 등으로부터 개인정보를 제공받은\n" +
    "경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">5 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">자동\n" +
    "수집 장치를 통한 수집</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">보유\n" +
    "근거</span></font>: <font face=\"맑은 고딕\"><span lang=\"ko-KR\">고객\n" +
    "요청 작업에 대한 처리</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">보유\n" +
    "기간</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자의\n" +
    "탈퇴 요청 및 개인정보 동의를 철회하는 때까지</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">다만</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는 회사의 약관에\n" +
    "따른 이용자의 부정이용기록 또는 부정이용이 의심되는\n" +
    "기록이 있는 경우에는 이용자의 탈퇴 요청 및 개인정보\n" +
    "동의의 철회에도 불구하고</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">수집\n" +
    "시점으로부터 </span></font>5<font face=\"맑은 고딕\"><span lang=\"ko-KR\">년간\n" +
    "보관하고 파기합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【개인정보의 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자\n" +
    "제공】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자 동의 없이 개인정보를 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자에게\n" +
    "제공하거나 외부에 공개하지 않으며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "개인정보 처리방침에서 명시한 목적 범위 내에서만\n" +
    "처리합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">전항에도\n" +
    "불구하고 회사는 다음 각호의 경우 개인정보를 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자에게\n" +
    "제공하거나 외부에 공개하고 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "사전에 동의한 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">통계작성</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">학술연구 또는\n" +
    "시장조사를 위하여 필요한 경우로서 특정 개인을 식별할\n" +
    "수 없는 형태로 제공하는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">영업의\n" +
    "양도</span></font>·<font face=\"맑은 고딕\"><span lang=\"ko-KR\">합병\n" +
    "등에 관한 사유가 발생하는 경우</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">단</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는 영업의 양도\n" +
    "등에 관한 사유가 발생하여 이용자의 개인정보 이전이\n" +
    "필요한 경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">관계법률에서\n" +
    "규정한 절차와 방법에 따라 개인정보 이전에 관한 사실\n" +
    "등을 사전에 고지하며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자에게는\n" +
    "개인정보 이전에 관한 동의 철회권을 부여합니다</span></font>.)</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">정보주체\n" +
    "또는 그 법정대리인이 의사표시를 할 수 없는 상태에\n" +
    "있거나 주소불명 등으로 사전 동의를 받을 수 없는\n" +
    "경우로서 명백히 정보주체 또는 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자의\n" +
    "급박한 생명</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">신체</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">재산의 이익을 위하여\n" +
    "필요하다고 인정되는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">5 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">그\n" +
    "밖에 법률에 특별한 규정이 있는 경우</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "다양한 혜택을 제공하기 위하여 이용자에게 ‘개인정보를\n" +
    "제공받는 자</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">제공\n" +
    "목적</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">제공하는\n" +
    "개인정보 항목</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">제공받는\n" +
    "자의 보유</span></font>/<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용기간’을\n" +
    "고지하고 명시적이고 개별적인 동의를 얻어 제</span></font>3<font face=\"맑은 고딕\"><span lang=\"ko-KR\">자에게\n" +
    "개인정보를 제공할 예정입니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>4<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【개인정보의 위탁에 관한 사항】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "원활한 개인정보 업무 처리를 위하여 다음과 같이\n" +
    "개인정보 처리업무를 위탁하고 있으며 위탁받은 업체가\n" +
    "관계 법령을 위반하지 않도록 관리</span></font>·<font face=\"맑은 고딕\"><span lang=\"ko-KR\">감독하고\n" +
    "있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "회사가 개인정보를 위탁업체에 제공하는 경우 위탁받은\n" +
    "업체가 개인정보보호 관계 법령을 위반하지 않도록\n" +
    "관리 및 감독하고 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">위탁업무의\n" +
    "내용이나 수탁자가 변경될 경우에는 지체없이 본\n" +
    "개인정보 처리방침을 통하여 공개하도록 하겠습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>5<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【 정보주체와 법정대리인의 권리ㆍ의무 및 그 행사방법에\n" +
    "관한 사항】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">정보주체</span></font>)\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">및 법정대리인은\n" +
    "회사에 대해 언제든지 다음 각 호의 개인정보 보호\n" +
    "관련 권리를 행사할 수 있습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">단</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">관계 법령에 따라\n" +
    "이용자의 개인정보 보호 관련 권리는 제한될 수 있으며</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">다른 법령에서 수집\n" +
    "대상으로 명시되어 있는 개인 정보는 삭제</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">처리정지 요구 권한이\n" +
    "제한될 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "열람요구</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">오류\n" +
    "등이 있을 경우 정정요구</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">삭제요구</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">처리정지 요구 </span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">수집\n" +
    "및 이용에 대한 동의 철회</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">가입\n" +
    "해지</span></font>)</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">전항에\n" +
    "따른 권리행사는 아래 표와 같은 방법으로 하실 수\n" +
    "있으며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이에 대해 지체없이 조치하겠습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">또한\n" +
    "이용자는 서면</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">전자우편을\n" +
    "회사에 발송하는 방법으로도 하실 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">구분\n" +
    "방법</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "조회 “마이페이지”를 클릭하여 조회 가능</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "정정 아래의 이메일 주소로 연락하여 정정 가능</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">수집\n" +
    "및 이용에 대한 동의 철회</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">가입해지\n" +
    "“회원탈퇴”를 클릭하여 탈퇴가능</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본조에\n" +
    "따른 권리행사는 정보주체의 법정대리인이나 위임을\n" +
    "받은 자 등 대리인을 통하여 하실 수 있습니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이 경우 위임장을\n" +
    "제출하여야 합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자가 본조에 따른 권리행사 시 요구를 한 자가\n" +
    "본인이거나 정당한 대리인</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">법정대리인\n" +
    "등</span></font>)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">에\n" +
    "해당하는지를 확인합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">5 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "수집 및 이용에 동의를 거부할 수 있으며 동의를 거부하는\n" +
    "경우 본인인증이 제한됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>6<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【개인정보의 파기】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "원칙적으로 개인정보 처리목적이 달성된 경우에는\n" +
    "지체없이 해당 개인정보를 파기합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">단</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자에게 별도의\n" +
    "동의를 받은 경우 또는 다른 법령에서 일정 기간 보존\n" +
    "의무를 부과하는 경우에는 해당 기간 동안 다른 개인\n" +
    "정보와 분리하여 보관합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">년간\n" +
    "이용 기록이 없는 회원의 경우</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용\n" +
    "중인 회원의 개인정보와 별도로 분리하여 보관합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "파기의 절차</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">기한\n" +
    "및 방법은 다음과 같습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">파기절차</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자가\n" +
    "입력한 정보는 목적 달성 후 내부 방침 및 기타 관련\n" +
    "법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이 때</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">저장된 정보는 법률에\n" +
    "의한 경우가 아니고서는 다른 목적으로 이용되지\n" +
    "않습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">파기기한</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자의\n" +
    "개인정보는 개인정보의 보유기간이 경과된 경우\n" +
    "및개인정보의 처리 목적 달성</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">해당\n" +
    "서비스의 폐지</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">사업의\n" +
    "종료 등 그 개인정보가 불필요하게 되었을 때에는\n" +
    "개인정보의 처리가 불필요한 것으로 인정되는 날로부터\n" +
    "</span></font>5<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일 이내에\n" +
    "그 개인정보를 파기합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">파기방법</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">전자적\n" +
    "파일 형태의 정보는 기록을 재생할 수 없는 기술적\n" +
    "방법을 사용합니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">종이에\n" +
    "출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여\n" +
    "파기합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>7<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【개인정보 관리책임자 】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "개인정보 처리에 관한 업무를 총괄해서 책임지고</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보 처리와\n" +
    "관련한 정보주체의 불만처리 및 피해구제 등을 위하여\n" +
    "아래와 같이 개인정보 보호책임자를 지정하고 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "보호책임자</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">성명\n" +
    "김남호</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">직책\n" +
    "개인정보 보호책임자</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이메일\n" +
    "</span></font>contact@amisplatform.com</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "회사의 서비스를 이용하시면서 발생한 모든 개인정보\n" +
    "보호 관련 문의</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">불만처리</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">피해구제 등에 관한\n" +
    "사항을 개인정보 보호책임자에게 문의하실 수 있습니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는 정보주체의\n" +
    "문의에 대해 지체 없이 답변 및 처리해드릴 것입니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자\n" +
    "개인정보와 관련한 계정 및 패스워드</span></font>(PIN\n" +
    "Number)<font face=\"맑은 고딕\"><span lang=\"ko-KR\">에 대한 보안\n" +
    "유지 책임은 해당 이용자 자신에게 있습니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는 패스워드에\n" +
    "대해 어떠한 방법으로도 이용자에게 직접적으로 질문하는\n" +
    "경우는 없으므로 타인에게 패스워드가 유출되지 않도록\n" +
    "각별히 주의하시기 바랍니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">특히\n" +
    "공공장소에서 온라인상에서 접속해 있을 경우에는\n" +
    "더욱 유의하여야 합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사가\n" +
    "가능한 기술적인 보완조치를 했음에도 불구하고</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">첨단 기술을 이용한\n" +
    "해킹 등 네트워크 상의 위험에 의해 발생하는 예기치\n" +
    "못한 사고로 인한 정보의 훼손에 관해서는 책임이\n" +
    "없습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>8<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【개인정보 자동 수집 장치의 설치ㆍ운영 및 그 거부에\n" +
    "관한 사항】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "귀하의 정보를 수시로 저장하고 찾아내는 쿠키</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">세션 등 개인정보를\n" +
    "자동으로 수집하는 장치를 설치∙운영합니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">쿠키는 웹사이트를\n" +
    "운영하는데 이용되는 서버가 사용자의 브라우저에게\n" +
    "보내는 조그마한 데이터 꾸러미로 이용자의 하드디스크에\n" +
    "저장됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">쿠키의\n" +
    "사용 목적</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">쿠키를\n" +
    "통해 이용자가 선호하는 설정 등을 저장하여 이용자에게\n" +
    "더욱 빠른 웹 환경을 지원하며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">편리한\n" +
    "이용을 위한 서비스 개선에 활용합니다</span></font>.\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이를 통해 이용자는\n" +
    "더욱 손쉽게 서비스를 이용할 수 있게 됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">쿠키의\n" +
    "설치∙운영 및 거부</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "쿠키 설치에 대한 선택권을 가지고 있으며</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">언제든지 이러한\n" +
    "쿠키의 저장을 거부하거나 삭제할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">쿠키\n" +
    "설정 거부 방법의 예</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자는\n" +
    "쿠키 설치에 대한 선택권을 가지고 있으며</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">언제든지 이러한\n" +
    "쿠키의 저장을 거부하거나 삭제할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 Internet Explorer\n" +
    ": <font face=\"맑은 고딕\"><span lang=\"ko-KR\">도구 메뉴 선택\n" +
    "</span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">인터넷\n" +
    "옵션 선택 </span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "탭 클릭 </span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">고급\n" +
    "개인정보 설정 </span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">쿠키\n" +
    "수준 설정</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 Chrome : <font face=\"맑은 고딕\"><span lang=\"ko-KR\">설정\n" +
    "메뉴 선택 </span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">고급\n" +
    "설정 표시 선택 </span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "및 보안 </span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">콘텐츠\n" +
    "설정 선택 </span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">쿠키\n" +
    "수준 설정</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 Safari : <font face=\"맑은 고딕\"><span lang=\"ko-KR\">환경설정\n" +
    "메뉴 선택 </span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "탭 선택 </span></font>&gt; <font face=\"맑은 고딕\"><span lang=\"ko-KR\">쿠키\n" +
    "및 웹 사이트 데이터 수준 설정</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">단</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">귀하께서 쿠키 설치를\n" +
    "거부하였을 경우 서비스 제공에 어려움이 있을 수\n" +
    "있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자의 </span></font>ADID/IDFA<font face=\"맑은 고딕\"><span lang=\"ko-KR\">를\n" +
    "자동수집하는 경우가 있습니다</span></font>. ADID/IDFA<font face=\"맑은 고딕\"><span lang=\"ko-KR\">란\n" +
    "각 </span></font>Android OS<font face=\"맑은 고딕\"><span lang=\"ko-KR\">와\n" +
    "</span></font>IOS<font face=\"맑은 고딕\"><span lang=\"ko-KR\">의\n" +
    "모바일 앱 이용자의 광고 식별 값을 의미합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 ADID/IDFA<font face=\"맑은 고딕\"><span lang=\"ko-KR\">의\n" +
    "사용 목적</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">온라인\n" +
    "맞춤형 광고 등 이용자에게 최적화된 맞춤형 서비스\n" +
    "및 혜택을 제공하기 위하여 사용됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 ADID/IDFA<font face=\"맑은 고딕\"><span lang=\"ko-KR\">의\n" +
    "수집 방법</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용자의\n" +
    "앱 방문</span></font>/<font face=\"맑은 고딕\"><span lang=\"ko-KR\">실행\n" +
    "시 자동 수집됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 ADID/IDFA<font face=\"맑은 고딕\"><span lang=\"ko-KR\">의\n" +
    "보유</span></font>/<font face=\"맑은 고딕\"><span lang=\"ko-KR\">이용\n" +
    "기간</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">수집일로부터\n" +
    "</span></font>1<font face=\"맑은 고딕\"><span lang=\"ko-KR\">년</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4 ADID/IDFA<font face=\"맑은 고딕\"><span lang=\"ko-KR\">의\n" +
    "수집 거부</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 Android OS:\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">설정</span></font>-&gt;<font face=\"맑은 고딕\"><span lang=\"ko-KR\">구글</span></font>(<font face=\"맑은 고딕\"><span lang=\"ko-KR\">구글설정</span></font>)-&gt;<font face=\"맑은 고딕\"><span lang=\"ko-KR\">광고</span></font>-&gt;<font face=\"맑은 고딕\"><span lang=\"ko-KR\">광고\n" +
    "맞춤설정 선택 해제</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 iOS:\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">설정</span></font>-&gt;<font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보보호</span></font>-&gt;<font face=\"맑은 고딕\"><span lang=\"ko-KR\">광고</span></font>-&gt;<font face=\"맑은 고딕\"><span lang=\"ko-KR\">광고\n" +
    "추적 제한</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>9<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "【링크 사이트에 대한 책임】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "이용자에게 다른 외부사이트로 연결되는 링크를 제공할\n" +
    "수 있습니다</span></font>. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이\n" +
    "경우 회사는 외부사이트에 대한 통제권이 없으므로\n" +
    "이용자가 외부사이트로부터 제공받는 서비스나 자료의\n" +
    "유용성</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">진실성</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">적법성에 대해 책임\n" +
    "및 보증할 수 없으며</span></font>, <font face=\"맑은 고딕\"><span lang=\"ko-KR\">링크된\n" +
    "외부사이트의 개인정보처리방침은 회사와 무관하므로\n" +
    "해당 외부사이트의 정책을 확인하시기 바랍니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><br/>\n" +
    "\n" +
    "</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\"><font face=\"맑은 고딕\"><span lang=\"ko-KR\">제</span></font>10<font face=\"맑은 고딕\"><span lang=\"ko-KR\">조\n" +
    "개인정보 처리방침의 개정】</span></font></p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">1 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">본\n" +
    "개인정보 처리방침은 </span></font>2021. [*]. [*]. <font face=\"맑은 고딕\"><span lang=\"ko-KR\">부터\n" +
    "적용됩니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">2 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">회사는\n" +
    "법률이나 서비스의 변경사항을 반영하기 위한 목적\n" +
    "등으로 개인정보 처리방침을 수정할 수 있습니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">3 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">개인정보\n" +
    "처리방침이 변경되는 경우 회사는 변경사항을 게시하며</span></font>,\n" +
    "<font face=\"맑은 고딕\"><span lang=\"ko-KR\">게시일로부터 </span></font>7<font face=\"맑은 고딕\"><span lang=\"ko-KR\">일이\n" +
    "지난 날부터 효력이 발생합니다</span></font>.</p>\n" +
    "<p style=\"line-height: 100%; margin-bottom: 0in\">4 <font face=\"맑은 고딕\"><span lang=\"ko-KR\">이전의\n" +
    "개인정보 처리방침은 아래에서 확인하실 수 있습니다</span></font>.</p>"

export default Object.freeze({
  install(Vue) {
    Vue.prototype.getTermOfUse = function () {
      return termOfUse
    }

    Vue.prototype.getPrivacy = function () {
      return privacy
    }
  }
})

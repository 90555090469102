<template>
  <div class="wrap">
    <!-- 현재 리워드 -->
    <div class="header-wrap">

      <div class="container">
        <div class="header-group">
          <span class="title">{{ token }} 거래 내역</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>

      <div class="history-top">
        <div class="reap-view">
          <div class="container">
            <div class="reap-inner">
              <h4 class="reap-tit">보유 {{ token }}</h4>
              <div class="total">
                <strong class="data">{{ balance | numberWithCommas | rewardDecimal}}</strong><strong class="data2">.{{ getMetrDecimalPlace(balance) }}</strong><span class="unit"> {{ token }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="reap-date">
          <div class="container">
            <div class="reap-inner">
              <div class="date-filter">
                <div class="date-area">
                  <button type="button" class="next-btn " @click="goPrev()" :disabled="!ablePrev"><i
                      class="icon-arrow-left" :class="{'disabled' : !ablePrev}"></i></button>
                  <date-picker class="date-box border-zero fz-20 pt-0 date-range" v-model="selectedDate"
                               :config="this.calendarOptions"
                               @input="selectedDatePicker()"
                               readonly
                  />
                  <button type="button" class="next-btn" @click="goNext()" :disabled="!ableNext"><i
                      class="icon-arrow-right" :class="{'disabled' : !ableNext}" :style="{'--stop' : stopColor}"></i></button>
                </div>
                <div class="filter-area">
                  <i class="icon filter">정렬</i>
                  <button type="button" class="filter-srch" @click="showCondition = true">{{
                      selectedType.name
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>



    <!-- TODO : 내역 있는 경우 -->
    <div class="content-wrap pt-210" v-if="historyList.length > 0">
      <div class="container">
        <div class="row">
          <div class="section type_filter" v-for="(day, index) in historyList" :key="'d' + index">
            <div class="filter_head">
              <h4 class="heading-date">{{ historyDay(day.day) }}</h4>
            </div>
            <div class="filter_cont">
              <ul>
                <li class="filter_summary clear" v-for="(history, dayIndex) in day.historyList" :key="'h' + dayIndex">
                  <div class="left">
                    <div class="state_circle">{{ history.type }}</div>
                  </div>
                  <div class="right">
                    <span class="target ellipsis">{{ history.title }}</span>
                    <strong class="price ellipsis" :class="{'receive' : history.type === '입금'}">{{ history.symbol }}{{ history.amount | numberWithCommas }}{{ token }}</strong>
                    <em class="date">{{ history.date }}</em>
                  </div>
                </li>

              </ul>
            </div>
          </div>


        </div>
      </div>
    </div>



    <div class="wrapper-center" v-if="historyList.length === 0">
      <!-- TODO : 내역이 없을 경우 -->
      <div class="alram-wrap">
        <div class="list-none">
          <i class="bg_symbol_15_op mb-20"></i>
          <p class="list-desc">내역이 없습니다.</p>
        </div>
      </div>
    </div>

    <history-select-type
        v-show="showCondition"
        :conditions="typeList"
        @selected="setSelectedCondition"
        @close="showCondition = false"
    />
  </div>
</template>

<script>
import HistorySelectType from "./HistorySelectType";
export default {
  name: "HistoryMetr",
  components: {
    HistorySelectType
  },
  data() {
    return {
      year: '',
      month: '',
      ablePrev: true,
      ableNext: false,
      balance: '0',
      historyList: [],
      selectedDate: '',
      selectedType: {
        type: '',
        name: '전체'
      },
      showCondition: false,
      typeList: [
        {
          type: '',
          name: '전체',
        },
        {
          type: 'DS',
          name: '입금',
        },
        {
          type: 'RS',
          name: '송금',
        },
        {
          type: 'WS',
          name: '출금',
        },
        /*{
          type: 'CS',
          name: '충전',
        },*/
        {
          type: 'PS',
          name: '결제',
        },
        {
          type: 'PC',
          name: '결제취소',
        },
        /*{
          type:'TH',
          name:'타행',
        },*/
      ],


      stopColor : '#030303FF',
      currentYear : 0,
      currentMonth : 0,

      token : 'AMIS',
    }
  },
  created() {
    let current = new Date()
    this.year = current.getFullYear()
    this.month = current.getMonth() + 1
    this.setSelectedDateValue()

    this.currentYear = this.year
    this.currentMonth = this.month
  },
  mounted() {
    let paramToken = this.$route.query.token
    if (paramToken) {
      this.token = paramToken
    }

    let balanceUrl = this.$WALLET_REAPPAY_BALANCE
    if (this.token !== 'AMIS') {
      balanceUrl = this.$WALLET_ETH_BALANCE
    }

    this.$get(balanceUrl, 'historypay_mounted', (result) => {
      this.balance = result.data * 1
    }, (result) => {
      this.httpError(result.data)
    })

    this.getHistory()
  },
  methods: {
/*
    getIntValueFromAmount(amount) {
      if ('string' !== typeof amount) {
        amount = String(amount)
      }

      let isFloat = amount.indexOf('.')
      if (isFloat) {
        return amount.split('.')[0]
      } else {
        return '0'
      }
    },
*/

    setSelectedDateValue() {
      this.selectedDate = this.year + '.' + this.month
      this.checkAbleNext()
    },
    selectedDatePicker() {
      this.year = this.selectedDate.split('.')[0]
      this.month = this.selectedDate.split('.')[1]
      this.checkAbleNext()
      this.getHistory()
    },

    getHistory() {
      let paramToken = this.token === 'AMIS' ? 'AMIS' : 'ETHEREUM'

      let queryString = '?year=' + this.year + '&month=' + this.month + '&tokenType=' + paramToken
      if (this.selectedType.type !== '') {
        queryString = queryString + '&type=' + this.selectedType.type
      }
      this.$get(this.$WALLET_REAPPAY_HISTORY + queryString, 'historypay_gethistory', (result) => {
        // this.balance = result.data.balance
        if (result.data.dayGroupList) {
          this.historyList = result.data.dayGroupList
        } else {
          this.historyList = []
        }

        for (let i = 0; i < this.historyList.length; i++) {
          let day = this.historyList[i]
          for (let j = 0; j < day.historyList.length; j++) {
            let history = day.historyList[j]
            history.symbol = history.amount.substring(0, 1)
            history.amount = Number(history.amount.substring(1).replace(/,/gi,'')).toFixed(8)
          }
        }
      }, (result) => {
        this.httpError(result.data)
      })
    },
    setSelectedCondition(_condition) {
      this.selectedType = _condition
      this.showCondition = false
      this.getHistory()
    },
    goPrev() {
      if (this.month > 1) {
        this.month = this.month - 1
      } else {
        this.year = this.year - 1
        this.month = 12
      }

/*      if (this.currentYear <= this.year) {
        if (this.currentMonth <= this.month) {
          this.stopColor = '#E9E9E9'
        } else {
          this.stopColor = '#030303FF'
        }
      } else {
        this.stopColor = '#030303FF'
      }*/
      this.setSelectedDateValue()
      this.ableNext = true
      this.getHistory()
    },
    goNext() {
      if (this.month * 1 === 12) {
        this.month = 1
        this.year = (this.year * 1) + 1
      } else {
        this.month = (this.month * 1) + 1
      }
/*      console.log('1')
      if (this.currentYear <= this.year) {
        console.log('2')
        if (this.currentMonth <= this.month) {
          this.stopColor = '#E9E9E9'
          this.ableNext = false
        } else {
          this.ableNext = true
          this.stopColor = '#030303FF'
        }
      } else {
        console.log('5')
        this.stopColor = '#030303FF'
      }*/
      this.setSelectedDateValue()
      this.ableNext = true
      this.getHistory()
    },
    historyDay(value) {
      let weeks = ['일', '월', '화', '수', '목', '금', '토']
      let date = new Date(this.year + '-' + ((this.month * 1) < 10 ? '0' : '') + this.month + ((value * 1) < 10 ? '-0' : '-') + value)
      let week = weeks[date.getDay()]

      return value + '일(' + week + ')'
    },
    checkAbleNext() {
      let current = new Date()
      let _year = current.getFullYear()
      let _month = current.getMonth() + 1
      let current2 = new Date(_year + '-' + ((_month * 1) < 10 ? '0' : '') + _month + '-01')
      let select = new Date(this.year + '-' + ((this.month * 1) < 10 ? '0' : '') + this.month + '-01')

      if (current2 <= select) {
        this.ableNext = false
      } else {
        this.ableNext = true
      }
      // FIXME 현재 날짜와 관계없이 다음 날짜 조회가능토록 수정
      this.ableNext = true
    }
  },
  filters: {
    historyType: (value) => {
      if (value === 'PS') {
        return '결제'
      } else if (value === 'PC') {
        return '결제 취소'
      } else if (value === 'CS') {
        return '충전'
      } else if (value === 'WS') {
        return '출금'
      } else if (value === 'DS') {
        return '입금'
      } else if (value === 'RS') {
        return '송금'
      } else if (value === 'TH') {
        return '타행'
      }
      return ''
    },



  }
}
</script>

<style scoped>
.icon-arrow-right { color: var(--stop); font-size: 13px; }

button:disabled { background-color: var(--white); color: var(--stop) !important; border: none; }
button:disabled * { color: var(--stop) !important;}

.wrapper-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <TopNav :isSearch="isSearch" v-on:searchEvent="getKeyword"/>
    <div class="content-wrap pt-50">
      <!-- TODO: 검색시 노출화면 -->
      <!-- 검색키워드 -->
      <div class="keyword_wrap">
        <div class="container" v-if="menuList.length == 0">  <!-- menuList 가 검색되면 추천 키워드 노출을 없애고 메뉴리스트 이미지만 보여준다.-->
          <div class="row">
            <div class="btn_keyword">
              <button class="text" aria-label="검색어선택">대구수성</button>
              <button class="close" aria-label="검색어삭제"></button>
            </div>
            <div class="btn_keyword">
              <button class="text" aria-label="검색어선택">대구</button>
              <button class="close" aria-label="검색어삭제"></button>
            </div>
            <div class="btn_keyword">
              <button class="text" aria-label="검색어선택">혁신도시</button>
              <button class="close" aria-label="검색어삭제"></button>
            </div>
            <div class="btn_keyword">
              <button class="text" aria-label="검색어선택">혁신도시</button>
              <button class="close" aria-label="검색어삭제"></button>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <ul class="list_type3">
              <li>
                <a @click="$router.push({ name : 'MenuDetail', params : { productIdx : 1 } })">
                  <figure>
                    <div class="frame">
                      <img class="menu_badge" src="/assets/image/@badge.png" alt="">
                      <img class="munu_img" src="/assets/image/@thoumb1.png" alt="">
                    </div>
                    <figcaption>
                      <strong class="ellipsis">18칼로리 슬림 카페라떼</strong>
                      <span>3,900원</span>
                    </figcaption>
                  </figure>
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <!-- //검색키워드 -->
      <!-- //검색시 노출화면 -->
      <!-- 검색리스트 -->
      <div class="search_area" v-if="this.searchType !== 'menu' && this.searchKeyword !== ''">  <!-- 메뉴검색이 아닐 경우에만 나타난다. -->
        <div class="list_place">
          <div class="item" v-for="item in listArr" :key="item.userIdx">
            <div class="container">
              <div class="left">
                <strong>{{ item.shopName }}</strong>
                <address>{{ item.shopAddress }}</address>
                <span>{{ item.shopAddressDetail }}</span>
              </div>
              <div class="right">
                <span>{{ distanceFormat(item.distance) }}m</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isMenuSearch && menuList.length > 0">
        <div class="pt-150"></div>
        <!-- TODO : .popup.type1 실행시 content-wrap에 pb-150 클래스 추가해주세요 -->
        <div class="popup type1">
          <div class="container">
            <div class="inner">
              <div class="info_area type1">
                <p class="total">총 선택 <span class="ordernum">5개</span></p>
                <strong class="price">68,000원</strong>
              </div>
              <div class="button-group mt-20">
                <button class="outline_l_btn btn-gray">전체주문취소</button>
                <button class="outline_l_btn btn-black" @click="$router.push({ name : 'Order' })">주문</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import TopNav from "@/components/common/TopNav";

export default {
  name: "SearchForm",
  props: {
    searchType : String,
    shopIdx : Number,  //특정 가맹점 정보를 가져오기위한 가맹점 idx. searchType 이 menu 일 경우 검사한다.
  },
  components: {
    TopNav,
  },
  data() {
    return {
      listArr: [],
      default_lat: 35.8448,
      default_lng: 128.7489,
      isSearch : true,
      searchKeyword: '',
      isMenuSearch : false,  //가맹점의 메뉴검색을 위해 호출하였는가  (기본값 false),
      menuList: [],  //가맹점으로부터 검색된 메뉴정보
    }
  },
  mounted() {
    this.isMenuSearch = this.searchType === 'menu';
    if (this.isMenuSearch) {
      //매장 상세메뉴를 검색하고 싶을경우
      console.log('')
    } else {
      //내 주변 매장을 검색할 경우
      this.getShopList()
    }
  },
  methods: {
    getKeyword(keyword){
      if (this.isMenuSearch) {  //메뉴검색일 경우
        this.searchKeyword = keyword
        this.getShopMenuKeyword()

      } else {  //위치검색일 경우
        this.searchKeyword = keyword
        this.getShopList()
      }
    },
    distanceFormat(distance){
      if (distance.toString().length >= 4){
        return (distance/1000).toFixed(2) + 'km'
      } else {
        return distance + 'm'
      }
    },
    getShopList(){
      let queryString = '?latitude=' + this.default_lat + '&longitude=' + this.default_lng +'&keyword=' + this.searchKeyword

      this.$shop_get(this.$SHOP + queryString, 'search_mounted', (result) => {
        let arrData = result.data
        arrData.sort(function(a, b) {
          return a.distance - b.distance;
        });
        this.listArr = arrData
      }, (result) => {
        console.log('err')
        this.httpError(result.data)
      })
    },

    getShopMenuKeyword() {
      //해당 매장에 대하여 이전에 검색한 키워드들을 가져온다.
      console.log('shopIdx' + this.shopIdx + ' searchKeyword : ' + this.searchKeyword)
    }
  },

}
</script>

<style scoped>
.content-wrap {
  background : var(--bg-gray);
}


.btn-gray { background: var(--gray02); color: #fff; } /* 취소 */
</style>
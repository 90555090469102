<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="logo">
            <img src="/image/logo_row01.svg" alt="amis">
          </span>
        </div>
      </div>
    </div>
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <strong class="confirm-tit-metr mt-10">{{ amount | numberWithCommas }}<em class="unit">{{ token }}</em></strong>
              <span class="confirm-subtit-metr">= {{ Math.floor(amount * usdPrice) | numberWithCommas}} 원</span>
              <p class="confirm-desc">출금 신청 완료 되었습니다.</p>
            </div>
            <div class="confirm-info-list">
              <dl>
                <dt class="text-tit">잔여 {{ token }}</dt>
                <dd class="point-color-black">{{ balance | numberWithCommas }}{{ token }}</dd>
              </dl>
              <dl>
                <dt class="text-tit">받는 주소</dt>
                <dd class="text-desc">{{ address.substring(0, 10) }}...{{ address.substring(address.length - 8) }}</dd>
              </dl>
              <dl>
                <dt class="text-tit">출금일시</dt>
                <dd class="text-desc">{{ new Date() | moment('YYYY-MM-DD HH:mm:ss') }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="$router.back()">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RequestMetrSuccess",
  data() {
    return {
      amount: '',
      balance: '',
      usdPrice: '',
      address: '',
      token : 'AMIS',
    }
  },
  mounted() {
    //기본 토큰은 AMIS 로 설정, 다른 토큰네임이 파라미터로 전달되면 해당 토큰네임 사용 (ETH)

    let token = this.$route.query.token
    if (token) {
      this.token = token
    }
    this.amount = this.$route.params.amount
    this.usdPrice = this.$route.params.usdPrice
    this.address = this.$route.params.address
    this.balance = this.$route.params.balance
    /*this.$get(this.$WALLET_REAPCHAIN_BALANCE, 'chargesuccess_mounted', (result) => {
      this.balance = result.data * 1
    }, (result) => {
      this.httpError(result.data)
    })*/
  },
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
.header-group .logo { position: relative; display: inline-block; height: 20px; width: 70px; }
</style>

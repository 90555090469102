<template>
  <div class="popup-wrap madal-bg" v-if="show" @click.self="close()">
    <div class="container">
      <div class="row">
        <div class="section_confirm">
          <div class="confirm-content">
            <div class="permit-group">
              <div class="confirm-box">
                <img src="/image/metetta_row.png" class="permit-group-logo" alt="아미스">
                <p class="confirm-guid">결제 플랫폼, 아미스 이용을 위해<br>
                  다음 권한의 허용이 필요합니다.</p>
              </div>
              <hr class="hr-1">
              <div class="confirm-box">
                <ul class="confirm-info">
                  <li>
                    <div class="icon phone icon-shrink">전화</div>
                    <div class="permit-text">
                      <h4>전화 <small>(필수)</small></h4>
                      <span>휴대번호와 사용자 동일여부 확인</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon camera icon-shrink">전화</div>
                    <div class="permit-text">
                      <h4>카메라 <small>(필수)</small></h4>
                      <span>결제시 QR촬영 기능 제공</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="button-wrap">
              <div class="button-group">
                <button class="btn btn-black" @click="close()">확인 했습니다</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PermissionRequest",
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showDialog() {
      this.show = true
    },
    close() {
      this.show = false
    },
  }
}
</script>

<style scoped>

</style>
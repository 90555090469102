import axios from 'axios';
import https from 'https';

/*let requestOption = () => {
  return {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    timeout: 60000
  }
}
let requestOptionWithToken = () => {
  return {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken_quick_order'),
    },
    timeout: 60000
  }
}*/
/*let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}*/

export default Object.freeze({
  install(Vue) {
    if (process.env.VUE_APP_DEBUG === '1') {
      const httpsAgent = new https.Agent({rejectUnauthorized: false});
      axios.defaults.httpsAgent = httpsAgent
    }
    Vue.prototype.$QUICK_ORDER_DOMAIN = process.env.VUE_APP_QUICK_ORDER_DOMAIN + "/v1"

    Vue.prototype.$QUICK_ORDER_LOGIN = "/user/auth/login"
    Vue.prototype.$QUICK_PRODUCT_LIST = "/user/product/list"
    Vue.prototype.$QUICK_PRODUCT_DETAIL = "/user/product/detail"
    Vue.prototype.$QUICK_CATEGORY_LIST = "/user/category/list"
    Vue.prototype.$QUICK_PAYMENT = "/user/order/set"
    Vue.prototype.$QUICK_CHECK_AVAILABILITY_PAYMENT = "/user/order/availability"
    Vue.prototype.$QUICK_ORDER_HISTORY = "/user/order/list"
    Vue.prototype.$QUICK_ORDER_HISTORY_DETAIL = "/user/order/detail_"
    Vue.prototype.$QUICK_ORDER_HISTORY_DELETE = "/user/order/delete_"
    Vue.prototype.$QUICK_ORDER_ALARM = "/user/order/alarm"
    Vue.prototype.$QUICK_ORDER_ALARM_CONFIRM = "/user/order/alarm/confirm"
    Vue.prototype.$QUICK_ORDER_PICKUP = "/user/order/pickUp"
    Vue.prototype.$QUICK_ORDER_PICKUP_OPTIONS = "/user/order/recent/pick"


    //사용자 최근 주문목록
    Vue.prototype.$QUICK_RECENT_ORDER = "/user/order/recent"

    // eslint-disable-next-line no-unused-vars
    Vue.prototype.$quick_order_post = function (callUrl, caller, postData, success, fail) {
      success({data:[]})
      /*if (pending_post[arguments[0] + caller]) {
        console.log('request post fail : ' + arguments[0] + caller)
        return
      }
      pending_post[arguments[0] + caller] = true
      let _requestOption = requestOption()
      let token = sessionStorage.getItem('accessToken_quick_order')
      if (token && token.length > 0) {
        _requestOption = requestOptionWithToken()
      }

      axios.post(this.$QUICK_ORDER_DOMAIN + callUrl, postData, _requestOption).then((response) => {
        pending_post[arguments[0] + caller] = false
        if (response.data.result === 'success'|| response.data.code === '00000') {
          success(response.data)
        } else {
          fail(response.data)
        }
      }).catch((e) => {
        pending_post[arguments[0] + caller] = false
        fail(e.response)
      })*/
    }

    // eslint-disable-next-line no-unused-vars
    Vue.prototype.$quick_order_put = function (callUrl, caller, postData, success, fail) {
      success({data:[]})
      /*if (pending_put[arguments[0] + caller]) {
        console.log('request put fail : ' + arguments[0] + caller)
        return
      }
      pending_put[arguments[0] + caller] = true
      let _requestOption = requestOption()
      let token = sessionStorage.getItem('accessToken_quick_order')
      if (token && token.length > 0) {
        _requestOption = requestOptionWithToken()
      }

      axios.put(this.$QUICK_ORDER_DOMAIN + callUrl, postData, _requestOption).then((response) => {
        pending_put[arguments[0] + caller] = false
        if (response.data.result === 'success'|| response.data.code === '00000') {
          success(response.data)
        } else {
          fail(response.data)
        }
      }).catch((e) => {
        pending_put[arguments[0] + caller] = false
        fail(e.response)
      })*/
    }

    // eslint-disable-next-line no-unused-vars
    Vue.prototype.$quick_order_delete = function (callUrl, caller, success, fail) {
      /*if (pending_delete[arguments[0] + caller]) {
        console.log('request delete fail : ' + arguments[0] + caller)
        return
      }
      pending_delete[arguments[0] + caller] = true
      let _requestOption = requestOption()
      let token = sessionStorage.getItem('accessToken_quick_order')
      if (token && token.length > 0) {
        _requestOption = requestOptionWithToken()
      }

      axios.delete(this.$QUICK_ORDER_DOMAIN + callUrl, _requestOption).then((response) => {
        pending_delete[arguments[0] + caller] = false
        if (response.data.result === 'success'|| response.data.code === '00000') {
          success(response.data)
        } else {
          fail(response.data)
        }
      }).catch((e) => {
        pending_delete[arguments[0] + caller] = false
        fail(e.response)
      })*/

      success({data:[]})
    }

    // eslint-disable-next-line no-unused-vars
    Vue.prototype.$quick_order_get = function (callUrl, caller, success, fail) {
      /*if (pending_get[arguments[0] + caller]) {
        console.log('request get fail : ' + arguments[0] + caller)
        return
      }
      pending_get[arguments[0] + caller] = true
      let _requestOption = requestOption()
      let token = sessionStorage.getItem('accessToken_quick_order')
      if (token && token.length > 0) {
        _requestOption = requestOptionWithToken()
      }

      return axios.get(this.$QUICK_ORDER_DOMAIN + callUrl, _requestOption).then((response) => {
        pending_get[arguments[0] + caller] = false
        if (response.data.result === 'success'|| response.data.code === '00000') {
          success(response.data)
        } else {
          fail(response.data)
        }
      }).catch(e => {
        pending_get[arguments[0] + caller] = false
        fail(e.response)
      })*/
      success({data:[]})
    }



  }
})


<template>
  <div class="wrap" :class="{'preventScroll' : isPreventScroll}">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group header-chain">
          <button type="button" class="nav-btn left" @click="close"><i class="icon-arrow-left"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <div class="container">
        <div class="row">
          <div class="product_view">
            <figure>
              <div class="frame">
                <img class="munu_img" :src="removeHost(menuDetail.imgPath)" alt="이미지등록필요">
              </div>
              <figcaption>
                <button class="nutrition_info outline_s_btn2" @click="changeShowNutritionInfo">영양정보</button>
                <h3 class="ellipsis2">{{ menuDetail.name }}</h3>
                <p>{{ menuDetail.exposition !== null ? menuDetail.exposition : '설명없음'}}</p>
                <strong>{{ menuDetail.price | numberWithCommas }}원</strong>
              </figcaption>
            </figure>
          </div>
          <div class="product_detail">
            <div class="item" v-for="option in menuDetail.options" :key="option.idx">
              <h3 :class="{'hide' : option.name === '온도'}">{{ option.name }}</h3>
              <div class="button-group">
                <button class="outline_m_btn" :class="{'btn-black' : option2.idx === option.selected, 'btn-line-black' : option2.idx !== option.selected}"
                        @click="selectOption(option, option2.idx, option2.name)"
                        v-for="option2 in option.options" :key="option2.idx">{{ option2.name }}</button>
              </div>
            </div>
<!--            <div class="item">
              <h3>사이즈</h3>
              <div class="button-group">
                <button class="outline_m_btn btn-black">벤티</button>
                <button class="outline_m_btn btn-line-black">대용량</button>
              </div>
            </div>
            <div class="item">
              <h3>컵 선택</h3>
              <div class="button-group">
                <button class="outline_m_btn btn-black">매장컵</button>
                <button class="outline_m_btn btn-line-black">유저블컵</button>
                <button class="outline_m_btn btn-line-black">일회용컵</button>
              </div>
            </div>-->
          </div>
          <!-- 메뉴 옵션 선택화면 가려지는걸 방지하기 위한 엘리먼트-->
          <div class="pt-170">
          </div>
        </div>
      </div>
      <!-- TODO : .popup.type1 실행시 content-wrap에 pb-170 클래스 추가해주세요 -->
      <div class="popup type1">
        <div class="container">
          <div class="inner">
            <div class="info_area type1">
              <div class="count">
                <button class="btn_count_down" @click="--quantity" :disabled="quantity === 1"><span class="hide">수량빼기</span></button>
                <input type="number" :value="quantity">
                <button class="btn_count_up" @click="++quantity"><span class="hide">수량더하기</span></button>
              </div>
              <strong class="price">{{ menuDetail.price * quantity | numberWithCommas }}원</strong>
            </div>
            <div class="button-group mt-20">
              <button class="outline_l_btn btn-black" @click="selectProduct">선택</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NutritionInfo v-if="this.showNutritionInfo" @close="changeShowNutritionInfo" :nutritionStr="menuDetail.nutrient"></NutritionInfo>
  </div>
</template>

<script>

import NutritionInfo from "@/components/order/shop/NutritionInfo";

export default {
  name: "MenuDetail",
  components: {
    NutritionInfo,
  },

  data() {
    return {
      title : "메뉴상세",
      productIdx : this.$route.params.productIdx,
      shopIdx : this.$route.params.shopIdx === '0' ? '' : this.$route.params.shopIdx,
      shopName : this.$store.getters.getShopInfo.shopName,
      quantity : 1,
      showNutritionInfo : false,
      menuDetail : {
        nutrient : '{}',
      },
      isPreventScroll : false,

      tempShopInfo : this.$store.getters.getShopInfo,
    }
  },

  mounted() {
    this.$quick_order_get(this.$QUICK_PRODUCT_DETAIL + '?productIdx=' + this.productIdx + '&shopIdx=' + this.shopIdx, 'shop_menu_detail', (result) => {
      this.menuDetail = result.data
      if (this.menuDetail.options !== null) {
        //1차옵션들을 정렬해준다.
        this.menuDetail.options.sort(function (option_1,option_2) {
          if(option_1.sort > option_2.sort) return -1;
          if(option_1.sort === option_2.sort) return 0;
          if(option_1.sort < option_2.sort) return 1;
        })

        //1차옵션 안의 세부옵션들을 다시 한 번 정렬해준다.
        this.menuDetail.options.forEach(option => {
          if (option.options.length > 0) {
            option.options.sort(function(op1, op2) {
              if(op1.sort > op2.sort) return 1;
              if(op1.sort === op2.sort) return 0;
              if(op1.sort < op2.sort) return -1;
            })
            option.selected = option.options[0].idx
            option.selectedName = option.options[0].name
          }
        })
      }


    },(result) => {
      this.httpError(result.data)
    })
  },

  methods: {

    incrementQuantity() {
      this.quantity++
    },

    selectProduct() {
      if (this.$route.params.isModify) {
        this.$store.commit('removeCartProduct', {productIdx : this.productIdx, symbol : this.$route.params.symbol})
      }
      this.addProduct()
    },

    addProduct() {
      let currentCartShopIdx = this.$store.getters.getCurrentCartShopInfo.currentCartShopIdx
      if (currentCartShopIdx !== this.shopIdx) {
        this.$store.commit('initAllState')
      }

      let optionGroups = []

      if (this.menuDetail.options !== null) {
        this.menuDetail.options.forEach(optionGroup => {
          optionGroups.push({optionGroupIdx : optionGroup.idx, optionIdx : optionGroup.selected, optionName : optionGroup.selectedName, options : optionGroup.options})
        })
      }

      this.$store.commit("addCartProduct", {
        productIdx : this.productIdx,
        productName : this.menuDetail.name,
        productImg : this.menuDetail.imgPath,
        optionGroups : optionGroups,
        quantity : this.quantity,
        productPrice : this.menuDetail.price,
        symbol : Math.random().toString(36).substring(2, 12), //객체 식별자인 심볼을 별도로 만든다. (무작위 문자열)
      })

      this.$store.commit('setCurrentCartShopInfo', {
        currentCartShopIdx : this.shopIdx,
      })

      this.close()
    },

    close() {
      let productType = this.$store.getters.getProductType
      console.log('productType2 : ' + productType)
      if (productType === 'shop') {
        let storedShopIdx = this.tempShopInfo.shopIdx
        let storedShopName = this.tempShopInfo.shopName

        this.$router.replace({name : 'ShopMenu', params : {shopIdx : storedShopIdx, shopName : storedShopName}})
      } else {
        this.$router.replace({name : 'ShopMenu', params : {shopIdx : '0', shopName : ''}})
      }
    },

    changeShowNutritionInfo() {
      this.showNutritionInfo = !this.showNutritionInfo;
      this.isPreventScroll = this.showNutritionInfo
      this.$forceUpdate()
    },

    selectOption(options_01, selectedOptionIdx, selectedOptionName) {  //1차옵션정보 (배열)과 선택한 옵션 idx
      options_01.selected = selectedOptionIdx
      options_01.selectedName = selectedOptionName
      this.$forceUpdate()  //데이터가 바뀌어도 렌더링되지 않아 강제로 렌더링시켜준다.
    },

  }
}
</script>

<style scoped>

/* button */

.outline_m_btn { height: 40px; padding: 0 10px; min-width: 70px; width: 100%;  border-radius: 20px;  font-size: 14px; font-weight: 500; cursor: pointer;}

.preventScroll {
  position: fixed;
  width : 100%;
  height: 100%;
  overflow: hidden;
}
</style>
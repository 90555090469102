<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <h1><img src="/assets/image/header_logo.png" alt="아미스"></h1>
<!--          <button type="button" class="nav-btn right" v-if="isReject||isApprove"-->
<!--                  @click="isApprove = false, isReject = false"><i class="icon-close"></i></button>-->
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <!-- TODO: 높이 값에 따라 클래스 type이 다릅니다. -->
      <div class="guide_alram type1">
        <div class="alram_inner">
          <figure>
            <div class="img_100">
              <img src="/assets/image/character_100.svg" alt="결제가 완료되었습니다.">
            </div>
          </figure>
          <div class="ar_content">
            <h4 class="mt-30">{{ appName() }}</h4>
            <strong class="price mt-10" v-if="amount">{{ amount | numberWithCommas }} 원</strong><br>
            <em class="exchange mt-10" v-if="amount">= {{ amount * krw | numberWithCommas | rewardDecimal  }}.{{ getMetrDecimalPlace(amount * krw) }}AMIS</em>
            <p class="notice mt-30" v-if="!isApprove && !isReject">결제요청을 승인하시겠습니까?</p>
          </div>
        </div>
      </div>
      <div class="bottom_wrap">
        <div class="alram_inner">
          <p class="alert-content bg-red" style="text-align: center; font-size: 16px; margin-bottom: 100px; color: white; line-height: 1.3" v-if="msg">{{ msg }}</p>
          <div class="button-group">
            <button class="outline_l_btn btn-gray" @click="reject" v-if="!isReject && !isApprove">거부</button>
            <button class="outline_l_btn btn-black" @click="approval" v-if="!isApprove && !isReject">승인</button>
            <button class="outline_l_btn btn-black" v-if="isApprove">{{ commentSuccess }}</button>
            <button class="outline_l_btn btn-black" v-if="isReject">{{ commentFail }}</button>
          </div>
        </div>
      </div>
    </div>
    <PinCheck v-if="isFirstPinChecked === '0'"
              ref="PinCheck"
              :title="'PIN 확인'"
              :amount="0"
              :unit="''"
              :sub-amount="0"
              :sub-unit="''"
              :show-close="false"/>
  </div>
</template>

<script>
import PinCheck from "@/components/common/PinCheck";
export default {
  name: "PaymentCharge",
  props: {
    orderId: String,
    amount: String,
    callback: String
  },
  components: {
    PinCheck,
  },
  data() {
    return {
      commentSuccess: '결제가 완료되었습니다.',
      commentFail: '결제 요청을 거부하였습니다.',
      isApprove: false,
      isReject: false,
      krw: null,
      item: null,
      isFirstPinChecked : '0',
      msg: null
    }
  },
  mounted() {
    let accessToken = sessionStorage.getItem('accessToken')
    if(!accessToken){
      this.$router.push({ name : 'ChargeLogin' })
    }
    this.conversion()
    this.pinCheck()
  },
  methods: {
    appName(){
      return process.env.VUE_APP_PAYMENT_TITLE
    },
    pinCheck() {
      //메인화면에 필요한 정보를 가져온다.
      this.$get(this.$MAIN_INFO, 'main_mounted', (result) => {
        this.item = result.data
        localStorage.setItem('firstPinChecked', '0')

        //핀 화면을 띄우고 일반인증 및 지문인증을 위한 로직으로 넘어가기위한 함수
        this.checkPinOrBio(this.item)

      }, () => {
        //this.httpError(result.data)
      })
    },
    conversion(){
      this.$get(this.$KRW_PRICE, 'conversion_krw', (result) => {
        this.krw = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    reject() {
      this.isReject = true
      window.exitApp()
    },
    approval() {
      let formData = new FormData()
      formData.append('orderId', this.orderId)
      formData.append('amount', this.amount)
      formData.append('callback', this.callback)
      this.$post(this.$PAYMENT_CHARGE, 'approve_payment', formData, (result) => {
        console.log(result)
        this.isApprove = true
        window.exitApp()
      }, (result) => {
        if (result.data.message && result.data.message.length > 0) {
          this.msg = result.data.message
        } else if (result.data.error.length > 0) {
          let message = result.data.error[0].message
          this.msg = message
        }
        this.msg = '결제 요청을 실패하였습니다.'
        // this.httpError(result.data)
        this.commentFail = '결제 요청을 실패하였습니다.'
        this.reject()
      })
    },
  }
}
</script>

<style scoped>

</style>
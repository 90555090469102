<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="title">탈퇴</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="leave-content-wrap">
      <div class="container">
        <div class="row mt-50">
          <div class="conp-section-30">
            <h5 class="half-tit">아미스 탈퇴시</h5>
            <div class="leave-content-detail mt-20">
              <i>•</i>
              <span>내 프로필, 결제내역, 리워드 받은 리워드, 그 외 사용자가 설정한 모든 정보가 사라지고 복구가 불가능합니다.</span>
            </div>
            <div class="leave-content-detail">
              <i>•</i>
              <span>단, 관련 법령에 따라 아미스의 정보보호정책에 명시되어 있는 보관 의무를 가지는 정보는 관련 법령이 규정한 기간 동안 보존됩니다.</span>
            </div>
            <div class="leave-content-detail">
              <i>•</i>
              <span>아미스는 소멸되고 환불받을 수 없습니다. 탈퇴 전 꼭 출금 신청을 하세요.</span>
            </div>
            <hr class="hr-3">
            <div class="price-output mt-20">
              <div class="data-value">
                <span class="data-tit">현재 남은 아미스</span>
                <span class="point-color-black">{{ payBalance | numberWithCommas }}<em class="unit">AMIS</em></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="leave-footer-wrap">
      <div class="container">
        <div class="row">
          <div class="checks">
            <input type="checkbox" id="leaveCheck" v-model="isChecked" @click="isChecked = !isChecked">
            <label for="leaveCheck">모든 정보를 삭제하는 것에 동의힙니다.</label>
          </div>
          <div class="button-wrap pt-30">
            <button class="l_btn w-100" :class="{'btn-disable':!isChecked, 'btn-black':isChecked}" @click="leave()">탈퇴
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Leave",
  data() {
    return {
      isChecked: false,
      payBalance: 0,
      chainBalance: 0,
    }
  },
  mounted() {
    this.$get(this.$WALLET_REAPPAY_BALANCE, 'leave_mounted', (result) => {
      this.payBalance = result.data * 1
    }, (result) => {
      this.httpError(result.data)
    })
    this.$get(this.$WALLET_REAPCHAIN_BALANCE, 'leave_mounted', (result) => {
      this.chainBalance = result.data * 1
    }, (result) => {
      this.httpError(result.data)
    })
  },
  methods: {
    goLeave() {
      if (!this.isChecked) {
        return
      }
      // this.$root.$refs.Confirm.showDialog('탈퇴', '탈퇴를 하시겠습니까?<br/>탈퇴 후 립페이 및 립체인은 소멸되고<br/>환불 받을 수 없습니다.', '확인', '취소', () => {
      this.$root.$refs.MTRConfirm.showDialog('탈퇴', '탈퇴를 하시겠습니까?<br/>탈퇴 후 아미스는 소멸되고<br/>환불 받을 수 없습니다.', '확인', '취소', () => {

        //간편결제 회원 해지
        this.$delete(this.$ACCOUNT_MEMBER, 'leave_goleave', () => {

          //카카오 연결 해제
          window.Kakao.API.request({
            url: '/v1/user/unlink',
            success: (res) => {
              console.log(res)
            },
            fail: (err) => {
              console.log(err)
            },
          })

          //탈퇴
          this.$put(this.$AUTH_LEAVE, 'leave_goleave', null, () => {
            sessionStorage.removeItem('accessToken')
            sessionStorage.removeItem('refreshToken')
            localStorage.removeItem('passToken')
            localStorage.removeItem('useLocalBio')
            this.$router.replace({name: 'LeaveSuccess'})
          }, (result) => {
            this.httpError(result.data)
          })

        }, (result) => {
          this.httpError(result.data)
        })

      }, () => {
      }, false)
    },

    leave(){
      if (!this.isChecked) {
        return
      }

      this.$root.$refs.MTRConfirm.showDialog('탈퇴', '탈퇴를 하시겠습니까?<br/>탈퇴 후 아미스는 소멸되고<br/>환불 받을 수 없습니다.', '확인', '취소', () => {

        //탈퇴
        this.$put(this.$AUTH_LEAVE, 'leave_goleave', null, () => {
          sessionStorage.removeItem('accessToken')
          sessionStorage.removeItem('refreshToken')
          localStorage.removeItem('passToken')
          localStorage.removeItem('useLocalBio')

          //회원탈퇴시 공지알림과 이벤트 알림도 비활성화 처리
          window.unsubscribedTopic(process.env.VUE_APP_TOPIC_EVENT)
          window.unsubscribedTopic(process.env.VUE_APP_TOPIC_NOTICE)

          this.$router.replace({name: 'LeaveSuccess'})
        }, (result) => {
          this.httpError(result.data)
        })

      }, () => {
      }, false)
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }

</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="title">알림</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <!-- 리스트 없을 경우 -->
      <div class="row col-top" v-if="loaded && items.length === 0">
        <div class="wrapper-center">
          <div class="alram-wrap">
            <div class="list-none">
              <span class="bg_symbol_15_op">
                <i class="hide">알림없음</i>
              </span>
              <p class="list-desc pt-20">등록된 알림이 없습니다.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 리스트 있을 경우 -->
      <div class="container" v-if="items.length > 0">
        <div class="row">
          <div class="list-area">
            <!-- 공지리스트 -->
            <ul class="notice-list">
              <li class="list-sub" v-for="(item, index) in items" :key="index">
                <div class="alram-item">
                  <a @click="goDetailPage(item.link)">
                    <div class="list-date">{{ item.date }}</div>
                    <div class="list-summary">
                      <div class="list-info">
                        <div class="list-icon">
                          <!--img :src="removeHost(item.path)" srcset="/image/notice/icon-1@2.png 2x" alt="공지">-->
                          <img :src="item.link | notificationImage">
                        </div>
                        <div class="list-text">
                          <p><strong>{{ /\[.*\]/.test(item.title) ? /\[.*\]/.exec(item.title)[0] : ''}}</strong> {{  /\[.*\]/.test(item.title) ? item.title.replace(/\[.*\]/.exec(item.title)[0], "") : item.title }}</p>
                          <em>{{ item.content }}</em>
                        </div>
                      </div>
                      <div class="list-detail">
                        <i class="icon-arrow-right" v-show="getLink(item.link) !== ''"></i>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationList",
  data() {
    return {
      items: [],
      page: 1,
      loading: false,
      loaded: false,
    }
  },
  mounted() {
    this.getList()
    window.$('#alarmDiv').on('scroll', this.onScrollEvent)
  },
  beforeDestroy() {
    window.$('#alarmDiv').off('scroll', this.onScrollEvent)
  },
  methods: {
    onScrollEvent() {
      if(window.$('#alarmDiv').scrollTop() + window.$('#alarmDiv').innerHeight() >= window.$('#alarmDiv')[0].scrollHeight - 10) {
        this.getList()
      }
    },
    getList() {
      this.loaded = false
      if(this.loading) {
        return
      }

      this.loading = true

      this.$get(this.$NOTIFICATION_LIST + '?page=' + this.page, 'notification_mounted', (result) => {
        if(result.data.length > 0) {
          this.items = this.items.concat(result.data)
          this.page++

          this.loading = false
        }
        this.loaded = true

      }, (result) => {
        this.httpError(result.data)
        this.loading = false
      })
    },
    goDetailPage(_type) {
      let link = this.getLink(_type)
      if (link !== '') {
        this.$router.push({name:link})
      }
    },
    getLink(value) {
      if (value === 'NT') {
        return 'NoticeList'
      } else if (value === 'EV') {
        return 'EventList'
      } else if (value === 'RP') {
        return 'HistoryPay'
      } else if (value === 'RC') {
        return 'HistoryChain'
      } else if (value === 'RW') {
        return 'HistoryReward'
      } else if (value === 'CC') {
        return 'HistoryCard'
      } else if (value === 'MT') {
        return 'HistoryMetr'
      } else {
        return ''
      }
    }
  },

  filters : {
    notificationImage : function (value) {
      console.log('value : ' + value)
      if (value === 'MT') {
        console.log('1 ')
        return '/image/notice/icon-3@2.png'
      } else {
        console.log('2 ')
        return '/image/notice/icon-1@2.png'
      }
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
.wrapper-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

</style>

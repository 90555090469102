<template>
  <div class="header-wrap" :class="{ 'map_nav': this.openMap === true }">
    <!-- TODO: 기본화면 -->
    <div class="container" v-show="!this.isSearch">
      <div class="header-group">
        <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
        <span class="title">{{ title }}</span>
        <button type="button" class="nav-btn right" @click="searchRouter()">
          <i class="icon ver_2 icon-search"></i>
        </button>
      </div>
    </div>
    <div class="header-group" v-show="this.isSearch">
      <div class="container">
        <button type="button" class="nav-btn left"  style="z-index: 1;" @click="$router.back()"><i class="icon-arrow-left"></i></button>
        <div class="heder-input">
          <input type="text" placeholder="검색어 입력" :value="keyword"  @input="keyword = $event.target.value" @keyup="getKeywords" @click="getAllKeywords">
          <!-- TODO : 텍스트 입력시 노출, 텍스트 없을 경우  비노출 -->
          <button class="input-close" aria-label="검색어지우기" @click="deleteData()" v-if="keyword.length > 0"></button>
          <button type="button" class="nav-btn right" @click="setKeyword()"><i class="icon ver_2 icon-search"></i></button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TopNav",
  props: {
    title: String,
    isSearch: Boolean,
    searchType: String,
    openMap: Boolean,
  },
  data() {
    return {
      keyword: '',
      searchedKeywords : [],
    }
  },
  methods: {
    back() {
      if (this.searchType === 'menu') {
        this.$router.replace({name : 'Main'})
      } else {
        this.$router.back()
      }
    },

    setKeyword(){
      if (this.searchType === 'menu') {
        this.setSearchedKeyword('menu', this.keyword)
      } else if (this.searchType === 'shop') {
        this.setSearchedKeyword('shop', this.keyword)
      }
      this.$emit('searchEvent', this.keyword )
    },
    deleteData(){
      this.keyword = ''
    },

    getKeywords(event) {
      if (event.which === 13) {  //enter 를 쳤을경우
        this.setKeyword()
      }
      if (this.searchType === 'menu') {
        this.searchedKeywords = this.getValidSearchedKeywords('searched_keyword_menu', this.keyword)
      } else if (this.searchType === 'shop') {
        this.searchedKeywords = this.getValidSearchedKeywords('searched_keyword_shop', this.keyword)
      }
      this.$emit('keyupEvent', this.searchedKeywords)
    },

    getAllKeywords() {
      if (this.searchType === 'menu') {
        this.searchedKeywords = this.getValidSearchedKeywords('searched_keyword_menu', '')
      } else if (this.searchType === 'shop') {
        this.searchedKeywords = this.getValidSearchedKeywords('searched_keyword_shop', '')
      }
      this.$emit('keyupEvent', this.searchedKeywords)
    },
    searchRouter(){
      if(this.openMap === true){
        this.$router.push({ name : 'SearchShop' })
      } else {
        this.$router.push({ name : 'SearchMenu', params : {type : 'menu'} })
      }
    }
  },
  // watch: {
  //   keyword(){
  //     this.setKeyword()
  //   }
  // }
}
</script>

<style scoped>
#search input {
  width: 385px;
}
.map_nav {
  position: static;
}
</style>
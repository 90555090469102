import moment from "moment";

export default Object.freeze({
  install(Vue) {

    Vue.prototype.breakLine = function (str, divideCount) {
      let ary = []
      for (let i=0; i < str.length; i = i + divideCount) {
        ary.push(str.slice(i, i + divideCount))
      }
      return ary.join('<br>')
    }

    Vue.prototype.removeHost = function (url) {
      if (!url) {
        return
      }

      url = url.split('/')
      url = url.splice(3)
      return process.env.VUE_APP_FILE_DOMAIN + "/" + url.join('/')
    }

    Vue.prototype.setSearchedKeyword = function (searchType, key) {

      let searchedKeywords
      let storageKey

      if (key === '') {
        return
      }

      if ('menu' === searchType) {
        searchedKeywords = localStorage.getItem('searched_keyword_menu')
        if (searchedKeywords === undefined || searchedKeywords === null) {
          localStorage.setItem('searched_keyword_menu', '{}')
          searchedKeywords = '{}'
        }
        storageKey = 'searched_keyword_menu'

      } else if ('shop' === searchType) {
        searchedKeywords = localStorage.getItem('searched_keyword_shop')
        if (searchedKeywords === undefined || searchedKeywords === null) {
          localStorage.setItem('searched_keyword_shop', '{}')
          searchedKeywords = '{}'
        }
        storageKey = 'searched_keyword_shop'

      }

      searchedKeywords = JSON.parse(searchedKeywords)
      searchedKeywords[key] = new Date().getTime() + (1000  * 60 * 60 * 24 * 180)
      //searchedKeywords[key] = new Date().getTime() + (1000  * 20)

      localStorage.setItem(storageKey, JSON.stringify(searchedKeywords))
    }

    //ddd
    Vue.prototype.getValidSearchedKeywords = function (storageKey, keyword) {
      let searchedKeywords = localStorage.getItem(storageKey)

      if (!searchedKeywords) {
        return null
      }
      searchedKeywords = JSON.parse(searchedKeywords)

      let resultKeys = []
      let resultObjects = []
      for (const [key, value] of Object.entries(searchedKeywords)) {
        if (key.startsWith(keyword)) {
          if (new Date().getTime() > value) {
            delete searchedKeywords[key]
          } else {
            resultObjects.push({
              key : key,
              expire : value
            })
          }
        }
      }

      //desc 역순으로 재배열한다.
      resultObjects.sort((a, b) => {
        if (a.expire < b.expire) {
          return 1
        } else if (a.expire > b.expire) {
          return -1
        }
        return 0
      })

      resultObjects.forEach(o => {
        resultKeys.push(o.key)
      })

      localStorage.setItem(storageKey, JSON.stringify(searchedKeywords))
      resultKeys = resultKeys.slice(0, 10)  //TODO 보여주는 최대 키워드수는 우선 10개로 맞추어놓는다.
      return resultKeys
    }

    Vue.prototype.deleteSearchedKeyword = function(storageKey, keyword) {
      let searchedKeywords = localStorage.getItem(storageKey)
      if (!searchedKeywords) {
        return
      }
      searchedKeywords = JSON.parse(searchedKeywords)
      Object.keys(searchedKeywords).forEach(key => {
        if (keyword === key) {
          delete searchedKeywords[keyword]
        }
      })

      localStorage.setItem(storageKey, JSON.stringify(searchedKeywords))
    }

    let isShowingMessage = false
    // 토스트 스타일 알림
    Vue.prototype.notify = function (type, msg, is_alert = false, showingTime = 3000) {
      if (is_alert) {
        alert(msg)
      } else {
        if (!isShowingMessage) {
          isShowingMessage = true
          if(type === 'error') {
            window.$('#alert-wrap').append("<div class=\"alert-content bg-red\"><i class=\"icon-alert-fail\"></i> <span>"+msg+"</span></div>")
          } else {
            window.$('#alert-wrap').append("<div class=\"alert-content\"><i class=\"icon-check\"></i> <span>"+msg+"</span></div>")
          }
          setTimeout(function() {
            window.$('#alert-wrap div:first-child').remove()
            isShowingMessage = false
          }, showingTime)
        }
      }
    }

    // 기본 HTTP 에러 처리
    Vue.prototype.httpError = function (data) {
      console.log(data)
      if (data.state === 401 || data.state === 403) {
        sessionStorage.clear()
        this.$router.push({name: 'Intro'}).catch(() => {
        })
      } else {
        if (data.message && data.message.length > 0) {
          this.notify('error', data.message, false)
        } else if (data.error && data.error.length > 0) {
          let message = data.error[0].message
          this.notify('error', message, false)
        }
      }
      // if(Array.isArray(data.error)) {
      //     for(let i=0; i<data.error.length; i++) {
      //         this.notify('error', data.error[i].message, false)
      //     }
      // } else {
      //     if(data.state === 401) {
      //         if(data.error === '해당 액세스 토큰의 유효기간이 만료되었음') {
      //             sessionStorage.removeItem('refreshToken')
      //             sessionStorage.removeItem('accessToken')
      //
      //             // this.$router.push({ name: 'Login', params: { msg: '해당 액세스 토큰의 유효기간이 만료되었습니다.' } }).catch(() => {})
      //             this.$router.push({ name: 'Login'}).catch(() => {})
      //         }
      //
      //         sessionStorage.removeItem('refreshToken')
      //         sessionStorage.removeItem('accessToken')
      //
      //         if(!localStorage.getItem('passToken')) {
      //             this.$router.push({ name: 'Login', params: { msg: '로그인이 필요한 서비스입니다.' } }).catch(() => {})
      //         }
      //         this.$router.push({ name: 'Login' }).catch(() => {})
      //     } else {
      //         this.notify('error', data.error, false)
      //     }
      // }
    }

    // 디바이스 푸시 갱신
    Vue.prototype.refreshPush = function () {
      if (window.getVersion()) {
        let device = ''
        if (window.getDeviceType() === 'ios') {
          device = 'I'
        } else if (window.getDeviceType() === 'android') {
          device = 'A'
        }
        let formData = new FormData()
        formData.append('fcmToken', window.getFCMToken() ? window.getFCMToken() : '')
        formData.append('os', device)


        this.$put(this.$USER_FCM_UPDATE, 'refreshPush', formData, () => {}, () => {
          this.notify('error', '푸시 서비스 등록에 문제가 발생했습니다.')
        })
      }
    }

    //토픽 설정
    Vue.prototype.setPushAlarm = function (callback) {
      this.$get(this.$USER_INFO, 'login_getPush', (result) => {
        let alarm = result.data
        alarm.noticeAlarm ? window.subscribedTopic(process.env.VUE_APP_TOPIC_NOTICE) : window.unsubscribedTopic(
            process.env.VUE_APP_TOPIC_NOTICE)
        alarm.eventAlarm ? window.subscribedTopic(process.env.VUE_APP_TOPIC_EVENT) : window.unsubscribedTopic(
            process.env.VUE_APP_TOPIC_EVENT)
        callback()
      }, (result) => {
        this.httpError(result.data)
        callback()
      })
    }

    // 토큰 갱신
    Vue.prototype.refreshTokenRate = 5 * 60 * 1000
    Vue.prototype.refreshToken = function () {
      let refreshTokan = sessionStorage.getItem('refreshToken')
      setTimeout(() => {
        this.refreshToken()
      }, this.refreshTokenRate)

      if (!refreshTokan) {
        return
      }

      let formData = new FormData()
      formData.append('refreshToken', refreshTokan)

      this.$post(this.$AUTH_REFRESHTOKEN, 'refreshToken', formData, (result) => {
        sessionStorage.setItem('accessToken', result.data.accessToken)
        sessionStorage.setItem('refreshToken', result.data.refreshToken)
        if (localStorage.getItem('passToken')) {
          localStorage.setItem('passToken', result.data.passToken)
        }

        //sso 재로그인
        this.$post(this.$SSO_AUTH_WITH_TOKEN, 'auto_sso' + '_login', null, (result) => {
          if (result.data.ssoToken) {
            let ssoToken = result.data.ssoToken
            let formData = new FormData()
            formData.append('ssoToken ', ssoToken)
            //ssoToken 도 스토리지에 추가
            sessionStorage.setItem('ssoToken', ssoToken)
/*            this.$reusable_post(this.$REUSABLE_AUTH_LOGIN, 'auto_reusable' + '_gossologin', formData, (result) => {
              if (result.data.accessToken && result.data.refreshToken) {
                sessionStorage.setItem('accessToken_reusable', result.data.accessToken)
                sessionStorage.setItem('refreshToken_reusable', result.data.refreshToken)
              }
            }, (result) => {
              this.httpError(result)
            })*/

/*            let formData2 = new FormData()
            formData2.append('ssoToken ', ssoToken)
            this.$quick_order_post(this.$QUICK_ORDER_LOGIN, 'quickOrderLogin', formData2, (result) => {
              sessionStorage.setItem('accessToken_quick_order', result.data.accessToken)
              sessionStorage.setItem('refreshToken_quick_order', result.data.refreshToken)

              //this.setStorageQuickOrderToken(result)
            }, (result) => {
              this.httpError(result.data)
            })*/
          }
        }, (result) => {
          this.httpError(result.data)
        })

      }, (result) => {
        this.httpError(result.data)

        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('refreshToken')
      })
    }

    //앱 종료 후 재실행시 메인화면에서 비정상적인 인증 토큰 오휴를 방지하기 위함
    Vue.prototype.refreshTokenFromMain = function (callback) {
      let refreshToken = sessionStorage.getItem('refreshToken')
      if (!refreshToken) {
        return
      }

      let formData = new FormData()
      formData.append('refreshToken ', refreshToken)
      console.log('실행2 ' + this.$AUTH_REFRESHTOKEN)

      this.$post(this.$AUTH_REFRESHTOKEN, '_refreshToken', formData, (result) => {
        sessionStorage.setItem('accessToken', result.data.accessToken)
        sessionStorage.setItem('refreshToken', result.data.refreshToken)
        localStorage.setItem('passToken', result.data.passToken)
        //sso 재로그인
        this.$post(this.$SSO_AUTH_WITH_TOKEN, 'auto_sso' + '_login', null, (result) => {
          if (result.data.ssoToken) {
            let ssoToken = result.data.ssoToken
            let formData = new FormData()
            formData.append('ssoToken ', ssoToken)
            //ssoToken 도 스토리지에 추가
            sessionStorage.setItem('ssoToken', ssoToken)
            this.$reusable_post(this.$REUSABLE_AUTH_LOGIN, 'auto_reusable' + '_gossologin', formData, (result) => {
              if (result.data.accessToken && result.data.refreshToken) {
                sessionStorage.setItem('accessToken_reusable', result.data.accessToken)
                sessionStorage.setItem('refreshToken_reusable', result.data.refreshToken)

              }
            }, () => {})

            callback()
            // let formData2 = new FormData()
            // formData2.append('ssoToken ', ssoToken)
            // this.$quick_order_post(this.$QUICK_ORDER_LOGIN, 'quickOrderLogin', formData2, (result) => {
            //   sessionStorage.setItem('accessToken_quick_order', result.data.accessToken)
            //   sessionStorage.setItem('refreshToken_quick_order', result.data.refreshToken)
            //
            //   callback()
            // }, () => {})
          }
        }, () => {console.log('실행3333')})

      }, () => {
        console.log('실행3')

        //this.httpError(error.data)

        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('refreshToken')
      })

    }

    Vue.prototype.doBack = function (back, name = 'Dashboard', redirect = false) {
      if (typeof back !== 'undefined') {
        this.$router.back()
      } else {
        if (redirect) {
          this.$router.push({name: name})
        } else {
          this.$router.replace({name: name})
        }
      }
    }

    Vue.prototype.calendarOptions = {
      format: 'YYYY.M',
      locale: moment.locale('ko'),
      icons: {
        time: 'glyphicon glyphicon-time',
        date: 'glyphicon glyphicon-calendar',
        up: 'icon-arrow-up',
        down: 'icon-arrow-down',
        previous: 'icon-arrow-left',
        next: 'icon-arrow-right',
        today: 'glyphicon glyphicon-screenshot',
        clear: 'glyphicon glyphicon-trash',
        close: 'glyphicon glyphicon-remove'
      },
      ignoreReadonly: true,
    }

    Vue.prototype.getMetrDecimalPlace = function(x) {
      if (Number.isInteger(x)) {  //정수일 때
        return '0000'
      } else if (Number(x) === x && x % 1 !== 0) {  //소수일 때
        x = x.toString()
      }
      let tmp = x.replaceAll(',','')
      let isFloat = tmp.indexOf('.')
      //정수인 경우
      if (isFloat < 0) {
        return '0000'
      } else {
        let decimal = tmp.split('.')[1]
        let decimalCount = decimal.slice(0, 4).length
        for (let i = decimalCount; i < 4; i++) {
          decimal = decimal + '0'
        }

        return decimal.slice(0, 4)
      }
    }

    Vue.prototype.getMetr8DecimalPlace = function(x) {
      if (Number.isInteger(x)) {
        return '00000000'
      } else if (Number(x) === x && x % 1 !== 0) {
        x = x.toString()
      }
      let tmp = x.replaceAll(',','')
      let isFloat = tmp.indexOf('.')
      //정수인 경우
      if (isFloat < 0) {
        return '00000000'
      } else {
        let decimal = tmp.split('.')[1]
        let decimalCount = decimal.slice(0, 8).length
        for (let i = decimalCount; i < 8; i++) {
          decimal = decimal + '0'
        }
        return decimal.slice(0, 8)
      }
    }


    Vue.prototype.autoLogin = function() {
      let passToken = localStorage.getItem('passToken')
      if (passToken) {
        let formData = new FormData
        formData.append('passToken', passToken)
        this.$post(this.$AUTH_AUTOLOGIN, 'login_login', formData, (result) => {
          this.loginDone(result)
        }, (result) => {
          //자동로그인에 실패할시 로그인 화면으로 이동한다.
          this.loginFail(result, true)
        })
      }
    }

    Vue.prototype.loginDone = function(result) {
      sessionStorage.setItem('accessToken', result.data.accessToken)
      sessionStorage.setItem('refreshToken', result.data.refreshToken)
      localStorage.setItem('passToken', result.data.passToken)

      this.refreshPush()
      this.setPushAlarm(() => {})
      localStorage.setItem('firstPinChecked', '0')

      this.$router.replace({ name: 'Main' })
    }

    Vue.prototype.loginFail = function(result, isAutoLogin = false) {
      sessionStorage.clear()
      localStorage.removeItem('passToken')
      localStorage.removeItem('useLocalBio')

      if (result.data.state === 400) {
        console.log(result.data.data)
        if (result.data.data.state === '1') { //탈퇴
          this.$router.push({name: 'LeaveUser'})
        } else if (result.data.data.state === '2') { //정지
          this.$root.$refs.MTRAlert.showAlert('아미스페이 정책 위반',
              '아미스페이 정책 위반으로 현재 계정은<br/>사용정지 처리되었습니다.',
              result.data.data.reason,
              result.data.data.time,
              () => {
              })
        } else if (result.data.data.state === '3') { //휴면
          this.$root.$refs.MTRAlert.showDialog('아미스페이 휴면계정',
              '현재 계정은 1년간 접속하지 않아<br/>휴면 상태로 전환된 상태입니다.<br/>' +
              '휴면 계정 해제를 통해<br/>정상적으로 이용하실 수 있습니다.',
              '휴면계정 해제', '취소', () => {
                this.$router.push({name: 'InactivityRequest'})
              }, () => {
              })
        } else {
          if (isAutoLogin) {
            this.notify('','세션이 만료되었습니다. 재로그인 해주세요.', false)
            this.$router.replace({name : 'Login'})
          } else {
            this.httpError(result.data)
          }
        }

      } else {
        this.httpError(result.data)
      }
    }



    Vue.prototype.clearStorage = function() {
      sessionStorage.clear()
      localStorage.removeItem('passToken')
      localStorage.removeItem('useLocalBio')
      localStorage.setItem('firstPinChecked', '0')
    }

    //this.$MAIN_INFO 에서 가져온 사용자 정보를 매개변수로 받는다.
    Vue.prototype.checkPinOrBio = function(user) {
      this.$get(this.$USER_INFO_DETAIL, 'main_checkbio', (result) => {
        //사용자 상세정보에서 지문인증 여부를 확인한다.
        if (result.data.biometrics) {
          //지문인증여부가 true 이면 기기에서 지문인증 가능여부를 묻는다.
          if (window.getAbleBio()) {
            //지문인증이 가능한 기기라면 로컬생체인증을 true 로 한다.
            localStorage.setItem('useLocalBio', 'true')
          }
        }
        //네이티브 메세지를 받기위한 상태값 변경
        window.isAlready = true
        window.changeAlreadyState()

        if (user.usePin !== true) {
          this.$router.push({name: 'SignUpPinRegist'})
        } else if (user.hp === null || user.hp === '') {
          this.$router.push({name: 'ChangePhone'})
          // } else if (this.item.email === null || this.item.email === '') {
          //   //이메일 설정은 우선 주석처리(20210715)
          //   this.$router.push({name: 'ChangeEmail'})
        } else if (localStorage.getItem('firstPinChecked') !== '1') {
          //로그인, 자동 로그인 후 첫 메인에서 핀체크 한번
          this.$refs.PinCheck.showPin(() => {
            localStorage.setItem('firstPinChecked', '1')
            this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
          })
        }

      }, () => {
        this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
      })
    },


    Vue.filter('rewardDecimal', function (x) {
      if ('string' !== typeof x) {
        x = String(x)
      }
      let isFloat = x.indexOf('.')
      if (isFloat) {
        x = x.split('.')[0]
      }
      return x
    })


    Vue.filter('numberWithCommas', function (x) {
      if (!x && x !== 0) {
        return
      }
      if (x.toString().split(".").length === 2) {
        let parts = x.toString().split(".")
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        parts[1] = parts[1].substr(0, 4)
        return parts.join('.')
      } else {
        return (x + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    })



    Vue.filter('numberWithCommasAll', function (x) {
      if (x.toString().split(".").length === 2) {
        let parts = x.toString().split(".")
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        if (parts[1].length > 8) {
          parts[1] = parts[1].substr(0, 7) + '...'
        } else {
          parts[1] = parts[1].substr(0, 8)
        }
        return parts.join('.')
      } else {
        return (x + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    })
  }
})

<template>
  <div class="popup-wrap bg-white z-index-400">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="title">{{ receiveTokenType === 'AMIS' ? '아미스' : '이더리움' }} 송금받기</span>
          <button type="button" class="nav-btn right" @click="$emit('close')"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="main-inner">
            <div class="input-close">
              <MTRInput
                  :type="'text'"
                  :placeholder="('송금받을 ' +  (receiveTokenType === 'AMIS' ? '아미스를' : '이더리움을') + ' 입력해 주세요.')"
                  :model="amount"
                  @update="(_model) => {this.amount=_model}"
                  :input-mode="'decimal'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <div class="container">
        <button class="l_btn_full btn-black" :class="{'btn-disable': amount * 1 <= 0}" @click="selected()">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import MTRInput from "../common/MTRInput";
export default {
  name: "ReceiveInputAmount",
  components: {
    MTRInput
  },
  props: ['oldAmount', 'receiveTokenType'],
  data() {
    return {
      amount: '',
    }
  },
  mounted() {
    this.amount = this.oldAmount
    console.log('token Type : ' + JSON.stringify(this.receiveTokenType))
  },
  methods: {
    selected() {
      if (this.amount < 0) {
        this.notify('error', '금액은 0보다 작을수 없습니다.')
        return
      }
      if (!/^[0-9-][0-9,.]*$/.test(this.amount)) {
        this.notify('error', '금액을 정확하게 입력해주세요.')
        return
      }
      this.amount = ''
      this.$emit('inputDone', this.amount)
    },
  },
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

import { render, staticRenderFns } from "./ShopMenu.vue?vue&type=template&id=97f06622&scoped=true&"
import script from "./ShopMenu.vue?vue&type=script&lang=js&"
export * from "./ShopMenu.vue?vue&type=script&lang=js&"
import style0 from "./ShopMenu.vue?vue&type=style&index=0&id=97f06622&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97f06622",
  null
  
)

export default component.exports
<template>
  <div class="wrap">
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row confirm-area-bottom">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <p class="confirm-subtit">신용카드가 정상적으로<br>등록되었습니다.</p>
            </div>
            <hr class="hr-3">
            <div class="confirm-info-info">
              <div class="clear">
                <div class="bank-icon">
                  <i class="sp-card" :class="$route.params.number | cardImage"></i>
                </div>
                <div class="bank-summary">
                  <span>{{ $route.params.number }}</span>
                  <em class="disabled">신용카드 별명 : {{ $route.params.alias ? $route.params.alias : '별명없음' }}</em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="$router.back()">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistCardSuccess",
  filters: {
    cardImage: (value) => {
      if (value.startsWith('삼성') || value.toLowerCase().startsWith('samsung')) {
        return 'card-02'
      } else if (value.startsWith('비씨') || value.toLowerCase().startsWith('bc')) {
        return 'card-08'
      } else if (value.startsWith('롯데') || value.toLowerCase().startsWith('lotte')) {
        return 'card-05'
      } else if (value.startsWith('현대') || value.toLowerCase().startsWith('hyundai')) {
        return 'card-04'
      } else if (value.startsWith('국민') || value.toLowerCase().startsWith('kb')) {
        return 'card-01'
      } else if (value.startsWith('신한') || value.toLowerCase().startsWith('shinhan')) {
        return 'card-03'
      } else if (value.startsWith('시티') || value.toLowerCase().startsWith('citi')) {
        return 'card-09'
      } else if (value.startsWith('농협') || value.toLowerCase().startsWith('nh')) {
        return 'card-11'
      } else if (value.startsWith('하나') || value.toLowerCase().startsWith('hana')) {
        return 'card-06'
      } else if (value.startsWith('비자') || value.toLowerCase().startsWith('visa')) {
        return 'card-15'
      } else if (value.startsWith('마스터') || value.toLowerCase().startsWith('master')) {
        return 'card-16'
      }
      return 'bank-30'
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

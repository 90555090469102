<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="header-group">
        <div class="container">
          <span class="title">퀵오더 내역</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <div class="payment_summary type2">
        <div class="container">
          <div class="order_head type2">
            <h2 class="store">{{ orderDetail.shopName }}</h2>
            <div>
              <p class="order_tit">주문일시</p>
              <p class="order_tit"><b class="time">{{ orderDate }}</b></p>
            </div>
            <div>
              <p class="order_tit">주문번호</p>
              <p class="order_tit"><b class="desc">{{ orderDetail.oid }}</b><em v-if="orderDetail.paymentType === 1" class="icon_badge mert" aria-label="메트라코인결제"></em></p>
            </div>
            <div class="order_step type1 pt-30">
              <span :class="{'step' : orderDetail.state === '0'}">결제완료</span>
              <span :class="{'step' : orderDetail.state === '1'}">접수완료</span>
              <span :class="{'step' : orderDetail.state === '2'}">대기중</span>
              <span :class="{'step' : orderDetail.state === '3'}">완료</span>
            </div>
          </div>
        </div>
      </div>
      <div class="payment_detail type_bar">
        <div class="container">
          <div class="row">
            <div class="detail_section">

              <div v-for="product in orderDetail.productList" :key="product.idx">
                <!-- 메뉴 요약 -->
                <div class="menu_summary clear">
                  <div class="left">
                    <img class="munu_img" :src="removeHost(product.img)" alt="">
                  </div>
                  <div class="right">
                    <strong class="ellipsis2">{{ product.name }}</strong>
                    <ul class="option">
                      <li v-for="optionGroup in product.optionGroupList" :key="optionGroup.idx">{{ optionGroup.optionList[0].name }}</li>
                    </ul>
                  </div>
                </div>
                <!-- //메뉴 요약 -->
                <!-- 수량선택 -->
                <div class="info_area type2 pt-10">
                  <div class="count">
                    <button class="btn_count_down"><span class="hide">수량빼기</span></button>
                    <input type="number" :value="product.count">
                    <button class="btn_count_up"><span class="hide">수량더하기</span></button>
                  </div>
                  <strong class="price">{{ product.price.toLocaleString('ko-KR') }}원</strong>
                </div>
                <!-- //수량선택 -->
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="payment_detail type_bar">
        <div class="container">
          <div class="row">
            <div class="detail_section">
              <div>
                <div class="gudie_list type2">
                  <dl>
                    <dt>총 주문금액</dt>
                    <dd>{{ orderDetail.price.toLocaleString('ko-KR') }}원</dd>
                  </dl>
                  <dl v-if="orderDetail.paymentType === 1">
                    <dt>사용리워드</dt>
                    <dd>{{ orderDetail.reward }}AMIS</dd>
                  </dl>
                </div>
              </div>
              <div>
                <div class="gudie_list type2 total">
                  <dl v-if="orderDetail.paymentType === 1">
                    <dt>총 결제금액<b class="pl-5">{{ orderDetail.productList.length }}개</b></dt>
                    <dd>
                      <strong>{{ (orderDetail.price * krwToMetr).toLocaleString('ko-KR') }}AMIS</strong>
                      <em class="unit1">(1AMIS={{ metrToKrw }}원)</em>
                    </dd>
                  </dl>

                  <dl v-else-if="orderDetail.paymentType === 2">
                    <dt>총 결제금액<b class="pl-5">{{ orderDetail.productList.length }}개</b></dt>
                    <dd>
                      <strong>{{ orderDetail.price }}원</strong>
                    </dd>
                  </dl>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="payment_button">
        <div class="container pb-30">
          <div class="row">
            <button class="outline_l_btn btn-line-black" @click="deleteQuickOrder" v-if="orderDetail.state === '3'">내역삭제</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "QuickOrderHistoryDetail",
  data() {
    return {
      orderIdx : this.$route.params.orderIdx,
      orderDetail : {
        price : 0,
        productList : [
          {
            price : 0,
          }
        ]
      },
      orderDate : '',


      metrToKrw : 0,  //1아미스당 원화시세
      krwToMetr : 0,  //1원당 아미스시세
    }
  },
  created() {
    this.getOrderDetail()
    this.exchangeMetrToKrw()
  },
  methods: {
    getOrderDetail() {
      this.$quick_order_get(this.$QUICK_ORDER_HISTORY_DETAIL  + this.orderIdx,
          'order_history_detail', (result) => {
            this.orderDetail = result.data
            this.orderDate = this.orderDetail.createAt.split('T')[0] + ' ' + this.orderDetail.createAt.split('T')[1]
          }, (result) => {
            this.httpError(result.data)
          })
    },

    //1아미스당 원화시세  METR-KRW
    exchangeMetrToKrw() {
      this.$get(this.$KRW_PRICE, 'krwToMetrPrice', (result) => {
        this.krwToMetr = parseFloat(result.data)
        this.metrToKrw = 1 / this.krwToMetr

        this.$store.commit('setKrwToMetr', {krwToMetr : this.krwToMetr})
        this.$store.commit('setMetrToKrw', {metrToKrw : this.metrToKrw})
        this.$emit('getExchangeKrwToMetr', this.krwToMetr)
      }, (result) => {
        this.httpError(result.data)
      })
    },

    deleteQuickOrder() {
      this.$quick_order_put(this.$QUICK_ORDER_HISTORY_DELETE  + this.orderIdx, 'quickOrderLogin', null, () => {
        this.$router.replace({name : 'QuickOrderHistory'})
        //this.setStorageQuickOrderToken(result)
      }, () => {
        this.notify('error', '픽업완료 상품내역만 삭제가능합니다.', false)
      })
    }

  }
}
</script>

<style scoped>

</style>
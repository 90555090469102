<template>
  <div>
    <div class="popup-wrap" :class="{'z-index-400' : showInputAmount }" v-if="!showInputAmount">
      <div class="overlay-bg" @click.self="$emit('close')"></div>
      <div class="half-layer-wrap">
        <div class="container">
          <!-- 아미스 -->
          <div class="payment-wrap pt-30 pb-120">
            <div class="row">
              <div class="payment-reload">
                <span class="meterra_logo">
                  <img src="/assets/image/eth_row02_gray.png" alt="ETH" v-if="tokenType === 'ETH'">
                  <img src="/image/logo_row01.svg" alt="아미스" v-else>
                </span>
              </div>
            </div>
            <!-- QR 부분 -->
            <div class="payment-sort">
              <div class="scan pb-0 ">
                <vue-qrcode :value="qrStr"/>
              </div>
            </div>
            <!-- //QR 부분 -->
            <div class="payment-info text-c">
              <div class="input-area address_copy">
                <input type="text" class="border-bottom text-c w-100" v-show="/^0x[a-fA-F0-9]{40}$/.test(qrStr)" @click="copyAddress()" v-model="qrStr" id="clipboard" readonly/>
                <button class="btn_copy" @click="copyAddress()"><span class="hide">주소복사</span></button>
              </div>
<!--              <div class="mt-20">
                <button type="button" class="l_btn btn-black" @click="openInputAmount()">금액 입력</button>
              </div>-->
              <div class="mt-20">
                <p class="fs-16">송금받기 QR코드를 스캔하세요.</p>
              </div>
            </div>

          </div>
          <!-- //아미스 -->
        </div>
      </div>

    </div>
    <receive-input-amount
        v-if="showInputAmount"
        :oldAmount="amount"
        @inputDone="inputDone"
        @close="closeInput"
        :receive-token-type="tokenType"
    />
  </div>

</template>

<script>
import VueQrcode from 'vue-qrcode'
import ReceiveInputAmount from "./ReceiveInputAmount";
export default {
  name: "ReceiveQR",
  components: {
    VueQrcode,
    ReceiveInputAmount
  },
  props: {
    tokenType : String
  },
  data() {
    return {
      qrStr: '',
      amount: '',
      showInputAmount: false,
    }
  },
  mounted() {
    this.getQr()
  },
  methods: {
    copyAddress() {
      let copyText = window.document.getElementById('clipboard')

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      window.document.execCommand("copy");
      copyText.setSelectionRange(0, 0)
      this.$root.$refs.MTRAlert.showAlert('주소 복사',
          '주소가 복사되었습니다.<br/>' + this.qrStr,
          '',
          '',
          () => {
          }, this.tokenType)
    },
    getQr() {
      let formData = new FormData()
      if (this.amount * 1 > 0) {
        formData.append('amount', this.amount)
      }
      this.$post(this.$REMITTANCE_RECEIVE_METR, 'receiveqr_getqr', formData, (result) => {
        if (result.data) {
          this.qrStr = result.data
        }
      }, (result) => {
        this.httpError(result.data)
        this.amount = ''
      })
    },
    openInputAmount() {
      console.log('ttt type : ' + this.tokenType)
      if (this.amount * 1 === 0) {
        this.amount = ''
      }
      this.showInputAmount = true
    },
    closeInput() {
      this.showInputAmount = false
    },
    inputDone(_amount) {
      this.amount = _amount
      this.closeInput()
      this.getQr()
    },
  }
}
</script>

<style scoped>
.btn_copy { position: absolute; right: 0; width: 44px; height: 50px; background: url(/assets/image/ico_linkcopy.png) no-repeat right 20px top 17px / 14px auto; }
</style>

<template>
<div class="main-bg">
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="title">AMIS Lock-up</span>
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50 pb-90">
      <div class="tab_wrap">
        <div class="container">
          <ul class="tablist">
            <li class="selected"><a>Lock-up</a></li>
            <li><a @click="$router.replace({name : 'LockUpHistory'})">내역</a></li>
          </ul>
        </div>
      </div>
      <div class="contents">
        <div class="container">
          <div class="row">

            <div class="lp_result mt-30 mb-60" v-for="(lockup, index) in lockupList" :key="index">
              <ul class="list">
                <li>
                  <p class="lp_key">
                    <img src="/assets/image/logo_row02_black.png" alt="amis">
                  </p>
                  <p class="lp_value type1 pt-7">
                    <strong class="data">{{ lockup.amount | numberWithCommas | rewardDecimal }}<em class="point">.{{ getMetrDecimalPlace(lockup.amount) }}</em></strong><span class="unit ml-10">AMIS</span>
                  </p>
                </li>
                <li>
                  <p class="lp_key">Lock-up 기간</p>
                  <div class="item-between-end">
                    <p class="lp_value type2 pt-10">
                      <strong class="data">{{ getDateDiff(getYearMonthDay(lockup.startAt), getYearMonthDay(lockup.endAt)) }}</strong>
                      <span class="unit">일</span>
                    </p>
                    <em class="lp_desc" v-if="lockup.startAt !== '0' && lockup.endAt !== '0'">{{ lockup.startAt === '0' ? '' : transDateFormatToYearMonthDay(lockup.startAt) }} ~ {{ lockup.endAt === '0' ? '' : transDateFormatToYearMonthDay(lockup.endAt) }}</em>
                  </div>
                </li>
                <!-- TODO : lock Up hidden 처리시 li에 .lp_hidden 추가  -->
                <li :class="{'lp_hidden' : !lockup.rewardPercent}">
                  <p class="lp_key">Lock-up 보상</p>
                  <p class="lp_value type2 pt-10">
                    <strong class="data">{{lockup.rewardPercent ? lockup.rewardPercent : ''}}</strong>
                    <span class="unit">%</span>
                  </p>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <BottomNav @test="openSheetBar"></BottomNav>

  <trade-history-sheet-bar v-if="isShowTradeSheetBar" @close="closeTradeSheetBar"></trade-history-sheet-bar>
  <SendTokenSheetBar v-if="isShowSendSheetBar" @close="closeTradeSheetBar"></SendTokenSheetBar>
  <WithdrawalSheetBar v-if="isShowWithdrawSheetBar" @close="closeTradeSheetBar"></WithdrawalSheetBar>
  <!-- //하단 네비영역 -->
</div>

</template>

<script>

import BottomNav from "@/components/lockup/bottom/BottomNav";
import SendTokenSheetBar from "@/components/main/SendTokenSheetBar";
import WithdrawalSheetBar from "@/components/main/WithdrawalSheetBar";
import TradeHistorySheetBar from "@/components/main/TradeHistorySheetBar";
export default {
  name: "LockUpList",
  components : {
    BottomNav,
    SendTokenSheetBar,
    WithdrawalSheetBar,
    TradeHistorySheetBar,
  },

  data() {
    return {
      lockupList : [
        {
          amount : 0,
          startAt : '0',
          endAt : '0',
        }
      ],
      isShowTradeSheetBar : false,
      isShowSendSheetBar : false,
      isShowWithdrawSheetBar : false,
    }
  },
  mounted() {
    this.getProcessingLockUp()
  },

  methods : {
    getProcessingLockUp() {
      console.log('wdqwdqwd')
      this.$get(this.$LOCKUP_PROCESSING, 'login_getPush', (result) => {
        if (result.data) {
          this.lockupList = result.data
        }
      }, () => {})
    },

    getYearMonthDay(dateStr) {
      return dateStr.split('T')[0]
    },

    transDateFormatToYearMonthDay(dateStr) {
      let splitStr = this.getYearMonthDay(dateStr)
      let year = splitStr.split('-')[0]
      let month = splitStr.split('-')[1]
      let day = splitStr.split('-')[2]

      return year + '.' + month + '.' + day
    },

    getDateDiff(d1, d2)  {
      const date1 = new Date(d1);
      const date2 = new Date(d2);
      const diffDate = date1.getTime() - date2.getTime();
      return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
    },

    //거래내역 하단 시트바 오픈
    openSheetBar(sheetBarType = 'trade') {
      console.log('qwdqwd')
      this.isShowTradeSheetBar = false
      this.isShowSendSheetBar = false
      this.isShowWithdrawSheetBar = false
      this.isPreventScroll = false
      if (sheetBarType === 'trade') {
        this.isShowTradeSheetBar = true
      } else if (sheetBarType === 'send') {
        this.isShowSendSheetBar = true
      } else if (sheetBarType === 'withdraw') {
        this.isShowWithdrawSheetBar = true
      }
    },

    closeTradeSheetBar() {
      this.isShowTradeSheetBar = false
      this.isShowReceiveSheetBar = false
      this.isShowSendSheetBar = false
      this.isShowWithdrawSheetBar = false
      this.isPreventScroll = false
    },



  }
}
</script>

<style scoped>
.main-bg { position: relative; width: 100%; min-height: 100%; height: auto; margin: 0 auto; }
</style>
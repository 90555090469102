<template>
  <div class="wrap">
    <!-- 상단 고정 해더 영역 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">신용카드 별명</h5>
            <div class="w-100">
              <div class="pt-30">
                <MTRInput
                    :type="'text'"
                    :placeholder="'카드별명을 입력해 주세요.(최대 10자)'"
                    :model="alias"
                    @update="(_model) => {this.alias=_model}"
                    :max-length="10"
                />
              </div>
              <p class="mt-15 text-c">카드 별명을 설정하여 더욱 편리하게 카드를 관리하세요.</p>
              <p class="alert-red mt-15 text-c" v-show="errors.alias">{{ errors.alias }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn btn-black " @click="changeAlias()">별명 변경</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTRInput from "../../common/MTRInput";
export default {
  name: "CardChangeNick",
  components: {
    MTRInput
  },
  data() {
    return {
      alias: '',
      errors: {
        alias: '',
      }
    }
  },
  mounted() {
    this.alias = this.$route.params.oldAlias
  },
  methods: {
    errorClear() {
      this.errors = {
        alias: '',
      }
    },
    changeAlias() {
      this.errorClear()
      let formData = new FormData()
      formData.append('cardIdx', this.$route.params.cardIdx)
      formData.append('alias', this.alias)
      this.$put(this.$CREDITCARD_SET_ALIAS, 'cardchangenick_changealias', formData, () => {
        this.notify('success', '성공적으로 변경되었습니다.', false)
        this.$router.back()
      }, (result) => {
        if (result.data.error.length > 0) {
          for (let i=0;i<result.data.error.length;i++) {
            let err = result.data.error[i]
            this.errors[err.field] = err.message
          }
        } else if (result.data.message) {
          this.errors.alias = result.data.message
        } else {
          this.errors.alias = '사용할 수 없는 별명입니다.'
        }
      })
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <button type="button" class="nav-btn" @click="$router.back()"><i class="icon-back"></i></button>
      </div>
    </div>
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">아이디 찾기</h5>
          </div>
          <div class="confirm-area-find">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <p class="confirm-subtit">아이디를 찾기 위해</p>
              <p class="confirm-desc">본인 인증을 진행해 주세요.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="niceEncode()">본인인증</button>
          </div>
        </div>
      </div>
    </div>
    <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
      <input type="hidden" name="m" value="checkplusService">
      <input type="hidden" name="EncodeData" id="nice-enc">
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FindId",
  data() {
    return {
      nice: '',
      phone: '',
    }
  },
  methods: {
    niceEncode() {
      axios.post("https://nice.payhub.co.kr/check-pay-plus/request", null, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
      }).then(response => {
        if (response.data.success === true) {
          this.niceShow(response.data.enc_data)
        } else {
          console.log(response.data)
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    niceShow(enc_data) {
      window.removeEventListener('message', this.niceDone)

      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      window.$('#nice-enc').val(enc_data)
      window.$('#nice-form').attr('target', 'popupChk')
      window.$('#nice-form').submit()

      window.addEventListener('message', this.niceDone);
    },
    niceDone(e) {
        console.log('nice done')
        console.log(e)
      if(e.origin === 'https://nice.payhub.co.kr') {
        this.phone = e.data.phone_no.substr(0,3)+'-'+e.data.phone_no.substr(3,4)+'-'+e.data.phone_no.substr(7,4)
        this.nice = e.data.payload

        window.removeEventListener('message', this.niceDone)

        this.niceCheck()
      }
    },
    niceCheck() {
      let formData = new FormData()
      // formData.append('hp', this.phone.trim())
      formData.append('auth', this.nice)


      //test
      this.$post(this.$AUTH_FINDID, 'findid_nicecheck', formData, (result) => {
        let id = result.data.id
        this.$router.replace({ name: 'FindIdSuccess', params:{id:id} })
      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({ name: 'FindIdFail'})
      })
    },
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>g

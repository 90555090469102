<template>
  <div class="wrap">
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row confirm-area-bottom">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <p class="tit-desc">고객님의 아이디는</p>
              <p class="confirm-subtit mt-10">{{ id }} 입니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="$router.back()">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindIdSuccess",
  data(){
    return {
      id: '',
    }
  },
  mounted() {
    this.id = this.$route.params.id
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>
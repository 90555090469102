<template>
  <div class="wrap">
    <!-- 현재 리워드 -->
    <div class="header-wrap">
      <div class="effect-filter pb-10">
        <div class="container">
          <div class="header-group">
            <span class="title">카드 결제 내역</span>
            <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
          </div>
          <div class="date-filter row">
            <div class="date-area">
              <button type="button" class="next-btn" @click="goPrev()" :disabled="!ablePrev"><i class="icon-arrow-left" :class="{'disabled' : !ablePrev}"></i></button>
              <date-picker class="date-box border-zero fz-20 pt-0 date-range" v-model="selectedDate"
                           :config="this.calendarOptions"
                           @input="selectedDatePicker()"
                           readonly
              />
              <button type="button" class="next-btn" @click="goNext()" :disabled="!ableNext"><i class="icon-arrow-right" :class="{'disabled' : !ableNext}" :style="{'--stop' : stopColor}"></i></button>
            </div>
            <div>
            </div>
            <div class="flex">
              <div class="filter-area">
                <i class="icon filter">정렬</i>
                <button type="button" class="filter-srch"  @click="showCondition = true">{{
                    selectedType.name
                  }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    &lt;!&ndash; //현재 리워드 &ndash;&gt;
    &lt;!&ndash; TODO : 내역 있는 경우 &ndash;&gt;
    <div class="content-wrap-auto pt-100">
      <div class="container">
        <div class="row">
          &lt;!&ndash; 내역 &ndash;&gt;
          <div class="transaction-group" v-for="(day, index) in historyList" :key="'d' + index">
            &lt;!&ndash; 날짜 &ndash;&gt;
            <div class="transaction-box">
              <span class="heading-date">{{ historyDay(day.day) }}</span>
            </div>
            <div class="conp-list card-list transaction-box">
              <div class="date-inner">
                <dl class="conp-item conp-item-his item-between-start" v-for="(history, dayIndex) in day.historyList" :key="'h' + dayIndex">
                  <dt>
                    <div class="item-center">
                      <i class="icon-circle sp-card" :class="history.card | cardImage"></i>
                      <p class="txt-ellipsis">
                        <span class="name">{{ history.shopName }}</span>
                        <em class="payment-info card-info">{{ history.card }}</em>
                      </p>
                    </div>
                  </dt>
                  <dd>
                    <strong class="data">{{ history.amount | numberWithCommas}}<em class="unit">원</em></strong>
                    <em class="payment-info">{{ history.state }}</em>
                    <em class="payment-info">{{ history.date }}</em>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          &lt;!&ndash; // 내역 &ndash;&gt;
        </div>
      </div>
    </div>-->

    <!-- TODO : 내역 있는 경우 -->
    <div class="content-wrap pt-110" v-if="historyList.length > 0">
      <div class="container">
        <div class="row">
          <div class="section type_filter" v-for="(day, index) in historyList" :key="'d' + index">
            <div class="filter_head">
              <h4 class="heading-date">{{ historyDay(day.day) }}</h4>
            </div>
            <div class="filter_cont">
              <ul>

                <li class="filter_summary clear" v-for="(history, dayIndex) in day.historyList" :key="'h' + dayIndex">
<!--                  <div class="left">
                    <div class="state_circle">{{ history.type }}</div>
                  </div>-->
                  <div class="left">
                    <div class="state_circle">
                      <div class="bank-icon">
                        <i class="sp-card" :class="history.card | cardImage"></i>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <span class="target ellipsis">{{ history.shopName }}</span>
                    <em class="card_info">{{ }}</em>
                    <strong class="price ellipsis">{{ history.amount | numberWithCommas}}원</strong>
                    <em class="date">{{ history.date }}</em>
                  </div>
                </li>

              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>



    <div class="wrapper-center" v-if="historyList.length === 0">
      <!-- TODO : 내역이 없을 경우 -->
      <div class="alram-wrap">
        <div class="list-none">
          <i class="bg_symbol_15_op mb-20"></i>
          <p class="list-desc">내역이 없습니다.</p>
        </div>
      </div>
    </div>

    <history-select-type
        v-show="showCondition"
        :conditions="typeList"
        @selected="setSelectedCondition"
        @close="showCondition = false"
    />
  </div>
</template>

<script>
import HistorySelectType from "./HistorySelectType";

export default {
  name: "HistoryCard",
  components: {
    HistorySelectType
  },
  data() {
    return {
      year: '',
      month: '',
      ablePrev: true,
      ableNext: false,
      historyList: [],
      selectedDate: '',
      selectedType: {
        type: '',
        name: '전체'
      },
      showCondition: false,
      typeList: [
        {
          type:'',
          name:'전체',
        },
        {
          type:'0',
          name:'결제',
        },
        {
          type:'1',
          name:'취소',
        },
      ],

      stopColor : '#E9E9E9',
      currentYear : 0,
      currentMonth : 0,
    }
  },
  created() {
    let current = new Date()
    this.year = current.getFullYear()
    this.month = current.getMonth() + 1
    this.setSelectedDateValue()

    this.currentYear = this.year
    this.currentMonth = this.month
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    setSelectedDateValue() {
      this.selectedDate = this.year + '.' + this.month
      this.checkAbleNext()
    },
    selectedDatePicker() {
      this.year = this.selectedDate.split('.')[0]
      this.month = this.selectedDate.split('.')[1]
      this.checkAbleNext()
      this.getHistory()
    },
    getHistory() {
      let queryString = '?year=' + this.year + '&month=' + this.month
      if (this.selectedType.type !== '') {
        queryString = queryString + '&type=' + this.selectedType.type
      }
      this.$get(this.$WALLET_CARD_HISTORY + queryString, 'historycard_gethistory', (result) => {
        this.historyList = result.data
        // if (result.data.dayGroupList) {
        //   this.historyList = result.data.dayGroupList
        // } else {
        //   this.historyList = []
        // }
      }, (result) => {
        this.httpError(result.data)
      })
    },
    setSelectedCondition(_condition) {
      this.selectedType = _condition
      this.showCondition = false
      this.getHistory()
    },
    goPrev() {
      if (this.month > 1) {
        this.month = this.month - 1
      } else {
        this.year = this.year - 1
        this.month = 12
      }

      if (this.currentYear <= this.year) {
        if (this.currentMonth <= this.month) {
          this.stopColor = '#E9E9E9'
        } else {
          this.stopColor = '#030303FF'
        }
      }

      this.setSelectedDateValue()
      this.ableNext = true
      this.getHistory()
    },
    goNext() {
      if (this.month * 1 === 12) {
        this.month = 1
        this.year = (this.year * 1) + 1
      } else {
        this.month = (this.month * 1) + 1
      }

      if (this.currentYear <= this.year) {
        if (this.currentMonth <= this.month) {
          this.stopColor = '#E9E9E9'
        } else {
          this.stopColor = '#030303FF'
        }
      }
      this.setSelectedDateValue()

      this.getHistory()
    },
    historyDay(value) {
      let weeks = ['일', '월', '화', '수', '목', '금', '토']
      let date = new Date(this.year + '-' + ((this.month * 1) < 10 ? '0' : '') + this.month + ((value * 1) < 10 ? '-0' : '-') + value )
      let week = weeks[date.getDay()]

      return value + '일(' + week + ')'
    },
    checkAbleNext() {
      let current = new Date()
      let _year = current.getFullYear()
      let _month = current.getMonth() + 1
      let current2 = new Date(_year + '-' + ((_month * 1) < 10 ? '0' : '') + _month + '-01')
      let select = new Date(this.year + '-' + ((this.month * 1) < 10 ? '0' : '') + this.month + '-01')

      if (current2 <= select) {
        this.ableNext = false
      } else {
        this.ableNext = true
      }
    }
  },
  filters: {
    historyType: (value) => {
      if (value === '0') {
        return '결제'
      } else if (value === '1') {
        return '취소'
      }
      return ''
    },
    cardImage: (value) => {
      if (value.startsWith('삼성') || value.toLowerCase().startsWith('samsung')) {
        return 'card-02'
      } else if (value.startsWith('비씨') || value.toLowerCase().startsWith('bc')) {
        return 'card-08'
      } else if (value.startsWith('롯데') || value.toLowerCase().startsWith('lotte')) {
        return 'card-05'
      } else if (value.startsWith('현대') || value.toLowerCase().startsWith('hyundai')) {
        return 'card-04'
      } else if (value.startsWith('국민') || value.toLowerCase().startsWith('kb')) {
        return 'card-01'
      } else if (value.startsWith('신한') || value.toLowerCase().startsWith('shinhan')) {
        return 'card-03'
      } else if (value.startsWith('시티') || value.toLowerCase().startsWith('citi')) {
        return 'card-09'
      } else if (value.startsWith('농협') || value.toLowerCase().startsWith('nh')) {
        return 'card-11'
      } else if (value.startsWith('하나') || value.toLowerCase().startsWith('hana')) {
        return 'card-06'
      } else if (value.startsWith('비자') || value.toLowerCase().startsWith('visa')) {
        return 'card-15'
      } else if (value.startsWith('마스터') || value.toLowerCase().startsWith('master')) {
        return 'card-16'
      }
      return 'bank-30'
    }
  }
}
</script>

<style scoped>

.icon-arrow-right { color: var(--stop); font-size: 13px; }

button:disabled { background-color: var(--white); color: var(--stop) !important; border: none; }
button:disabled * { color: var(--stop) !important;}

.wrapper-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div>
    <div class="wrap" v-show="!isClickedAgreement">
      <div class="header-wrap">
        <div class="container">
          <div class="header-group">
            <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
            <span v-if="!isEdit" class="title">문의하기</span>
            <span v-else class="title">문의수정</span>
          </div>
        </div>
      </div>
      <div class="content-wrap-v3">
        <div class="container">
          <div class="row">
            <div class="confirm-area">
              <div class="confirm-info-info">
                <div class="pt-30 select-wrap">
                  <label for="category" class="blind">상담분류</label>
                  <select class="select-form" name="category" id="category" v-model="item.categoryIdx">
                    <option value="">상담분류</option>
                    <option v-for="(category, index) in qnaCategory" :key="index" :value="category.idx">{{ category.name }}</option>
                  </select>
                </div>
                <div class="mt-10">
                  <input type="text" placeholder="제목을 입력해 주세요." v-model="item.title">
                </div>
                <div class="mt-10">
                  <textarea class="textarea-box" maxlength="400" placeholder="내용을 입력하세요" v-model="item.content"></textarea>
                </div>
                <div class="mt-10 filebox">
                  <label @click="uploadImage()">파일찾기</label>
                  <input type="text" class="upload-name" value="첨부파일" placeholder="첨부파일" readonly v-model="item.originFileName">
                  <input type="file" id="imageFileUpload" @change="upload($event)" accept="image/*" hidden>
                </div>
                <div class="mt-10" :class="{'mb-20': isEdit}">
                  <p class="desc-info">{{ item.version }}</p>
                </div>
                <div class="mt-20 mb-30 checks" v-if="!isEdit">
                  <input type="checkbox" id="agree" name="agree" v-model="qnaAgree">
                  <label for="agree">(필수) 개인정보 수집, 이용동의</label>
                  <button type="button" class="next-btn" @click="openAgreement"><i class="icon-arrow-right"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-wrap">
        <div class="container">
          <div class="row">
            <div class="button-wrap">
              <button v-if="!isEdit" class="l_btn btn-black w-100" :class="{'btn-disable' : item.categoryIdx === '' || item.title === '' || item.content === '' || qnaAgree === false}" @click="regist()">등록</button>
              <button v-else class="l_btn btn-black w-100" :class="{'btn-disable' : item.categoryIdx === '' || item.title === '' || item.content === ''}" @click="edit()">등록</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Term02 v-show="isClickedAgreement" :is-used-as-component="true" @close="closeAgreement"></Term02>
  </div>
</template>

<script>
import Term02 from "@/components/user/signup/term/Term02";
export default {
  name: "QnaRegist",
  components : {
    Term02
  },
  data() {
    return {
      isEdit: false,
      qnaCategory: [],

      item: {
        categoryIdx: '',
        categoryList: [],
        content: '',
        fileIdx: '',
        idx: '',
        originFileName: '',
        title: '',
        version: ''
      },

      qnaFile: null,
      qnaAgree: false,
      deleteFileIdx: '',
      isClickedAgreement : false,
    }
  },
  mounted() {
    if (this.$route.params.idx && this.$route.params.idx !== '') {
      this.isEdit = true
    }
    this.$get(this.$QNA_CATEGORY, 'qnsregist_mounted', (result) => {
      this.qnaCategory = result.data

      if (this.isEdit) {

        this.qnaAgree = true
        this.$get(this.$QNA_UPDATE_DETAIL + this.$route.params.idx, 'qnsregist_mounted', (result) => {
          this.item = result.data
        }, (result) => {
          this.httpError(result.data)
        })

      } else {
        this.item.version = '폰기종 : ' + this.deviceInfo(window.getDeviceInfo()) + '/ OS : ' + window.getOSInfo()
      }

    }, (result) => {
      this.httpError(result.data)
    })
  },
  methods: {
    uploadImage() {
      window.document.getElementById('imageFileUpload').click()
    },

    openAgreement() {
      this.isClickedAgreement = true
    },

    closeAgreement() {
      this.isClickedAgreement = false
    },

    upload(e) {
      if (this.isEdit && this.item.fileIdx) {
        this.deleteFileIdx = this.item.fileIdx
      }
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      this.qnaFile = files[0]
      this.item.originFileName = files[0].name
      // let formData = new FormData()
      // if (this.profile.imageIdx !== null) {
      //   formData.append('deleteIdx', this.profile.imageIdx)
      // }
      // formData.append('profileImage', files[0])
      //
      // this.$post(this.$USER_UPLOAD_IMAGE, 'myinfo_upload', formData, () => {
      //   this.getInfo()
      // }, (result) => {
      //   this.httpError(result.data)
      // })
    },
    regist() {
      if (this.item.categoryIdx === '') {
        this.notify('error', '상담 분류를 선택해주세요.')
        return
      }
      if (this.item.title === '') {
        this.notify('error', '제목을 입력해주세요.')
        return
      }
      if (this.item.content === '') {
        this.notify('error', '내용을 입력해주세요.')
        return
      }
      if (this.qnaAgree === false) {
        this.notify('error', '동의 후 등록 가능 합니다.')
        return
      }
      let formData = new FormData()
      formData.append('categoryIdx', this.item.categoryIdx)
      formData.append('content', this.item.content)
      formData.append('title', this.item.title)
      formData.append('version', this.item.version)

      if (this.qnaFile !== null) {
        formData.append('attachments', this.qnaFile)
      }

      this.$post(this.$QNA_REGIST, 'qnaregist_regist', formData, () => {
        this.notify('success', '성공적으로 등록되었습니다.', false)
        this.$router.back()
      }, (result) => {
        this.httpError(result.data)
      })

    },
    edit() {
      if (this.item.categoryIdx === '') {
        this.notify('error', '상담 분류를 선택해주세요.')
        return
      }
      if (this.item.title === '') {
        this.notify('error', '제목을 입력해주세요.')
        return
      }
      if (this.item.content === '') {
        this.notify('error', '내용을 입력해주세요.')
        return
      }
      let formData = new FormData()
      formData.append('categoryIdx', this.item.categoryIdx)
      formData.append('content', this.item.content)
      formData.append('title', this.item.title)

      if (this.deleteFileIdx !== '') {
        formData.append('deleteIdx', this.deleteFileIdx)
      }

      if (this.qnaFile !== null) {
        formData.append('attachments', this.qnaFile)
      }
      this.$put(this.$QNA_EDIT + this.$route.params.idx, 'qnaregist_edit', formData, () => {
        /*this.$root.$refs.Alert.showAlert('',
            '수정이 완료되었습니다.',
            '',
            '',
            () => {
              this.$router.back()
            })*/
        this.notify('', '문의사항이 수정되었습니다.', false)
        this.$router.replace({name : 'QnaList'})
      }, (result) => {
        this.httpError(result.data)
      })
    },
    deviceInfo(identifier) {
      switch (identifier) {
        case "iPhone6,1", "iPhone6,2":
          return "iPhone 5s"
        case "iPhone7,2":
          return "iPhone 6"
        case "iPhone7,1":
          return "iPhone 6 Plus"
        case "iPhone8,1":
          return "iPhone 6s"
        case "iPhone8,2":
          return "iPhone 6s Plus"
        case "iPhone8,4":
          return "iPhone SE"
        case "iPhone9,1", "iPhone9,3":
          return "iPhone 7"
        case "iPhone9,2", "iPhone9,4":
          return "iPhone 7 Plus"
        case "iPhone10,1", "iPhone10,4":
          return "iPhone 8"
        case "iPhone10,2", "iPhone10,5":
          return "iPhone 8 Plus"
        case "iPhone10,3", "iPhone10,6":
          return "iPhone X"
        case "iPhone11,2":
          return "iPhone XS"
        case "iPhone11,4", "iPhone11,6":
          return "iPhone XS Max"
        case "iPhone11,8":
          return "iPhone XR"
        case "iPhone12,1":
          return "iPhone 11"
        case "iPhone12,3":
          return "iPhone 11 Pro"
        case "iPhone12,5":
          return "iPhone 11 Pro Max"
        case "iPhone12,8":
          return "iPhone SE (2nd generation)"
        case "iPhone13,1":
          return "iPhone 12 mini"
        case "iPhone13,2":
          return "iPhone 12"
        case "iPhone13,3":
          return "iPhone 12 Pro"
        case "iPhone13,4":
          return "iPhone 12 Pro Max"
        case "iPhone14,4":
          return "iPhone 13 mini"
        case "iPhone14,5":
          return "iPhone 13"
        case "iPhone14,2":
          return "iPhone 13 Pro"
        case "iPhone14,3":
          return "iPhone 13 Pro Max"
        case "iPad6,11", "iPad6,12":
          return "iPad (5th generation)"
        case "iPad7,5", "iPad7,6":
          return "iPad (6th generation)"
        case "iPad7,11", "iPad7,12":
          return "iPad (7th generation)"
        case "iPad11,6", "iPad11,7":
          return "iPad (8th generation)"
        case "iPad12,1", "iPad12,2":
          return "iPad (9th generation)"
        case "iPad4,1", "iPad4,2", "iPad4,3":
          return "iPad Air"
        case "iPad5,3", "iPad5,4":
          return "iPad Air 2"
        case "iPad11,3", "iPad11,4":
          return "iPad Air (3rd generation)"
        case "iPad13,1", "iPad13,2":
          return "iPad Air (4th generation)"
        case "iPad2,5", "iPad2,6", "iPad2,7":
          return "iPad mini"
        case "iPad4,4", "iPad4,5", "iPad4,6":
          return "iPad mini 2"
        case "iPad4,7", "iPad4,8", "iPad4,9":
          return "iPad mini 3"
        case "iPad5,1", "iPad5,2":
          return "iPad mini 4"
        case "iPad11,1", "iPad11,2":
          return "iPad mini (5th generation)"
        case "iPad14,1", "iPad14,2":
          return "iPad mini (6th generation)"
        case "iPad6,3", "iPad6,4":
          return "iPad Pro (9.7-inch)"
        case "iPad7,3", "iPad7,4":
          return "iPad Pro (10.5-inch)"
        case "iPad8,1", "iPad8,2", "iPad8,3", "iPad8,4":
          return "iPad Pro (11-inch) (1st generation)"
        case "iPad8,9", "iPad8,10":
          return "iPad Pro (11-inch) (2nd generation)"
        case "iPad6,7", "iPad6,8":
          return "iPad Pro (12.9-inch) (1st generation)"
        case "iPad7,1", "iPad7,2":
          return "iPad Pro (12.9-inch) (2nd generation)"
        case "iPad8,5", "iPad8,6", "iPad8,7", "iPad8,8":
          return "iPad Pro (12.9-inch) (3rd generation)"
        case "iPad8,11", "iPad8,12":
          return "iPad Pro (12.9-inch) (4th generation)"
        case "iPad13,4", "iPad13,5", "iPad13,6", "iPad13,7":
          return "iPad Pro (11-inch) (3rd generation)"
        case "iPad13,8", "iPad13,9", "iPad13,10", "iPad13,11":
          return "iPad Pro (12.9-inch) (5th generation)"
        default:
          return identifier
      }
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
        <span class="logo">
          <img src="/image/logo_row01.svg" alt="meterra">
        </span>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <div class="section-password">
        <div class="area-password">
          <div class="instruction">
            <div class="container">
              <div class="row">
                <h3>PIN</h3>
                <span>아미스 결제 시 2차 인증이 필요합니다.</span>
                <h4>핀번호를 입력해 주세요.</h4>
              </div>
            </div>
          </div>
          <div class="password">
            <div class="container">
              <div class="row">
                <span class="blind">비밀번호 6자리는 입력하면 자동전환됩니다.</span>
                <!-- TODO : 비밀번호 입력 시 .on 클래스 추가 -->
                <span id="key_1" class="word" :class="{'on' : pin.length >= 1}"></span>
                <span id="key_2" class="word" :class="{'on' : pin.length >= 2}"></span>
                <span id="key_3" class="word" :class="{'on' : pin.length >= 3}"></span>
                <span id="key_4" class="word" :class="{'on' : pin.length >= 4}"></span>
                <span id="key_5" class="word" :class="{'on' : pin.length >= 5}"></span>
                <span id="key_6" class="word" :class="{'on' : pin.length >= 6}"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="area-keyboard">
          <div class="container">
            <table class="keyboard">
              <tbody>
              <tr>
                <td>
                  <button type="button" class="key" @click="numBtn('1')">
                    <span>1</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('2')">
                    <span>2</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('3')">
                    <span>3</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="key" @click="numBtn('4')">
                    <span>4</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('5')">
                    <span>5</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('6')">
                    <span>6</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="key" @click="numBtn('7')">
                    <span>7</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('8')">
                    <span>8</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('9')">
                    <span>9</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="key" @click="cancel()">
                    <span class="cancel">취소</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('0')">
                    <span>0</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="backspace()">
                    <span class="icon delete">지우기</span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUpPinRegist",
  data() {
    return {
      pin: '',
    }
  },
  methods: {
    numBtn(_num) {
      this.pin = this.pin + _num
      if (this.pin.length === 6 ) {
        this.$router.replace({name:'SignUpPinCheck', params:{old_pin:this.pin}})
      }
    },
    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },
    cancel() {
      this.$root.$refs.MTRConfirm.showDialog('확인해주세요.', '핀번호를 설정하지 않을경우<br/>아미스를 정상적으로 사용할 수 없습니다.<br/>' +
          '서비스를 종료 하시겠습니까?', '확인', '취소', () => {
        //자동로그인 실패 처리(로컬 스토리지 토큰 비우기)
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('refreshToken')
        localStorage.removeItem('passToken')
        localStorage.removeItem('useLocalBio')
        this.$router.replace({ name: 'Intro' })
      }, () => {

      })
    }
  }
}
</script>

<style scoped>
.header-group .logo { position: relative; display: inline-block; height: 20px; width: 70px; }
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div>
    <div class="wrap">
      <div class="header-wrap">
        <div class="header-group header-dropshadow">
          <div class="container">
            <span class="title">결제</span>
            <button type="button" class="nav-btn right"><i class="icon-close" @click="$router.replace({name : 'Order'})"></i></button>
          </div>
        </div>
      </div>
      <div class="content-wrap pt-50 pb-145">
        <!-- 결제예정금액 표시 -->
        <div class="payment_summary type1">
          <div class="container">
            <ul class="list info3">
              <li>
                <span class="itit">매장</span>
                <b class="idesc">{{ shopName }}</b>
              </li>
              <li>
                <span class="itit">주문상품</span>
                <b class="idesc">{{ orderProductName }}</b>
              </li>
              <li class="price">
                <span class="itit">결제금액</span>
                <b class="idesc">{{ orderProductPrice | numberWithCommas }}원</b>
              </li>
            </ul>
          </div>
        </div>

        <CardPayment v-if="isCardPayment" :cards="this.cards"></CardPayment>
        <MetrPayment v-if="!isCardPayment" :order-product-price="orderProductPrice"
                     @getExchangeKrwToMetr="getExchangeKrwToMetr"
                     @setUseMetrPoint="setUseMetrPoint"></MetrPayment>

        <!-- //결제방식 -->
      </div>
      <!-- TODO: content-wrap 클래스에 pb-145 고정 -->

      <div class="fixed_b_wrap">
        <div class="container">
          <div class="row clear">
            <a class="other_payment" @click.prevent="changePaymentType">{{ isCardPayment ? '아미스' : '신용카드'}} 결제 <i class="icon-arrow-right"></i></a>
            <button class="outline_l_btn btn-black" @click="paymentPin" v-if="!isCardPayment || (isCardPayment && cards.length > 0)">
              <em class="primary pr-10">{{ isCardPayment ? orderProductPrice : orderProductPrice * krwToMetr | numberWithCommas }}{{ isCardPayment ? '원' : 'Metr'}}</em>결제</button>
            <!-- 카드결제이면서 등록된 카드가 없을 때-->
            <button class="outline_l_btn btn-line-black" v-if="isCardPayment && cards.length <= 0" @click="$router.push({name:'RegistCard'})"><i class="ico_add"></i>신용카드등록</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MetrPayment from "./MetrPayment";
import CardPayment from "./CardPayment";

export default {
  name: "OrderPayment",
  components: {
    MetrPayment,
    CardPayment,
  },
  data() {
    return {
      isCardPayment : true,
      cards: [],
      shopIdx : '',
      shopName : '',
      orderProductName : '',
      orderProductPrice : '',
      krwToMetr : '',
    }
  },

  created() {

    let cart = this.$store.getters.getCartList
    if (cart.length <= 0) {
      if (this.$store.getters.getShopInfo.shopIdx > 0) {
        let shopInfo = this.$store.getters.getShopInfo
        this.$router.replace({name : 'ShopMenu', params : {shopIdx : shopInfo.shopIdx, shopName : shopInfo.shopName}})
      } else {
        this.$router.replace({name : 'ShopMenu', params : {shopIdx : '0', shopName : ''}})
      }
    }



    this.isCardPayment = this.$store.getters.getPaymentType === 2
    this.getMyCardList()
  },

  mounted() {
    let shopInfo = this.$store.getters.getShopInfo;
    this.shopIdx = shopInfo.shopIdx
    this.shopName = shopInfo.shopName

    let cartList = this.$store.getters.getCartList;
    let totalQuantity = this.$store.getters.getTotalQuantity
    this.orderProductPrice = this.$store.getters.getTotalPrice  //최종주문금액
    this.orderProductName = this.getProductName(cartList, totalQuantity)  //최종주문 상품명

    this.$store.commit('setOrderMenuName', {orderMenuName : this.orderProductName})

  },

  methods : {
    getProductName(cartList, totalQuantity) {
      if (totalQuantity <= 0) {
        return ''
      } else if (totalQuantity === 1) {
        return cartList[0].productName + ' ' + '1개'
      }

      let isOneKindProduct = true
      let beforeProductIdx

      cartList.forEach(product => {
        if (beforeProductIdx && beforeProductIdx !== product.productIdx) {
          isOneKindProduct = false
        }
        beforeProductIdx = product.productIdx
      })


      if (isOneKindProduct) {
        return cartList[0].productName + ' ' + totalQuantity + '개'
      }

      return cartList[0].productName + ' 외 ' + (totalQuantity - 1) + '개'
    },

    changePaymentType() {
      this.isCardPayment = !this.isCardPayment
      this.$store.commit('setPaymentType', {paymentType : this.isCardPayment ? 2 : 1})

      if (this.isCardPayment) {
        this.getMyCardList()
      }
    },
    paymentPin() {
      this.$router.push({name : 'PaymentPin', params : {isCardPayment : this.isCardPayment}})
    },

    getMyCardList() {
      this.$get(this.$CREDITCARD_LIST, 'cardlist', (result) => {
        this.cards = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },

    getExchangeKrwToMetr(krwToMetr) {
      this.krwToMetr = krwToMetr
    },

    //사용할 아미스 리워드를 자식컴포넌트로 부터 가져온다.
    setUseMetrPoint(useMetrPoint) {
      this.$store.commit('setUseMetrPoint', {useMetrPoint : useMetrPoint})
    },

  }
}
</script>

<style scoped>
.payment_summary.type1 { background: var(--bg-gray) url("/assets/image/bg_payment.png") no-repeat center top 10px / 160px auto; }
.primary { color: var(--meterra-green); }
</style>
import { render, staticRenderFns } from "./QnaList.vue?vue&type=template&id=19594d7c&scoped=true&"
import script from "./QnaList.vue?vue&type=script&lang=js&"
export * from "./QnaList.vue?vue&type=script&lang=js&"
import style0 from "./QnaList.vue?vue&type=style&index=0&id=19594d7c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19594d7c",
  null
  
)

export default component.exports
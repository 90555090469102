<template>
  <div class="wrap">
    <!-- 상단 고정 해더 영역 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">계정입력</h5>
            <div class="w-100">
              <div class="pt-30 d-flex-row">
                <MTRInput
                    :type="'text'"
                    :placeholder="'아이디(영문,숫자 포함 8자리 이상)'"
                    :model="id"
                    @update="(_model) => {this.isChecked = false; this.id=_model}"
                />
                <button type="button" class="l_btn btn-black ml-10" @click="checkId()">확인</button>
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.id">{{ errors.id }}</p>
              <p class="alert-green mt-15 text-c" v-show="isChecked">사용 가능한 아이디입니다.</p>
              <div class="pt-10">
                <MTRInput
                    :type="'password'"
                    :placeholder="'비밀번호(영문, 숫자, 특수문자 포함 8자 이상)'"
                    :model="password"
                    @update="(_model) => {this.password=_model}"
                />
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.password">{{ errors.password }}</p>
              <div class="pt-10">
                <MTRInput
                    :type="'password'"
                    :placeholder="'비밀번호를 한번 더 입력해 주세요.'"
                    :model="passwordCk"
                    @update="(_model) => {this.passwordCk=_model}"
                />
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.passwordCk">{{ errors.passwordCk }}</p>
              <div class="pt-10 d-flex-row">
                <MTRInput
                    :type="'text'"
                    :placeholder="'추천인 등록(선택)'"
                    :model="recommended"
                    @update="(_model) => {this.recommended=_model}"
                />
                <button type="button" class="l_btn btn-black ml-10" @click="checkRecId()">확인</button>
              </div>
              <p class="alert-red mt-15 text-c" v-show="!isCheckedRec">{{ errors.recommended }}</p>
              <p class="alert-green mt-15 text-c" v-show="isCheckedRec">추천 가능한 아이디입니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn btn-black" :class="{'btn-disable' : !(isChecked && password.length > 0 && passwordCk.length > 0)}"
                  :disabled="!(isChecked && password.length > 0 && passwordCk.length > 0)"
                  @click="goNext()">다음</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTRInput from "../../common/MTRInput";

export default {
  name: "SignUp01",
  components: {
    MTRInput
  },
  data() {
    return {
      id: '',
      isChecked: false,
      isCheckedRec: false,
      password: '',
      passwordCk: '',
      recommended: '',
      errors: {
        id: '',
        password: '',
        passwordCk: '',
        recommended: '',
      }
    }
  },
  methods: {
    errorClear() {
      this.errors = {id: '', password: '', passwordCk: ''}
    },
    checkId() {
      if (this.isChecked) {
        return
      }
      this.errorClear()
      if (this.id === '') {
        this.errors.id = '아이디를 정확하게 입력해주세요.'
        return
      }
      if (!/^[a-zA-Z]/.test(this.id)) {
        this.errors.id = '첫글자는 영문만 가능합니다.'
        return
      }
      this.$get(this.$AUTH_ID + '?id=' + this.id, 'signup01_checkid', () => {
        this.isChecked = true
        console.log('isChecked : ' + this.isChecked)
        console.log('isCheckedRec : ' + this.isCheckedRec)
      }, (result) => {
        if (result.data.error.length > 0) {
          for (let i = 0; i < result.data.error.length; i++) {
            let err = result.data.error[i]
            this.errors[err.field] = err.message
          }
        } else if (result.data.message) {
          this.errors.id = result.data.message
          this.$refs.idCheck.disable = true
        } else {
          this.errors.id = '사용할 수 없는 아이디입니다.'
        }
        this.isChecked = false
      })


    },
    checkRecId() {
      this.errorClear()
      if (this.recommended === '') {
        this.errors.recommended = '아이디를 정확하게 입력해주세요.'
        return
      }
      if (!/^[a-zA-Z]/.test(this.recommended)) {
        this.errors.recommended = '첫글자는 영문만 가능합니다.'
        return
      }
      this.$get(this.$AUTH_ID + '?id=' + this.recommended, 'signup01_checkid', () => {
        console.log('0')
        this.errors.recommended = '해당 사용자가 존재하지 않습니다.'
        this.isCheckedRec = false

        console.log('isChecked : ' + this.isChecked)
        console.log('isCheckedRec : ' + this.isCheckedRec)
        this.$forceUpdate()
      }, () => {
        this.isCheckedRec = true
      })
    },
    goNext() {
      this.errorClear()
      if (this.isChecked !== true) {
        this.errors.id = '중복 확인을 해주세요.'
        return
      }
      if (this.password === '') {
        this.errors.password = '비밀번호를 입력해주세요.'
        return
      }
      if (this.passwordCk === '') {
        this.errors.passwordCk = '비밀번호를 한번 더 입력해주세요.'
        return
      }
      if (this.password !== this.passwordCk) {
        this.errors.passwordCk = '비밀번호가 일치하지 않습니다.'
        return
      }
      let isNum = /[0-9]/g.test(this.password)
      let isEng = /[a-z]/ig.test(this.password)
      let isSpe = /[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi.test(this.password)
      if (!isNum || !isEng || !isSpe) {
        this.errors.password = '비밀번호는 영문, 숫자, 특수문자 포함 8자 이상으로 입력해주세요.'
        return
      }
      this.$router.push({name: 'SignUp02', params: {id: this.id, password: this.password}})
    }

  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState(),
  ],
  state: {
    cart: [],
    token : {
      accessToken : '',
      refreshToken : '',
      accessTokenQuickOrder : '',
      accessTokenReusable : '',
      passToken : '',
    },
    totalQuantity : '0',
    totalPrice : '0',
    krwToMetr : '',
    metrToKrw : '',
    shopName : '', //현재 보고있는 가맹점 이름
    shopIdx : '',  //현재 보고있는 가맹점 idx
    currentCartShopIdx : '', //현재 카트에 담긴 상품의 매장 idx, 카트에 담긴 상품이 현재 보고있는 가맹점의 상품인지 검사하기 위함
    currentProductIdx : '',  //현재 보고있는 가맹점 상품 idx
    paymentType : 2,  //기본값 2
    userIdx : '',
    useMetrPoint : 0,
    orderMenuName : '',
    productType : 'headquarter',
    isOrderedHeadQuarter : true, //본사상품을 주문하는가
  },
  mutations: {
    //전체 state를 초기화한다.
    initAllState : (state) => {
      state.cart = []
      state.totalQuantity = '0'
      state.totalPrice = '0'
      state.currentCartShopIdx = ''
      state.currentProductIdx = ''  //현재 보고있는 가맹점 상품 idx
      state.paymentType = 2  //기본값 2
      state.useMetrPoint = 0
      state.orderMenuName = ''
      state.krwToMetr = ''
      //state.shopName = '' //현재 보고있는 가맹점 이름  TODO shop과 productType 까지 초기화 시키면 다른 가맹점에서 장바구니 담을시 버그소지가 있음
      //state.shopIdx = ''  //현재 보고있는 가맹점 idx
      //state.productType = 'headquarter'
      state.isOrderedHeadQuarter = true
    },
    //카트의 모든 상품을 초기화한다.
    initCartProducts : (state) => {
      state.cart = []
    },

    initToken : (state) => {
      state.token = {
        accessToken : '',
        refreshToken : '',
        accessTokenQuickOrder : '',
        accessTokenReusable : '',
      }
    },

    setKrwToMetr : (state, params) => {
      state.krwToMetr = params.krwToMetr
    },

    setMetrToKrw : (state, params) => {
      state.metrToKrw = params.metrToKrw
    },
    //카트에 상품을 추가한다.
    addCartProduct : (state, params) => {
      state.cart.push({
        productIdx: params.productIdx,
        productName : params.productName,
        productImg : params.productImg,
        optionGroups : params.optionGroups,
        productPrice : params.productPrice,
        quantity : params.quantity,
        symbol : params.symbol,
      })

      state.totalPrice = (Number(state.totalPrice) + (Number(params.productPrice) * params.quantity)).toString()
      state.totalQuantity = Number(state.totalQuantity) + params.quantity
    },

    setShopInfo : (state, params) => {
      state.shopIdx = params.shopIdx
      state.shopName = params.shopName
    },

    setUserIdx : (state, params) => {
      state.userIdx = params.userIdx
    },

    setCurrentProductInfo : (state, params) => {
      state.currentProductIdx = params.productIdx
    },

    setCurrentCartShopInfo : (state, params) => {
      state.currentCartShopIdx = params.currentCartShopIdx
    },


    //카트에 담긴 상품수량을 증감시키고 총합 금액과 개수를 계산한다.
    countQuantity : (state, params) => {
      let cartProduct = state.cart.find(product => product.productIdx === params.productIdx)
      if (params.isIncrement) {
        state.totalPrice = (Number(state.totalPrice) + cartProduct.productPrice).toString()
        ++state.totalQuantity
        ++cartProduct.quantity
      } else {
        state.totalPrice = (Number(state.totalPrice) - cartProduct.productPrice).toString()
        --state.totalQuantity
        --cartProduct.quantity
      }
    },

    //카트에서 삼풍을 삭제한다.
    removeCartProduct : (state, params) => {
      let cartProduct = state.cart.find(product => product.productIdx === params.productIdx && product.symbol === params.symbol)
      state.totalPrice = Number(state.totalPrice) - (cartProduct.productPrice * cartProduct.quantity)
      state.totalQuantity = state.totalQuantity - cartProduct.quantity
      state.cart.splice(state.cart.indexOf(cartProduct), 1)
    },

    setPaymentType : (state, params) => {
      state.paymentType = params.paymentType
    },

    setUseMetrPoint : (state, params) => {
      state.useMetrPoint = params.useMetrPoint
    },

    setAccessToken : (state, params) => {
      state.token.accessToken = params.accessToken
      state.token.refreshToken = params.refreshToken
    },

    setPassToken : (state, params) => {
      state.token.accessToken = ''
      state.token.refreshToken = ''
      state.token.passToken = params.passToken
    },

    setAccessTokenReusable : (state, params) => {
      state.token.accessTokenReusable = params.accessTokenReusable
    },

    setAccessTokenQuickOrder : (state, params) => {
      state.token.accessTokenQuickOrder = params.accessTokenQuickOrder
    },

    setOrderMenuName : (state, params) => {
      state.orderMenuName = params.orderMenuName
    },

    setProductType : (state, params) => {
      state.productType = params.productType
    },

    setIsOrderedHeadQuarter : (state, params) => {
      state.isOrderedHeadQuarter = params.isOrderedHeadQuarter
    },
  },

  getters: {
    getShopInfo : (state) => {
      return {
        shopIdx : state.shopIdx,
        shopName : state.shopName
      }
    },

    getUserIdx : (state) => {
      return state.userIdx
    },

    getCurrentProductInfo : (state) => {
      return {
        currentProductIdx : state.currentProductIdx,
      }
    },

    getCurrentCartShopInfo : (state) => {
      return {
        currentCartShopIdx : state.currentCartShopIdx
      }
    },

    //현재 카트에 담긴 모든수량의 상품을 가져온다.
    getCartProductQuantity : (state) => {
      state.totalQuantity = 0
      state.cart.forEach(e => {
        state.totalQuantity = (Number(state.totalQuantity) + Number(e.quantity)).toString()
      })

      return state.totalQuantity
    },

    //현재 카트에 담긴 상품가격의 총합을 가져옴
    getTotalPrice : (state) => {
      return state.totalPrice
    },

    getTotalQuantity : (state) => {
      return state.totalQuantity
    },

    getPaymentType : (state) => {
      return state.paymentType
    },

    getCartProductById : (state) => (params) => {
      return state.cart.find(product => product.productIdx === params.productIdx)
    },

    getCartList : (state) => {
      return state.cart
    },

    getUseMetrPoint : (state) => {
      return state.useMetrPoint
    },

    getAllToken : (state) => {
      return state.token
    },

    getKrwToMetr : (state) => {
      return state.krwToMetr
    },

    getMetrToKrw : (state) => {
      return state.metrToKrw
    },

    getOrderMenuName : (state) => {
      return state.orderMenuName
    },

    getProductType : (state) => {
      return state.productType
    },

    isOrderedHeadQuarter : (state) => {
      return state.isOrderedHeadQuarter
    },

    getPassToken : (state) => {
      return state.token.passToken
    }
  },
});
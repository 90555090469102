<template>
  <div class="wrap">
    <div class="popup type3" @click.self="$emit('close')">
      <div class="popup_wrap">
        <div class="container">
          <div class="inner">
            <div class="popup_tit">
              <h2>출금신청</h2>
            </div>
            <div class="popup_contents text_type">
              <h3 class="hide">출금신청</h3>
              <ul class="list info2">
                <li><a @click="$router.push({name:'RequestMetr'})">AMIS</a></li>
                <li><a @click="$router.push({name:'RequestMetr', query : {'token' : 'ETH'}})">ETH</a></li>
              </ul>
            </div>
          </div>
        </div>
        <button type="button" class="popup_close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WithdrawalSheetBar"
}
</script>

<style scoped>

</style>
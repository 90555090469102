<template>
  <div>
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <h1><img src="/image/header_logo.png" alt="아미스"></h1>
<!--          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>-->
          <button type="button" class="nav-btn right" @click="test"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>

<!--    <div class="wrapper">
      <div class="content"><vue-loading type="spiningDubbles" color="var(&#45;&#45;meterra-green)" :size="{ width: '50px', height: '50px' }"></vue-loading></div>
    </div>-->
  </div>


</template>

<script>
export default {
  name: "Test",
  methods : {
    test() {
      this.$router.replace({name: 'RequestMetrSuccess', params: {reason: '테스트 메시지'}})
    }
  }
}
</script>

<style scoped>
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}


.header-wrap { position: fixed; width: 100%; top: 0; z-index: 10; box-sizing: border-box; background: var(--white); }
.header-wrap .header-group h1 { display: inline-block; width: 110px; height: auto; }

</style>
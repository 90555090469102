<template>
  <div class="popup-wrap">
    <div class="overlay-bg" @click.self="$emit('close')"></div>
    <div class="half-listlayer-wrap">
      <div class="close">
        <button type="button" class="nav-btn right" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <div class="half-content">
        <div class="half-content-heading">
          <h5 class="half-tit">신용카드 선택</h5>
        </div>
        <hr class="hr-2">

        <div class="half-content-area">
          <ul class="bank-list">
            <li  v-for="(card, index) in cards" :key="index">
              <a @click="selectCard(card)">
                <div class="bank-icon">
                  <i class="sp-card" :class="card.name | cardImage"></i>
                </div>
                <div class="bank-summary">
                  <span>{{ card.title }}<i v-show="card.mainCard" class="badge ml-10">주카드</i></span>
                  <em class="disabled">{{ card.alias ? card.alias : '별명없음' }}</em>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="pt-30">
          <button class="outline_l_btn btn-outline-black" @click="$router.push({name:'RegistCard'})"><i class="icon more"></i>신용카드 등록</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentCardSelect",
  props: ['cards'],
  methods: {
    selectCard(_card) {
      this.$emit('selected', _card)
    }
  },
  filters: {
    cardImage: (value) => {
      if (value.startsWith('삼성') || value.toLowerCase().startsWith('samsung')) {
        return 'card-02'
      } else if (value.startsWith('비씨') || value.toLowerCase().startsWith('bc')) {
        return 'card-08'
      } else if (value.startsWith('롯데') || value.toLowerCase().startsWith('lotte')) {
        return 'card-05'
      } else if (value.startsWith('현대') || value.toLowerCase().startsWith('hyundai')) {
        return 'card-04'
      } else if (value.startsWith('국민') || value.toLowerCase().startsWith('kb')) {
        return 'card-01'
      } else if (value.startsWith('신한') || value.toLowerCase().startsWith('shinhan')) {
        return 'card-03'
      } else if (value.startsWith('시티') || value.toLowerCase().startsWith('citi')) {
        return 'card-09'
      } else if (value.startsWith('농협') || value.toLowerCase().startsWith('nh')) {
        return 'card-11'
      } else if (value.startsWith('하나') || value.toLowerCase().startsWith('hana')) {
        return 'card-06'
      } else if (value.startsWith('비자') || value.toLowerCase().startsWith('visa')) {
        return 'card-15'
      } else if (value.startsWith('마스터') || value.toLowerCase().startsWith('master')) {
        return 'card-16'
      }
      return 'bank-30'
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">FAQ/문의</span>
        </div>
        <div class="header-wrap pay-tab mt-50">
          <div @click="$router.replace({name:'FaqList'})">FAQ</div>
          <div class="active">문의</div>
        </div>
      </div>
    </div>
    <div class="content-wrap-v3 pt-110">
      <div class="container">
        <!-- TODO : 문의 없을 경우 -->
        <div class="alram-wrap" v-if="loaded && items.length === 0">
          <div class="list-none">
            <i class="bg_symbol_15_op mb-20"></i>
            <p class="list-desc">등록된 문의가 없습니다.</p>
          </div>
        </div>

        <!-- TODO : 문의 있을 경우 -->
        <div class="qna-list" v-if="items.length > 0">
          <ul>
            <li v-for="(item, index) in items" :key="'i' + index">
              <a @click="goDetail(item.idx, item.regDate)">
                <p class="qna-date">
                  {{ item.regDate }}
                  <small :class="stateClass(item.state)">{{ item.state | state }}</small>
                </p>
                <p class="fs-16">
                  {{ item.title }}
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="$router.push({name:'QnaRegist'})">문의하기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QnaList",
  data() {
    return {
      items: [],
      loaded: false
    }
  },
  mounted() {
    this.getQna()
  },
  methods: {
    getQna() {
      this.selectedIdx = ''
      this.$get(this.$QNA_LIST, 'qnalist_getqna', (result) => {
        this.items = result.data
        this.loaded = true
      }, (result) => {
        this.httpError(result.data)
        this.loaded = true
      })
    },
    goDetail(_no, _date) {
      this.$router.push({name:'QnaDetail', params:{idx:_no, date: _date}})
    },
    stateClass(value) {
      if (value === '0' ) {
        return 'qna-state-0'
      } else if (value === '1' ) {
        return 'qna-state-1'
      } else if (value === '2' ) {
        return 'qna-state-2'
      }
      return ''
    }
  },
  filters: {
    state: (value) => {
      if (value === '0' ) {
        return '답변대기'
      } else if (value === '1' ) {
        return '처리중'
      } else if (value === '2' ) {
        return '답변완료'
      }
      return ''
    },

  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <TopNav :isSearch="isSearch" v-on:searchEvent="getKeyword" @keyupEvent="getSearchedKeywords" :searchType="'menu'"/>
    <div class="content-wrap pt-50">
      <!-- TODO: 검색시 노출화면 -->
      <!-- 검색키워드 -->
      <div class="keyword_wrap">
        <div class="container" v-if="productList.length <= 0">  <!-- menuList 가 검색되면 추천 키워드 노출을 없애고 메뉴리스트 이미지만 보여준다.-->
          <div class="row">
            <div class="btn_keyword" v-for="searchedKeyword in searchedKeywords" :key="searchedKeyword.id">
              <button class="text" aria-label="검색어선택">{{ searchedKeyword }}</button>
              <button class="close" aria-label="검색어삭제" @click="removeSearchedKeyword(searchedKeyword)"></button>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <ul class="list_type3">
              <li v-for="product in productList" :key="product.idx">
                <a @click="$router.replace({ name : 'MenuDetail', params : { shopIdx : shopIdx, productIdx : product.idx } })">
                  <figure>
                    <div class="frame">
                      <img class="munu_img" :src="removeHost(product.imgPath)" alt="">
                    </div>
                    <figcaption>
                      <strong class="ellipsis">{{ product.name }}</strong>
                      <span>{{ product.price }}원</span>
                    </figcaption>
                  </figure>
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import TopNav from "@/components/common/TopNav";

export default {
  name: "SearchMenu",
  components: {
    TopNav,
  },
  data() {
    return {
      isSearch : true,
      productList: [],  //가맹점으로부터 검색된 메뉴정보
      page : 1,
      size : 100,
      shopIdx : this.$store.getters.getShopInfo.shopIdx,
      searchedKeywords : [],
    }
  },
  mounted() {
  },
  methods: {
    //removeSearchedKeyword
    removeSearchedKeyword(keyword) {
      this.deleteSearchedKeyword('searched_keyword_menu', keyword)
      this.searchedKeywords = this.getValidSearchedKeywords('searched_keyword_menu', '')
    },

    getKeyword(keyword){
      let paramShopIdx = this.shopIdx === '0' ? '' : this.shopIdx
      this.$quick_order_get(this.$QUICK_PRODUCT_LIST + '?' + 'page=' + this.page +
          '&size=' + this.size + '&shopIdx=' + paramShopIdx + '&word=' + keyword,
          'shop_menu', (result) => {
            this.productList = result.data
          }, (result) => {
            this.httpError(result.data)
          })
    },

    getSearchedKeywords(searchedKeywords) {
      this.searchedKeywords = searchedKeywords
    }

  },

}
</script>


<style scoped>
.content-wrap {
  background : var(--bg-gray);
}
</style>
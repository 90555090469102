export default Object.freeze({
    install(Vue) {
        Vue.prototype.getAroundShop = function() {
            if (this.gps_use === true){
            // let queryString = '?latitude=' + this.default_lat + '&longitude=' + this.default_lng + '&distance=500'

                //this.gps_lat = 35.8447;
                //this.gps_lng = 128.7489;
                let queryString = '?latitude=' + this.gps_lat + '&longitude=' + this.gps_lng + '&distance=1000'

                this.$shop_post(this.$SHOP_AROUND + queryString, 'aroundmap_mounted', null, (result) => {
                    if (result.data.length === 0) {
                        if (this.openMap){
                            this.initMap()
                            this.gpsTracking()
                        }
                        return
                    } else {

                        let temp = result.data
                        let arrData = []
                        //FIXME 우선 오픈되어있는 가맹점만 탐색
                        //오픈되어있는 가맹점만 탐색
                        temp.forEach(shop => {
                            if (shop.openState) {
                                arrData.push(shop)
                            }
                        })

                        arrData.sort(function(a, b) {
                            return a.distance - b.distance;
                        });

                        if (this.openMap === true){
                            this.aroundShop = arrData
                            this.initMap()
                            this.gpsTracking()
                        } else {
                            this.aroundShopList.push(arrData[0])
                            this.aroundShopList.push(arrData[1])
                        }
                    }
                }, (result) => {
                    this.httpError(result.data)
                })
            }
        }
        // map api
        Vue.prototype.initMap = function () {
            console.log(window.kakao)

            let container = document.getElementById('map');
            let options = {
                center: new window.kakao.maps.LatLng(this.gps_lat, this.gps_lng),
                level: 3
            };

            // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
            let mapTypeControl = new window.kakao.maps.MapTypeControl();
            this.map = new window.kakao.maps.Map(container, options);
            let positions = []

            for (let i = 0; i < this.aroundShop.length; i++) {
                let position = ({
                    content: '<div>'+this.aroundShop[i].shopName+'</div>',
                    latlng: new window.kakao.maps.LatLng(this.aroundShop[i].latitude, this.aroundShop[i].longitude)
                })
                positions.push(position);

                let marker = new window.kakao.maps.Marker({
                    map: this.map, // 마커를 표시할 지도
                    position: positions[i].latlng, // 마커의 위치
                });

                // 마커에 표시할 인포윈도우를 생성합니다
                let infowindow = new window.kakao.maps.InfoWindow({
                    content: positions[i].content // 인포윈도우에 표시할 내용
                });
                window.kakao.maps.event.addListener(marker, 'mouseover', this.makeOverListener(this.map, marker, infowindow));
                window.kakao.maps.event.addListener(marker, 'mouseout', this.makeOutListener(infowindow));
            }



            // 지도에 컨트롤을 추가해야 지도위에 표시됩니다
            // kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
            this.map.addControl(mapTypeControl, window.kakao.maps.ControlPosition.TOPRIGHT);

            // 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
            let zoomControl = new window.kakao.maps.ZoomControl();
            this.map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT);
            this.getRegion()
        }
        Vue.prototype.makeOverListener = function (map, marker, infowindow) {
            return function () {
                infowindow.open(map, marker);
            };
        }
        Vue.prototype.makeOutListener = function (infowindow) {
            return function () {
                infowindow.close();
            };
        }
        Vue.prototype.getRegion = function (){
            let geocoder = new window.kakao.maps.services.Geocoder();
            searchAddrFromCoords(this.map.getCenter(), this.displayCenterInfo);

            function searchAddrFromCoords(coords, callback) {
                // 좌표로 행정동 주소 정보를 요청합니다
                geocoder.coord2RegionCode(coords.getLng(), coords.getLat(), callback);
            }

        }

        // GPS 위치
        Vue.prototype.gpsTracking = function (){
            if (this.gps_use) {
                // this.map.panTo(new window.kakao.maps.LatLng(this.default_lat,this.default_lng))
                this.map.panTo(new window.kakao.maps.LatLng(this.gps_lat,this.gps_lng))
                let gps_content = '<div class="icon ver_2 current_location"><span class="hide">현재위치</span></div>'
                let currentOverlay = new window.kakao.maps.CustomOverlay({
                    // position: new window.kakao.maps.LatLng(this.default_lat,this.default_lng ),
                    position: new window.kakao.maps.LatLng(this.gps_lat,this.gps_lng ),
                    content: gps_content,
                    map: this.map
                })
                currentOverlay.setMap(this.map)
            } else {
                alert("설정에서 차단 허용 변경")
                this.gpsCheck()
            }
        }
        Vue.prototype.gpsCheck = function (){
            if (navigator.geolocation) {
                let options = {timeout:60000}
                navigator.geolocation.getCurrentPosition(this.showLocation, this.errorHandler, options)
            } else {
                alert("GPS_추적이 불가합니다.")
                this.gps_use = false
            }
        }

        // 현 위치로
        Vue.prototype.setBounds = function () {
            let bounds = new window.kakao.maps.LatLngBounds();
            // bounds.extend(new window.kakao.maps.LatLng(this.default_lat,this.default_lng))
            bounds.extend(new window.kakao.maps.LatLng(this.gps_lat,this.gps_lng))
            // LatLngBounds 객체에 추가된 좌표들을 기준으로 지도의 범위를 재설정합니다
            // 이때 지도의 중심좌표와 레벨이 변경될 수 있습니다
            this.map.setBounds(bounds)
            let level = this.map.getLevel()
            this.map.setLevel(level + 1)
        }
    }
})

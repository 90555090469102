<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
        <span class="logo">
          <img src="/image/logo_row01.svg" alt="meterra">
        </span>
        </div>
      </div>
    </div>
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <strong class="confirm-tit mt-10">{{ sendAmount | numberWithCommas }} <em class="unit">{{ tokenType }}</em></strong>
              <span class="weight-semi-bold fs-18 mt-10">= {{ Math.floor(sendAmount * rate)  }}원 <em class="fs-16">(1{{ tokenType }}={{ rate | numberWithCommas }}원)</em></span>
              <p class="confirm-desc">송금완료 되었습니다.</p>
            </div>
            <div class="confirm-info-list">
              <dl>
                <dt class="text-tit">잔여 {{ tokenType }}</dt>
                <dd class="point-color-black">{{ userAmount | numberWithCommas }} {{ tokenType }}</dd>
              </dl>
              <dl>
                <dt class="text-tit">받는사람</dt>
                <dd class="text-desc">{{ targetUserName }}</dd>
              </dl>
              <dl>
                <dt class="text-tit">송금일시</dt>
                <dd class="text-desc">{{ regDate }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="$router.back()">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SendMetrSuccess",
  data() {
    return {
      regDate: '',
      sendAmount: '',
      targetUserName: '',
      userAmount: '',
      tokenType : 'AMIS',
      rate: 100,
    }
  },
  mounted() {
    this.regDate = this.$route.params.regDate
    this.sendAmount = this.$route.params.sendAmount
    this.targetUserName = this.$route.params.targetUserName
    this.userAmount = this.$route.params.userAmount
    this.tokenType = this.$route.params.tokenType

    let tokenPrice = this.tokenType === 'AMIS' ? this.$TOKEN_PRICE : this.$ETH_PRICE

    this.$get(tokenPrice, 'sendpaysuccess_mounted', (result) => {
      this.rate = result.data
    }, (result) => {
      this.httpError(result.data)
    })
  }
}
</script>

<style scoped>
.header-group .logo { position: relative; display: inline-block; height: 20px; width: 70px; }
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

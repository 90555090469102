<template>
  <div class="wrap">
    <!-- 상단 고정 해더 영역 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">이메일 변경</h5>
            <div class="w-100">
              <div class="pt-30 d-flex-row">
                <MTRInput
                    :type="'email'"
                    :placeholder="'이메일을 입력해 주세요.'"
                    :model="email"
                    @update="(_model) => {this.email=_model}"
                />
                <button type="button" class="l_btn btn-black ml-10" @click="checkEmail()">확인</button>
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.email">{{ errors.email }}</p>
              <p class="alert-green mt-15 text-c" v-show="isChecked">사용 가능한 이메일입니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn" :class="{'btn-disable': !isChecked, 'btn-black': isChecked}" @click="changeEmail()">다음</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTRInput from "../../common/MTRInput";
export default {
  name: "ChangeEmail",
  components: {
    MTRInput
  },
  data() {
    return {
      isChecked: false,
      email: '',
      errors: {
        email: '',
      },
      success: {
        email: '',
      }
    }
  },
  methods: {
    errorClear() {
      this.errors = { email: ''}
      this.success = { email: ''}
    },
    checkEmail() {
      if (!(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(this.email))) {
        this.errors.email = '이메일을 정확하게 입력해주세요.'
        return
      }
      this.errorClear()
      this.$get(this.$AUTH_EMAIL + '?email=' + this.email, 'changeemail_checkemail', () => {
        this.isChecked = true
        this.success.email = '사용 가능한 이메일입니다.'
      }, (result) => {
        if (result.data.error.length > 0) {
          for (let i=0;i<result.data.error.length;i++) {
            let err = result.data.error[i]
            this.errors[err.field] = err.message
          }
        } else if (result.data.message) {
          this.errors.email = result.data.message
        } else {
          this.errors.email = '사용할 수 없는 이메일입니다.'
        }
        this.isChecked = false
      })
    },
    changeEmail() {
      if (this.isChecked) {
        let formData = new FormData()
        formData.append('email', this.email)

        //test
        this.$put(this.$AUTH_UPDATE_EMAIL, 'changeemail_changeemail', formData, () => {
          this.$root.$refs.MTRAlert.showAlert('이메일 변경',
              '변경이 완료되었습니다.',
              '',
              '',
              () => {
                this.$router.back()
              })
        }, (result) => {
          this.httpError(result.data)
        })
      }
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

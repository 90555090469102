<template>
  <div class="wrap">
    <div class="intro-bg">
      <div class="container">
        <div class="row">
          <div class="intro-wrap">
            <div class="intro-box flex-center-wrap">
              <img src="/assets/image/intro.png" class="intro-logo" alt="일상을 편하게 변화시킬, 아미스">
            </div>
            <div class="intro-box">
              <div class="button-group">
                <button class="btn l_btn btn-black" @click="goLogin()">로그인</button>
              </div>
              <a href="#" class="link-signup" @click="goSignUp()">회원가입</a>
              <p class="copyright">Copyright © 2022 AMIS Inc.</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isTest">
        before login<br/><br/>
        <button @click="goLogin()">login</button><br/>
        <button @click="goSignUp()">sign up</button>

        <br/><br/><br/>디바이스 연동 테스트<br/>
        <button @click="getFCM()">fcm토큰 정보</button><br/><br/>
        <button @click="subTopic()">fcm구독설정(test)</button><br/><br/>
        <button @click="unSubTopic()">fcm구독해제(test)</button><br/><br/>
        <button @click="setPin()">핀 저장 테스트</button><br/><br/>
        <button @click="deletePin()">핀 삭제 테스트</button><br/><br/>
        <button @click="bioAuth()">생체인증 테스트</button><br/><br/>
        <button @click="bioType()">생체인증 종류</button><br/><br/>
        <button @click="bioAble()">생체인증 가능여부</button><br/><br/>
        <button @click="openQr()">QR 열기</button><br/><br/>
        <button @click="openQrReapchain()">QR 열기(아미스)</button><br/><br/>
        <button @click="deviceType()">디바이스 타입</button><br/><br/>
        <button @click="appVersion()">앱 버전</button><br/><br/>
        <button @click="deviceInfo1()">디바이스 정보</button><br/><br/>
        <button @click="reqContact()">연락처 요청</button><br/><br/>
        <button @click="openWebPage()">웹페이지 열기</button><br/><br/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeforeLogin",
  data() {
    return {
      isTest: false,
    }
  },
  mounted() {

    //네이티브 메세지를 못받는 상태값으로 변경
    window.isAlready = false
    window.changeAlreadyState()

    if (this.isTest) {
      window.addEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
      window.addEventListener(this.$EVENT_QR_STRING, this.setQr)
      window.addEventListener(this.$EVENT_CONTACTS, this.setCon)
      window.addEventListener(this.$EVENT_REQUEST_PERMISSION, this.reqPermi)
    }
  },
  beforeDestroy() {
    if (this.isTest) {
      window.removeEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
      window.removeEventListener(this.$EVENT_QR_STRING, this.setQr)
      window.removeEventListener(this.$EVENT_CONTACTS, this.setCon)
      window.removeEventListener(this.$EVENT_REQUEST_PERMISSION, this.reqPermi)
    }
  },
  methods: {
    goLogin() {
      this.$router.replace({ name: 'Login' })
    },
    goSignUp() {
      this.$router.push({ name: 'TermAgree' })
    },

    //test
    getFCM() {
      let fcm = window.getFCMToken()
      alert('fcm : ' + fcm)
    },
    subTopic() {
      window.subscribedTopic('test')
    },
    unSubTopic() {
      window.unsubscribedTopic('test')
    },

    setPin() {
      window.setPin('123456')
    },
    deletePin() {
      window.removePin()
    },
    bioAuth() {
      window.requestBio()
    },
    bioResult(e) {
      let pin = e.detail
      alert('pin : ' + pin + '(' + pin.length + '자리)')
    },
    bioType() {
      let type = window.getBioType()
      alert('bio type : ' + type)
    },
    bioAble() {
      let able = window.getAbleBio()
      alert(able ? '생체인증 가능' : '생체인증 불가능')
    },
    openQr() {
      window.openQRReader('타이틀테스트')
    },
    openQrReapchain() {
      window.openQRReader('아미스 타이틀테스트')
    },
    setQr(e) {
      let qr = e.detail
      alert('qr 인식 결과 : ' + qr)
    },
    deviceType() {
      let device = window.getDeviceType()
      alert('device type : ' + device)
    },
    appVersion() {
      let version = window.getVersion()
      alert('version : ' + version)
    },
    deviceInfo1() {
      let info = '폰기종 : ' + window.getDeviceInfo() + '/ OS : ' + window.getOSInfo()
      alert('info : ' + info)
    },
    reqContact() {
      window.requestContact()
    },
    setCon(e) {
      let contacts = e.detail
      alert('contacts : ' + contacts)
    },
    openWebPage() {
      window.openWeb('https://www.naver.com')
    },
    reqPermi() {
      alert('권한요청 들어옴.')
    }
  },
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

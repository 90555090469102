import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Intro from "../components/intro/Intro";
import BeforeLogin from "../components/intro/BeforeLogin";
import Login from "../components/user/login/Login";
import LeaveUser from "../components/user/login/LeaveUser";
import InactivityRequest from "../components/user/login/inactivity/InactivityRequest";
import InactivitySuccess from "../components/user/login/inactivity/InactivitySuccess";
import InactivityFail from "../components/user/login/inactivity/InactivityFail";
import TermAgree from "../components/user/signup/TermAgree";
import SignUp01 from "../components/user/signup/SignUp01";
import SignUp02 from "../components/user/signup/SignUp02";
import Term01 from "../components/user/signup/term/Term01";
import Term02 from "../components/user/signup/term/Term02";
import Term03 from "../components/user/signup/term/Term03";
import Term04 from "../components/user/signup/term/Term04";
import SignUpFinish from "../components/user/signup/SignUpFinish";
import SignUpFail from "../components/user/signup/SignUpFail";
import FindId from "../components/user/find/findId/FindId";
import FindIdSuccess from "../components/user/find/findId/FindIdSuccess";
import FindIdFail from "../components/user/find/findId/FindIdFail";
import FindPw from "../components/user/find/findPw/FindPw";
import FindPwNoId from "../components/user/find/findPw/FindPwNoId";
import FindPwChange from "../components/user/find/findPw/FindPwChange";
import FindPwChangeFinish from "../components/user/find/findPw/FindPwChangeFinish";
import SignUpPinRegist from "../components/user/signup/pin/SignUpPinRegist";
import SignUpPinCheck from "../components/user/signup/pin/SignUpPinCheck";
import SignUpPinFinish from "../components/user/signup/pin/SignUpPinFinish";
import SignUpBioSuccess from "../components/user/signup/pin/SignUpBioSuccess";
import SignUpBioFail from "../components/user/signup/pin/SignUpBioFail";
import Main from "../components/main/Main";
import NotificationList from "../components/main/notification/NotificationList";
import ReceiveInputAmount from "../components/receive/ReceiveInputAmount";
import PaymentInputAmount from "../components/payment/PaymentInputAmount";
import PaymentSuccess from "../components/payment/PaymentSuccess";
import PaymentFail from "../components/payment/PaymentFail";
import SendMetrContactList from "../components/send/metr/SendMetrContactList";
import SendMetrAmount from "../components/send/metr/SendMetrAmount";
import SendMetrSuccess from "../components/send/metr/SendMetrSuccess";
import SendMetrFail from "../components/send/metr/SendMetrFail";
import HistoryMetr from "../components/history/HistoryMetr";
import HistoryCard from "../components/history/HistoryCard";
import HistoryReward from "../components/history/HistoryReward";
import MyPage from "../components/mypage/MyPage";
import MyInfo from "../components/myinfo/MyInfo";
import ChangeEmail from "../components/myinfo/email/ChangeEmail";
import ChangePhone from "../components/myinfo/phone/ChangePhone";
import ChangePhoneSuccess from "../components/myinfo/phone/ChangePhoneSuccess";
import ChangePhoneFail from "../components/myinfo/phone/ChangePhoneFail";
import ChangePassword from "../components/myinfo/password/ChangePassword";
import ChangePasswordSuccess from "../components/myinfo/password/ChangePasswordSuccess";
import ChangePasswordFail from "../components/myinfo/password/ChangePasswordFail";
import ChangePinCheck from "../components/myinfo/pin/ChangePinCheck";
import ChangePinRegist from "../components/myinfo/pin/ChangePinRegist";
import ChangePinRegistRE from "../components/myinfo/pin/ChangePinRegistRE";
import ChangePinSuccess from "../components/myinfo/pin/ChangePinSuccess";
import RestorePin from "../components/myinfo/pin/RestorePin";
import RegistBioSuccess from "../components/myinfo/bio/RegistBioSuccess";
import RegistBioFail from "../components/myinfo/bio/RegistBioFail";
import CardList from "../components/myinfo/card/CardList";
import CardChangeNick from "../components/myinfo/card/CardChangeNick";
import RegistCard from "../components/myinfo/card/regist/RegistCard";
import RegistCardNick from "../components/myinfo/card/regist/RegistCardNick";
import RegistCardSuccess from "../components/myinfo/card/regist/RegistCardSuccess";
import RegistCardFail from "../components/myinfo/card/regist/RegistCardFail";
import LeaveSuccess from "../components/myinfo/leave/LeaveSuccess";
import Leave from "../components/myinfo/leave/Leave";
import NoticeList from "../components/mypage/notice/NoticeList";
import EventList from "../components/mypage/event/EventList";
import EventDetail from "../components/mypage/event/EventDetail";
import FaqList from "../components/mypage/faq/FaqList";
import FaqSubList from "../components/mypage/faq/FaqSubList";
import QnaList from "../components/mypage/qna/QnaList";
import QnaDetail from "../components/mypage/qna/QnaDetail";
import QnaRegist from "../components/mypage/qna/QnaRegist";
import TermList from "../components/mypage/term/TermList";
import RequestMetr from "../components/mypage/withdraw/RequestMetr";
import RequestMetrSuccess from "../components/mypage/withdraw/RequestMetrSuccess";
import RequestMetrFail from "../components/mypage/withdraw/RequestMetrFail";
import HistoryReusableCub from "../components/history/HistoryReusableCub";
import ShopMenu from "../components/order/shop/ShopMenu";
import MenuDetail from "../components/order/shop/MenuDetail";
import NutritionInfo from "../components/order/shop/NutritionInfo";
import SearchForm from "@/components/common/SearchForm";
import SearchMenu from "../components/order/shop/SearchMenu";
import Order from "@/components/order/Order";
import OrderPayment from "../components/order/payment/OrderPayment";
import PaymentPin from "../components/order/payment/PaymentPin";
import PaymentComplete from "../components/order/payment/PaymentComplete";
import Test from "../components/Test";
import QuickOrderHistory from "../components/order/history/QuickOrderHistory";
import QuickOrderHistoryDetail from "../components/order/history/QuickOrderHistoryDetail";
import PaymentFailed from "@/components/order/payment/PaymentFailed";
import PaymentCharge from "@/components/payment/PaymentCharge";
import ChargeLogin from "@/components/payment/ChargeLogin";
import LockUpList from "@/components/lockup/LockUpList";
import LockUpHistory from "@/components/lockup/LockUpHistory";

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Intro',
            component: Intro,
        },
        {
            path: '/beforeLogin',
            name: 'BeforeLogin',
            component: BeforeLogin,
        },

        //login
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/inactivity/request',
            name: 'InactivityRequest',
            component: InactivityRequest,
        },
        {
            path: '/inactivity/success',
            name: 'InactivitySuccess',
            component: InactivitySuccess,
        },
        {
            path: '/inactivity/fail',
            name: 'InactivityFail',
            component: InactivityFail,
        },
        {
            path: '/leave/user',
            name: 'LeaveUser',
            component: LeaveUser,
        },

        //term
        {
            path: '/term/agree',
            name: 'TermAgree',
            component: TermAgree,
        },
        {
            path: '/term/term01',
            name: 'Term01',
            component: Term01,
        },
        {
            path: '/term/term02',
            name: 'Term02',
            component: Term02,
        },
        {
            path: '/term/term03',
            name: 'Term03',
            component: Term03,
        },
        {
            path: '/term/term04',
            name: 'Term04',
            component: Term04,
        },
        {
            path: '/terms',
            name: 'TermList',
            component: TermList,
        },

        //signup
        {
            path: '/signup/step1',
            name: 'SignUp01',
            component: SignUp01,
        },
        {
            path: '/signup/step2',
            name: 'SignUp02',
            component: SignUp02,
        },
        {
            path: '/signup/finish',
            name: 'SignUpFinish',
            component: SignUpFinish,
        },
        {
            path: '/signup/fail',
            name: 'SignUpFail',
            component: SignUpFail,
        },

        //signup - pin
        {
            path: '/signup/pin/regist',
            name: 'SignUpPinRegist',
            component: SignUpPinRegist,
        },
        {
            path: '/signup/pin/check',
            name: 'SignUpPinCheck',
            component: SignUpPinCheck,
        },
        {
            path: '/signup/pin/finish',
            name: 'SignUpPinFinish',
            component: SignUpPinFinish,
        },

        //signup - bio
        {
            path: '/signup/bio/success',
            name: 'SignUpBioSuccess',
            component: SignUpBioSuccess,
        },
        {
            path: '/signup/bio/fail',
            name: 'SignUpBioFail',
            component: SignUpBioFail,
        },

        //find id
        {
            path: '/find/id',
            name: 'FindId',
            component: FindId,
        },
        {
            path: '/find/id/success',
            name: 'FindIdSuccess',
            component: FindIdSuccess,
        },
        {
            path: '/find/id/fail',
            name: 'FindIdFail',
            component: FindIdFail,
        },

        //find pw
        {
            path: '/find/pw',
            name: 'FindPw',
            component: FindPw,
        },
        {
            path: '/find/pw/noId',
            name: 'FindPwNoId',
            component: FindPwNoId,
        },
        {
            path: '/find/pw/change',
            name: 'FindPwChange',
            component: FindPwChange,
        },
        {
            path: '/find/pw/change/finish',
            name: 'FindPwChangeFinish',
            component: FindPwChangeFinish,
        },

        //main
        {
            path: '/main',
            name: 'Main',
            component: Main,
        },
        {
            path: '/notifications',
            name: 'NotificationList',
            component: NotificationList,
        },

        // //charge
        // {
        //     path: '/charge',
        //     name: 'Charge',
        //     component: Charge,
        // },
        // {
        //     path: '/charge/success',
        //     name: 'ChargeSuccess',
        //     component: ChargeSuccess,
        // },
        // {
        //     path: '/charge/fail',
        //     name: 'ChargeFail',
        //     component: ChargeFail,
        // },
        // {
        //     path: '/charge/success/virtual',
        //     name: 'ChargeSuccessVirtual',
        //     component: ChargeSuccessVirtual,
        // },
        //
        // //auto charge
        // {
        //     path: '/autoCharge/regist',
        //     name: 'AutoChargeRegist',
        //     component: AutoChargeRegist,
        // },
        // {
        //     path: '/autoCharge',
        //     name: 'AutoCharge',
        //     component: AutoCharge,
        // },
        // {
        //     path: '/autoCharge/remove',
        //     name: 'AutoChargeRemove',
        //     component: AutoChargeRemove,
        // },
        //
        //receive
        {
            path: '/receive/input/amount',
            name: 'ReceiveInputAmount',
            component: ReceiveInputAmount,
        },

        //send - metr
        {
            path: '/send/metr/contacts',
            name: 'SendMetrContactList',
            component: SendMetrContactList,
        },
        {
            path: '/send/metr/amount',
            name: 'SendMetrAmount',
            component: SendMetrAmount,
        },
        {
            path: '/send/metr/success',
            name: 'SendMetrSuccess',
            component: SendMetrSuccess,
        },
        {
            path: '/send/metr/fail',
            name: 'SendMetrFail',
            component: SendMetrFail,
        },

        //payment
        {
            path: '/payment/input/amount',
            name: 'PaymentInputAmount',
            component: PaymentInputAmount,
        },
        {
            path: '/payment/success',
            name: 'PaymentSuccess',
            component: PaymentSuccess,
        },
        {
            path: '/payment/fail',
            name: 'PaymentFail',
            component: PaymentFail,
        },
        {
            path: '/payment/charge',
            name: 'PaymentCharge',
            component: PaymentCharge,
            props: true
        },
        {
            path: '/payment/charge/login',
            name: 'ChargeLogin',
            component: ChargeLogin,
            props: true
        },

        //history
        {
            path: '/history/metr',
            name: 'HistoryMetr',
            component: HistoryMetr,
        },
        {
            path: '/history/card',
            name: 'HistoryCard',
            component: HistoryCard,
        },
        {
            path: '/history/reward',
            name: 'HistoryReward',
            component: HistoryReward,
        },
        {
            path: '/history/reusableCub',
            name: 'HistoryReusableCub',
            component: HistoryReusableCub,
        },

        //mypage
        {
            path: '/mypage',
            name: 'MyPage',
            component: MyPage,
        },


        //mypage - request meterra
        {
            path: '/mypage/requestMetr',
            name: 'RequestMetr',
            component: RequestMetr,
        },
        {
            path: '/mypage/requestMetr/success',
            name: 'RequestMetrSuccess',
            component: RequestMetrSuccess,
        },
        {
            path: '/mypage/requestMetr/fail',
            name: 'RequestMetrFail',
            component: RequestMetrFail,
        },

        //mypage - notice
        {
            path: '/mypage/notices',
            name: 'NoticeList',
            component: NoticeList,
        },

        //mypage - event
        {
            path: '/mypage/events',
            name: 'EventList',
            component: EventList,
        },
        {
            path: '/mypage/event/:idx',
            name: 'EventDetail',
            component: EventDetail,
        },

        //mypage - faq
        {
            path: '/mypage/faq/list',
            name: 'FaqList',
            component: FaqList,
        },
        {
            path: '/mypage/faq/sub/list',
            name: 'FaqSubList',
            component: FaqSubList,
        },

        //mypage - qna
        {
            path: '/mypage/qna/list',
            name: 'QnaList',
            component: QnaList,
        },
        {
            path: '/mypage/qna/detail',
            name: 'QnaDetail',
            component: QnaDetail,
        },
        {
            path: '/mypage/qna/regist',
            name: 'QnaRegist',
            component: QnaRegist,
        },

        //myinfo
        {
            path: '/mypage/myinfo',
            name: 'MyInfo',
            component: MyInfo,
        },

        //myinfo - change email
        {
            path: '/mypage/myinfo/change/email',
            name: 'ChangeEmail',
            component: ChangeEmail,
        },

        //myinfo - change phone
        {
            path: '/mypage/myinfo/change/phone',
            name: 'ChangePhone',
            component: ChangePhone,
        },
        {
            path: '/mypage/myinfo/change/phone/success',
            name: 'ChangePhoneSuccess',
            component: ChangePhoneSuccess,
        },
        {
            path: '/mypage/myinfo/change/phone/fail',
            name: 'ChangePhoneFail',
            component: ChangePhoneFail,
        },

        //myinfo - change password
        {
            path: '/mypage/myinfo/change/password',
            name: 'ChangePassword',
            component: ChangePassword,
        },
        {
            path: '/mypage/myinfo/change/password/success',
            name: 'ChangePasswordSuccess',
            component: ChangePasswordSuccess,
        },
        {
            path: '/mypage/myinfo/change/password/fail',
            name: 'ChangePasswordFail',
            component: ChangePasswordFail,
        },

        //myinfo - change pin
        {
            path: '/mypage/myinfo/change/pin/check',
            name: 'ChangePinCheck',
            component: ChangePinCheck,
        },
        {
            path: '/mypage/myinfo/change/pin/regist',
            name: 'ChangePinRegist',
            component: ChangePinRegist,
        },
        {
            path: '/mypage/myinfo/change/pin/re',
            name: 'ChangePinRegistRE',
            component: ChangePinRegistRE,
        },
        {
            path: '/mypage/myinfo/change/pin/success',
            name: 'ChangePinSuccess',
            component: ChangePinSuccess,
        },
        {
            path: '/mypage/myinfo/change/pin/restore',
            name: 'RestorePin',
            component: RestorePin,
        },

        //myinfo - regist bio
        {
            path: '/mypage/myinfo/change/bio/success',
            name: 'RegistBioSuccess',
            component: RegistBioSuccess,
        },
        {
            path: '/mypage/myinfo/change/bio/fail',
            name: 'RegistBioFail',
            component: RegistBioFail,
        },

        // //myinfo - account
        // {
        //     path: '/mypage/myinfo/accounts',
        //     name: 'AccountList',
        //     component: AccountList,
        // },
        // {
        //     path: '/mypage/myinfo/account/change/nick',
        //     name: 'AccountChangeNick',
        //     component: AccountChangeNick,
        // },
        // {
        //     path: '/mypage/myinfo/account/regist',
        //     name: 'RegistAccount',
        //     component: RegistAccount,
        // },
        // {
        //     path: '/mypage/myinfo/account/regist/auth',
        //     name: 'AuthAccount',
        //     component: AuthAccount,
        // },
        // {
        //     path: '/mypage/myinfo/account/regist/ars',
        //     name: 'AccountARS',
        //     component: AccountARS,
        // },
        // {
        //     path: '/mypage/myinfo/account/regist/ars/fail',
        //     name: 'AccountARSFail',
        //     component: AccountARSFail,
        // },
        // {
        //     path: '/mypage/myinfo/account/regist/nick',
        //     name: 'RegistAccountNick',
        //     component: RegistAccountNick,
        // },
        // {
        //     path: '/mypage/myinfo/account/regist/success',
        //     name: 'RegistAccountSuccess',
        //     component: RegistAccountSuccess,
        // },
        //
        // //myinfo - virtual account
        // {
        //     path: '/mypage/myinfo/virtual/accounts',
        //     name: 'VirtualAccountList',
        //     component: VirtualAccountList,
        // },
        // {
        //     path: '/mypage/myinfo/virtual/account/regist/success',
        //     name: 'VirtualAccountSuccess',
        //     component: VirtualAccountSuccess,
        // },

        //myinfo - card
        {
            path: '/mypage/myinfo/cards',
            name: 'CardList',
            component: CardList,
        },
        {
            path: '/mypage/myinfo/card/change/nick',
            name: 'CardChangeNick',
            component: CardChangeNick,
        },
        {
            path: '/mypage/myinfo/card/regist',
            name: 'RegistCard',
            component: RegistCard,
        },
        {
            path: '/mypage/myinfo/card/regist/nick',
            name: 'RegistCardNick',
            component: RegistCardNick,
        },
        {
            path: '/mypage/myinfo/card/regist/success',
            name: 'RegistCardSuccess',
            component: RegistCardSuccess,
        },
        {
            path: '/mypage/myinfo/card/regist/fail',
            name: 'RegistCardFail',
            component: RegistCardFail,
        },

        //myinfo - leave
        {
            path: '/mypage/myinfo/leave',
            name: 'Leave',
            component: Leave,
        },
        {
            path: '/mypage/myinfo/leave/success',
            name: 'LeaveSuccess',
            component: LeaveSuccess,
        },

        //퀵오더
        /*{
            path: '/order/around/map',
            name: 'AroundMap',
            component: AroundMap,
            props: true
        },
*/
        //검색
        {
            path: '/order/search',
            name: 'SearchForm',
            component: SearchForm,
        },
        {
            path: '/order/search/menu',
            name: 'SearchMenu',
            component: SearchMenu,
        },
        {
            path: '/order/history',
            name: 'QuickOrderHistory',
            component: QuickOrderHistory,
        },

        {
            path: '/order/history/:orderIdx(\\d+)',
            name: 'QuickOrderHistoryDetail',
            component: QuickOrderHistoryDetail,
        },

        //상품주문
        {
            path: '/order/shop/:shopIdx(\\d+)',
            name: 'ShopMenu',
            component: ShopMenu,
        },

        //상품주문 (최종)
        {
            path: '/order/shop/final',
            name: 'Order',
            component: Order,
        },
        //상품상세
        {
            path: '/order/shop/:shopIdx(\\d+)/product/:productIdx(\\d+)',
            name: 'MenuDetail',
            component: MenuDetail,
        },
        //상품상세 영양정보
        {
            path: '/order/shop/product/:productIdx(\\d+)/nutrition',
            name: 'NutritionInfo',
            component: NutritionInfo,
        },
        //상품결제페이지
        {
            path: '/order/shop/payment',
            name: 'OrderPayment',
            component: OrderPayment,
        },
        //상품결제 핀입력
        {
            path: '/order/shop/payment/pin',
            name: 'PaymentPin',
            component: PaymentPin,
            props: true,
        },
        //결제완료 페이지
        {
            path: '/order/shop/:shopIdx(\\d+)/payment/complete',
            name: 'PaymentComplete',
            component: PaymentComplete,
            props: true,
        },
        //결제실패 페이지
        {
            path: '/order/shop/:shopIdx(\\d+)/payment/failed',
            name: 'PaymentFailed',
            component: PaymentFailed,
            props: true,
        },

        //락업
        {
            path: '/lock-up',
            name: 'LockUpList',
            component: LockUpList,
            props: true,
        },

        {
            path: '/lock-up/history',
            name: 'LockUpHistory',
            component: LockUpHistory,
            props: true,
        },



        {
            path: '/test',
            name: 'Test',
            component: Test,
            props: true,
        },
    ],

    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="title">내 문의</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <div class="container">
        <div class="qna-detail pt-50">
          <div class="title">
            <p class="date">{{ regDate }} <small :class="stateClass(item.state)">{{ item.state | state }}</small></p>
            <p class="fs-16">
              {{ item.title }}
            </p>
          </div>
          <div v-show="item.state === '2'" class="answer">
            <p class="fs-16 weight-bold">답변 <small>{{ item.answerDate }}</small></p>
            <div class="mt-20" v-html="item.answer"></div>
          </div>
          <div v-show="item.state === '2'" class="bg-gray">
            <hr class="hr-4">
          </div>
          <div class="contents">
            <p class="fs-16 weight-bold">내용<small>{{ regDate }}</small></p>
            <p class="mt-20">{{ item.content }}</p>
          </div>
          <div class="p-20">
            <div class="mt-10 filebox">
              <label>첨부파일</label>
              <input type="text" class="upload-name" v-model="item.originFileName" disabled>
            </div>
            <div class="mt-10">
              <p class="desc-info">{{ item.version }}</p>
            </div>
          </div>

          <div class="row">
            <div class="button-group" v-show="item.state === '0'">
              <button class="btn l_btn btn-black" @click="goEdit()">수정</button>
              <button class="btn l_btn btn-gray ml-14" @click="goDelete()">삭제</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QnaDetail",
  data() {
    return {
      item: {
        idx: '',
        title: '',
        content: '',
        version: '',
        answer: '',
        answerDate: '',
        fileIdx: '',
        originFileName: '',
        state: '',
      },
      regDate: '',
    }
  },
  mounted() {
    this.regDate = this.$route.params.date

    this.$get(this.$QNA_DETAIL + this.$route.params.idx, 'qnadetail_mounted', (result) => {
      this.item = result.data
    }, (result) => {
      this.httpError(result.data)
    })
  },
  methods: {
    goEdit() {
      this.$router.replace({name: 'QnaRegist', params: {idx: this.$route.params.idx}})
    },
    goDelete() {
      this.$root.$refs.MTRConfirm.showDialog('문의 삭제', '해당 문의를 삭제합니다.', '확인', '취소', () => {
        this.$delete(this.$QNA_DELETE + this.$route.params.idx, 'qnadetail_godelete', () => {
          this.$router.back()
        }, (result) => {
          this.httpError(result.data)
        })
      }, () => {

      })
    },
    stateClass(value) {
      if (value === '0') {
        return 'qna-state-0'
      } else if (value === '1') {
        return 'qna-state-1'
      } else if (value === '2') {
        return 'qna-state-2'
      }
      return ''
    }
  },
  filters: {
    state: (value) => {
      if (value === '0') {
        return '답변대기'
      } else if (value === '1') {
        return '처리중'
      } else if (value === '2') {
        return '답변완료'
      }
      return ''
    },

  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

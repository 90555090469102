<template>
  <!-- 결제예정금액 표시 -->
  <!-- 결제방식 -->
  <div class="payment_detail">
    <div class="container">
      <div class="row">
        <div class="detail_section">
          <!-- 신용카드 -->
          <div>
            <div class="title">
              <h3>신용카드</h3>
            </div>
            <!-- 등록된 신용카드 -->
            <ul class="list_wrap type2">
              <li  v-for="card in cards" :key="card.idx">
                <a>
                  <div class="list card list_arrow">
                    <div class="logo_box">
                      <span class="sp-card" :class="[{'card-01' : card.name.startsWith('국민')},
                        {'card-02' : card.name.startsWith('삼성')},
                        {'card-03' : card.name.startsWith('신한')},
                        {'card-04' : card.name.startsWith('현대')},
                        {'card-05' : card.name.startsWith('롯데')},
                        {'card-06' : card.name.startsWith('하나')},
                        {'card-07' : card.name.startsWith('우리')},
                        {'card-08' : card.name.startsWith('BC')},
                        {'card-09' : card.name.startsWith('씨티')},
                        {'card-10' : card.name.startsWith('수협')},
                        {'card-11' : card.name.startsWith('농협')},
                        {'card-12' : card.name.startsWith('광주')},
                        {'card-13' : card.name.startsWith('제주')},
                        {'card-14' : card.name.startsWith('전북')},
                        {'card-15' : card.name.startsWith('비자')},
                        {'card-16' : card.name.startsWith('마스터') || card.name.startsWith('MASTER')},]"></span>
                    </div>
                    <div class="summary">
                      <p class="card_text">{{ card.title }}<em class="card_badge" v-show="card.mainCard">주카드</em></p>
                      <span class="text">{{card.alias}}</span>
                    </div>
                    <i class="icon-arrow-right"></i>
                  </div>
                </a>
              </li>

            </ul>

            <!-- //등록된 신용카드 -->
          </div>
        </div>
        <div class="detail_descript" v-if="cards.length > 0">
          <p>실시간 가격변동으로 인해 결제완료 후, 결제예정금액과 차이가 있을 수 있습니다.</p>
          <p>퀵오더는 주문완료 후 취소가 어렵습니다. 기타 사항은 해당 가맹점으로 문의 부탁드립니다.</p>
        </div>
        <!-- 결제 가능한 카드가 없습니다 화면 -->
        <div class="nodata" v-if="cards.length <= 0">
          <figure>
            <div class="img_120">
              <img src="/assets/image/none_120.png" alt="결제 가능한 카드가 없습니다.">
            </div>
            <figcaption>
              <p class="ar_title">결제 가능한 카드가 없습니다.</p>
              <p class="ar_desc">신용카드를 먼저 등록해 주세요.</p>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>




</template>

<script>
export default {
  name: "CardPayment",
  props : {
    cards : Array
  }

}
</script>

<style scoped>
/* arrow */
.icon-arrow-right { color: var(--light-gray03); font-size: 12px; }

.list_wrap.type2 { position: relative; }
.list_wrap.type2 { position: relative; }
.list_wrap.type2 li + li { padding-top: 16px; }
.list.list_arrow { padding-right: 30px; }
.list.list_arrow i { position: absolute; right: 10px; top: 50%; transform: translateY(-50%); font-size: 16px; }

</style>
<template>
  <div class="popup-wrap z-index-400 bg-white" v-if="show">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
        <span class="logo">
          <img src="/image/logo_row01.svg" alt="amis">
        </span>
          <button type="button" class="nav-btn right" @click="cancel"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap" :class="{'pt-20' : amount > 0, 'pt-50' : amount <= 0}">
      <div class="section-password">
        <div class="area-password">
          <div class="instruction">
            <div class="container">
              <div class="row">
                <div class="w-100 p-20 align-items-baseline"
                     v-show="title !== '생체 인증 등록' && title !== 'PIN 확인'">
                  <p class="pt-15 pb-30 confirm-info-visual">
                    <strong v-show="shopName" class="weight-bold fs-16">{{ shopName }}</strong>
                    <strong v-show="amount > 0" class="confirm-tit mt-10">{{ amount | numberWithCommasAll }}<em
                        class="unit">{{ unit }}</em></strong>

                    <span v-show="amount > 0" class="weight-semi-bold fs-18 mt-10">= {{ Math.floor(amount * rate) | numberWithCommasAll }}원 <em class="fs-16">(1{{ unit }}={{ rate | numberWithCommas }}원)</em></span>
                    <i v-show="subAmount > 0" class="point-color-green fs-20">+ {{
                        subAmount | numberWithCommas
                      }}{{ subUnit }}</i>
                    <!-- 수수료 타입(수수료율) -->
                    <i v-if="feeAmount > 0 && feeType === '0'" class="point-color-green mt-10 fs-20">- {{
                        feeAmount | numberWithCommas
                      }}{{ subUnit }} (수수료 {{ fee }}%)</i>
                    <!-- 수수료 타입(고정금액) -->
                    <i v-if="feeAmount > 0 && feeType === '1'" class="point-color-green mt-10 fs-20">- {{
                        feeAmount | numberWithCommas
                      }}{{ subUnit }} (수수료)</i>
                    <small v-show="title !== '결제'" class="fs-16 mt-10">{{ title }}하시겠습니까?</small>
                  </p>
                  <h4>핀번호를 입력해 주세요.</h4>
                </div>
                <div v-show="title === '생체 인증 등록' || title === 'PIN 확인'">
                  <h3>PIN</h3>
                  <h4>핀번호를 입력해 주세요.</h4>
                </div>

              </div>
            </div>
          </div>
          <div class="password">
            <div class="container">
              <div class="row">
                <span class="blind">비밀번호 6자리는 입력하면 자동전환됩니다.</span>
                <!-- TODO : 비밀번호 입력 시 .on 클래스 추가 -->
                <span id="key_1" class="word" :class="{'on' : pin.length >= 1}"></span>
                <span id="key_2" class="word" :class="{'on' : pin.length >= 2}"></span>
                <span id="key_3" class="word" :class="{'on' : pin.length >= 3}"></span>
                <span id="key_4" class="word" :class="{'on' : pin.length >= 4}"></span>
                <span id="key_5" class="word" :class="{'on' : pin.length >= 5}"></span>
                <span id="key_6" class="word" :class="{'on' : pin.length >= 6}"></span>
              </div>
            </div>
          </div>
          <button v-show="failCount >= 0" class="reset-pin-btn" @click="$router.push({name:'RestorePin'})">핀번호를
            잊어버렸어요.<i
                class="icon-arrow-right arrow-dgray pl-5"></i></button>
        </div>
        <div class="area-keyboard">
          <div class="container">
            <table class="keyboard">
              <tbody>
              <tr>
                <td>
                  <button type="button" class="key" @click="numBtn('1')">
                    <span>1</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('2')">
                    <span>2</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('3')">
                    <span>3</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="key" @click="numBtn('4')">
                    <span>4</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('5')">
                    <span>5</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('6')">
                    <span>6</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button type="button" class="key" @click="numBtn('7')">
                    <span>7</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('8')">
                    <span>8</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('9')">
                    <span>9</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button v-show="true" type="button" class="key" @click="cancel()">
                    <span class="cancel">취소</span>
                  </button>
<!--                  <button v-show="!showClose" class="key">
                    <span class="cancel"></span>
                  </button>-->
                </td>
                <td>
                  <button type="button" class="key" @click="numBtn('0')">
                    <span>0</span>
                  </button>
                </td>
                <td>
                  <button type="button" class="key" @click="backspace()">
                    <span class="icon delete">지우기</span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PinCheck",
  props: {
    title: {
      required: false,
    },
    shopName: {
      required: false,
    },
    amount: {
      required: false,
    },
    unit: {
      required: false,
    },
    subAmount: {
      required: false,
    },
    subUnit: {
      required: false,
    },
    feeType: {  /*수수료 타입*/
      required: false,
    },
    feeAmount: {  /*수수료 금액*/
      required: false,
    },
    fee: {  /*수수료 비율*/
      required : false,
    },
    showClose: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      callback: null,
      usdPrice: 0.1,
      pin: '',
      failCount: 0,
      isLoading: false,
      rate: 100,
    }
  },
  mounted() {

    //reap chain 시세 받아오기
    this.$get(this.$TOKEN_PRICE, 'pincheck_mounted', (result) => {
      this.usdPrice = result.data
    }, (result) => {
      this.httpError(result.data)
    })
    window.addEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
    this.$on(this.$EVENT_CLOSE_PINCHECK, this.close)

  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
    this.$off(this.$EVENT_CLOSE_PINCHECK)
  },
  methods: {
    showPin(callback) {
      this.callback = callback
      this.pin = ''
      this.failCount = 0
      this.show = true
      if (localStorage.getItem('useLocalBio') === 'true') {
        window.requestBio()
      }

      let rateUrl = this.$TOKEN_PRICE
      console.log('wqdwq ; ' + this.unit)
      if (this.unit !== 'AMIS') {
        rateUrl = this.$ETH_PRICE
      }
      this.$get(rateUrl, 'sendpaysuccess_mounted', (result) => {
        this.rate = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    close() {
      this.pin = ''
      this.failCount = 0
      this.show = false
      this.isLoading = false
    },
    numBtn(_num) {
      this.pin = this.pin + _num
      if (this.pin.length === 6) {
        console.log('222')
        this.checkPin()
      }
    },
    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },
    cancel() {
      if (localStorage.getItem('firstPinChecked') === '0') {
        this.notify('error', '핀인증을 완료하여 주세요.', false)
      } else {
        this.close()
      }
    },
    bioResult(e) {
      let pin = e.detail
      if (localStorage.getItem('useLocalBio') === 'true') {
        if (pin.length === 6) {
          this.pin = pin
          this.checkPin()
        } else {
          this.notify('error', '생체 인증에 실패 했습니다.')
        }
      }
    },
    checkPin() {
      let formData = new FormData()
      formData.append('pin', this.pin)
      this.$post(this.$AUTH_CHECK_PIN, 'pincheck_numbtn', formData, () => {
        window.setPin(this.pin)
        this.isLoading = true
        this.callback()
      }, (result) => {
        console.log('failCount : ' + this.failCount)
        this.httpError(result.data)
        this.isLoading = false
        this.pin = ''
        this.failCount++
      })
    }
  }
}
</script>

<style scoped>
.header-group .logo { position: relative; display: inline-block; height: 20px; width: 70px; }
</style>

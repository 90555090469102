<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
        <span class="logo">
          <img src="/image/logo_row01.svg" alt="amis">
        </span>
        </div>
      </div>
    </div>
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row confirm-area-bottom">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <p class="confirm-subtit">탈퇴가 완료되었습니다.</p>
              <p class="item-desc fs-16 mt-10">그동안 아미스 서비스를<br/>이용해주셔서 감사합니다.<br/>더욱 좋은 모습으로 찾아 뵙겠습니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="$router.replace({ name: 'BeforeLogin' })">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeaveSuccess"
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
.header-group .logo { position: relative; display: inline-block; height: 20px; width: 70px; }
</style>
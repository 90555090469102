<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="title">결제하기</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="main-inner">
            <div class="input-close">
              <MTRInput
                  :type="'text'"
                  :placeholder="'결제 금액을 입력해주세요.'"
                  :model="metrAmount"
                  @update="(_model) => {this.metrAmount=_model}"
                  :input-mode="'numeric'"
                  :disable="true"
              />
            </div>
            <div class="price-output mt-20">
              <div class="data-value point-data">
                <span class="data-tit">아미스</span>
                <span class="point-color-black">{{ payBalance | numberWithCommas }}<em class="unit">AMIS</em></span>
              </div>
              <div class="data-value point-data">
                <span class="data-tit"></span>
                <span class="weight-semi-bold">{{ payBalance * rate | numberWithCommas }}원 <em class="fs-12">(1AMIS={{ rate | numberWithCommas }}원)</em></span>
              </div>
            </div>
            <div class="input-close mt-30 d-flex-row">
              <MTRInput
                  class="mr-10"
                  style="width: calc(100% - 109px);"
                  :type="'number'"
                  :placeholder="'사용 리워드를 입력해주세요.'"
                  :model="rewardAmount"
                  @update="(_model) => {this.rewardAmount=_model}"
                  :input-mode="'numeric'"
                  :is-big="true"
              />
              <button type="button" class="l_btn btn-black" @click="useAll()">전액사용</button>
            </div>
            <div class="price-output mt-20">
              <div class="data-value point-data">
                <span class="data-tit">리워드</span>
                <span class="point-color-green">{{ rewardBalance | numberWithCommas }}<em class="unit">AMIS</em></span>
              </div>
            </div>
            <div class="d-flex-row mt-20">
              <div class="radio">
                <input type="radio" id="autoChecks" v-model="isUseAllReward" @click="isUseAllReward = !isUseAllReward">
                <label for="autoChecks">항상 전액 사용</label>
              </div>
<!--              <button class="ml-auto text-c">충전하기<i class="icon-arrow-right arrow-dgray pl-5"></i></button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <div class="container">
        <button class="l_btn_full btn-black" @click="goPayment()">결제하기</button>
      </div>
    </div>

    <pin-check
        ref="PinCheck"
        :title="'결제'"
        :amount="payAmount"
        :unit="'AMIS'"
        :sub-amount="rewardAmount"
        :sub-unit="'AMIS'"
        :shop-name="shopName"
    />
  </div>
</template>

<script>
import MTRInput from "../common/MTRInput";
import PinCheck from "../common/PinCheck";

export default {
  name: "PaymentInputAmount",
  components: {
    PinCheck,
    MTRInput
  },
  data() {
    return {
      metrAmount:'',
      rewardAmount: '',

      amount: '',
      payAmount:'',
      payBalance: '0',
      rewardBalance: '0',
      isUseAllReward: false,
      paymentQR: '',
      shopName: '',
      isOutside: 'false',
      rate: 100,
    }
  },
  mounted() {
    this.paymentQR = this.$route.params.paymentQR
    this.amount = this.$route.params.amount
    if (this.$route.params.isOutside) {
      this.isOutside = this.$route.params.isOutside
    }
    this.payAmount = this.amount
    //qr로 상점 정보 필요 => 이전화면에서 받아오면 굳이 추가로 처리할 필요없음


    this.isUseAllReward = (localStorage.getItem('isUseAllReward') === 'true')
    this.$get(this.$WALLET_REWARD_BALANCE, 'paymentinputamount_mounted', (result) => {
      this.rewardBalance = result.data * 1

      if (this.isUseAllReward) {
        if (this.rewardBalance > 0) {
          this.rewardAmount = this.rewardBalance
          this.payAmount = this.amount - this.rewardAmount
        }

      }
    }, (result) => {
      this.httpError(result.data)
    })

    this.$get(this.$WALLET_REAPPAY_BALANCE, 'paymentinputamount_mounted', (result) => {
      this.payBalance = result.data * 1
    }, (result) => {
      this.httpError(result.data)
    })

    this.$get(this.$TOKEN_PRICE, 'sendpaysuccess_mounted', (result) => {
      this.rate = result.data
    }, (result) => {
      this.httpError(result.data)
    })
  },
  methods: {
    useAll() {
      this.rewardAmount = this.rewardBalance
      this.payAmount = this.amount - this.rewardAmount
    },
    goPayment() {
      if (!/^[0-9-][0-9,.]*$/.test(this.payAmount)) {
        this.notify('error', '금액을 입력해주세요.')
        return
      }
      if (this.paymentQR === '') {
        this.notify('error', '결제 정보를 가져오지 못했습니다.')
        return
      }
      this.$refs.PinCheck.showPin(() => {
        let formData = new FormData()
        //파라메터 추가 필요
        formData.append('amount', this.payAmount)
        formData.append('qr', this.paymentQR)
        if (this.rewardAmount * 1 > 0) {
          formData.append('reward', this.rewardAmount)
        }

        this.$post(this.$PAYMENT_PAYMENT_PAYNARA_QR, 'paymentinputamount_gopayment', formData, (result) => {
          let point = result.data.data.point
          this.shopName = result.data.data.shopName
          this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
          this.$router.replace({name:'PaymentSuccess', params:{
              paymentQR: this.paymentQR,
              rewardAmount: this.rewardAmount,
              payAmount: this.payAmount,
              shopName: this.shopName,
              point: point,
              isOutside: this.isOutside
            }})
        }, () => {
          this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
          this.$router.replace({name:'PaymentFail', params: {
              paymentQR: this.paymentQR,
            }})
        })
      })
    }
  },
  watch: {
    isUseAllReward() {
      if (this.isUseAllReward) {
        localStorage.setItem('isUseAllReward', 'true')
      } else {
        localStorage.setItem('isUseAllReward', 'false')
      }
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">이벤트</span>
        </div>
        <div class="header-wrap pay-tab mt-50">
          <div @click="getItems(0)" :class="{'active' : type === 0}">진행중 이벤트</div>
          <div @click="getItems(1)" :class="{'active' : type === 1}">종료된 이벤트</div>
        </div>
      </div>
    </div>
    <div class="notice-content-wrap pt-110">
      <div class="container">
        <!-- TODO : 이벤트 없을 경우 -->
        <div class="alram-wrap" v-if="loaded && items.length === 0">
          <div class="list-none">
            <i class="bg_symbol_15_op mb-20"></i>
            <p class="list-desc">등록된 이벤트가 없습니다.</p>
          </div>
        </div>
        <!-- TODO : 이벤트 있을 경우 -->
        <div class="notice-list" v-if="items.length > 0">
          <ul>
            <li v-for="(item, index) in items" :key="index">
              <a @click="goDetail(item.idx)">
                <div class="event-area">
                  <img :src="item.image ? removeHost(item.image.imagePath) : '/image/photo_preparing_shop.svg'">
                </div>
                <p class="mt-20 fs-16">
                  {{ item.title }}<small v-show="item.newNotice">NEW</small>
                </p>
                <p class="notice-date">{{ $moment(item.timeS, 'YYYYMMDD') | moment('YY.MM.DD')}}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventList",
  data() {
    return {
      items: [],
      type: 0, //0 : 진행중, 1 : 종료
      loaded: false,
    }
  },
  mounted() {
    this.getItems(this.type)
  },
  methods: {
    getItems(_type) {
      this.type = _type
      this.items = []
      this.loaded = false
      this.$get(this.$EVENT_LIST + _type, 'noticelist_getitems', (result) => {
        this.items = result.data
        this.loaded = true
      }, (result) => {
        this.httpError(result.data)
        this.loaded = true
      })
    },
    goDetail(_no) {
      this.$router.push({name:'EventDetail',params:{idx:_no}})
    }
  }
}
</script>

<style scoped>
.wrapper-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

</style>

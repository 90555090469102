<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
          <span class="title">신용카드 등록</span>
        </div>
      </div>
    </div>
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="main-inner">
            <div class="select-wrap">
              <button class="select-form" @click="showSetting = true">{{ selectedType.name }}</button>
            </div>
            <div class="input-close mt-20">
              <p class="regist-card-tit">카드번호</p>
              <div class="regist-card-no">
                <input inputmode="numeric" type="number" class="pl-20" placeholder="0000"
                       v-model="cardNo0" ref="cardNo0" maxlength="4"/>
                <small class="card-line">—</small>
                <input inputmode="numeric" type="number" placeholder="0000"
                       v-model="cardNo1" ref="cardNo1" maxlength="4"/>
                <small class="card-line">—</small>
                <input inputmode="numeric" type="number" placeholder="0000"
                       v-model="cardNo2" ref="cardNo2" maxlength="4"/>
                <small class="card-line">—</small>
                <input inputmode="numeric" type="number" class="pr-20" placeholder="0000"
                       v-model="cardNo3" ref="cardNo3" maxlength="4"/>
              </div>
            </div>
            <div class="input-close mt-20 d-flex-row">
              <div class="w-100 mr-10">
                <p class="regist-card-tit">유효기간</p>
                <MTRInput
                    :type="'text'"
                    :placeholder="'MMYY'"
                    :model="expireDate"
                    @update="(_model) => {this.expireDate=_model}"
                    :max-length="4"
                    :input-mode="'numeric'"
                />
              </div>
              <div class="w-100 ml-10">
                <p class="regist-card-tit">카드 비밀번호</p>
                <MTRInput
                    :type="'password'"
                    :placeholder="'비밀번호 앞 2자리'"
                    :model="password"
                    @update="(_model) => {this.password=_model}"
                    :input-mode="'numeric'"
                    :max-length="2"
                />
              </div>
            </div>
            <div class="mt-20 w-100">
              <p class="regist-card-tit" v-show="selectedType.type === '0'">생년월일</p>
              <p class="regist-card-tit" v-show="selectedType.type === '1'">사업자 등록번호</p>
              <MTRInput
                  :type="'text'"
                  :placeholder="selectedType.type === '0' ? '생년월일 8자리를 입력해 주세요.' : '사업자등록번호 10자리를 입력해 주세요.'"
                  :model="info"
                  @update="(_model) => {this.info=_model}"
                  :max-length="selectedType.type === '0' ? 8 : 10"
                  :input-mode="'numeric'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <div class="container">
        <button class="l_btn_full btn-black" :class="{'btn-disable' : !isEnterAll}"  @click="goNext()" :disabled="!isEnterAll">다음</button>
      </div>
    </div>
    <card-setting
        v-show="showSetting"
        :conditions="typeList"
        :title="'유형 선택'"
        @selected="setSelectedCondition"
        @close="showSetting=false"
    />
  </div>
</template>

<script>
import MTRInput from "../../../common/MTRInput";
import CardSetting from "../CardSetting";

export default {
  name: "RegistCard",
  components: {
    CardSetting,
    MTRInput
  },
  data() {
    return {
      showSetting: false,
      selectedType: {
        type: '0',
        name: '개인',
      },
      typeList: [
        {
          type: '0',
          name: '개인',
        },
        {
          type: '1',
          name: '법인',
        },
      ],
      cardNo0: '',
      cardNo1: '',
      cardNo2: '',
      cardNo3: '',
      expireDate: '',
      info: '',
      password: '',
      isEnterAll: false,
    }
  },
  mounted() {
    // test start
    // this.$router.replace({name: 'RegistCardNick'})
    // this.$router.replace({name: 'RegistCardFail'})
    // test end
    this.checkNext()
  },
  methods: {
    setSelectedCondition(_condition) {
      this.showSetting = false
      this.selectedType = _condition
    },
    checkNext() {
      if (this.cardNo0.length < 4
          || this.cardNo1.length < 4
          || this.cardNo2.length < 4
          || this.cardNo3.length < 4
          || this.password.length < 2
          || this.expireDate.length < 2
          || this.info.length < 8) {
        this.isEnterAll = false
      } else if (this.cardNo0.length >= 4
          && this.cardNo1.length >= 4
          && this.cardNo2.length >= 4
          && this.cardNo3.length >= 4
          && this.password.length >= 2
          && this.expireDate.length >= 2
          && this.info.length >= 8) {
        this.isEnterAll = true
      }

    },
    goNext() {
      let number = '' + this.cardNo0 + this.cardNo1 + this.cardNo2 + this.cardNo3
      if (number.length < 15 || number.length > 16) { /*카드번호 15-16자리*/
        this.notify('error', '카드 번호를 정확하게 입력해주세요.', false)
        return
      }
      if (this.expireDate.length !== 4) {
        this.notify('error', '유효기간을 정확하게 입력해주세요.', false)
        return
      }
      if (this.password.length !== 2) {
        this.notify('error', '비밀번호를 정확하게 입력해주세요.', false)
        return
      }
      if (this.selectedType.type === '0' && this.info.length !== 8) {
        this.notify('error', '생년월일을 정확하게 입력해주세요.', false)
        return
      }
      if (this.selectedType.type === '1' && this.info.length !== 10) {
        this.notify('error', '사업자번호를 정확하게 입력해주세요.', false)
        return
      }

      let formData = new FormData()
      formData.append('expireDate', this.expireDate)
      formData.append('info', this.info)
      formData.append('password', this.password)
      formData.append('number', number)

      //test
      this.$post(this.$CREDITCARD_REGIST, 'registcard_gonext', formData, (result) => {
        let number = result.data.number
        let cardIdx = result.data.cardIdx
        this.$router.replace({name: 'RegistCardNick', params: {number: number, cardIdx: cardIdx}})
      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({name: 'RegistCardFail'})
      })
    }
  },
  watch: {
    cardNo0() {
      if (this.cardNo0.length > 4) {
        this.cardNo0 = this.cardNo0.substr(0, 4)
      } else if (this.cardNo0.length === 4) {
        this.$refs.cardNo1.focus()
      }
      this.checkNext()
    },
    cardNo1() {
      if (this.cardNo1.length > 4) {
        this.cardNo1 = this.cardNo1.substr(0, 4)
      } else if (this.cardNo1.length === 4) {
        this.$refs.cardNo2.focus()
      }
      this.checkNext()
    },
    cardNo2() {
      if (this.cardNo2.length > 4) {
        this.cardNo2 = this.cardNo2.substr(0, 4)
      } else if (this.cardNo2.length === 4) {
        this.$refs.cardNo3.focus()
      }
      this.checkNext()
    },
    cardNo3() {
      if (this.cardNo3.length > 4) {
        this.cardNo3 = this.cardNo3.substr(0, 4)
      }
      this.checkNext()
    },
    expireDate() {
      this.checkNext()
    },
    info() {
      this.checkNext()
    },
    password() {
      this.checkNext()
    },
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
.select-form {
  text-align: left;
}
</style>

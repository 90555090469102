import axios from 'axios';
import https from 'https';
/*

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken_reusable'),
        },
        timeout: 60000
    }
}
let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}
*/

export default Object.freeze({
    install(Vue) {
        if (process.env.VUE_APP_DEBUG === '1') {
            const httpsAgent = new https.Agent({rejectUnauthorized: false});
            axios.defaults.httpsAgent = httpsAgent
        }
        Vue.prototype.$REUSABLE_DOMAIN = process.env.VUE_APP_REUSABLE_DOMAIN

        Vue.prototype.$REUSABLE_AUTH_LOGIN = "/auth/login"
        Vue.prototype.$REUSABLE_AUTH_LOGOUT = "/auth/logout"
        Vue.prototype.$REUSABLE_AUTH_REFRESHTOKEN = "/auth/refreshToken"

        Vue.prototype.$REUSABLE_PAYMENT_HISTORY = "/payment/history" // + type(0 : 대여, 1 : 반납)
        Vue.prototype.$REUSABLE_PAYMENT_QR = "/payment/qr"

        Vue.prototype.$REUSABLE_CUB_LIST = "/use/products_with_count"
        Vue.prototype.$REUSABLE_CUB_DETAIL = "/use/products_with_count_" // + productQr
        Vue.prototype.$REUSABLE_TOTAL_COUNT = "/use/total_count"

        // eslint-disable-next-line no-unused-vars
        Vue.prototype.$reusable_post = function (callUrl, caller, postData, success, fail) {
            /*if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken_reusable')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.post(this.$REUSABLE_DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })*/

            success({data:[]})
        }

        // eslint-disable-next-line no-unused-vars
        Vue.prototype.$reusable_put = function (callUrl, caller, postData, success, fail) {
            /*if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken_reusable')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(this.$REUSABLE_DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })*/
            success({data:[]})
        }

        // eslint-disable-next-line no-unused-vars
        Vue.prototype.$reusable_delete = function (callUrl, caller, success, fail) {
            /*if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken_reusable')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(this.$REUSABLE_DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })*/

            success({data:[]})
        }

        // eslint-disable-next-line no-unused-vars
        Vue.prototype.$reusable_get = function (callUrl, caller, success, fail) {
            /*if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken_reusable')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(this.$REUSABLE_DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })*/

            success({data:[]})
        }
    }
})


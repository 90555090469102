<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <h1><img src="/assets/image/header_logo.png" alt="아미스"></h1>
          <button type="button" class="nav-btn right" @click="$router.replace({name : 'Main'})"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-50">
      <!-- TODO: 높이 값에 따라 클래스 type이 다릅니다. -->
      <div class="guide_alram type2">
        <div class="alram_inner">
          <figure>
            <div class="img_150">
              <img src="/assets/image/symbol_150.png" alt="결제가 완료되었습니다.">
            </div>
            <figcaption>
              <p class="ar_title">결제가 완료되었습니다.</p>
              <p class="ar_desc" v-if="metrPointComment !== ''">적립 리워드는 <b>{{ metrPointComment }}AMIS</b> 입니다.</p>
            </figcaption>
          </figure>
        </div>
      </div>
      <div class="gudie_list type1">
        <div class="container">
          <div class="row">
            <dl>
              <dt>결제일시</dt>
              <dd>{{ today }}</dd>
            </dl>
            <dl>
              <dt>주문매장</dt>
              <dd>{{ shopName }}</dd>
            </dl>
            <dl>
              <dt>주문메뉴</dt>
              <dd>{{ orderMenuName }}</dd>
            </dl>
            <dl>
              <dt>결제금액</dt>
              <dd v-if="isCardPayment">
                <strong>31,490원</strong>
              </dd>

              <dd v-if="!isCardPayment">
                <strong>{{ orderPrice.toLocaleString('ko-KR') }}Metr</strong>
                <em class="unit1">(1Metr={{ metrToKrw }}원)</em>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="bottom_wrap">
        <div class="alram_inner">
          <div class="button-group">
            <button class="outline_l_btn btn-black" @click="goMain">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentComplete",
  props : {
    isCardPayment : Boolean,
    metrPoint : String,
  },

  data() {
    return {
      metrPointComment : '',
      today : '',
      shopName : '',
      orderMenuName : '',
      orderPrice : '0',

      krwToMetr : 0,
      metrToKrw : 0,
    }
  },
  mounted() {

    this.shopName = this.$store.getters.getShopInfo.shopName
    this.orderMenuName = this.$store.getters.getOrderMenuName;

    if(this.shopName) {
      if (!this.isCardPayment) {
        this.metrToKrw = this.$store.getters.getMetrToKrw
        this.krwToMetr = this.$store.getters.getKrwToMetr
        this.orderPrice = this.krwToMetr * this.$store.getters.getTotalPrice
      }
    }



    let now = new Date()
    let month = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1
    let day = now.getDate()  < 10 ? '0' + now.getDate() : now.getDate()

    let hour = now.getHours()  < 10 ? '0' + now.getHours() : now.getHours()
    let minute = now.getMinutes()  < 10 ? '0' + now.getMinutes() : now.getMinutes()
    let second = now.getSeconds()  < 10 ? '0' + now.getSeconds() : now.getSeconds()

    this.today = now.getFullYear() + '.' + month + '.' + day
        + ' ' + hour + ':' + minute + ':' + second

    if (this.metrPoint !== '') {
      if (this.metrPoint === '0') {
        this.metrPointComment = '0'
      } else {
        this.metrPointComment = '+' + this.metrPoint
      }
    }
  },

  methods : {
    initCart() {
      this.$store.commit('initAllState')
      this.$store.commit('setShopInfo', {
        shopIdx : this.shopIdx,
        shopName : this.shopName,
      })
      this.isUseCart = false
    },

    goMain(){
      this.initCart()
      this.$router.replace({name : 'Main'})
    }
  },

}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
.header-wrap .header-group h1 { display: inline-block; width: 110px; height: auto; }
</style>
<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="title" v-if="token === 'AMIS'">아미스 출금신청</span>
          <span class="title" v-else-if="token === 'ETH'">이더리움 출금신청</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <div class="input-close">
              <MTRInput
                  :type="'text'"
                  :placeholder="'수량을 입력해주세요.'"
                  :model="amount"
                  @update="(_model) => {this.amount=_model}"
                  :input-mode="'decimal'"
              />
            </div>
            <div class="data-value point-data mt-20">
              <span class="data-tit">KRW</span>
              <span class="point-color-black">= {{ amount * usdPrice | numberWithCommas }}<em
                  class="unit"> KRW</em></span>
            </div>
            <div class="btn-group">
              <button class="outline_l_btn btn-outline-black" @click="getMaxAmount()">MAX</button>
            </div>
            <div class="data-value point-data mt-20" v-if="token === 'AMIS'">
              <span class="data-tit">아미스</span>
              <span class="point-color-black">{{ balance | numberWithCommas | rewardDecimal }}<em class="unit2">.{{ getMetr8DecimalPlace(balance) }}</em><em class="unit"> AMIS</em></span>
            </div>

            <div class="data-value point-data mt-20" v-else-if="token === 'ETH'">
              <span class="data-tit">이더리움</span>
              <span class="point-color-black">{{ balance | numberWithCommas | rewardDecimal }}<em class="unit2">.{{ getMetr8DecimalPlace(balance) }}</em><em class="unit"> ETH</em></span>
            </div>

            <div class="d-flex-row mt-15">
              <MTRInput
                  class="mr-10"
                  :type="'text'"
                  :placeholder="'받는 주소를 입력해주세요.'"
                  :model="address"
                  @update="(_model) => {this.address=_model}"
              />
              <button class="l_btn btn-black" @click="openQR()"><i class="icon-qr"></i></button>
            </div>
            <div class="leave-content-detail mt-20">
              <i>•</i>
              <span>수량, 주소가 정확한지 확인하세요.</span>
            </div>
            <div class="leave-content-detail">
              <i>•</i>
              <span>잘못된 주소로 출금을 할 경우 회수가 어렵습니다.</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn btn-black" :class="{'btn-disable' : !(amount && address)}" :disabled="!(amount && address)" @click="pinCheck()">출금하기</button>
        </div>
      </div>
    </div>

    <pin-check
        ref="PinCheck"
        :title="'출금 신청'"
        :amount="amount"
        :unit="token"
        :sub-amount="0"
        :sub-unit="''"
    />
  </div>
</template>

<script>
import MTRInput from "../../common/MTRInput";
import PinCheck from "../../common/PinCheck";
export default {
  name: "RequestMetr",
  components: {
    PinCheck,
    MTRInput
  },
  data() {
    return {
      accounts: [],
      amount: '',
      balance: '0',
      address: '',
      showAccounts: false,
      usdPrice: 0.1,  //TODO 이전에는 프로젝트에서는 usd 를 썼겠지만 아미스에서는 아미스 시세를 가져옴
      fees: 0,
      token : 'AMIS',
    }
  },
  mounted() {
    let paramToken = this.$route.query.token
    if (paramToken) {
      this.token = paramToken
    }

    let rateUrl = this.token === 'AMIS' ? this.$TOKEN_PRICE : this.$ETH_PRICE
    //아미스나 이더 시세 받아오기
    this.$get(rateUrl, 'requestchain_mounted', (result) => {
      this.usdPrice = result.data
    }, (result) => {
      this.httpError(result.data)
    })

    let balanceUrl;
    console.log('paramToken : ' + this.token)
    if (this.token === 'AMIS') {
      balanceUrl = this.$WALLET_REAPCHAIN_BALANCE
    } else {
      balanceUrl = this.$WALLET_ETH_BALANCE
    }

    //test
    this.$get(balanceUrl, 'requestchain_mounted', (result) => {
      this.balance = result.data
    }, (result) => {
      this.httpError(result.data)
    })

    //fees  아직까지는 수수료가 0임
    /*this.$get(this.$REAP_FEES, 'requestchain_mounted', (result) => {
      this.fees = result.data
    }, (result) => {
      this.httpError(result.data)
    })*/

    window.addEventListener(this.$EVENT_QR_STRING, this.setQr)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_QR_STRING, this.setQr)
  },
  methods: {
    getMaxAmount() {
      if (this.balance - this.fees < 0) {
        this.amount = '0'
        return
      }
      this.amount = this.balance - this.fees
    },
    pinCheck() {
      if (!/^[0-9-][0-9,.]*$/.test(this.amount)) {
        this.notify('error', '수량을 정확하게 입력해주세요.')
        return
      }
      if (this.amount * 1 > this.balance - this.fees) {
        this.notify('error', '잔고가 부족합니다.')
        return
      }
      if (this.amount * 1 <= this.fees * 1) {
        this.notify('error', '최소한 수수료(' + this.fees + ' AMIS) 보다 큰 금액을 입력해야 합니다.')
        return
      }
      if (this.address === '') {
        this.notify('error', '주소를 정확하게 입력해주세요.')
        return
      }
      if (!/^0x[a-fA-F0-9]{40}$/.test(this.address)) {
        this.notify('error', '지원되는 주소 방식이 아닙니다.')
        return
      }

      let tokenParam = this.token === 'AMIS' ? 'AMIS' : 'ETHEREUM'
      console.log('cur usd :  ' + this.usdPrice)
      this.$refs.PinCheck.showPin(() => {
        let formData = new FormData()
        //test
        formData.append('toAddress', this.address)
        formData.append('amount', this.amount)
        formData.append('tokenType', tokenParam)
        this.$isLoading(true)
        this.$post(this.$WALLET_REAPCHAIN_WITHDRAW, 'requestchain_pincheck', formData, () => {
          this.$isLoading(false)
          this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
          this.$router.replace({
            name: 'RequestMetrSuccess',
            params: {amount: this.amount, usdPrice: (this.usdPrice + ''), address: this.address, balance : this.balance},
            query: {token : this.token}
          })
        }, (result) => {
          this.$isLoading(false)
          this.httpError(result.data)
          this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
          this.$router.replace({name: 'RequestMetrFail', params: {reason: result.data.message}})
        })
      })
    },
    openQR() {
      if (this.token === 'AMIS') {
        window.openQRReader('아미스 출금신청')
      } else if (this.token === 'ETH') {
        window.openQRReader('이더리움 출금신청')
      }
    },
    setQr(e) {
      let qr = e.detail
      this.address = qr
    }
  },
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap main-bg">
    <div class="header-wrap header-dropshadow">
      <div class="container">
        <div class="header-group">
          <span class="title">퀵오더 내역</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
        <div class="filter-group">
          <div class="date-area">
            <button type="button" class="next-btn" @click="increaseMonth(false)"><i class="icon-arrow-left"></i></button>
            <span class="date-range">{{ year }}.{{ month }}</span>
            <button type="button" class="next-btn disabled" @click="increaseMonth(true)"><i class="icon-arrow-right" :style="{'--stop' : stopColor}"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrap" :class="{'pt-100' : orderList.length > 0}">
      <div class="container">
        <div class="row">
          <div class="list_wrap type1">

            <article class="slot type3 slot-history" v-for="order in orderList" :key="order.idx" @click="$router.push({name : 'QuickOrderHistoryDetail', params : {orderIdx : order.idx}})">
              <div class="order_head">
                <div>
                  <p class="order_tit"><b class="time">{{ order.date.split('T')[0] + ' ' + order.date.split('T')[1]}}</b></p>
                </div>

                <em class="shop_state step1" v-if="order.state === '0'">결제완료</em>
                <em class="shop_state step2" v-else-if="order.state === '1'">접수완료</em>
                <em class="shop_state step4" v-else-if="order.state === '2'">대기중</em>
                <em class="shop_state step3" v-else-if="order.state === '3'">완료</em>

              </div>
              <div class="menu_summary type2 clear pt-15">
                <div class="left">
                  <img class="munu_img" :src="removeHost(order.img)" alt="">
                </div>
                <div class="right">
                  <span class="od_store">{{ order.shopName }}</span>
                  <strong class="ellipsis2">{{ order.productName }}</strong>
                </div>
              </div>
              <div class="order_btn">
                <button class="m_btn m_btn_full btn-line-black" v-if="order.state === '3'">주문상세 <i class="icon-arrow-right pl-5" style="color:var(--meterra-black);"></i> </button>
                <button class="m_btn m_btn_full btn-primary" v-else>주문상세 <i class="icon-arrow-right pl-5" style="color:var(--meterra-black);"></i> </button>
              </div>
            </article>



          </div>
        </div>
      </div>

    </div>

    <div class="wrapper-center" v-if="orderList.length <= 0">
      <!-- TODO : 내역이 없을 경우 -->
      <div class="alram-wrap">
        <div class="list-none">
          <i class="bg_symbol_15_op mb-20"></i>
          <p class="list-desc">내역이 없습니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickOrderHistory",
  data() {
    return {
      orderList : [],
      year : 0,
      month : 0,
      currentYear : 0,
      currentMonth : 0,

      page : 1,
      size : 1000000,
      now : new Date(),

      stopColor : '#DADADA',
      isStopIncrement : true,
    }
  },

  created() {
    this.getCurrentDate()
    this.getOrderList()
  },

  methods : {
    getCurrentDate() {
      this.year = this.now.getFullYear()
      this.month = this.now.getMonth() + 1

      this.currentYear = this.year
      this.currentMonth = this.month
    },

    getOrderList() {

      this.$quick_order_get(this.$QUICK_ORDER_HISTORY + '?year=' + this.year + '&month=' + this.month +
          '&page=' + this.page + '&size=' + this.size, 'quickOrderHistory', (result) => {
        this.orderList = result.data
        //this.setStorageQuickOrderToken(result)
      }, (result) => {
        this.httpError(result.data)
      })
    },

    increaseYear(isIncrement) {
      if (isIncrement) {
        this.year++
      } else {
        this.year--
      }
    },

    increaseMonth(isIncrement) {

      if (isIncrement) {

        if (!this.isStopIncrement) {
          if ((this.month + 1) > 12) {
            this.month = 1
            this.year++
          } else {
            this.month++

            if (this.currentYear <= this.year) {
              if (this.currentMonth <= this.month) {
                this.stopColor = '#DADADA'
                this.isStopIncrement = true
              }
            }

          }
        }

      } else {
        this.stopColor = ''
        this.isStopIncrement = false

        if ((this.month - 1) <= 0) {
          this.month = 12
          this.year--
        } else {
          this.month--
        }

      }

      this.getOrderList()
    }


  }
}
</script>

<style scoped>
/* guide */
.wrap { position: relative; width: 100%; min-height: 100%; margin: 0 auto; height: auto}
.btn-primary { background: var(--meterra-green); }
.icon-arrow-right { color: var(--stop); font-size: 13px; }
</style>
<template>
  <div class="wrap">
    <!-- 현재 리워드 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="title">리워드 내역</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>
      <div class="history-top">
        <div class="reap-view">
          <div class="container">
            <div class="reap-inner">
              <h4 class="reap-tit">현재 리워드</h4>
              <div class="total">
                <strong class="data">{{ balance | numberWithCommas | rewardDecimal}}</strong><strong class="data2">.{{ getMetrDecimalPlace(balance) }}</strong><span class="unit">AMIS</span>
<!--
                <strong>{{ item.rewardAmount | numberWithCommas | rewardDecimal }}</strong><strong>.{{ getMetrDecimalPlace(item.rewardAmount) }}</strong><i class="unit pl-5">Metr</i>
-->

              </div>
            </div>
          </div>
        </div>
        <div class="reap-date">
          <div class="container">
            <div class="reap-inner">
              <div class="date-filter">
                <div class="date-area">
                  <button type="button" class="next-btn" @click="goPrev()" :disabled="!ablePrev"><i
                      class="icon-arrow-left" :class="{'disabled' : !ablePrev}"></i></button>
                  <date-picker class="date-box border-zero fz-20 pt-0 date-range" v-model="selectedDate"
                               :config="this.calendarOptions"
                               @input="selectedDatePicker()"
                               readonly
                  />
                  <button type="button" class="next-btn" @click="goNext()" :disabled="!ableNext"><i
                      class="icon-arrow-right" :class="{'disabled' : !ableNext}" :style="{'--stop' : stopColor}"></i></button>
                </div>
                <div class="filter-area">
                  <i class="icon filter">정렬</i>
                  <button type="button" class="filter-srch" @click="showCondition = true">{{
                      selectedType.name
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //현재 리워드 -->
    <!-- TODO : 내역 있는 경우 -->
    <div class="content-wrap-auto pt-210">
      <div class="container">
        <div class="row">
          <!-- 내역 -->

          <div class="transaction-group" v-for="(day, index) in historyList" :key="'d' + index">
            <!-- 날짜 -->
            <div class="transaction-box">
              <span class="heading-date">{{ historyDay(day.day) }}</span>
            </div>
            <div class="conp-list met-list transaction-box">
              <div class="date-inner">
                <dl class="conp-item conp-item-his item-between" v-for="(history, dayIndex) in day.historyList"
                    :key="'h' + dayIndex">
                  <dt>
                    <div class="item-center">
                      <p class="txt-ellipsis">
                        <span class="name">{{ history.shopName }}</span>
                        <em class="payment-info">{{ history.date }}</em>
                        <em v-show="history.price" class="payment-info">이용금액</em>
                        <em v-show="history.rate" class="payment-info">적립률</em>
                      </p>
                    </div>
                  </dt>
                  <dd>
                    <strong class="data">{{ history.amount }}<em class="unit"> AMIS</em></strong>
                    <em class="payment-info">충전</em>
                    <em v-show="history.price" class="payment-info">{{ history.price }} AMIS</em>
                    <em v-show="history.rate" class="payment-info">{{ history.rate }}</em>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <!-- // 내역 -->
        </div>
      </div>
    </div>

    <div class="wrapper-center" v-show="historyList.length === 0">
      <!-- TODO : 내역이 없을 경우 -->
      <div class="alram-wrap">
        <div class="list-none">
          <i class="bg_symbol_15_op mb-20"></i>
          <p class="list-desc">내역이 없습니다.</p>
        </div>
      </div>
    </div>

    <history-select-type
        v-show="showCondition"
        :conditions="typeList"
        @selected="setSelectedCondition"
        @close="showCondition = false"
    />
  </div>
</template>

<script>
import HistorySelectType from "./HistorySelectType";
export default {
  name: "HistoryReward",
  components: {
    HistorySelectType
  },
  data() {
    return {
      year: '',
      month: '',
      currentYear : 0,
      currentMonth : 0,

      ablePrev: true,
      ableNext: false,
      balance: '0',
      historyList: [],
      selectedDate: '',
      selectedType: {
        type: '',
        name: '전체'
      },
      showCondition: false,
      typeList: [
        {
          type: '',
          name: '전체',
        },
        {
          type: '0',
          name: '적립',
        },
        {
          type: '1',
          name: '사용',
        }
      ],

      stopColor : '#DADADA',
    }
  },
  created() {
    let current = new Date()
    this.year = current.getFullYear()
    this.month = current.getMonth() + 1
    this.setSelectedDateValue()
    this.getCurrentDate()
  },
  mounted() {

/*    this.$get(this.$WALLET_REWARD_BALANCE, 'historypay_mounted', (result) => {
      this.balance = result.data * 1
    }, (result) => {
      this.httpError(result.data)
    })*/

    this.getHistory()
  },
  methods: {
    getCurrentDate() {
      let now = new Date()
      this.year = now.getFullYear()
      this.month = now.getMonth() + 1

      this.currentYear = this.year
      this.currentMonth = this.month
    },

    setSelectedDateValue() {
      this.selectedDate = this.year + '.' + this.month
      this.checkAbleNext()
    },
    selectedDatePicker() {
      this.year = this.selectedDate.split('.')[0]
      this.month = this.selectedDate.split('.')[1]
      this.checkAbleNext()
      this.getHistory()
    },
    getHistory() {
      let queryString = '?year=' + this.year + '&month=' + this.month
      if (this.selectedType.type !== '') {
        queryString = queryString + '&type=' + this.selectedType.type
      }
      this.$get(this.$WALLET_REWARD_HISTORY + queryString, 'historyreward_gethistory', (result) => {
        this.balance = result.data.balance
        if (result.data.dayGroupList) {
          this.historyList = result.data.dayGroupList
        } else {
          this.historyList = []
        }

      }, (result) => {
        this.httpError(result.data)
      })
    },
    setSelectedCondition(_condition) {
      this.selectedType = _condition
      this.showCondition = false
      this.getHistory()
    },
    goPrev() {
      if (this.month > 1) {
        this.month = this.month - 1
      } else {
        this.year = this.year - 1
        this.month = 12
      }
      this.stopColor = ''
      this.setSelectedDateValue()
      this.ableNext = true
      this.getHistory()
    },
    goNext() {
      if (this.month * 1 === 12) {
        this.month = 1
        this.year = (this.year * 1) + 1
      } else {
        this.month = (this.month * 1) + 1
      }

      console.log('curY : ' + this.currentYear)
      console.log('curM : ' + this.currentMonth)
      console.log('Y : ' + this.year)
      console.log('M : ' + this.month)
      if (this.currentYear <= this.year) {
        if (this.currentMonth <= this.month) {
          this.stopColor = '#DADADA'
        }
      }
      this.setSelectedDateValue()

      this.getHistory()
    },
    historyDay(value) {
      let weeks = ['일', '월', '화', '수', '목', '금', '토']
      let date = new Date(this.year + '-' + ((this.month * 1) < 10 ? '0' : '') + this.month + ((value * 1) < 10 ? '-0' : '-') + value)
      let week = weeks[date.getDay()]

      return value + '일(' + week + ')'
    },
    checkAbleNext() {
      let current = new Date()
      let _year = current.getFullYear()
      let _month = current.getMonth() + 1
      let current2 = new Date(_year + '-' + ((_month * 1) < 10 ? '0' : '') + _month + '-01')
      let select = new Date(this.year + '-' + ((this.month * 1) < 10 ? '0' : '') + this.month + '-01')

      if (current2 <= select) {
        this.ableNext = false
      } else {
        this.ableNext = true
      }
    }
  },
  filters: {
    historyType: (value) => {
      if (value === '0') {
        return '적립'
      } else if (value === '1') {
        return '사용'
      }
      return ''
    },

  }
}
</script>

<style scoped>
/*.reap-view .re-tit { font-size: 14px; }
.reap-view .re-data { display: flex; }
.reap-view .re-data strong, .re-data .unit { font-size: 20px; font-weight: 700; color: var(--meterra-green);}
.reap-view .re-data .icon-arrow-right { margin-left: 10px; color: var(--gray01); font-size: 10px; }*/

.reap-view .total { margin-bottom: 4px; }
.reap-view .total .data { font-size: 30px; font-weight: 700; color: var(--white); }
.reap-view .total .data2 { font-size: 30px; font-weight: 700; color: var(--amis-purple); }
.reap-view .total .unit { padding-left: 5px; font-size: 16px;; color: var(--white); font-weight: 400; }
.reap-view .total .unit2 { padding-left: 5px; font-size: 16px;; color: var(--amis-purple); font-weight: 400; }


.icon-arrow-right { color: var(--stop); font-size: 13px; }

button:disabled { background-color: var(--white); color: var(--stop) !important; border: none; }
button:disabled * { color: var(--stop) !important;}

.wrapper-center {
  min-height: auto;
}

.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

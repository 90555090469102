<template>
  <div class="popup-wrap">
    <div class="overlay-bg" @click.self="$emit('close')"></div>
    <div class="half-listlayer-wrap">
      <div class="close" style="z-index: 1">
        <button type="button" class="nav-btn right" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <div class="half-content">
        <div class="half-content-heading">
          <h5 class="half-tit">유형 선택</h5>
        </div>
        <hr class="hr-2">
        <div class="half-content-area">
          <ul class="half-select">
            <li v-for="(condition, index) in conditions" :key="index">
              <a @click="selected(condition)">
                {{ condition.name }}
              </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistorySelectType",
  props: ['conditions'],
  methods: {
    selected(_condition) {
      this.$emit('selected', _condition)
    },

    test() {
      console.log('닫기')
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left"
                  @click="$router.replace({name:'SendMetrContactList'})"><i
              class="icon-back"></i></button>
          <span class="title">{{ tokenType === 'AMIS' ? '아미스' : '이더리움'}} 송금하기</span>
        </div>
      </div>
    </div>
<!--    <div class="content-wrap-v2 h-100-104">-->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 v-if="username !== ''" class="half-tit">{{ this.username }}</h5>
            <div class="input-close mt-20">
              <MTRInput
                  :type="'text'"
                  :placeholder="'금액을 입력해주세요.'"
                  :model="amount"
                  @update="(_model) => {this.amount=_model}"
                  :input-mode="'decimal'"/>
            </div>

            <div class="data-value point-data mt-20">
              <span class="data-tit">{{ tokenType === 'AMIS' ? '아미스' : '이더리움'}}</span>
              <span class="point-color-black">
                <strong class="unit">{{ this.balance | numberWithCommas | rewardDecimal }}</strong>
                <strong class="unit">.{{ getMetr8DecimalPlace(this.balance) }}</strong>
                <em class="unit"> {{ tokenType }}</em>
              </span>

            </div>

          </div>
        </div>
      </div>
    </div>

<!--    <div class="send-footer-wrap">-->
<!--      <div class="container">-->
<!--        <button class="send-charge-btn" @click="$router.replace({name: 'Charge'})">충전하기<i class="icon-arrow-right arrow-dgray pl-5"></i>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->

    <div class="button-wrap">
      <div class="container">
        <button class="l_btn_full btn-black" @click="goNext()">송금하기
        </button>
      </div>
    </div>

    <pin-check
        ref="PinCheck"
        :title="'송금'"
        :amount="amount"
        :unit="tokenType"
        :sub-amount="0"
        :sub-unit="tokenType"
        :fee-type="feeType"
        :fee="feeType === '0' ? fee : 0"
        :fee-amount="feeAmount"
    />
  </div>

</template>

<script>
import MTRInput from "../../common/MTRInput";
import PinCheck from "../../common/PinCheck";

export default {
  name: "SendMetrAmount",
  components: {
    MTRInput,
    PinCheck
  },
  data() {
    return {
      username: '',
      userIdx: '',
      walletAddress: '',
      amount: '',
      balance: '0',
      usdPrice: 0,
      minAmount: 0,
      remittanceMinAmount : 0,
      maxAmount: 0,
      feeType: '',  // "0" => %, "1" => 고정금액
      fee: 0, // 수수료률
      feeAmount: 0,   // 수수료 금액
      // isDirectAccount: false,
      isDirectAble: false,
      sendType: '',  // pay, account
      tokenType: this.$route.params.tokenType,
      balanceUrl : '',

      amountInputDisabled: true,
      showFeeInfo: false,
      feeInfoList: [],

      withdrawLimit: '0',  // 타행출금 시간(출금, 송금받고 몇시간 후에 가능한지)

      errors: {
        amount: '',
      },
    }
  },
  mounted() {
    this.username = this.$route.params.username
    this.userIdx = this.$route.params.userIdx
    this.walletAddress = this.$route.params.address
    this.balanceUrl = this.tokenType === 'AMIS' ? this.$WALLET_REAPCHAIN_BALANCE : this.$WALLET_ETH_BALANCE
    // if (/\d/.test(this.$route.params.amount)) {
    //   this.amount = this.$route.params.amount
    // }

    let tokenPrice = this.tokenType === 'AMIS' ? this.$TOKEN_PRICE : this.$ETH_PRICE
    this.$get(tokenPrice, 'sendpayamount_mounted', (result) => {
      this.usdPrice = result.data
    }, (result) => {
      this.httpError(result.data)
    })

    this.$get(this.balanceUrl, 'sendpayamount_mounted', (result) => {
      this.balance = result.data
    }, (result) => {
      this.httpError(result.data)
    })

    let minUrl = this.tokenType === 'AMIS' ? this.$WALLET_MIN_WITHDRAW_REAPCHAIN : this.$WALLET_MIN_WITHDRAW_ETH
    this.$get(minUrl, 'sendpayamount_mounted', (result) => {
      this.minAmount = result.data

    }, (result) => {
      this.httpError(result.data)
    })

    /*출금 제한 시간 조회*/
    this.$get(this.$WITHDRAW_LIMIT, 'sendpayamount_mounted', (result) => {
      this.withdrawLimit = result.data

    }, (result) => {
      this.httpError(result.data)
    })

    //립페이, 송금 최소 금액
    this.getMinRemittanceFee()

    if (this.userIdx === ''){
      this.selectedSendType('pay')
    } else {
      this.getTotalFeeInfo()
    }
  },
  methods: {
    errorClear() {
      this.errors = {
        amount: '',
      }
    },
    selectedSendType(_type) {
      this.sendType = _type
      this.amount = ''
      this.fee = 0
      this.feeAmount = 0
      this.feeType = ''

      if (this.sendType === 'account') {  /*타행 송금*/
        this.checkAccount()

      } else {
        this.initSendPay()
        this.amountInputDisabled = false
      }
    },
    checkAccount() {
      this.isDirectAble = false
      this.$get(this.$CHECK_PAY_TO_KRW + '?userIdx=' + this.userIdx, 'payment_mounted', () => {
        this.isDirectAble = true
        this.amountInputDisabled = false

        /*타행송금 가능 금액 조회*/
        this.initSendAccount()

      }, (result) => {
        this.httpError(result.data)
        this.sendType = ''
        this.amountInputDisabled = true
      })
    },
    initSendPay() {
      /*아미스, 이더 잔고조회*/
      this.$get(this.balanceUrl, 'sendpayamount_balance', (result) => {
        this.balance = result.data

      }, (result) => {
        this.httpError(result.data)
      })

      /*립페이 최소 송금금액 조회*/
/*      this.$get(this.$WALLET_MIN_SEND_REAPPAY, 'sendpayamount_minsendpay', (result) => {
        this.minAmount = result.data
      }, (result) => {
        this.httpError(result.data)
      })*/

      /*송금 타입별 수수료 정보 조회*/
      this.getTotalFeeInfo()
    },

    getMinRemittanceFee() {
      let minRemittanceFeeUrl = this.tokenType === 'AMIS' ? this.$WALLET_MIN_SEND_REAPPAY : this.$WALLET_MIN_SEND_ETH
      /*아미스 최소 송금금액 조회*/
      this.$get(minRemittanceFeeUrl, 'sendpayamount_minsendpay', (result) => {
        this.remittanceMinAmount = result.data
      }, () => {
      })
    },

    initSendAccount() {
      /*타행송금 가능 금액 조회 TODO balance 는 따로 가져오고있으니 우선 주석처리 */
      /*this.$get(this.$WALLET_WITHDRAWABLE_REAPPAY, 'sendpayamount_withdrawablepay', (result) => {
        this.balance = result.data * 1
      }, (result) => {
        this.httpError(result.data)
      })*/

      /*타행출금 최소 금액 조회*/
      /*this.$get(this.$WALLET_MIN_WITHDRAW_REAPCHAIN, 'sendpayamount_minwithdrawablepay', (result) => {
        this.minAmount = result.data
      }, (result) => {
        this.httpError(result.data)
      })*/

      /*송금 타입별 수수료 정보 조회*/
      this.getTotalFeeInfo()
    },
    getTotalFeeInfo() { /*수수료 정보 조회*/
      let cls = "0"
      let walletTypeIdx = "1"

      if (this.sendType === 'account') { /*타행송금*/
        cls = "2"
        walletTypeIdx = "5"
      }

      walletTypeIdx = this.tokenType === 'ETH' ? '4' : walletTypeIdx

      let queryString = '?cls=' + cls + '&walletTypeIdx=' + walletTypeIdx  // cls : 0(송금), 1(출금), 2(타행출금) // walletTypeIdx(화폐고유값) : 1(아미스), 2(리워드), 3(카드), 4(이더리움), 5(-)

      this.$get(this.$WALLET_TRANSACTION_GETFEEINFO + queryString, 'requestcash_getfeeinfo', (result) => {
        this.feeInfoList = result.data
        this.maxAmount = this.feeInfoList[this.feeInfoList.length - 1].maxAmount

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goCharge() {
      this.$router.replace({name: 'Charge'})
    },
    goNext() {
      if (!/^[0-9-][0-9,.]*$/.test(this.amount)) {
        this.notify('error', '수량을 정확하게 입력해주세요.')
        return
      }
      if (this.amount * 1 > this.balance * 1) { /*잔고체크*/
        this.notify('error', '송금 가능 금액이 부족합니다.')
        return
      }
      if (this.amount * 1 < this.minAmount * 1) {  /*최소 송금금액 체크*/
        this.notify('error', '최소 송금 수량은 ' + this.numberWithCommas(this.minAmount) + this.tokenType + ' 입니다.')
        return
      }
      /*수수료 체크*/
      if (this.amount * 1 < this.feeAmount + (this.minAmount * 1)) {
        this.notify('error', '송금 수수료 ' + this.numberWithCommas(this.remittanceMinAmount + this.feeAmount) + this.tokenType + ' 큰 금액부터 송금 가능합니다.')
        return
      }
      if (this.amount * 1 > this.maxAmount) { /*최대 송금금액이상일 경우*/
        this.notify('error', '최대 송금 수량은 ' + this.numberWithCommas(this.maxAmount) + this.tokenType + ' 입니다.')
        return;
      }
      if (this.sendType === 'account' && !this.isDirectAble) {
        this.checkAccount()
        return
      }

      let paramTokenType = this.tokenType === 'AMIS' ? 'AMIS' : 'ETHEREUM'
      this.$refs.PinCheck.showPin(() => {
        let formData = new FormData()
        //파라메터 추가 필요
        // formData.append('receiveUserIdx', this.userIdx)
        formData.append('amount', this.amount)
        formData.append('toAddress', this.walletAddress)
        formData.append('tokenType', paramTokenType)

        this.$put(this.$REMITTANCE_SEND, 'sendpayamount_gonext', formData, (result) => {

          let regDate = result.data.regDate
          let sendAmount = result.data.sendAmount
          let targetUserName = result.data.targetUserName
          let userAmount = result.data.userAmount


          let feeType = result.data.feeType
          let fee = 0
          if (feeType === '0') {  /*수수료율(0), 고정금액(1)*/
            fee = result.data.feeValue /*수수료율*/
          }
          let feeAmount = result.data.fee /*수수료 금액*/

          this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
          this.$router.replace({
            name: 'SendMetrSuccess', params: {
              regDate: regDate,
              sendAmount: sendAmount,
              targetUserName: targetUserName,
              userAmount: userAmount,
              feeType: feeType,
              fee: fee,
              feeAmount: feeAmount,
              tokenType : paramTokenType,
              internal: result.data.internal
            }
          })
          this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
        }, (result) => {

          this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
          this.$router.replace({
            name: 'SendMetrFail', params: {
              targetUserName: this.username,
              userAmount: this.balance,
              reason: result.data.message,
            }
          })
        })
      })
    },
    getFee() {
      let cls = "0"
      let walletTypeIdx = "1"

      if (this.sendType === 'account') { /*타행송금*/
        cls = "2"
        walletTypeIdx = "5"
      }

      let queryString = '?amount=' + this.amount + '&cls=' + cls + '&walletTypeIdx=' + walletTypeIdx  // cls : 0(송금), 1(출금), 2(타행출금) // walletTypeIdx(화폐고유값) : 1(립페이), 2(리워드), 3(립체인), 4(카드), 5(계좌)

      /*출금 수수료 조회*/
      this.$get(this.$WALLET_TRANSACTION_GETFEE + queryString, 'requestcash_getfee', (result) => {
        this.feeType = result.data.type
        if (this.feeType === '0') {  /*수수료율(0), 고정금액(1)*/
          this.fee = result.data.fee
        }
        this.feeAmount = result.data.feeAmount

      }, (result) => {
        this.httpError(result.data)
      })
    },
    closePopup() {
      this.showFeeInfo = false
    },
    numberWithCommas(_number) {
      return _number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  watch: {
    amount() {
      if (this.amount * 1 > this.balance * 1) { /*송금 가능 금액보다 클 경우*/
        if (this.balance * 1 <= this.maxAmount) {
          this.errors.amount = '송금 가능 금액은 ' + this.numberWithCommas(this.balance) + 'PAY 입니다.'
        } else {
          this.errors.amount = '최대 송금 금액은 ' + this.numberWithCommas(this.maxAmount) + 'PAY 입니다.'
        }
      } else if (this.amount * 1 > this.maxAmount) { /*최대 송금금액보다 클 경우*/
        this.errors.amount = '최대 송금 금액은 ' + this.numberWithCommas(this.maxAmount) + 'PAY 입니다.'
      } else if (this.minAmount <= (this.amount * 1)) {  /*최소 송금금액 이상일 경우*/
        this.errorClear()
        this.getFee()
      } else {
        this.errorClear()
        this.feeAmount = 0
      }
      // }
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">이용약관</span>
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <div class="container">
        <div class="notice-list pt-50">
          <ul class="pt-30">
            <li class="border-bottom">
              <a @click="$router.push({name:'Term01'})">
                <p class="mr-20 fs-16">
                  이용약관
                </p>
                <i class="icon-arrow-right"></i>
              </a>
            </li>
            <li class="border-bottom">
              <a @click="$router.push({name:'Term02'})">
                <p class="mr-20 fs-16">
                  개인정보 처리방침
                </p>
                <i class="icon-arrow-right"></i>
              </a>
            </li>
<!--            <li class="border-bottom">
              <a @click="$router.push({name:'Term03'})">
                <p class="mr-20 fs-16">
                  위치기반 서비스 이용약관
                </p>
                <i class="icon-arrow-right"></i>
              </a>
            </li>
            <li class="border-bottom">
              <a @click="$router.push({name:'Term04'})">
                <p class="mr-20 fs-16">
                  전자금융거래 이용약관
                </p>
                <i class="icon-arrow-right"></i>
              </a>
            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermList"
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>
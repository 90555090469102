<template>
  <div class="wrap">
    <!-- 상단 고정 해더 영역 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">로그인</h5>
            <div class="w-100">
              <div class="pt-30">
                <MTRInput
                    :type="'text'"
                    :placeholder="'아이디를 입력해 주세요.'"
                    :model="id"
                    @update="(_model) => {this.id=_model}"/>
              </div>
              <div class="pt-10">
                <MTRInput
                    :type="'password'"
                    :placeholder="'비밀번호를 입력해 주세요.'"
                    :model="password"
                    @update="(_model) => {this.password=_model}"/>
              </div>
              <div class="mt-30 d-flex-row">
                <button class="mr-auto" @click="$router.push({ name: 'TermAgree' })">회원가입</button>
                <div class="d-flex-row ml-auto">
                  <button @click="$router.push({ name: 'FindId' })">아이디 찾기</button>
                  <span class="ml-10 mr-10 color-lgray3"> | </span>
                  <button class="mr-auto" @click="$router.push({ name: 'FindPw' })">비밀번호 찾기</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn btn-black" @click="login()">로그인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTRInput from "../../common/MTRInput";

export default {
  name: "Login",
  components: {
    MTRInput
  },
  data() {
    return {
      id: '',
      password: '',
    }
  },
  mounted() {

    //네이티브 메세지를 못받는 상태값으로 변경
    window.isAlready = false
    window.changeAlreadyState()
    this.autoLogin()

  },
  methods: {
    checkSocial() {
      return window.useSocial()
    },
    login() {
      if (this.id === '') {
        this.notify('error', '아이디를 정확하게 입력해주세요.')
        return
      }
      if (this.password === '') {
        this.notify('error', '비밀번호를 정확하게 입력해주세요.')
        return
      }
      let formData = new FormData()
      formData.append('id', this.id.trim())
      formData.append('password', this.password.trim())


      this.$post(this.$AUTH_LOGIN, 'login_login', formData, (result) => {
        this.loginDone(result)
      }, (result) => {
        this.loginFail(result)
      })
    },




  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <span class="title">리유저블컵 내역</span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>
      <div class="history-top">
        <div class="reap-view reusalbe">
          <div class="container">
            <div class="reap-inner">
              <h4 class="reap-tit">리유저블컵 총 사용 횟수</h4>
              <div class="total">
                <strong class="data">{{ totalCount | numberWithCommas }}</strong><span class="unit">회</span>
              </div>
            </div>
          </div>
        </div>
        <div class="reap-date">
          <div class="container">
            <div class="reap-inner">
              <div class="date-filter">
                <div class="date-area">
                  <button type="button" class="next-btn" @click="goPrev()" :disabled="!ablePrev"><i
                      class="icon-arrow-left" :class="{'disabled' : !ablePrev}"></i></button>
                  <span class="date-range">{{ selectedCub.productName }}</span>
                  <button type="button" class="next-btn" @click="goNext()" :disabled="!ableNext"><i
                      class="icon-arrow-right" :class="{'disabled' : !ableNext}"></i></button>
                </div>
                <div class="point-color-black">
                  {{ selectedCub.count }}회
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO : 내역 있는 경우 -->
    <div class="content-wrap-auto pt-210">
      <div class="container">
        <div class="row">
          <!-- 내역 -->

          <div class="transaction-group" v-for="(day, index) in historyList" :key="'d' + index">
            <!-- 날짜 -->
            <div class="transaction-box">
              <span class="heading-date">{{ day.date }}</span>
            </div>
            <div class="conp-list met-list transaction-box">
              <div class="date-inner">
                <dl class="conp-item conp-item-his item-between" v-for="(history, dayIndex) in day.productUseList" :key="'h' + dayIndex">
                  <dt>
                    <div class="item-center">
                      <p class="txt-ellipsis">
                        <span class="name">{{ history.shopName }}</span>
                        <em class="payment-info">{{ history.date }}</em>
                      </p>
                    </div>
                  </dt>
                  <dd>
                    <strong class="data">{{ history.count }}</strong>
                    <em class="payment-info">{{ history.cause }}</em>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <!-- // 내역 -->
        </div>
      </div>
    </div>

    <div class="wrapper-center" v-show="historyList.length === 0">
      <!-- TODO : 내역이 없을 경우 -->
      <div class="alram-wrap">
        <div class="list-none">
          <i class="bg_symbol_15_op mb-20"></i>
          <p class="list-desc">내역이 없습니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryReusableCub",
  data() {
    return {
      ablePrev: true,
      ableNext: false,
      totalCount: '0',
      historyList: [],

      showCondition: false,

      selectedCub: {
        productName: '',
        productQr: '',
        count: 0,
      },
      selectedIndex: 0,
      cubList: [],

      isReusableLogin: false,
    }
  },
  mounted() {

    this.$reusable_get(this.$REUSABLE_TOTAL_COUNT, this.$options.name + '_mounted', (result) => {
      this.totalCount = result.data * 1
    }, (result) => {
      this.httpError(result.data)
    })


    this.getCubList()
  },
  methods: {
    getCubList() {
      this.$reusable_get(this.$REUSABLE_CUB_LIST, this.$options.name + '_getCubList', (result) => {
        this.cubList = result.data
        if (this.selectedCub.productQr === '') {
          if (this.cubList.length > 0) {
            this.selectedCub = this.cubList[0]
            this.selectedIndex = 0
            this.ablePrev = false
            if (this.cubList.length <= 1) {
              this.ableNext = false
            } else {
              this.ableNext = true
            }
            this.getHistory()
          } else {
            this.ablePrev = false
            this.ableNext = false
          }

        }
      }, (result) => {
        this.httpError(result.data)
      })
    },
    getHistory() {
      if (this.selectedCub.productQr === '') {
        return
      }
      this.$reusable_get(this.$REUSABLE_CUB_DETAIL + this.selectedCub.productQr, this.$options.name + '_gethistory', (result) => {
        // this.balance = result.data.balance

        this.historyList = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    goPrev() {
      if (this.selectedIndex === 0) {
        return
      }
      this.ableNext = true
      this.selectedIndex--
      if (this.selectedIndex === 0) {
        this.ablePrev = false
      }
      this.selectedCub = this.cubList[this.selectedIndex]
      this.getHistory()
    },
    goNext() {
      if (this.selectedIndex === this.cubList.length - 1) {
        return
      }
      this.selectedIndex++
      this.ablePrev = true
      if (this.selectedIndex < this.cubList.length - 1) {
        this.ableNext = true
      } else {
        this.ableNext = false
      }

      this.selectedCub = this.cubList[this.selectedIndex]
      this.getHistory()
    },
    reusableLogin() {
      //sso 로그인
      this.$post(this.$SSO_AUTH_WITH_TOKEN, this.$options.name + '_login', null, (result) => {
        if (result.data.ssoToken) {
          let ssoToken = result.data.ssoToken
          let formData = new FormData()
          formData.append('ssoToken ', ssoToken)

          this.$reusable_post(this.$REUSABLE_AUTH_LOGIN, this.$options.name + '_gossologin', formData, (result) => {
            if (result.data.accessToken && result.data.refreshToken) {
              sessionStorage.setItem('accessToken_reusable', result.data.accessToken)
              this.isReusableLogin = true
            } else {
              // this.$router.push({name:'SignUp', params:{userInfo:result.data, ssoToken:ssoToken}})
            }

          }, (result) => {
            this.httpError(result)
          })

        } else {
          // this.$router.push({name:'SignUp', params:{userInfo:result.data, ssoToken:ssoToken}})
        }
      }, (result) => {
        this.httpError(result.data)
      })
    }
  },
  watch: {
    isReusableLogin() {
      if (this.isReusableLogin) {
        this.getCubList()
      } else {
        this.reusableLogin()
      }
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <!-- 상단 고정 해더 영역 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">핀번호 분실</h5>
            <div class="w-100">
              <div class="pt-30">
                <MTRInput
                    :type="'password'"
                    :placeholder="'비밀번호를 입력해 주세요.'"
                    :model="password"
                    @update="(_model) => {this.password=_model}"
                />
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.password">{{ errors.password }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn btn-black " @click="checkPassword()">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTRInput from "../../common/MTRInput";
export default {
  name: "RestorePin",
  components: {
    MTRInput
  },
  data() {
    return {
      password: '',
      errors: {
        password: '',
      },
      profile: {
        id: '',
        imageIdx: '',
        imagePath: '',
        email: '',
        hp: '',
        biometrics: false
      },
    }
  },
  mounted() {
    this.$get(this.$USER_INFO_DETAIL, 'restorepin_mounted', (result) => {
      this.profile = result.data
    }, (result) => {
      this.httpError(result.data)
    })
  },
  methods: {
    errorClear() {
      this.errors = {password: ''}
    },
    checkPassword() {
      if (this.password !== '') {
        this.errorClear()

        let formData = new FormData()
        formData.append('password', this.password)

        this.$post(this.$AUTH_PASSWORD, 'restorepin_checkpassword', formData, () => {
          this.$router.replace({name: 'ChangePinRegist', params: {old_pin: '', password: this.password, kakao: ''}})
        }, (result) => {
          // this.httpError(result.data)
          this.errors.password = result.data.message
        })
      }
    },
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
        <span class="logo">
          <img src="/image/logo_row01.svg" alt="meterra">
        </span>
          <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
        </div>
      </div>
    </div>
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <p class="confirm-subtit">송금이 실패하였습니다.</p>
              <p class="confirm-desc">다시 한번 시도해 주세요.</p>
            </div>
            <div class="confirm-info-list">
              <dl>
                <dt class="text-tit">잔여 AMIS</dt>
                <dd class="point-color-black">{{ userAmount | numberWithCommas }}AMIS</dd>
              </dl>

              <dl>
                <dt class="text-tit">받는사람</dt>
                <dd class="text-desc">{{ targetUserName }}</dd>
              </dl>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="$router.replace({name: 'SendMetrContactList'})">재송금</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SendMetrFail",
  data() {
    return {
      userAmount: '',
      targetUserName: '',
    }
  },
  mounted() {
    this.userAmount = this.$route.params.userAmount
    this.targetUserName = this.$route.params.targetUserName
  }
}
</script>

<style scoped>
.header-group .logo { position: relative; display: inline-block; height: 20px; width: 70px; }
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">{{ $route.params.title }}</span>
        </div>
      </div>
    </div>
    <div class="notice-content-wrap">
      <div class="container">
        <div class="notice-list">
          <ul>
            <li v-for="(item, index) in items" :key="'i' + index">
              <a @click="goDetail(item.idx)">
                <p class="mr-20 fs-16">
                  {{ item.title }}
                </p>
                <i :class="{'icon-arrow-down':item.idx !== selectedIdx, 'icon-arrow-up':item.idx === selectedIdx}"></i>
              </a>
              <div class="bg-gray p-1">
                <div v-show="item.idx === selectedIdx" class="p-20">
                  <div>
                    <div  v-html="item.content"></div>
                  </div>
                  <div class="pt-10" v-for="(img, imgIndex) in item.imageList" :key="'i' + imgIndex">
                    <img :src="removeHost(img.imagePath)" alt="FAQ 이미지">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqSubList",
  data() {
    return {
      items: [],
      selectedIdx: '',
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$get(this.$FAQ_LIST + '?qCategoryIdx=' + this.$route.params.idx, 'faqlist_getfaq', (result) => {
        this.items = result.data
        console.log('result :  ' + JSON.stringify(this.items))
      }, (result) => {
        this.httpError(result.data)
      })
    },
    goDetail(_no) {
      if (this.selectedIdx === _no) {
        this.selectedIdx = ''
      } else {
        this.selectedIdx = _no
      }
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
.notice-content-wrap{ position: relative; height: 100%; padding-top: 50px; overflow: auto; }
</style>

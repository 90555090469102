<template>
  <div class="popup-wrap madal-bg z-index-500" v-if="show" @click.self="close()">
    <div class="container">
      <div class="row">
        <div class="section_confirm">
          <div class="relative-wrap">
            <div class="confirm-content">
              <div class="permit-group">
                <div class="confirm-box">
                  <img src="/image/ethereum.svg" alt="아미스" v-if="tokenType === 'ETH'">
                  <img src="/image/logo_row01.svg" alt="아미스" v-else><br/><br/>
                  <h3 class="confirm-title">{{ title }}</h3>
                </div>
                <hr class="hr-1">
                <div class="confirm-box">
                  <p class="confirm-guid" v-html="msg"></p>
                  <p class="dk-pink fs-16 mt-15" v-if="reason !== ''">[ 사유 ]</p>
                  <p class="dk-pink mt-15" v-if="reason !== ''">{{ reason }}</p>
                  <p class="mt-15" v-if="date !== ''">{{ date }}</p>
                </div>
              </div>
              <div class="button-wrap">
                <div class="container">
                  <div class="button-group">
                    <button class="btn btn-black" @click="ok()">확인</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MTRAlert",
  data() {
    return {
      show: false,
      title: '',
      msg: '',
      reason: '',
      date: '',
      callback: null,
      tokenType : '',
    }
  },
  methods: {
    showAlert(title, msg, reason = '', date = '', callback, tokenType = '') {
      this.title = title
      this.msg = msg
      this.reason = reason
      this.date = date
      this.callback = callback
      this.tokenType = tokenType
      this.show = true
    },
    showDialog(title, msg, reason = '', date = '', callback, tokenType = '') {
      this.title = title
      this.msg = msg
      this.reason = reason
      this.date = date
      this.callback = callback
      this.tokenType = tokenType
      this.show = true
    },
    close() {
      this.show = false
    },
    ok() {
      this.close()
      this.callback()
    },
  }
}
</script>

<style scoped>
.confirm-box img {
  height: 30px;
  width: 100px;
}
</style>
<template>
  <!-- 아미스 -->
  <div class="payment-wrap">
    <div class="row">
      <div class="payment-reload">
                <span class="meterra_logo">
                  <img src="/image/logo_row01.svg" alt="아미스">
                </span>
        <button class="circle-btn">
          <i class="icon-reload" @click="refresh()"></i>
        </button>
      </div>
    </div>
    <!-- QR 부분 -->
    <div class="payment-sort">
      <div class="swiper-container payment-slot">
        <div class="swiper-wrapper">
          <div class="swiper-slide scan">
            <vue-qrcode :value="qrStr"/>
          </div>
          <div class="swiper-slide scan">
            <vue-barcode :value="barcodeStr" />
          </div>
        </div>
        <div class="swiper-button-next payment-button-next"></div>
        <div class="swiper-button-prev payment-button-prev"></div>
      </div>
    </div>
    <!-- //QR 부분 -->
    <!-- 리워드 입력창 -->
    <div class="payment-info">
      <div class="input-area">
        <input type="text" placeholder="사용 리워드를 입력해주세요." v-model="rewardAmount">
        <button type="button" class="l_btn btn-black" @click="getCode('0')">확인</button>
      </div>
      <div class="price-area">
        <div class="data-value point-data">
          <span class="data-tit">리워드</span>
          <span class="point-color-black">{{ rewardBalance | numberWithCommas }}<em class="unit">AMIS</em></span>
        </div>
        <div class="data-value point-data">
          <span class="data-tit">사용할 리워드</span>
          <span class="point-color-green">{{ rewardAmountDone | numberWithCommas }}<em class="unit">AMIS</em></span>
        </div>
      </div>
      <div class="checks">
        <input type="checkbox" id="autoChecks" v-model="isUseAllReward" @click="isUseAllReward = !isUseAllReward">
        <label for="autoChecks">항상 전액 사용</label>
      </div>
    </div>
    <!-- //리워드 입력창 -->
  </div>
  <!-- //아미스 -->
</template>

<script>
import VueQrcode from 'vue-qrcode'
import VueBarcode from 'vue-barcode'

export default {
  name: "PaymentMetr",
  components: {
    VueQrcode,
    VueBarcode,
  },
  data() {
    return {
      qrStr: '',
      barcodeStr: '',
      rewardAmount: '',
      rewardAmountDone: '0',
      rewardBalance: '0',
      isUseAllReward: false,
      timerTick: 0,
      isRefreshing: false,
      swiper: null,
    }
  },
  mounted() {
    this.isUseAllReward = (localStorage.getItem('isUseAllReward') === 'true')
    if (!this.isUseAllReward) {
      this.getCode('0')
    }
    this.$get(this.$WALLET_REWARD_BALANCE, 'payment_qr_mounted', (result) => {
      this.rewardBalance = result.data * 1

      if (this.isUseAllReward) {
        if (this.rewardBalance > 0) {
          this.rewardAmount = this.rewardBalance
        }

        this.getCode('0')
      }
    }, (result) => {
      this.httpError(result.data)
    })
  },
  methods: {
    initSwiper() {
      setTimeout(() => {
        if(this.swiper) {
          this.swiper.destroy()
        }

        this.swiper = new window.Swiper(".payment-slot", {
          spaceBetween: 20,
          loop: false,
          navigation: {
            nextEl: ".payment-button-next",
            prevEl: ".payment-button-prev",
          }
        });
      }, 1000)
    },
    timerStart() {
      this.timerTick = 1
      this.timerTicker()
    },
    timerTicker() {
      if (this.$route.name !== 'Main') {
        return
      }
      if (this.timerTick <= 0) {
        return
      }
      setTimeout(() => {
        this.timerTick--
        this.timerTicker()
      }, 1000)
    },
    refresh() {
      if (this.timerTick > 0) {
        this.notify('error', this.timerTick + '초 후 가능합니다.')
        return
      }
      this.timerStart()
      this.getCode('1')
    },
    rotateStart() {
      this.isRefreshing = true
    },
    rotateEnd() {
      this.isRefreshing = false
    },
    getCode(_type) {
      if (_type === '1') {
        this.rotateStart()
      }
      let formData = new FormData()
      formData.append('type', _type)
      let amount = ''
      if (this.rewardAmount !== '' && this.rewardAmount * 1 !== 0) {
        if (this.rewardBalance * 1 >= this.rewardAmount * 1) {
          amount = this.rewardAmount
        } else {
          this.notify('error', '리워드가 부족합니다.')
          return
        }
      }
      if (amount !== '') {
        formData.append('reward', amount)
      }

      //test
      this.$post(this.$PAYMENT_QR_BARCODE, 'payment_metr_getcode', formData, (result) => {
        this.qrStr = result.data.qr
        this.barcodeStr = result.data.barcode

        this.rewardAmountDone = amount * 1
        if (_type === '1') {
          this.rotateEnd()
        }

        this.initSwiper()
      }, (result) => {
        this.httpError(result.data)
        if (_type === '1') {
          this.rotateEnd()
        }
      })
    },
  },
  watch: {
    isUseAllReward() {
      if (this.isUseAllReward) {
        localStorage.setItem('isUseAllReward', 'true')
        if (this.rewardBalance > 0) {
          this.rewardAmount = this.rewardBalance
          this.getCode('0')
        }
      } else {
        localStorage.setItem('isUseAllReward', 'false')
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>

  <div class="wrap main-bg">
    <TopNav :isSearch="isSearch" :title="shopName" :searchType="'menu'"/>
    <div class="content-wrap pt-50" :class="{ 'pb-150' : isUseCart}">
      <div class="category_menu">
        <div class="container">
          <div class="menu_tab">
            <a :class="{'on' : selected_category_1 === category.idx}" v-for="category in category_1" :key="category.idx"
               @click="clickFirstCategory(category.idx)">{{ category.name }}</a>
          </div>
          <div class="swiper category">
            <div class="swiper-wrapper">
              <div v-for="category in category_2" :key="category.idx" class="swiper-slide cate_slide">
                <div :class="{'active' : selected_category_2 === category.idx}" @click="setCurrentCategory(category.idx)" class="cate-btn">{{ category.name }}</div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="category_list">
        <div class="container">
          <div class="row">
            <ul class="list_type3">
              <li v-for="product in getExposureProducts" :key="product.idx">
                <a :class="{'soldout' : product.soldOut}"
                    @click="$router.replace({name : 'MenuDetail', params : { productIdx : product.idx, shopIdx : shopIdx === '' ? '0' : shopIdx }})">
                  <figure>
                    <div class="frame">
                      <img class="munu_img" :src="removeHost(product.imgPath)" alt="">
                    </div>
                    <figcaption>
                      <strong class="ellipsis">{{ product.name }}</strong>
                      <span>{{ product.price | numberWithCommas }}원</span>
                    </figcaption>
                  </figure>
                </a>
              </li>
            </ul>


          </div>
        </div>
      </div>
    </div>
    <div class="popup type1" v-if="isUseCart">
      <div class="container">
        <div class="inner">
          <div class="info_area type1">
            <p class="total">총 선택 <span class="ordernum">{{totalQuantity}}개</span></p>
            <strong class="price">{{totalPrice | numberWithCommas}}원</strong>
          </div>
          <div class="button-group mt-20">
            <button class="outline_l_btn btn-gray" @click="initCart">전체주문취소</button>
            <button class="outline_l_btn btn-black" @click="goOrder">주문</button>
          </div>
        </div>
      </div>
    </div>

    <div class="content-wrap" v-if="category_1.length === 0 || productList.length <= 0">
      <div class="row col-top">
        <div class="alram-wrap">
          <div class="list-none" style="padding: 50px ">
              <span class="bg_symbol_15_op">
                <i class="hide">알림없음</i>
              </span>
            <p class="list-desc pt-20">준비된 상품이 없습니다.</p>
          </div>
        </div>
      </div>
    </div>

  </div>



</template>

<script>


import TopNav from "@/components/common/TopNav";

export default {
  name: "ShopMenu",
  components: {
    TopNav,
  },
  created() {
    let shopIdxParam = this.$route.params.shopIdx
    let shopNameParam = this.$route.params.shopName

    this.shopIdx = shopIdxParam === '0' ? '' : shopIdxParam  //0이면 본사
    this.shopName = shopNameParam

    this.$store.commit('setIsOrderedHeadQuarter', {
      isOrderedHeadQuarter : this.shopIdx === ''
    })

    if (shopNameParam) {
      this.$store.commit('setShopInfo', {
        shopIdx : shopIdxParam,
        shopName : shopNameParam,
      })
      this.$store.commit('setProductType', {productType : 'shop'})
    } else {
      this.$store.commit('setShopInfo', {
        shopIdx : '0',
        shopName : '',
      })
      this.$store.commit('setProductType', {productType : 'headquarter'})
    }
    //document.getElementsByClassName('h-100vh')[0].classList.toggle('styled');
  },

  mounted() {

    //this.getProductList()
    this.getCategory_1()
    this.isUseCart = this.totalQuantity > 0 && Number(this.shopIdx) === Number(this.currentCartShopIdx)
    this.shopName = this.$store.getters.getShopInfo.shopName

    //페이지 첫 라우팅시 정적으로 멈춰있는 스와이퍼 기능을 On 해준다.
    this.initSwiper()
  },
  data() {
    return {
      isSearch: false,
      showSetting: false,
      isFirstCategory: true,
      searchWord: '',
      page: '1',
      size: '10000000',
      totalQuantity: this.$store.state.totalQuantity,
      totalPrice: this.$store.state.totalPrice,
      shopIdx: '',
      shopName: '',
      selected_category_2: '',
      isUseCart: false,
      currentCartShopIdx: this.$store.getters.getCurrentCartShopInfo.currentCartShopIdx,
      selected_category_1: '',
      category_1: [],
      category_2: [],
      productList : [],
      swiper : '',
    }
  },
  computed: {
    getOrderProductQuantity() {
      return this.$store.getters.getCartProductQuantity
    },

    getExposureProducts() {
      if (this.shopIdx === '') {
        return this.productList
      }
      return this.productList.filter(o => o.exposure)
    },
  },

  methods: {
    initSwiper() {
      setTimeout(() => {
        if(this.swiper) {
          this.swiper.destroy()
        }

        /*this.swiper = new window.Swiper(".category", {
          slidesPerView: 'auto',

        })*/
        this.swiper = new window.Swiper(".category", {
          slidesPerView: 'auto',
          allowTouchMove: true,
          observer : true,
          observeParents: true
        })

      }, 100)
    },

    getProductList() {
      this.$quick_order_get(this.$QUICK_PRODUCT_LIST + '?' + 'page=' + this.page +
          '&size=' + this.size + '&shopIdx=' + this.shopIdx + '&categoryIdx=' + this.selected_category_2,
          'shop_menu', (result) => {
            this.productList = result.data
          }, (result) => {
            this.httpError(result.data)
          })
    },

    getCategory_1() {
      this.$quick_order_get(this.$QUICK_CATEGORY_LIST, 'shop_menu', (result) => {
        this.category_1 = result.data
        if (this.category_1.length > 0) {
          this.selected_category_1 =  this.category_1[0].idx
          this.getCategory_2()
        }
      },(result) => {
        this.httpError(result.data)
      })
    },

    getCategory_2() {
      this.$quick_order_get(this.$QUICK_CATEGORY_LIST + '?upperIdx=' + this.selected_category_1, 'shop_menu', (result2) => {
        this.category_2 = result2.data
        if (this.category_2.length > 0) {
          this.selected_category_2 = this.category_2[0].idx
        }
        this.getProductList()
      }, (result2) => {
        this.httpError(result2.data)
      })

    },

    setCurrentCategory(categoryIdx) {
      this.selected_category_2 = categoryIdx
      this.getProductList()
    },

    searchMenu() {
      this.$get(this.$SHOP_MENU_LIST + '/search=' + this.searchWord, 'shop_menu', (result) => {
        this.productList = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    clickFirstCategory(categoryIdx) {
      this.selected_category_1 = categoryIdx
      this.selected_category_2 = categoryIdx
      this.getCategory_2()
    },

    initCart() {
      this.$store.commit('initAllState')
      this.$store.commit('setShopInfo', {
        shopIdx : this.shopIdx,
        shopName : this.shopName,
      })
      this.isUseCart = false
    },

    goOrder() {
      if (this.shopIdx !== '') {
        this.$store.commit('setProductType', {productType : 'shop'})
        this.$router.push({name : 'Order'})
      } else {
        this.$store.commit('setProductType', {productType : 'headquarter'})
        this.$router.replace({name : 'AroundMap', params : {isHeadOfficeOrder : true}})
      }
    },

  }
}
</script>

<style scoped>
.content-wrap{  min-height: 100%; height: auto }
.cate_slide { display: inline-block; width: auto;}
.main-bg {
  height: auto;
}
</style>
<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
          <span class="title">{{ tokenType === 'AMIS' ? '아미스' : '이더리움'}} 송금하기</span>
        </div>
      </div>
    </div>

    <div class="content-wrap pt-50">
      <div class="container">
        <div class="row">
          <div class="remit-area">
            <div class="contact-method">
              <ul class="method-tab">
                <!-- TODO : 탭 선택시 .select 클래스 추가 -->
                <li class="select"><a>연락처</a></li>
                <li class=""><a @click="openQR()">QR스캔</a></li>
              </ul>
            </div>
            <hr class="hr-2">
            <div class="input-search">
              <input type="text" placeholder="이름을 검색하세요." v-model="searchKeyword">
            </div>
            <div class="contact-list" v-show="isSendSelectedContact">
              <ul>
                <li v-show="searchKeyword === ''" v-for="(contact, index) in contactList" :key="'c' + index">
                  <a class="contact" @click="selectContact(contact)">
                    <span class="user-name">{{ contact.name }}</span>
                    <i class="icon-arrow-right"></i>
                  </a>
                </li>
                <li v-show="searchKeyword !== ''" v-for="(contact, index) in searchList" :key="'s' + index">
                  <a class="contact" @click="selectContact(contact)">
                    <span class="user-name">{{ contact.name }}</span>
                    <i class="icon-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="contact-list" v-show="!isSendSelectedContact">
              <button class="l_btn btn-pink w-100" @click="selectContact(searchKeyword)">입력된 전화번호로 송금</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SendMetrContactList",
  data() {
    return {
      searchKeyword: '',
      contactList: [],
      searchList: [],
      isSendSelectedContact: true,
      tokenType : this.$route.query.tokenType
    }
  },
  mounted() {
    window.requestContact()
    window.addEventListener(this.$EVENT_QR_STRING, this.setQr)
    window.addEventListener(this.$EVENT_CONTACTS, this.setContact)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_QR_STRING, this.setQr)
    window.removeEventListener(this.$EVENT_CONTACTS, this.setContact)
  },
  methods: {
    selectContact(_contact) {
      //let type = 'reapchain'
      let type = this.tokenType === 'AMIS' ? 'AMIS' : 'ETHEREUM'

      let params = ''
      if (this.isSendSelectedContact){
        params = '?tokenType=' + type + '&hp=' + _contact.phone
      } else {
        params = '?tokenType=' + type + '&hp=' + _contact
      }

      this.$get(this.$REMITTANCE_CHECK_PHONE + params, 'sendpaycontactlist_selectcontact', (result) => {
        // let amount = result.data.amount
        let userIdx = result.data.userIdx
        let username = result.data.userName
        let address = result.data.userWalletAddress
        this.goNext(userIdx, username, address)
      }, (result) => {
        this.httpError(result.data)
      })
    },
    openQR() {
      let title = (this.tokenType === 'AMIS' ? '아미스' : '이더리움') + ' 송금하기'
      window.openQRReader(title)
    },
    setQr(e) {
      let qr = e.detail

      //test - reapchain
      let url = this.$REMITTANCE_CHECK_QR_REAPCAIN

      this.$get(url + qr, 'sendpaycontactlist_setqr', (result) => {
          let userIdx = result.data.user.userIdx
          let username = result.data.user.userName
          let address = result.data.user.address
          this.goNext(userIdx, username, address)

      }, (result) => {
        this.httpError(result)
      })
    },
    setContact(e) {
      this.searchKeyword = ''
      let contacts = e.detail
      this.contactList = JSON.parse(contacts)
    },
    goNext(_userIdx, _username, _address) {
      this.$router.replace({
        name: 'SendMetrAmount', params: {
          userIdx: _userIdx,
          username: _username,
          address: _address,
          tokenType : this.tokenType,
        }
      })
    }
  },
  watch: {
    searchKeyword() {
      this.searchList = []
      if (this.searchKeyword !== '') {
        if (/^[0-9]*$/.test(this.searchKeyword) && (this.searchKeyword.length === 10 || this.searchKeyword.length === 11)) {  /*전화번호 입력 시*/
          this.isSendSelectedContact = false

        } else { /*전화번호 입력이 아닐 경우*/
          this.isSendSelectedContact = true
          for (let i = 0; i < this.contactList.length; i++) {
            let contact = this.contactList[i]
            if (contact.name.indexOf(this.searchKeyword) != -1) {
              this.searchList.push(contact)
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

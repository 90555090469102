<template>

    <div class="wrap">
      <div class="header-wrap" v-show="!loading">
        <div class="container">
          <div class="header-group">
            <h1><img src="/assets/image/header_logo.png" alt="아미스"></h1>
            <button type="button" class="nav-btn right" @click="$router.back()"><i class="icon-close"></i></button>
          </div>
        </div>
      </div>
      <div class="content-wrap pt-50" v-show="!loading">
        <div class="section-password">
          <div class="area-password">
            <div class="instruction">
              <div class="container">
                <div class="row">
                  <h3>PIN</h3>
                  <h4>핀번호를 입력해 주세요.</h4>
                </div>
              </div>
            </div>
            <div class="password">
              <div class="container">
                <div class="row">
                  <span class="blind">비밀번호 6자리는 입력하면 자동전환됩니다.</span>
                  <!-- TODO : 비밀번호 입력 시 .on 클래스 추가 -->
                  <span id="key_1" class="word" :class="{'on' : pin.length >= 1}"></span>
                  <span id="key_2" class="word" :class="{'on' : pin.length >= 2}"></span>
                  <span id="key_3" class="word" :class="{'on' : pin.length >= 3}"></span>
                  <span id="key_4" class="word" :class="{'on' : pin.length >= 4}"></span>
                  <span id="key_5" class="word" :class="{'on' : pin.length >= 5}"></span>
                  <span id="key_6" class="word" :class="{'on' : pin.length >= 6}"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="area-keyboard">
            <div class="container">
              <div class="pin_find" v-show="failCount >= 3">
                <a href="">핀번호를 잊어버렸어요.<i class="icon-arrow-right"></i></a>
              </div>
              <table class="keyboard">
                <tbody>
                <tr>
                  <td>
                    <button type="button" class="key" @click="numBtn('1')">
                      <span>1</span>
                    </button>
                  </td>
                  <td>
                    <button type="button" class="key" @click="numBtn('2')">
                      <span>2</span>
                    </button>
                  </td>
                  <td>
                    <button type="button" class="key" @click="numBtn('3')">
                      <span>3</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button type="button" class="key" @click="numBtn('4')">
                      <span>4</span>
                    </button>
                  </td>
                  <td>
                    <button type="button" class="key" @click="numBtn('5')">
                      <span>5</span>
                    </button>
                  </td>
                  <td>
                    <button type="button" class="key" @click="numBtn('6')">
                      <span>6</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button type="button" class="key" @click="numBtn('7')">
                      <span>7</span>
                    </button>
                  </td>
                  <td>
                    <button type="button" class="key" @click="numBtn('8')">
                      <span>8</span>
                    </button>
                  </td>
                  <td>
                    <button type="button" class="key" @click="numBtn('9')">
                      <span>9</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button type="button" class="key" @click="$router.back()">
                      <span class="cancel">취소</span>
                    </button>
                  </td>
                  <td>
                    <button type="button" class="key" @click="numBtn('0')">
                      <span>0</span>
                    </button>
                  </td>
                  <td>
                    <button type="button" class="key" @click="backspace">
                      <span class="icon delete">지우기</span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Loading v-show="loading"></Loading>
    </div>



</template>

<script>
import Loading from "@/components/common/Loading";
export default {
  name: "PaymentPin",
  components: {Loading},
  props : {
    isCardPayment : Boolean,
  },
  data() {
    return {
      show: false,
      pin: '',
      failCount : 0,
      metrQrCode : '',
      cardBarCode : '',
      loading : false,
    }
  },
  mounted() {
    let cart = this.$store.getters.getCartList
    if (cart.length <= 0) {
      if (this.$store.getters.getShopInfo.shopIdx > 0) {
        let shopInfo = this.$store.getters.getShopInfo
        this.$router.replace({name : 'ShopMenu', params : {shopIdx : shopInfo.shopIdx, shopName : shopInfo.shopName}})
      } else {
        this.$router.replace({name : 'ShopMenu', params : {shopIdx : '0', shopName : ''}})
      }
    }

    let paymentType = this.$store.getters.getPaymentType
    if (paymentType === 1) {
      this.getMetrCode()
    } else if (paymentType === 2) {
      this.getCardBarCode()
    }
/*    this.getCardBarCode()
    this.getMetrCode()*/
  },
  methods: {
    numBtn(_num) {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin + _num
      if (this.pin.length === 6) {
        this.checkPin()
      }
    },
    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },

    cancel() {
      this.close()
    },
    bioResult(e) {
      let pin = e.detail
      if (localStorage.getItem('useLocalBio') === 'true') {
        if (pin.length === 6) {
          this.pin = pin
          this.checkPin()
        } else {
          this.notify('error', '생체 인증에 실패 했습니다.')
        }
      }
    },
    checkPin() {
      let formData = new FormData()
      formData.append('pin', this.pin)
      this.$post(this.$AUTH_CHECK_PIN, 'pincheck_numbtn', formData, () => {
        this.isLoading = true
        this.failCount = 0
        this.pin = ''

        this.finalPayment()
      }, (result) => {
        this.httpError(result.data)
        this.isLoading = false
        this.pin = ''
        this.failCount++
      })
    },



    finalPayment() {

      let cart = this.$store.getters.getCartList
      let useMetrPoint = this.$store.getters.getUseMetrPoint
      let paymentType = this.$store.getters.getPaymentType
      let totalPrice = this.$store.getters.getTotalPrice
      let shopInfo = this.$store.getters.getShopInfo
      let userIdx = this.$store.getters.getUserIdx
      let userCode = ''
      let productName = this.$store.getters.getOrderMenuName

      let formData = new FormData()
      formData.append('payType',  paymentType)
      formData.append('price', totalPrice)
      formData.append('productName', productName)  //FIXME 상품명도 고쳐야함
      formData.append('productType', this.$store.getters.getProductType)

      this.setProductsFormData(cart, formData)

      //결제타입이 아미스 결제일 경우
      if (paymentType === 1) {
        formData.append('reward', useMetrPoint)
      }

      formData.append('shopIdx', shopInfo.shopIdx)
      formData.append('shopName', shopInfo.shopName)

      if (paymentType === 1) {
        userCode = this.metrQrCode
      } else if (paymentType === 2) {
        userCode = this.cardBarCode
      }

      formData.append('userCode', userCode)
      formData.append('userIdx', userIdx)

      this.loading = true

      this.$quick_order_post(this.$QUICK_PAYMENT, 'quickOrderLogin', formData, (result) => {
        this.$store.commit('setOrderMenuName', {orderMenuName : productName})
        if (this.isCardPayment) {
          this.$router.replace({name : 'PaymentComplete', params : {isCardPayment : this.isCardPayment, metrPoint : ''}})
        } else {
          this.$router.replace({name : 'PaymentComplete', params : {isCardPayment : this.isCardPayment, metrPoint : result.data.point}})
        }
      }, (error) => {
        this.$router.replace({name : 'PaymentFailed', params : {shopIdx : this.$store.getters.getShopInfo.shopIdx, nonOrderableProductsIdxs : error.data.data}})
      })


    },

    getCardBarCode() {
      this.$get(this.$CREDITCARD_LIST, 'paymentCardCode', (result) => {
        if (result.data.length <= 0) {
          this.notify('error', '등록된 카드가 없습니다.', false)
          return
        }

        for (let i = 0; i < result.data.length; i++) {
          let card = result.data[i]
          if (card['mainCard']) {
            let formData = new FormData
            formData.append('cardIdx', card.idx)
            this.$post(this.$PAYMENT_CARD_BARCODE, 'paymentcard_getcode', formData, (result) => {
              this.cardBarCode = result.data[0]
              console.log('리프레시 cardCode : ' + this.cardBarCode)
              //this.finalPayment()
            }, () => {})

            break
          }
        }

      }, () => {

      })
    },

    getMetrCode() {
      let formData = new FormData
      formData.append('type',1)
      formData.append('reward', this.$store.getters.getUseMetrPoint)

      this.$post(this.$PAYMENT_QR_BARCODE, 'paymentpay_getcode2', formData, (result) => {
        this.metrQrCode = result.data.qr
        console.log('리프레시 metrCode : ' + this.metrQrCode)
        //this.finalPayment()
      }, () => {})
    },


    setProductsFormData(cart, formData) {

      for (let i=0; i < cart.length; i++) {
        formData.append('products[' +i+ '].idx', cart[i].productIdx)
        formData.append('products[' +i+ '].count', cart[i].quantity)

        let optionGroups = cart[i].optionGroups

        for (let j=0; j < optionGroups.length; j++) {
          formData.append('products[' +i+ '].optionGroups[' +j+ '].idx', optionGroups[j].optionGroupIdx)

          //let options = optionGroups[j].options
          formData.append('products[' +i+ '].optionGroups[' +j+ '].options[' +0+ '].idx', optionGroups[j].optionIdx)
/*
          for (let k=0; k < options.length; k++) {

            formData.append('products[' +i+ '].optionGroups[' +j+ '].options[' +0+ '].idx', optionGroups[j].optionIdx)
          }
*/
        }
      }
    },

  }
}
</script>

<style scoped>
.header-wrap { position: fixed; width: 100%; top: 0; z-index: 10; box-sizing: border-box; background: var(--white); }
.header-wrap .header-group h1 { display: inline-block; width: 110px; height: auto; }

.area-keyboard .pin_find { position: absolute; left: 50%; transform: translateX(-50%); margin-top: -50px }
.area-keyboard .pin_find i { padding-left: 6px; color: var(--dark-gray02); font-size: 10px; vertical-align: 1px;}

</style>
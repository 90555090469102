<template>
<div>
  <!-- 화면 ID : UI-16 -->
  <div class="wrap main-bg">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">주문</span>
          <button type="button" class="nav-btn right" @click="close"><i class="icon-close"></i></button>
        </div>
        <div class="header-add">
          <h2>결제수단</h2>
          <div class="button-group">
            <button class="outline_m_btn" @click="paymentType = 2" :class="{'btn-black' : paymentType === 2, 'btn-line-black' : paymentType !== 2}">신용카드</button>
            <button class="outline_m_btn" @click="paymentType = 1" :class="{'btn-black' : paymentType === 1, 'btn-line-black' : paymentType !== 1}">METERRA</button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrap pt-160 pb-150">
      <div class="container">
        <div class="row">
          <div class="order_list">
            <ul class="list_type4">
              <h2>주문메뉴</h2>

              <li v-for="product in cart" :key="product.idx">
                <div class="slot type3 slot-history">
                  <!-- 메뉴 요약 -->
                  <div class="menu_summary clear">
                    <div class="left">
                      <img class="munu_img" :src="removeHost(product.productImg)" alt="">
                    </div>
                    <div class="right">
                      <strong class="ellipsis2">{{ product.productName }}</strong>
                      <ul class="option">
                        <li v-for="optionGroup in product.optionGroups" :key="optionGroup.optionGroupIdx">{{ optionGroup.optionName }}</li>
                      </ul>
                    </div>
                  </div>
                  <!-- //메뉴 요약 -->
                  <!-- 수량선택 -->
                  <div class="info_area type2 pt-10">
                    <div class="count">
                      <button class="btn_count_down" :disabled="product.quantity <= 1" @click="countQuantity(product.productIdx, false)"><span class="hide">수량빼기</span></button>
                      <input type="number" :value="product.quantity">
                      <button class="btn_count_up" @click="countQuantity(product.productIdx, true)"><span class="hide">수량더하기</span></button>
                    </div>
                    <strong class="price">{{ product.productPrice * product.quantity | numberWithCommas}}원</strong>
                  </div>
                  <!-- //수량선택 -->
                  <div class="slot_btn">
                    <button class="m_close" aria-label="닫기" @click="openDeleteModal(product.productIdx, product.symbol)"></button>
                    <button class="m_option" aria-label="옵셥설정" @click="modifyOption(product.productIdx, product.symbol)"></button>
                  </div>
                </div>
              </li>


            </ul>
          </div>
        </div>
      </div>
      <!-- TODO : .popup.type1 실행시 content-wrap에 pb-150 클래스 추가해주세요 -->
      <div class="popup type1">
        <div class="container">
          <div class="inner">
            <div class="info_area type1">
              <p class="total">총 결제금액 <span class="ordernum">{{ $store.getters.getTotalQuantity }}개</span></p>
              <strong class="price">{{ $store.getters.getTotalPrice | numberWithCommas}}원</strong>
            </div>
            <div class="button-group mt-20">
              <button class="outline_l_btn btn-black" @click="goOrderPayment">결제</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- //화면 ID : UI-16 -->

  <!-- 화면 ID : UI-16-1 -->
  <!-- TODO: 안내 확인창 -->
  <div v-if="deleteModal">
    <div class="madal">
      <div class="container">
        <div class="section_confirm">
          <div class="confirm-content">
            <div class="permit-group">
              <div class="confirm-box">
                <img src="/assets/image/metetta_row.png" class="update-logo" alt="meterra">
                <h3 class="confirm-title">메뉴 삭제</h3>
              </div>
              <hr class="hr-1">
              <div class="confirm-box">
                <p class="confirm-guid">해당 메뉴를 삭제하시겠습니까?</p>
              </div>
            </div>
            <div class="button-wrap">
              <div class="button-group modal">
                <button class="btn btn-gray" @click="deleteModal = false">취소</button>
                <button class="btn btn-black" @click="removeCartProduct">확인</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dimmed"></div>
  </div>
  <!-- //화면 ID : UI-16-1 -->
</div>
</template>

<script>
export default {
  name: "Order",
  data() {
    return {
      deleteModal : false,
      paymentType : 2, //1.Metr, 2.신용카드 (기본값은 카드)
      cart : [],
      removeProductIdx : '',
      removeProductSymbol : '',
      shopIdx : this.$store.getters.getShopInfo.shopIdx,
      shopName : this.$store.getters.getShopInfo.shopName
    }
  },
  mounted() {
    this.cart = this.$store.getters.getCartList
    if (this.cart.length <= 0) {
      if (this.$store.getters.getShopInfo.shopIdx > 0) {
        let shopInfo = this.$store.getters.getShopInfo
        this.$router.replace({name : 'ShopMenu', params : {shopIdx : shopInfo.shopIdx, shopName : shopInfo.shopName}})
        return;
      } else {
        this.$router.replace({name : 'ShopMenu', params : {shopIdx : '0', shopName : ''}})
        return
      }
    }

  },

  methods: {
    close() {
      let productType = this.$store.getters.getProductType
      if (productType === 'shop') {
        this.$router.replace({name : 'ShopMenu', params : {shopIdx : this.shopIdx, shopName : this.shopName}})
      } else {
        this.$router.replace({name : 'ShopMenu', params : {shopIdx : '0', shopName : ''}})
      }
    },
    modifyOption(productIdx, symbol) {  //옵션수정
      let shopIdx = '0'
      if (!this.$store.getters.isOrderedHeadQuarter) {
        shopIdx = this.shopIdx
      }
      this.$router.replace({name : 'MenuDetail', params : { productIdx : productIdx, shopIdx : shopIdx, isModify : 'true', symbol : symbol }})
    },
    countQuantity(productIdx, isIncrement) {
      if (isIncrement) {
        this.$store.commit('countQuantity', {productIdx : productIdx, isIncrement : true})
      } else {
        this.$store.commit('countQuantity', {productIdx : productIdx,isIncrement : false})
      }
      //this.$forceUpdate()  //데이터가 바뀌어도 렌더링되지 않아 강제로 렌더링시켜준다.
    },

    removeCartProduct() {
      this.$store.commit('removeCartProduct', {productIdx : this.removeProductIdx, symbol : this.removeProductSymbol})
      this.deleteModal = false
    },

    //장바구니 상품삭제 모달을 띄워준다.
    openDeleteModal(productIdx, symbol) {
      this.deleteModal = true
      this.removeProductIdx = productIdx
      this.removeProductSymbol = symbol
    },

    //마지막 결제수단을 함께 지정하고 결제페이지로 이동
    goOrderPayment() {
      this.$store.commit('setPaymentType', {paymentType : this.paymentType})
      this.$router.push({name : 'OrderPayment'})
    },
  },
}
</script>

<style scoped>

.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
.header-wrap .header-group { position: relative; width: 100%; display: inline-block; text-align: center; height: 50px; line-height: 48px; /*background: var(--white);*/ }
.button-group .btn { width: 100%; padding: 17px 0; font-size: 16px; font-weight: 500; color: var(--white);}
.button-group button + button { margin-left: 14px; }
.outline_m_btn { height: 40px; padding: 0 10px; min-width: 70px; width: 100%; background: var(--white); border-radius: 20px;  font-size: 14px; font-weight: 500; cursor: pointer;}
.btn-black { background: var(--meterra-black); color: #fff; }
/* button */
.button-wrap { position: relative; width: 100%;} /* 버튼 두개 사용가능 */

.button-group.modal button + button { margin-left: unset; }

.slot_btn .m_close { border-radius: 0 12px 0 0; }
.slot_btn .m_option { position: absolute; right: 0; top: 30px; border-radius: 0 0 0 12px; }
</style>
<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          <span class="title">FAQ/문의</span>
        </div>
        <div class="header-wrap pay-tab mt-50">
          <div class="active">FAQ</div>
          <div @click="$router.replace({name:'QnaList'})">문의</div>
        </div>
      </div>
    </div>
    <div class="notice-content-wrap pt-110">
      <div class="container">
        <div class="faq-search-group">
          <div class="input-search">
            <input type="text" placeholder="검색어를 입력하세요." v-model="searchKeyword"
                   @update="(_model) => {this.searchKeyword=_model}">
          </div>
        </div>
        <!-- TODO : FAQ 있을 경우 -->
        <div class="notice-list"  v-if="searchKeyword === ''">
          <ul>
            <li class="border-bottom"  v-for="(category, index) in faqCategory" :key="'c' + index">
              <a @click="goFaqSubList(category)">
                <p class="mr-20 fs-16">
                  {{ category.name }}
                </p>
                <i class="icon-arrow-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="notice-list"  v-else-if="items.length > 0">
          <ul>
            <li v-for="(item, index) in items" :key="'i' + index">
              <a @click="goDetail(item.idx)">
                <p class="mr-20 fs-16">
                  {{ item.title }}
                </p>
                <i :class="{'icon-arrow-down':item.idx !== selectedIdx, 'icon-arrow-up':item.idx === selectedIdx}"></i>
              </a>
              <div class="bg-gray p-1">
                <div v-show="item.idx === selectedIdx" class="p-20">
                  <div>
                    <div v-html="item.content"></div>
                  </div>
                  <div class="pt-10" v-for="(img, imgIndex) in imageList" :key="'i' + imgIndex">
                    <img :src="removeHost(img.imagePath)" alt="FAQ 이미지">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <!-- TODO : 문의 없을 경우 -->
        <div class="alram-wrap" v-if="searchKeyword.length === 0 && faqCategory.length === 0">
          <div class="list-none">
            <i class="bg_symbol_15_op mb-20"></i>
            <p class="list-desc">등록된 문의가 없습니다.</p>
          </div>
        </div>

<!--         TODO : 입력된 검색어가 없을 경우
        <div class="alram-wrap h-100-110" v-else-if="searchKeyword.trim() === ''">
          <div class="list-none">
            <i class="bg_symbol_15_op mb-20"></i>
            <p class="list-desc">검색어를 입력하세요.</p>
          </div>
        </div>-->

        <!-- TODO : FAQ 검색 결과가 없을 경우 -->
        <div class="alram-wrap h-100-110" v-else-if="searchKeyword.length > 0 && items.length === 0">
          <div class="list-none">
            <i class="bg_symbol_15_op mb-20"></i>
            <p class="list-desc">검색 결과가 없습니다.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqList",
  data() {
    return {
      searchKeyword: '',
      items: [],
      faqCategory: [],
      selectedIdx: '',
    }
  },
  mounted() {
    this.$get(this.$FAQ_CATEGORY, 'faqlist_mounted', (result) => {
      this.faqCategory = result.data
    }, (result) => {
      this.httpError(result.data)
    })
  },
  methods: {
    getFaq() {
      if (this.searchKeyword.trim() === '') {
        return
      }
      this.selectedIdx = ''
      this.$get(this.$FAQ_LIST + '?keyword=' + this.searchKeyword, 'faqlist_getfaq', (result) => {
        this.items = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goFaqSubList(_category) {
      this.$router.push({name: 'FaqSubList', params: {idx: _category.idx, title: _category.name}})
    },
    goDetail(_no) {
      if (this.selectedIdx === _no) {
        this.selectedIdx = ''
      } else {
        this.selectedIdx = _no
      }
    }
  },
  watch: {
    searchKeyword() {
      this.getFaq()
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

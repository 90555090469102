<template>
  <div class="wrap">
    <!-- 상단 고정 해더 영역 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">비밀번호 변경</h5>
            <div class="w-100">
              <div class="pt-30">
                <MTRInput
                    :type="'password'"
                    :placeholder="'비밀번호(영문, 숫자, 특수문자 포함 8자 이상)'"
                    :model="password"
                    @update="(_model) => {this.password=_model}"
                />
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.password">{{ errors.password }}</p>
              <div class="pt-10">
                <MTRInput
                    :type="'password'"
                    :placeholder="'비밀번호를 한번 더 입력해 주세요.'"
                    :model="passwordCk"
                    @update="(_model) => {this.passwordCk=_model}"
                />
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.passwordCk">{{ errors.passwordCk }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button type="button" class="btn btn-black" :class="{'btn-disable' : password.length <= 0 || passwordCk.length <= 0}" :disabled="passwordCk.length <= 0 || passwordCk.length <= 0" @click="changePassword()">비밀번호 변경</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTRInput from "../../../common/MTRInput";
export default {
  name: "FindPwChange",
  components: {
    MTRInput
  },
  data() {
    return {
      id: '',
      nice: '',
      password: '',
      passwordCk: '',
      errors: {
        password:'',
        passwordCk: '',
      }
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.nice = this.$route.params.auth
  },
  methods: {
    errorClear() {
      this.errors = {password: '', passwordCk: ''}
    },
    changePassword() {
      this.errorClear()
      if (this.password === '') {
        this.errors.password = '영문, 숫자, 특수문자 포함 8자 이상 사용하세요.'
        return
      }
      if (this.passwordCk === '') {
        this.errors.passwordCk = '비밀번호를 한번 더 입력해주세요.'
        return
      }
      if (this.passwordCk !== this.password) {
        this.errors.passwordCk = '비밀번호가 일치하지 않습니다.'
        return
      }
      let formData = new FormData()
      // formData.append('hp', this.phone.trim())
      formData.append('auth', this.nice)
      formData.append('id', this.id)
      formData.append('password', this.password)
      formData.append('passwordCk', this.passwordCk)

      //test
      this.$put(this.$AUTH_UPDATE_FIND_PW, 'findpwchange_changepassword', formData, () => {
        this.$router.replace({ name: 'FindPwChangeFinish'})
      }, (result) => {
        if (result.data.error.length > 0) {
          for (let i=0;i<result.data.error.length;i++) {
            let err = result.data.error[i]
            this.errors[err.field] = err.message
          }
        } else if (result.data.message) {
          this.errors.password = result.data.message
        } else {
          this.errors.password = '사용할 수 없는 비밀번호입니다.'
        }
      })
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

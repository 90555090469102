<template>
  <div class="main-wrap">
    <div class="wrap main-bg" :class="{'preventScroll' : isPreventScroll}">
      <div class="content-wrap pb-120">
        <div class="container">
          <div class="row">
            <!-- 사용자 정보 -->
            <div class="main-article pt-15">
              <div class="card-user">

                <h1 class="logo"><a>
                  <img src="/assets/image/logo_circle.png" alt="아미스">
                </a></h1>

                <div class="flex">
                  <div class="sc-alarm">
                    <a class="alarm-group" @click="$router.push({name:'NotificationList'})">
                      <i class="icon alarm-b">알림</i>
                      <!-- TODO : 새로운 알림이 있을 경우에만 클래스 new , style = 'display : block' -->
                      <i class="blind new" v-show="item.newNotification" style="display: block;">NEW</i>
                    </a>
                  </div>
                  <div class="sc-profile ml-10">
                    <div class="sc-thumb-box">
                      <span class="icon noimage" v-if="!item.imagePath">
                        <span class="blind">기본이미지</span>
                      </span>

                      <img v-else class="noimage" :src="removeHost(item.imagePath)" alt="사용자 사진">
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- 사용자 정보 -->
<!--            <div class="main-article pt-15">
              <div class="card-user">
                <div class="sc-profile">
                  <div class="sc-thumb-box">
                    <span class="icon noimage" v-if="!item.imagePath">
                      <span class="blind">기본이미지</span>
                    </span>
                    <img v-else class="noimage" :src="removeHost(item.imagePath)" alt="사용자 사진">
                  </div>
                  <div class="sc-info-box">
                    <strong class="sc-name">{{ item.id }}</strong>
                  </div>
                </div>
                <div class="sc-alarm">
                  <a class="alarm-group" @click="$router.push({name:'NotificationList'})">
                    <i class="icon alarm-b">알림</i>
                    &lt;!&ndash; TODO : 새로운 알림이 있을 경우에만 클래스 new , style = 'display : block' &ndash;&gt;
                    <i v-show="item.newNotification" class="blind new">NEW</i>
                  </a>
                </div>
              </div>
            </div>-->


            <!-- 컨텐츠 내용 -->
            <div class="main-article">
              <!-- 지갑 -->
              <div class="main-wallet">
                <div class="sc-card-box">
                  <div class="sc-card card-full flex-col">
                    <div class="slot slot-type1">
                      <div class="slot-inner">
                        <div class="wallet-head">
                          <img src="/assets/image/logo_row02_gray.png" alt="metera">
                          <button class="circle-btn" @click="refreshBalance()">
                            <i class="icon-reload white"></i>
                          </button>
                        </div>
                        <div class="wallet-bady">
                          <div class="total">
<!--                            <strong class="data">{{ item.tokenAmount | numberWithCommas | rewardDecimal }}</strong><strong class="data2">.{{ getMetrDecimalPlace(item.tokenAmount) }}</strong><span class="unit">AMIS</span>-->
                            <strong class="data">{{ item.tokenAmount | numberWithCommas | rewardDecimal }}<em class="point">.{{ getMetrDecimalPlace(item.tokenAmount) }}</em></strong><span class="unit">AMIS</span>
                          </div>
                          <div class="exchange">
                            <span class="data">{{ Math.floor(item.tokenAmount * rate) | numberWithCommas }}</span><span
                              class="unit">원</span>
                            <span class="rate"> (1AMIS=<em>{{ rate | numberWithCommas }}</em>원)</span>
                          </div>
                          <button class="qr-btn circle-btn"
                                  @click="openReceive('')">
                            <i class="icon slot-qr"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- FIXME -->
                    <div class="slot slot-type1 mt-12">
                      <div class="slot-inner">
                        <div class="wallet-head">
                          <img src="/assets/image/eth_row02_gray.png" alt="ETH">
                          <button class="circle-btn" @click="refreshBalance()">
                            <i class="icon-reload" style="color: #fff;"></i>
                          </button>
                        </div>
                        <div class="wallet-bady">
                          <div class="total">
                            <strong class="data">{{ ethAmount | numberWithCommas | rewardDecimal }}<em class="point">.{{ getMetrDecimalPlace(ethAmount) }}</em></strong><span class="unit">ETH</span>
                          </div>
                          <div class="exchange">
                            <span class="data">{{ Math.floor(ethAmount * ethRate) | numberWithCommas }}</span><span class="unit">원</span>
                            <span class="rate"> (1ETH=<em>{{ ethRate | numberWithCommas }}</em>원)</span>
                          </div>
                          <button class="qr-btn circle-btn"
                                  @click="openReceive('ETH')">
                            <i class="icon slot-qr"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="card-wide">
                    <div class="sc-card">
                      <a class="slot-folder" @click="openSheetBar('receive')">
                        <div class="slot-inner">
                          <i class="icon ver_2 ico_slot1">송금받기</i>
                          <span class="sl-title">송금받기</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="card-wide">
                    <div class="sc-card">
<!--                      <a class="slot-folder" @click="$router.push({name:'SendMetrContactList'})">-->
                      <a class="slot-folder" @click="openSheetBar('send')">
                        <div class="slot-inner">
                          <i class="icon ver_2 ico_slot2">송금보내기</i>
                          <span class="sl-title">송금보내기</span>
                        </div>
                      </a>
                    </div>
                  </div>

                </div>
              </div>

              <!-- 배너광고 -->
              <div class="sc-card-box pb-5" v-if="eventList.length > 0">
                <div class="card-full">
                  <div class="sc-card banner">
                    <div class="page-control">
                      <div class="slide_count"></div>
                    </div>
                    <div class="swiper-container main-banner">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide slot ss1"
                             v-for="event in eventList"
                             :style="{'background' : 'url(' + removeHost(event.imagePath) + ')no-repeat center center', 'background-size' : 'cover'}"
                             :key="event.idx"></div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 최근 결제 내역 -->
              <div class="sc-card-box" v-for="transaction in item.transactionList" :key="transaction.idx">
                <!-- TODO : 내역 있음-->
                <div class="card-full">
                  <div class="sc-card">
                    <a class="slot slot-history">
                      <div class="slot-inner">
                        <div class="hs-text">
                          <span class="hs-tit">{{ transaction.title }}</span>
                          <span class="hs-date">{{ transaction.regDate }}</span>
                        </div>
                        <div class="hs-data">
                          <p><strong>{{ transaction.amount }}</strong><i class="unit">AMIS</i></p>
                          <span class="data-result">{{ transaction.ts_type }}</span>
                        </div>
                      </div>

<!--                      <div class="slot-inner">
                        <div class="hs-none">
                          최근 이용내역이 없습니다.
                        </div> v-for="transaction in item.transactionList" :key="transaction.idx"
                      </div>-->


                    </a>
                  </div>
                </div>
              </div>

              <!-- 최근 결제 내역 없음 -->
              <div class="sc-card-box" v-if="item.transactionList.length <= 0">
                <!-- TODO : 내역 있음-->
                <div class="card-full">
                  <div class="sc-card">
                    <a class="slot slot-history">
                      <div class="slot-inner">
                        <div class="hs-none">
                          최근 이용내역이 없습니다.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 하단 네비영역 -->
    <div class="nav-wrap nav-pay z-index-399">
      <div class="container">
        <div class="nav-row">
          <div class="nav-group">
            <!-- TODO : 네비로 이동시 nav-landing active class 추가 -->
            <div class="nav-group-box">
              <a class="nav-landing" @click="$router.push({name:'LockUpList'})">
                <i class="icon ver_2 asset1">락업</i>
                <span class="landing-name">락업</span>
              </a>
              <a class="nav-landing" @click="openSheetBar('trade')">
                <i class="icon ver_2 asset2">내역</i>
                <span class="landing-name">내역</span>
              </a>
            </div>
            <div class="nav-group-box">
              <a class="nav-landing" @click="openSheetBar('withdraw')">
                <i class="icon ver_2 asset3">출금</i>
                <span class="landing-name">출금</span>
              </a>
              <a class="nav-landing" @click="$router.push({name:'MyPage'})">
                <i class="icon ver_2 asset4">마이</i>
                <span class="landing-name">마이</span>
              </a>
            </div>
          </div>
          <div class="payment-group">
            <a class="nav-payment" @click="openSheetBar('send')">
              <div class="payment-area">
              </div>
              <span class="payment-name">송금</span>
            </a>
          </div>
        </div>
      </div>
      <!-- //하단 네비영역 -->
    </div>

    <payment-q-r
        v-show="showPaymentQR"
        @close="closePopup"
    />

    <receive-q-r
        v-if="showReceive"
        @close="closePopup"
        :token-type="receiveTokenType"
    />

    <payment-real
        ref="Payment"
        v-if="showPayment_real"
        @close="closePopup"
    />

    <pin-check
        v-if="isFirstPinChecked === '0'"
        ref="PinCheck"
        :title="'PIN 확인'"
        :amount="0"
        :unit="''"
        :sub-amount="0"
        :sub-unit="''"
        :show-close="false"
    />

    <trade-history-sheet-bar v-if="isShowTradeSheetBar" @close="closeTradeSheetBar"></trade-history-sheet-bar>
    <ReceiveTokenSheetBar v-if="isShowReceiveSheetBar" @close="closeTradeSheetBar" @openSheetBar="openReceive"></ReceiveTokenSheetBar>
    <SendTokenSheetBar v-if="isShowSendSheetBar" @close="closeTradeSheetBar" @openSheetBar="openReceive"></SendTokenSheetBar>
    <WithdrawalSheetBar v-if="isShowWithdrawSheetBar" @close="closeTradeSheetBar" @openSheetBar="openReceive"></WithdrawalSheetBar>
  </div>

</template>

<script>
import PaymentQR from "../payment/PaymentQR";
import ReceiveQR from "../receive/ReceiveQR";
import PinCheck from "../common/PinCheck";

import PaymentReal from "@/components/payment/PaymentReal";

import TradeHistorySheetBar from "./TradeHistorySheetBar";
import ReceiveTokenSheetBar from "@/components/main/ReceiveTokenSheetBar";
import SendTokenSheetBar from "@/components/main/SendTokenSheetBar";
import WithdrawalSheetBar from "@/components/main/WithdrawalSheetBar";

export default {
  name: "Main",
  components: {
    PaymentReal,
    ReceiveQR,
    PaymentQR,
    PinCheck,
    TradeHistorySheetBar,
    ReceiveTokenSheetBar,
    SendTokenSheetBar,
    WithdrawalSheetBar,
  },
  data() {
    return {
      item: {
        imagePath: '',
        id: '',
        newNotification: false,
        tokenAmount: '0',
        eventList: [],
        transactionList: [], // 리스트 대신 최근 이용내역 한 건만 내려주게 수정 필요
        usePin: true,
        useReapChain: true,
      },
      receiveTokenType : '',
      ethAmount: '0',
      showPaymentQR: false,
      showReceive: false,
      showPayment_real: false,
      rate: 0,
      ethRate: 0,
      quickOrderTitle: "최근 퀵오더",
      aroundTitle: "내 주변",
      gps_use: null, //gps의 사용가능 여부
      gps_lat: null, // 위도
      gps_lng: null, // 경도
      recentOrders : [],
      isShowTradeSheetBar : false,
      isShowReceiveSheetBar : false,
      isShowSendSheetBar : false,
      isShowWithdrawSheetBar : false,
      isFirstPinChecked : '0',
      orderAlarmList : [],
      eventList : [],
      isPreventScroll : false,
    }
  },

  mounted() {

    //액세스 토큰 유무검사
    let accessToken = sessionStorage.getItem('accessToken')
    if (!accessToken) {
      this.clearStorage()
      this.notify('', '로그인이 필요한 서비스입니다..', false)
      this.$router.replace({name : 'BeforeLogin'})
    } else {
      //앱 종료 후 재실행시 메인화면에서 비정상적인 인증 토큰 오휴를 방지하기 위함
      console.log('실행1')
      this.refreshTokenFromMain(this.afterMounted())
    }
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_REFRESH, this.refreshBalance)
    window.removeEventListener(this.$EVENT_SHAKED, this.openPaymentShaked)
  },
  methods: {

    afterMounted() {
      //핀 인증여부를 가져온다.
      this.isFirstPinChecked = localStorage.getItem('firstPinChecked')

      //TOKEN-KRW (ARMIS-KRW) 의 시세를 가져온다
      this.getAmisRate()
      this.getEthRate()

      //메인화면에 필요한 정보를 가져온다.
      this.$get(this.$MAIN_INFO, 'main_mounted', (result) => {
        this.item = result.data
        this.eventList = this.item.eventList

        this.$store.commit('setUserIdx', {userIdx : this.item.idx})
        setTimeout(() => {
          this.initSwiper()
        }, 100)

        this.checkPinOrBio(this.item)

      }, () => {
        //this.httpError(result.data)
      })

      window.addEventListener(this.$EVENT_REFRESH, this.refreshBalance)
      window.addEventListener(this.$EVENT_SHAKED, this.openPaymentShaked)

      //ETH 잔액을 가져옴
      this.getEthBalance(false)
    },

    getAmisRate() {
      //TOKEN-KRW (ARMIS-KRW) 의 시세를 가져온다
      this.$get(this.$TOKEN_PRICE, this.$options.name + '_mounted', (result) => {
        this.rate = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },

    getEthRate() {
      //TOKEN-KRW (ARMIS-KRW) 의 시세를 가져온다
      this.$get(this.$ETH_PRICE, 'eth-rate', (result) => {
        this.ethRate = result.data
      }, () => {})
    },


    //거래내역 하단 시트바 오픈
    openSheetBar(sheetBarType = 'trade') {
      this.isShowReceiveSheetBar = false
      this.isShowSendSheetBar = false
      this.isShowWithdrawSheetBar = false
      this.isShowTradeSheetBar = false
      this.showPaymentQR = false
      this.showReceive = false
      this.showPayment_real = false
      this.isPreventScroll = true

      if (sheetBarType === 'trade') {
        this.isShowTradeSheetBar = true
      } else if (sheetBarType === 'receive')  {
        this.isShowReceiveSheetBar = true
      } else if (sheetBarType === 'send') {
        this.isShowSendSheetBar = true
      } else if (sheetBarType === 'withdraw') {
        this.isShowWithdrawSheetBar = true
      }
    },

    closeTradeSheetBar() {
      this.isShowTradeSheetBar = false
      this.isShowReceiveSheetBar = false
      this.isShowSendSheetBar = false
      this.isShowWithdrawSheetBar = false
      this.isPreventScroll = false
    },


    refreshBalance() {
      this.showPaymentQR = false;
      this.showReceive = false;
      this.showPayment_real = false
      this.getBalance()
      this.getEthBalance()
    },

    initSwiper() {
      new window.Swiper(".main-banner", {
        spaceBetween: 20,
        loop: true,
        pagination: {
          el: '.slide_count',
          type: 'fraction',
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        }
      });
    },

    //모든 시트를 닫음
    closePopup() {
      this.showPaymentQR = false
      this.showReceive = false
      this.isPreventScroll = false
      this.showPayment_real = false
      this.receiveTokenType = ''
      this.isShowTradeSheetBar = false
      this.isShowReceiveSheetBar = false
      this.isShowSendSheetBar = false
      this.isShowWithdrawSheetBar = false
    },

    //송금받기 시트바 열기
    openReceive(tokenType = '') {
      if (this.showReceive) {
        this.closePopup()
      } else {
        this.receiveTokenType = tokenType
        this.isShowTradeSheetBar = false
        this.isShowReceiveSheetBar = false
        this.isShowSendSheetBar = false
        this.isShowWithdrawSheetBar = false
        this.showReceive = true
        this.showPayment_real = false
      }
    },

    openPaymentShaked() {
      this.showReceive = false
      this.showPayment_real = false
    },

    //아미스 잔액을 가져옴
    getBalance() {
      let url = this.$WALLET_REAPPAY_BALANCE
      this.$get(url, 'main_getbalance' + 1, (result) => {
        this.item.tokenAmount = result.data
        this.notify('','잔액이 새로고침 되었습니다.',false, 1000)
      }, (result) => {
        this.httpError(result.data)
      })
    },

    //아미스 잔액을 가져옴
    getEthBalance(isShowMessage = true) {
      this.$get(this.$WALLET_ETH_BALANCE, 'main_getbalance' + 4, (result) => {
        this.ethAmount = result.data
        if (isShowMessage) {
          this.notify('','잔액이 새로고침 되었습니다.',false, 1000)
        }
      }, (result) => {
        this.httpError(result.data)
      })
    },

    errorHandler(error) {
      if (error.code == 1) {
        alert("접근차단")
      } else if (error.code == 2) {
        alert("위치를 반환할 수 없습니다.")
      }
      this.gps_use = false
    },

  },
  filters: {
    historyType: (value) => {
      if (value === 'PS') {
        return '결제'
      } else if (value === 'PC') {
        return '결제 취소'
      } else if (value === 'CS') {
        return '충전'
      } else if (value === 'WS') {
        return '출금'
      }
      return value
    }
  }
}
</script>

<style scoped>
.s_history {
  height: calc(100vh - 593px);
  overflow-y: scroll;
}

.preventScroll {
  position: fixed;
  width : 100%;
  height: 100%;
  overflow: hidden;
}

.main-wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row confirm-area-bottom">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <p class="confirm-subtit">핀번호가 정상적으로<br/>설정되었습니다.</p>
              <p class="confirm-desc">더 빠른 결제를 위해 생체인증을 사용하세요.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <div class="container">
              <div class="button-group">
                <button class="btn l_btn btn-black" @click="bioAuth()">생체인증</button>
                <button class="btn l_btn btn-gray" @click="goNext()">다음에</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUpPinFinish",
  mounted() {
    window.addEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
  },
  methods: {
    bioAuth() {
      window.requestBio()
    },
    bioResult(e) {
      let pin = e.detail
      if (pin.length === 6) {
        this.$router.replace({name: 'SignUpBioSuccess'})
      } else {
        this.$router.replace({name: 'SignUpBioFail'})
      }
    },
    goNext() {
      //test
      localStorage.setItem('firstPinChecked', '1')
      this.$router.replace({name: 'Main'})
    }
  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="main-bg">
    <div class="wrap">
      <div class="header-wrap">
        <div class="container">
          <div class="header-group">
            <span class="title">AMIS Lock-up</span>
            <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-arrow-left"></i></button>
          </div>
        </div>
      </div>
      <div class="content-wrap pt-50 pb-90">
        <div class="tab_wrap">
          <div class="container">
            <ul class="tablist">
              <li><a @click="$router.replace({name : 'LockUpList'})">Lock-up</a></li>
              <li class="selected"><a>내역</a></li>
            </ul>
          </div>
        </div>
        <div class="month_wrap">
          <div class="container">
            <div class="monthlist">
              <h2>{{ year }}.{{ month }}</h2>
              <button class="month_btn btn_prev"><i class="icon-arrow-left" @click="goPrev()" :disabled="!ablePrev" :class="{'disabled' : !ablePrev}"></i></button>
              <button class="month_btn btn_next" @click="goNext()" :disabled="!ableNext"><i class="icon-arrow-right" :class="{'disabled' : !ableNext}" :style="{'--stop' : stopColor}"></i></button>
            </div>
          </div>
        </div>
        <div class="contents">
          <div class="container">
            <div class="wrapper-center" v-show="lockupList.length === 0">
              <!-- TODO : 내역이 없을 경우 -->
              <div class="alram-wrap">
                <div class="list-none">
                  <i class="bg_symbol_15_op mb-20"></i>
                  <p class="list-desc">내역이 없습니다.</p>
                </div>
              </div>
            </div>

            <div class="row">

              <div class="history_wrap mt-30 mb-60" v-for="item in lockupList" :key="item.lockupIdx">
                <ul class="historylist">
                  <li>
                    <ul class="list type2">
                      <li class="tit">
                        <p class="lp_key">Lock-up 수량</p>
                        <p class="lp_value type3 pt-10">
                          <strong class="data">{{ item.amount | numberWithCommas | rewardDecimal }}<em class="point">.{{ getMetrDecimalPlace(item.amount) }}</em></strong><span class="unit">AMIS</span>
                        </p>
                      </li>
                      <li :class="{'lp_hidden' : !item.rewardAmount}">
                        <p class="lp_key">Lock-up 보상</p>
                        <p class="lp_value type3 pt-10">
                          <strong class="data">{{ item.rewardAmount | numberWithCommas | rewardDecimal }}<em class="point">.{{ item.rewardAmount ? getMetrDecimalPlace(item.rewardAmount) : '0000'}}</em></strong><span class="unit">{{ item.rewardCurrency }}</span>
                        </p>
                      </li>
                      <li>
                        <p class="lp_key">Lock-up 기간</p>
                        <div class="item-between-end">
                          <p class="lp_value type3 pt-10">
                            <strong class="data">{{ getDateDiff(getYearMonthDay(item.startAt), getYearMonthDay(item.endAt)) }}<em class="unit">일</em></strong>
                          </p>
                          <em class="lp_desc">{{ transDateFormatToYearMonthDay(item.startAt) }} ~ {{ transDateFormatToYearMonthDay(item.endAt) }}</em>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 하단 네비영역 -->
    <BottomNav @test="openSheetBar"></BottomNav>

    <trade-history-sheet-bar v-if="isShowTradeSheetBar" @close="closeTradeSheetBar"></trade-history-sheet-bar>
    <SendTokenSheetBar v-if="isShowSendSheetBar" @close="closeTradeSheetBar"></SendTokenSheetBar>
    <WithdrawalSheetBar v-if="isShowWithdrawSheetBar" @close="closeTradeSheetBar"></WithdrawalSheetBar>

  </div>
</template>

<script>

import BottomNav from "@/components/lockup/bottom/BottomNav";
import SendTokenSheetBar from "@/components/main/SendTokenSheetBar";
import WithdrawalSheetBar from "@/components/main/WithdrawalSheetBar";
import TradeHistorySheetBar from "@/components/main/TradeHistorySheetBar";
export default {
  name: "LockUpHistory",
  components : {
    BottomNav,
    SendTokenSheetBar,
    WithdrawalSheetBar,
    TradeHistorySheetBar,
  },
  data() {
    return {
      year : '',
      month : '',

      currentYear : 0,
      currentMonth : 0,

      //stopColor : '#E9E9E9',
      stopColor : '#030303FF',

      ablePrev: true,
      ableNext: true,

      lockupList : [],

      isShowTradeSheetBar : false,
      isShowSendSheetBar : false,
      isShowWithdrawSheetBar : false,
    }
  },
  mounted() {
    this.getCurrentDate()
    this.getLockUpHistory()
  },
  methods : {

    //거래내역 하단 시트바 오픈
    openSheetBar(sheetBarType = 'trade') {
      console.log('qwdqwd')
      this.isShowTradeSheetBar = false
      this.isShowSendSheetBar = false
      this.isShowWithdrawSheetBar = false
      this.isPreventScroll = false
      if (sheetBarType === 'trade') {
        this.isShowTradeSheetBar = true
      } else if (sheetBarType === 'send') {
        this.isShowSendSheetBar = true
      } else if (sheetBarType === 'withdraw') {
        this.isShowWithdrawSheetBar = true
      }
    },

    closeTradeSheetBar() {
      this.isShowTradeSheetBar = false
      this.isShowReceiveSheetBar = false
      this.isShowSendSheetBar = false
      this.isShowWithdrawSheetBar = false
      this.isPreventScroll = false
    },

    getCurrentDate() {
      let now = new Date()
      this.year = now.getFullYear()
      this.month = now.getMonth() + 1

      this.currentYear = this.year
      this.currentMonth = this.month
      console.log('getCurrentDate')
    },

    goPrev() {
      if (this.month > 1) {
        this.month = this.month - 1
      } else {
        this.year = this.year - 1
        this.month = 12
      }

      //현재월까지만 조회해야할 경우 주석풀기 TODO
      /*if (this.currentYear <= this.year) {
        if (this.currentMonth <= this.month) {
          this.stopColor = '#E9E9E9'
        } else {
          this.stopColor = '#030303FF'
        }
      }
      this.ableNext = true*/

      this.getLockUpHistory()
    },
    goNext() {
      if (this.month * 1 === 12) {
        this.month = 1
        this.year = (this.year * 1) + 1
      } else {
        this.month = (this.month * 1) + 1
      }

      //현재월까지만 조회해야할 경우 주석풀기 TODO
      /*if (this.currentYear <= this.year) {
        if (this.currentMonth <= this.month) {
          this.stopColor = '#E9E9E9'
          this.ableNext = false
        } else {
          this.stopColor = '#030303FF'
        }
      }*/

      this.getLockUpHistory()
    },

    getLockUpHistory() {
      this.$get(this.$LOCKUP_HISTORY + '?' + 'year=' + this.year + '&month=' + this.month, 'login_getPush', (result) => {
        this.lockupList = result.data.filter(history => {
          return history.state !== 'PROCESSING' ? history : undefined
        })

      }, () => {})
    },

    getYearMonthDay(dateStr) {
      return dateStr.split('T')[0]
    },

    transDateFormatToYearMonthDay(dateStr) {
      let splitStr = this.getYearMonthDay(dateStr)
      let year = splitStr.split('-')[0]
      let month = splitStr.split('-')[1]
      let day = splitStr.split('-')[2]

      return year + '.' + month + '.' + day
    },

    getDateDiff(d1, d2)  {
      const date1 = new Date(d1);
      const date2 = new Date(d2);
      const diffDate = date1.getTime() - date2.getTime();
      return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
    }

  }
}
</script>

<style scoped>
.icon-arrow-right { color: var(--stop); font-size: 13px; }

button:disabled { background-color: var(--white); color: var(--stop) !important; border: none; }
button:disabled * { color: var(--stop) !important;}

.main-bg { position: relative; width: 100%; min-height: 100%; margin: 0 auto; }

.lp_hidden p { position: relative; visibility: hidden; }
.lp_hidden::before { content: ''; width: 160px; height: 47px; position: absolute; left: 20px; bottom: 20px; background: none no-repeat center / 160px auto; }

</style>
<template>
  <div class="wrap">
    <div class="content-wrap-v3">
      <div class="container">
        <div class="row confirm-area-bottom">
          <div class="confirm-area">
            <div class="confirm-info-visual">
              <i class="bg_symbol_20 mb-30"></i>
              <p class="confirm-subtit">{{ $route.params.reason }}</p>
              <p class="confirm-desc">확인 후 다시 시도해 주세요.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="button-wrap">
            <button class="l_btn btn-black w-100" @click="$router.back()">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePasswordFail"
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>

<template>
  <div class="wrap">
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
          <span class="title">위치기반서비스 이용약관</span>
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <div class="container">
        <div class="row">
          <div class="main-inner">
            <!-- 약관 내용 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Term03"
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>